import React, { useState, useEffect } from "react";
import more_icon from "../../assets/icons/more_vert.svg";
import { useAppSelector, useAppDispatch } from "../../redux/store";
import {
  fetchRolePrivileges,
  fetchTeammates,
  updateTeammateRole,
  updateTeammateStatus,
} from "../../redux/Teammates/teammateActions";
import PaginationComponent from "../../components/Pagination/Pagination";
import { CheckCircleOutline, HighlightOffOutlined, ExpandMore, ExpandLess } from "@mui/icons-material";
import DrawerComponent from "../../components/Drawers/drawer";
import { categories } from "../../types/PermissionsCategories/PermissionsCategories";
import { TeammatesType } from "../../types/Teammates/Teammates";
import { fetchPrivileges, fetchRoles } from "../../redux/Roles/roleActions";
import ModalComponent from "../../components/Modals/modal";
import { useTeammatesPermissions } from "../../helpers/permissionsHelper/teammatesPermissions";
import { fetchAccounts } from "../../redux/Account/accountActions";

const TeammateTable = () => {
  const dispatch = useAppDispatch();
  const { teammatesPermissions } = useTeammatesPermissions();
  const { loading, teammates } = useAppSelector((state) => state.teammate);
  const { selectedAccount, profile } = useAppSelector((state) => state.auth);
  const [currentPage, setCurrentPage] = useState(1);
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);
  const { privileges, roles } = useAppSelector((state) => state.role);
  const [loader, setLoader] = useState(false);
  const [privilegeLoader, setPrivilegeLoader] = useState(false);

  const [isUpdateStatus, setIsUpdateStatus] = useState(false);

  type TeammateType = {
    first_name: string;
    last_name: string;
    email: string;
    role: string;
    role_id: string;
    is_active: boolean;
    id: string;
    privileges: number[] | string[];
    limit: string;
  };

  const [selectedTeamate, setSelectedTeammate] = useState<TeammateType>({
    first_name: "",
    is_active: false,
    id: "",
    last_name: "",
    email: "",
    role: "",
    role_id: "",
    privileges: [],
    limit: ""
  });

  const handleSelectedTeammate = (teammate: TeammatesType) => {
    setOpenDropdownId(null);
    setSelectedTeammate({
      id: teammate.id,
      is_active: teammate.is_active,
      first_name: teammate.teammateUser.first_name,
      last_name: teammate.teammateUser.last_name,
      email: teammate.teammateUser.email,
      role: teammate.rolePrivilege.role.name,
      role_id:teammate.rolePrivilege.role_id,
      privileges: teammate.rolePrivilege.privileges,
      limit: teammate.limit
    });
  };

  const [isAssignRole, setIsAssignRole] = useState(false);

  const handleSelectedRole = (name: string, id: number) => {
    setPrivilegeLoader(true)
    setSelectedTeammate({
      ...selectedTeamate,
      role_id: id.toString(),
      role: name
    })
    setIsAssignRole(false);

    // get role privileges
    dispatch(fetchRolePrivileges({
      role_id: id.toString(),
      account_id: selectedAccount?.id.toString()
    })).then((res) => {
      if(res?.type === 'fetchRolePrivileges/fulfilled'){
          setPrivilegeLoader(false)
          setSelectedTeammate({
            ...selectedTeamate,
            role_id: id.toString(),
            role: name,
            privileges: res?.payload?.data
          })
      }
      else if(res?.type === 'fetchRolePrivileges/rejected'){
        setPrivilegeLoader(false)
      }
    })
  };

  const [viewTeammate, setViewTeammate] = useState(false);
  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const capitalizeFirstLetter = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  const toggleDropdown = (id: string) => {
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };

  const getStatusBg = (val: boolean) => {
    let result;
    switch (val) {
      case false:
        result = "failed-badge";
        break;
      case true:
        result = "success-badge";
        break;
      default:
        result = "failed-badge";
        break;
    }
    return result;
  };

  const toggleTeammateStatus = (teammate: TeammatesType) => {
    setIsUpdateStatus(true);
    setOpenDropdownId(null);
    setSelectedTeammate({
      id: teammate.id,
      is_active: teammate.is_active,
      first_name: teammate.teammateUser.first_name,
      last_name: teammate.teammateUser.last_name,
      email: teammate.teammateUser.email,
      role: teammate.rolePrivilege.role.name,
      role_id: teammate.rolePrivilege.role_id,
      privileges: teammate.rolePrivilege.privileges,
      limit: teammate.limit
    });
  };


  const handleThreshold = (val: string) =>{
    setSelectedTeammate({
      ...selectedTeamate,
      limit: val
    })
  }

  const handleUpdateTeammate = () =>{
    setLoader(true);
    dispatch(updateTeammateRole({
      id: selectedTeamate?.id,
      roleId: parseInt(selectedTeamate?.role_id),
      accountId: selectedAccount?.id,
      limit: parseFloat(selectedTeamate?.limit)
    })).then((res) => {
      if(res?.type === "updateTeammateRole/fulfilled"){
        setViewTeammate(false);
        setLoader(false);
        if (selectedAccount?.type === "sub_account") {
          dispatch(
            fetchTeammates({
              account_id: selectedAccount?.id,
            }))
        }
      }
      else if(res?.type === "updateTeammateRole/rejected"){
        setLoader(false);
      }
    })
  }

  const handleTeammateStatus = () => {
    setLoader(true);
    dispatch(
      updateTeammateStatus({
        id: selectedTeamate?.id,
        is_active: selectedTeamate?.is_active ? false : true,
      })
    ).then((res) => {
      if (res?.type === "updateTeammateStatus/fulfilled") {
        setLoader(false);
        setIsUpdateStatus(false);
        if (selectedAccount?.type === "sub_account") {
          dispatch(
            fetchTeammates({
              account_id: selectedAccount?.id,
            })
          );
          dispatch(fetchAccounts());
        }
      } else if (res?.type === "updateTeammateStatus/rejected") {
        setLoader(false);
      }
    });
  };

  useEffect(() => {
    dispatch(fetchPrivileges());
  }, [dispatch]);

  useEffect(() => {
    if (selectedAccount?.type === "sub_account") {
      dispatch(fetchRoles({ id: selectedAccount?.id }));
    }
  }, [dispatch, selectedAccount]);

  useEffect(() => {
    if (selectedAccount?.type === "sub_account") {
      dispatch(
        fetchTeammates({
          account_id: selectedAccount?.id,
        })
      );
    }
  }, [dispatch, selectedAccount]);

  return (
    <>
      {/* Teammate status MODALS */}
      <ModalComponent
        title="Update Teammate's Status"
        subtitle=""
        isOpen={isUpdateStatus}
        onClose={() => setIsUpdateStatus(false)}
      >
        <div>
          <p className="mt-[10px] text-sm font-mediumn leading-5 text-[#5C715E] mb-8">
            Are you sure you want to{" "}
            {selectedTeamate?.is_active ? "disable" : "enable"}{" "}
            <span className="text-[#2B8C34] font-semibold">
              {selectedTeamate?.first_name} {selectedTeamate?.last_name}
            </span>{" "}
            on this account?
          </p>

          <div className="flex justify-end">
            <div className="flex gap-[40px] items-center cursor-pointer">
              <p
                className=" text-sm font-mediumn leading-5 text-[#5C715E]"
                onClick={() => setIsUpdateStatus(false)}
              >
                No
              </p>
              <button
                onClick={handleTeammateStatus}
                disabled={loader}
                className=" outline-none text-white rounded-lg disabled:bg-[#ABB6AC] bg-[#B92043] text-sm font-mediumn leading-5 px-[20px] py-[10px]"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </ModalComponent>

      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          <p className="text-primary font-semibold text-base leading-5">
            List of Teammates
          </p>
        </div>

        {/* tables */}
        <div className="relative">
          {loading ? (
            <div>
              <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            </div>
          ) : (
            <>
              {!teammatesPermissions.viewTeammates ? (
                <div>
                  <div className="w-full pb-[80px] mt-[80px] flex items-center justify-center">
                    <p>You don't have access to this data</p>
                  </div>
                </div>
              ) : (
                <>
                  {teammates?.length === 0 ? (
                    <div>
                      <div className="w-full pb-[80px] mt-[80px] flex items-center justify-center">
                        <p>There are no teammates added yet</p>
                      </div>
                    </div>
                  ) : (
                    <section>
                      <table className="w-full text-left relative">
                        <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                          <tr>
                            <th
                              scope="col"
                              className="px-4 py-4 font-semibold "
                            >
                              First Name
                            </th>
                            <th scope="col" className="px-4 py-4 font-semibold">
                              Last Name
                            </th>
                            <th scope="col" className="px-4 py-4 font-semibold">
                              Email
                            </th>
                            <th scope="col" className="px-4 py-4 font-semibold">
                              Role
                            </th>
                            <th scope="col" className="px-4 py-4 font-semibold">
                              Status
                            </th>
                            <th scope="col" className="px-4 py-4 font-semibold">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {teammates?.map((teammate, index) => {
                            return (
                              <tr
                                key={index}
                                className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                              >
                                <th
                                  scope="row"
                                  className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                                >
                                  {teammate.teammateUser.first_name}
                                </th>
                                <td className="py-4 px-4 text-[#5C715E]">
                                  {teammate.teammateUser.last_name}
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                  {teammate.teammateUser.email}
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                  {teammate.rolePrivilege.role.name && capitalizeFirstLetter(
                                    teammate.rolePrivilege.role.name
                                  )}
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                  <div className="flex">
                                    <div
                                      className={getStatusBg(
                                        teammate?.is_active || false
                                      )}
                                    >
                                      <p>
                                        {teammate?.is_active
                                          ? "Active"
                                          : "Inactive"}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td className="py-4 px-4 relative text-[#5C715E]">
                                  <div className="">
                                    <img
                                      src={more_icon}
                                      alt="more icon"
                                      onClick={() =>
                                        toggleDropdown(teammate?.id)
                                      }
                                    />

                                    {openDropdownId === teammate?.id && (
                                      <ul className="absolute mt-5 bg-white z-[6] rounded-lg shadow-dropdown py-2 px-2 right-10 top-6 min-w-[187px]">
                                       {teammatesPermissions.viewTeammates &&
                                        <li
                                          onClick={() => {
                                            handleSelectedTeammate(teammate);
                                            setViewTeammate(true);
                                          }}
                                          className="py-3 px-2 cursor-pointer hover:bg-[#EDF7EE]"
                                        >
                                          <p className="text-[#344335] text-sm font-medium">
                                            View Teammate
                                          </p>
                                        </li>
                                        }

                                        {teammatesPermissions.deleteTeammates &&
                                          profile.is_superuser && (
                                            <li
                                              onClick={() =>
                                                toggleTeammateStatus(teammate)
                                              }
                                              className="py-3 px-2 cursor-pointer hover:bg-[#EDF7EE]"
                                            >
                                              <p className="text-[#344335] text-sm font-medium">
                                                {teammate?.is_active
                                                  ? "Disable Teammate"
                                                  : "Enable Teammate"}
                                              </p>
                                            </li>
                                          )}
                                      </ul>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>

                      <div>
                        <PaginationComponent
                          page={currentPage}
                          onPageChange={(page) => onPageChange(1)}
                          totalItems={10}
                          pageSize={10}
                        />
                      </div>
                    </section>
                  )}
                </>
              )}
            </>
          )}

          {/* Drawer component */}
          <DrawerComponent
            title=""
            isOpen={viewTeammate}
            onClose={() => setViewTeammate(false)}
            width={`w-[926px]`}
            overflow={false}
          >
            <div>
              <div className="flex justify-between h-[90vh]">
                <div className="w-1/2 border-r border-r-[#DDE2DE] h-[90vh] pr-6">
                  <p className="font-semibold text-lg leading-[32px] text-[#344335]">
                    Edit team mate
                  </p>
                  <p className="mt-2 font-medium  text-sm leading-[18px] text-[#5C715E]">
                    Make changes to this team mate
                  </p>

                  <div className="mt-7">
                    <div className="grid grid-cols-2 gap-4 mb-5">
                      <div>
                        <p className="text-sm leading-[18px] text-[#344335] mb-2">
                          First Name
                        </p>
                        <input
                          disabled
                          value={selectedTeamate?.first_name}
                          className="w-full disabled:text-[#ABB6AC] h-[44px] disabled:bg-[#F7F7F7]  text-sm font-medium leading-5 resize-none border border-[#96A397] disabled:border-[#ABB6AC]  rounded-lg outline-none px-3 flex justify-center items-center"
                        />
                      </div>
                      <div>
                        <p className="text-sm leading-[18px] text-[#344335] mb-2">
                          Last Name
                        </p>
                        <input
                          disabled
                          value={selectedTeamate?.last_name}
                          className="w-full disabled:text-[#ABB6AC] h-[44px] disabled:bg-[#F7F7F7]  text-sm font-medium leading-5 resize-none border border-[#96A397] disabled:border-[#ABB6AC] rounded-lg outline-none px-3 flex justify-center items-center"
                        />
                      </div>
                    </div>

                    <div className="mb-5">
                      <p className="text-sm leading-[18px] text-[#344335] mb-2">
                        Email Address
                      </p>
                      <input
                        disabled
                        value={selectedTeamate?.email}
                        className="w-full disabled:text-[#ABB6AC] h-[44px] disabled:bg-[#F7F7F7]  text-sm font-medium leading-5 resize-none border border-[#96A397] disabled:border-[#ABB6AC] rounded-lg outline-none px-3 flex justify-center items-center"
                      />
                    </div>

                    <div className="mb-5">
                      <p className="text-sm leading-[18px] text-[#344335] mb-2">
                        Assign Role
                      </p>

                      <div className="relative">
                        <div
                          onClick={() => setIsAssignRole(!isAssignRole)}
                          className={`flex cursor-pointer justify-between items-center w-full rounded-lg border border-[#96A397] bg-white py-3 px-4 text-[#96A397] text-sm`}
                          style={
                            isAssignRole
                              ? { boxShadow: "0px 0px 0px 2px #D5E8D6" }
                              : {}
                          }
                        >
                          <p
                            className={`${
                              selectedTeamate?.role
                                ? "text-[#344335] text-sm"
                                : "text-[#96A397] text-sm"
                            }`}
                          >
                            {selectedTeamate?.role || "Assigned Role"}
                          </p>
                          {isAssignRole ? (
                            <ExpandLess
                              style={{ fontSize: "20px", color: "#344335" }}
                            />
                          ) : (
                            <ExpandMore
                              style={{ fontSize: "20px", color: "#344335" }}
                            />
                          )}
                        </div>
                      {/*  SELECT OPTIONS */}
                      {isAssignRole && (
                        <div
                          className="rounded-[18px] absolute py-2 bg-white w-full mt-2 px-2 max-h-[300px] overflow-scroll z-10"
                          style={{
                            boxShadow:
                              "0px 16px 32px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.25)",
                          }}
                        >
                          {roles.map((val, index) => {
                            return (
                              <div
                                key={index}
                                onClick={() =>
                                  handleSelectedRole(
                                    val?.name,
                                    val?.id
                                  )
                                }
                                className={`px-4 py-3  text-sm font-normal cursor-pointer hover:bg-[#EDF7EE] hover:rounded-[8px] ${
                                  index < roles.length - 1 ? "mb-1" : ""
                                } ${
                                  selectedTeamate?.role === val?.name
                                    ? "bg-[#EDF7EE] border-[1px] border-[#2B8C34] rounded-[8px] text-[#24752B]"
                                    : "text-[#344335] bg-white"
                                }`}
                              >
                                <p className=" leading-5 text-sm font-medium">
                                  {val?.name}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    </div>

                    <div>
                      <p className="text-sm leading-[18px] text-[#344335] mb-2">
                        Auto-Approval Threshold
                      </p>
                      <input
                         type="number"
                        value={selectedTeamate?.limit}
                        onChange={(e) => handleThreshold(e.target.value)}
                        className="w-full text-[#344335] disabled:text-[#ABB6AC] h-[44px] disabled:bg-[#F7F7F7]  text-sm font-medium leading-5 resize-none border border-[#96A397] disabled:border-[#ABB6AC] rounded-lg outline-none px-3 flex justify-center items-center"
                      />
                    </div>
                  </div>

                  <div className="flex justify-end gap-4 mt-6">
                    <div>
                    <button
                      type="submit"
                      className="bg-transparent 
                          w-full
                        rounded-lg px-5 py-3 text-[#5C715E] text-sm font-medium transition-all"
                    >
                      Cancel Changes
                    </button>
                    </div>
                    <div>
                    <button
                      type="submit"
                      onClick={handleUpdateTeammate}
                      disabled={loader}
                      className="bg-primary 
                          disabled:bg-[#ABB6AC] w-full
                        rounded-lg px-5 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
                    >
                      Save Changes
                    </button>
                    </div>
                  </div>
                </div>

                <div className="w-1/2 border-l border-l-[#DDE2DE] h-[90vh] overflow-auto remove-scroll pl-6 relative">
                  <div className="fixed w-full bg-white">
                    <p className="font-semibold text-lg leading-[32px] text-[#344335]">
                      {selectedTeamate?.role} Role Privileges
                    </p>
                    <p className="mt-2 font-medium  text-sm leading-[18px] text-[#5C715E] mb-6">
                      {selectedTeamate?.role} will have the following
                      privileges:
                    </p>
                  </div>

                  <div className="h-full overflow-auto remove-scroll mt-[20%] pr-4">
                    {/* PROFILE */}
                    <div className="mb-6">
                      <p className="text-[#344335] text-base font-semibold leading-[32px] mb-2">
                        {categories.PROFILE}
                      </p>
                      
                      {
                        privilegeLoader && (
                          <div className="animate-pulse w-full mt-2 mb-4">
                          <div className="h-4 bg-gray-200 rounded-lg  w-full mb-2"></div>
                          <div className="h-4 bg-gray-200 rounded-lg  w-full mb-2"></div>
                          <div className="h-4 bg-gray-200 rounded-lg  w-full"></div>
                        </div>
                        )
                      }
                      
                      {!privilegeLoader && privileges
                        ?.filter(
                          (privilege) =>
                            privilege?.category === categories.PROFILE
                        )
                        .map((privilege, index) => {
                          const isPrivilegeIncluded =
                            selectedTeamate?.privileges
                              ?.map((p) => p.toString())
                              .includes(privilege?.id);

                          return (
                            <React.Fragment key={index}>
                              <div className="flex justify-between items-center mb-2">
                                <p className="text-[#5C715E] text-sm font-medium">
                                  {privilege?.name || "Nill"}
                                </p>
                                <div>
                                  {isPrivilegeIncluded ? (
                                    <CheckCircleOutline
                                      style={{
                                        color: "#2B8C34",
                                        fontSize: "18px",
                                      }}
                                    />
                                  ) : (
                                    <HighlightOffOutlined
                                      style={{
                                        color: "#B92020",
                                        fontSize: "18px",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                    </div>

                    {/* PAYMENT */}
                    <div className="mb-6">
                      <p className="text-[#344335] text-base font-semibold leading-[32px] mb-2">
                        {categories.PAYMENT}
                      </p>

                      {
                        privilegeLoader && (
                          <div className="animate-pulse w-full mt-2 mb-4">
                          <div className="h-4 bg-gray-200 rounded-lg  w-full mb-2"></div>
                          <div className="h-4 bg-gray-200 rounded-lg  w-full mb-2"></div>
                          <div className="h-4 bg-gray-200 rounded-lg  w-full"></div>
                        </div>
                        )
                      }
                      
                      {!privilegeLoader && privileges
                        ?.filter(
                          (privilege) =>
                            privilege?.category === categories.PAYMENT
                        )
                        .map((privilege, index) => {
                          const isPrivilegeIncluded =
                            selectedTeamate?.privileges
                              ?.map((p) => p.toString())
                              .includes(privilege?.id);

                          return (
                            <React.Fragment key={index}>
                              <div className="flex justify-between items-center mb-2">
                                <p className="text-[#5C715E] text-sm font-medium">
                                  {privilege?.name || "Nill"}
                                </p>
                                <div>
                                  {isPrivilegeIncluded ? (
                                    <CheckCircleOutline
                                      style={{
                                        color: "#2B8C34",
                                        fontSize: "18px",
                                      }}
                                    />
                                  ) : (
                                    <HighlightOffOutlined
                                      style={{
                                        color: "#B92020",
                                        fontSize: "18px",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                    </div>

                    {/* TRANSACTION */}
                    <div className="mb-6">
                      <p className="text-[#344335] text-base font-semibold leading-[32px] mb-2">
                        {categories.TRANSACTION}
                      </p>
                      
                      {
                        privilegeLoader && (
                          <div className="animate-pulse w-full mt-2 mb-4">
                          <div className="h-4 bg-gray-200 rounded-lg  w-full mb-2"></div>
                          <div className="h-4 bg-gray-200 rounded-lg  w-full mb-2"></div>
                          <div className="h-4 bg-gray-200 rounded-lg  w-full"></div>
                        </div>
                        )
                      }
                       
                      {!privilegeLoader && privileges
                        ?.filter(
                          (privilege) =>
                            privilege?.category === categories.TRANSACTION
                        )
                        .map((privilege, index) => {
                          const isPrivilegeIncluded =
                            selectedTeamate?.privileges
                              ?.map((p) => p.toString())
                              .includes(privilege?.id);

                          return (
                            <React.Fragment key={index}>
                              <div className="flex justify-between items-center mb-2">
                                <p className="text-[#5C715E] text-sm font-medium">
                                  {privilege?.name || "Nill"}
                                </p>
                                <div>
                                  {isPrivilegeIncluded ? (
                                    <CheckCircleOutline
                                      style={{
                                        color: "#2B8C34",
                                        fontSize: "18px",
                                      }}
                                    />
                                  ) : (
                                    <HighlightOffOutlined
                                      style={{
                                        color: "#B92020",
                                        fontSize: "18px",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                    </div>

                    {/* ACCOUNT */}
                    <div className="mb-6">
                      <p className="text-[#344335] text-base font-semibold leading-[32px] mb-2">
                        {categories.ACCOUNT}
                      </p>

                      {
                        privilegeLoader && (
                          <div className="animate-pulse w-full mt-2 mb-4">
                          <div className="h-4 bg-gray-200 rounded-lg  w-full mb-2"></div>
                          <div className="h-4 bg-gray-200 rounded-lg  w-full mb-2"></div>
                          <div className="h-4 bg-gray-200 rounded-lg  w-full"></div>
                        </div>
                        )
                      }

                      {!privilegeLoader && privileges
                        ?.filter(
                          (privilege) =>
                            privilege?.category === categories.ACCOUNT
                        )
                        .map((privilege, index) => {
                          const isPrivilegeIncluded =
                            selectedTeamate?.privileges
                              ?.map((p) => p.toString())
                              .includes(privilege?.id);

                          return (
                            <React.Fragment key={index}>
                              <div className="flex justify-between items-center mb-2">
                                <p className="text-[#5C715E] text-sm font-medium">
                                  {privilege?.name || "Nill"}
                                </p>
                                <div>
                                  {isPrivilegeIncluded ? (
                                    <CheckCircleOutline
                                      style={{
                                        color: "#2B8C34",
                                        fontSize: "18px",
                                      }}
                                    />
                                  ) : (
                                    <HighlightOffOutlined
                                      style={{
                                        color: "#B92020",
                                        fontSize: "18px",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                    </div>

                    {/* RECIPIENT */}
                    <div className="mb-6">
                      <p className="text-[#344335] text-base font-semibold leading-[32px] mb-2">
                        {categories.RECIPIENT}
                      </p>

                      {
                        privilegeLoader && (
                          <div className="animate-pulse w-full mt-2 mb-4">
                          <div className="h-4 bg-gray-200 rounded-lg  w-full mb-2"></div>
                          <div className="h-4 bg-gray-200 rounded-lg  w-full mb-2"></div>
                          <div className="h-4 bg-gray-200 rounded-lg  w-full"></div>
                        </div>
                        )
                      }
                      
                      {!privilegeLoader && privileges
                        ?.filter(
                          (privilege) =>
                            privilege?.category === categories.RECIPIENT
                        )
                        .map((privilege, index) => {
                          const isPrivilegeIncluded =
                            selectedTeamate?.privileges
                              ?.map((p) => p.toString())
                              .includes(privilege?.id);

                          return (
                            <React.Fragment key={index}>
                              <div className="flex justify-between items-center mb-2">
                                <p className="text-[#5C715E] text-sm font-medium">
                                  {privilege?.name || "Nill"}
                                </p>
                                <div>
                                  {isPrivilegeIncluded ? (
                                    <CheckCircleOutline
                                      style={{
                                        color: "#2B8C34",
                                        fontSize: "18px",
                                      }}
                                    />
                                  ) : (
                                    <HighlightOffOutlined
                                      style={{
                                        color: "#B92020",
                                        fontSize: "18px",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                    </div>

                    {/* TEAMMATES */}
                    <div className="mb-6">
                      <p className="text-[#344335] text-base font-semibold leading-[32px] mb-2">
                        {categories.TEAMMATES}
                      </p>

                      {
                        privilegeLoader && (
                          <div className="animate-pulse w-full mt-2 mb-4">
                          <div className="h-4 bg-gray-200 rounded-lg  w-full mb-2"></div>
                          <div className="h-4 bg-gray-200 rounded-lg  w-full mb-2"></div>
                          <div className="h-4 bg-gray-200 rounded-lg  w-full"></div>
                        </div>
                        )
                      }

                      {!privilegeLoader && privileges
                        ?.filter(
                          (privilege) =>
                            privilege?.category === categories.TEAMMATES
                        )
                        .map((privilege, index) => {
                          const isPrivilegeIncluded =
                            selectedTeamate?.privileges
                              ?.map((p) => p.toString())
                              .includes(privilege?.id);

                          return (
                            <React.Fragment key={index}>
                              <div className="flex justify-between items-center mb-2">
                                <p className="text-[#5C715E] text-sm font-medium">
                                  {privilege?.name || "Nill"}
                                </p>
                                <div>
                                  {isPrivilegeIncluded ? (
                                    <CheckCircleOutline
                                      style={{
                                        color: "#2B8C34",
                                        fontSize: "18px",
                                      }}
                                    />
                                  ) : (
                                    <HighlightOffOutlined
                                      style={{
                                        color: "#B92020",
                                        fontSize: "18px",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                    </div>

                    {/* ROLES_PRIVILEDGES */}
                    <div className="mb-6">
                      <p className="text-[#344335] text-base font-semibold leading-[32px] mb-2">
                        {categories.ROLES_PRIVILEDGES?.replace("_", " ")}
                      </p>
                      
                      {
                        privilegeLoader && (
                          <div className="animate-pulse w-full mt-2 mb-4">
                          <div className="h-4 bg-gray-200 rounded-lg  w-full mb-2"></div>
                          <div className="h-4 bg-gray-200 rounded-lg  w-full mb-2"></div>
                          <div className="h-4 bg-gray-200 rounded-lg  w-full"></div>
                        </div>
                        )
                      }

                      {!privilegeLoader && privileges
                        ?.filter(
                          (privilege) =>
                            privilege?.category === categories.ROLES_PRIVILEDGES
                        )
                        .map((privilege, index) => {
                          const isPrivilegeIncluded =
                            selectedTeamate?.privileges
                              ?.map((p) => p.toString())
                              .includes(privilege?.id);

                          return (
                            <React.Fragment key={index}>
                              <div className="flex justify-between items-center mb-2">
                                <p className="text-[#5C715E] text-sm font-medium">
                                  {privilege?.name || "Nill"}
                                </p>
                                <div>
                                  {isPrivilegeIncluded ? (
                                    <CheckCircleOutline
                                      style={{
                                        color: "#2B8C34",
                                        fontSize: "18px",
                                      }}
                                    />
                                  ) : (
                                    <HighlightOffOutlined
                                      style={{
                                        color: "#B92020",
                                        fontSize: "18px",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                    </div>

                    {/* SETTINGS */}
                    <div className="mb-6">
                      <p className="text-[#344335] text-lg font-semibold leading-[32px] mb-2">
                        {categories.SETTINGS}
                      </p>

                      {
                        privilegeLoader && (
                          <div className="animate-pulse w-full mt-2 mb-4">
                          <div className="h-4 bg-gray-200 rounded-lg  w-full mb-2"></div>
                          <div className="h-4 bg-gray-200 rounded-lg  w-full mb-2"></div>
                          <div className="h-4 bg-gray-200 rounded-lg  w-full"></div>
                        </div>
                        )
                      }

                      {!privilegeLoader && privileges
                        ?.filter(
                          (privilege) =>
                            privilege?.category === categories.SETTINGS
                        )
                        .map((privilege, index) => {
                          const isPrivilegeIncluded =
                            selectedTeamate?.privileges
                              ?.map((p) => p.toString())
                              .includes(privilege?.id);

                          return (
                            <React.Fragment key={index}>
                              <div className="flex justify-between items-center mb-2">
                                <p className="text-[#5C715E] text-sm font-medium">
                                  {privilege?.name || "Nill"}
                                </p>
                                <div>
                                  {isPrivilegeIncluded ? (
                                    <CheckCircleOutline
                                      style={{
                                        color: "#2B8C34",
                                        fontSize: "18px",
                                      }}
                                    />
                                  ) : (
                                    <HighlightOffOutlined
                                      style={{
                                        color: "#B92020",
                                        fontSize: "18px",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DrawerComponent>
        </div>
      </div>
    </>
  );
};

export default TeammateTable;
