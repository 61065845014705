  
import { useAppSelector } from "../../../redux/store";
import { useEffect, useState } from "react";
import { categories } from "../../../types/PermissionsCategories/PermissionsCategories";


export const usePaymentPermissions = () => {
  const { userPermissions } = useAppSelector((state) => state.auth);
  const [permissions, setPermissions] = useState<any>({});

  interface PaymentPermissions {
    initiatePayment: boolean;
    approvePayment: boolean;
    viewPaymentRecords: boolean;
    downloadReceipt: boolean;
    // Add more payment permissions here if needed
}

  useEffect(() => {
    const paymentPermissions: PaymentPermissions = {
      initiatePayment: false,
      approvePayment: false,
      viewPaymentRecords: false,
      downloadReceipt: false,
    // Initialize other payment permissions here
  };

    userPermissions.forEach((permission) => {
      if (permission.category === categories.PAYMENT) {
        switch (permission.name) {
          case "Can Initiate Payment":
            paymentPermissions.initiatePayment = true;
            break;
          case "Can Approve Payment":
            paymentPermissions.approvePayment = true;
            break;
          case "Can View Payment Records":
            paymentPermissions.viewPaymentRecords = true;
            break;
          case "Can Download Payment Receipt":
            paymentPermissions.downloadReceipt = true;
            break;
          default: 
             break;
          // Add cases for other payment permissions here
        }
      }
    });

    setPermissions(paymentPermissions);
  }, [userPermissions]);

  return {
    paymentPermissions: permissions,
  };
};

  