import React, { useEffect, useState } from "react";
import logo from "../../assets/icons/Logo_Big.svg";
import warning from "../../assets/icons/warning.svg";
import { useNavigate } from "react-router-dom";
import { Formik, Form, FormikHelpers } from "formik";
import { emailAuthValidator } from "../../validationSchema/validator";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { user2faVerify, user2faEmailResendCode } from "../../redux/Auth/authActions";
import { reset2fa } from "../../redux/Auth/auth";

const EmailLoginPage = () => {
 
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const {loading, is_2fa_verified} = useAppSelector((state) => state.auth)
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [loader, setLoader] = useState(false)


    // Format minutes and seconds with leading zeros
  const formattedTime = `0${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;


  interface Values {
    code: string;
  }

  const handleSubmit = (values: Values) => {
    dispatch(user2faVerify({
        token: values.code
    }))
  };

  const handleResendCode = () =>{
    setLoader(true)
    dispatch(user2faEmailResendCode()).then((res)=>{
      if (res?.type === "user2faEmailResendCode/fulfilled") {
        setLoader(false)
      }
      else if(res?.type === "user2faEmailResendCode/rejected"){
        setLoader(false)
      }
    })
  }

  const handleBackNav = () =>{
    dispatch(reset2fa())
    navigate("/")
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (minutes === 0 && seconds === 0) {
        clearInterval(interval);
        // Handle timer expiration here
      } else if (seconds === 0) {
        setMinutes(minutes - 1);
        setSeconds(59);
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [minutes, seconds]);

  useEffect(()=>{
    if(is_2fa_verified){
      navigate("/dashboard");
    }
  },[is_2fa_verified,navigate])

  return (
    <>
      <div className="relative flex overflow-hidden">
        <div className="hidden md:block absolute -top-20 -right-24 bg-[#DBFFDF] w-[350px] h-[350px] rounded-full blur-[150px]"></div>
        <div className="w-[40%] bg-primary min-h-screen relative hidden md:block">
          <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
            <img src={logo} alt="logo" />
          </div>
        </div>
        <div className="w-full md:w-[60%] px-4 md:px-0 flex justify-center flex-col min-h-screen">
          <div className="min-w-full md:min-w-[32rem] mx-auto">
            <h6 className="text-primary font-semibold text-lg">2-Factor Authentication</h6>
            <p className="text-[#5C715E] text-sm font-medium max-w-lg">
                Keep your account extra secure with a second login step.
            </p>
            {/* form */}
            <Formik
              initialValues={{
                code: ""
              }}
              validationSchema={emailAuthValidator}
              onSubmit={(
                values: Values,
                { setSubmitting }: FormikHelpers<Values>
              ) => {
                handleSubmit(values);
              }}
            >
              {({
                handleChange,
                isSubmitting,
                handleSubmit,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <Form className="mt-8" onSubmit={handleSubmit}>
                  <div>
                    <label
                      htmlFor="code"
                      className="block mb-2 text-[#344335] text-sm font-normal"
                    >
                    Email Authentication Code
                    </label>
                    <input
                      type="text"
                      name="code"
                      placeholder="Enter the 6 digit code sent to your email"
                      className={
                        touched.code && errors.code
                          ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                          : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                      }
                      value={values.code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.code && errors.code ? (
                      <div className="flex">
                        <img src={warning} className="" alt="warning" />
                        <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          {touched.code && errors.code}
                        </small>
                      </div>
                    ) : null}
                  </div>

                  <div className="mt-2">
                    <p
                      className="text-[#5C715E] font-medium text-sm"
                    >
                      Didn't receive the mail?
                      {
                              minutes === 0 && seconds === 0 
                                    ?
                                 <span onClick={handleResendCode} className={`text-primary ${loader ? 'pointer-events-none opacity-[0.5]': ''} underline cursor-pointer font-semibold ml-1`}>Resend Code</span>
                                    :
                       (  
                        <>         
                       <span className="text-[#5C715E] font-semibold ml-1">Resend Code in</span><span className="text-primary font-semibold"> {formattedTime}</span>
                       </>
                        )
                      
                      }
                    </p>
                  </div>

                  <div className="mt-6">
                    <button
                      type="submit"
                      disabled={loading}
                      className="bg-primary 
                                disabled:bg-[#ABB6AC] 
                                rounded-lg w-full py-4 text-white font-medium hover:bg-[#24752B] transition-all"
                    >
                      Authenticate Account
                    </button>
                  </div> 
                </Form>
              )}
            </Formik>
            <div className="mt-3 text-center">
                    <p
                      className="text-secondary font-semibold text-sm"
                    >
                      Already have an account? <button onClick={handleBackNav} className='text-[#2B8C34]'>Log In</button>
                    </p>
             </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailLoginPage;
