import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  createRoles,
  fetchPrivileges,
  fetchRoles,
  updateRoles,
} from "./roleActions";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

type privilegeType = {
  createdAt: string;
  id: string;
  name: string;
  updatedAt: string;
  category: string;
};

export type privilegesType = {
  id: string;
  name: string;
  category: string;
};

export type roleType = {
  createdAt: string;
  detail: string;
  name: string;
  type: string;
  id: number;
  updatedAt: string;
  teammateCount?: number;
  privileges: privilegesType[];
};

type RoleState = {
  loading: boolean;
  privileges: privilegeType[];
  roles: roleType[];
  creationLoading: boolean;
  updateLoading: boolean;
  selectedRole: roleType;
};

const initialState: RoleState = {
  loading: false,
  privileges: [],
  roles: [],
  creationLoading: false,
  updateLoading: false,
  selectedRole: {
    createdAt: "",
    detail: "",
    name: "",
    type: "",
    id: 0,
    updatedAt: "",
    privileges: [],
  },
};

// Configure Redux Persist
const rolePersistConfig = {
  key: "role",
  storage,
  blacklist: [],
  // Add any additional configuration options as needed
};

export const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    logOut: () => {
      localStorage.setItem("token", "");
      localStorage.clear();

      return initialState;
    },
    handleSelectedRole: (state, payload) => {
      state.selectedRole = payload?.payload;
    },
  },
  extraReducers: (builder) => {
    // get all privileges
    builder.addCase(fetchPrivileges.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchPrivileges.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.privileges = action.payload.data;
      }
    );
    builder.addCase(fetchPrivileges.rejected, (state) => {
      state.loading = false;
    });
    // get all roles
    builder.addCase(fetchRoles.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchRoles.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.roles = action.payload.data;
      }
    );
    builder.addCase(fetchRoles.rejected, (state) => {
      state.loading = false;
    });

    // get all roles
    builder.addCase(createRoles.pending, (state) => {
      state.creationLoading = true;
    });
    builder.addCase(
      createRoles.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.creationLoading = false;
      }
    );
    builder.addCase(createRoles.rejected, (state) => {
      state.creationLoading = false;
    });

    // get all roles
    builder.addCase(updateRoles.pending, (state) => {
      state.updateLoading = true;
    });
    builder.addCase(
      updateRoles.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updateLoading = false;
      }
    );
    builder.addCase(updateRoles.rejected, (state) => {
      state.updateLoading = false;
    });
  },
});

const roleReducer = persistReducer(rolePersistConfig, roleSlice.reducer);
export const { logOut, handleSelectedRole } = roleSlice.actions;

export default roleReducer;
