import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl, baseApiUrl } from "../config";
import axios from "axios";
import toast from "react-hot-toast";
import {
  CreateRecipient,
  CreateRecipientTag,
  DeleteRecipientType,
  RecipientParams,
  SearchRecipient,
  ValuesType,
} from "../../types/Recipients/Recipients";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

export const getAllRecipients = createAsyncThunk(
  "getAllRecipients",
  async ({ page,per_page }: RecipientParams, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${baseApiUrl}/recipients?page=${page}&page_size=${per_page}`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": ` ${getToken()}`,
        },
      });
      if (res.status === 200) {
        let data = res.data;
        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error(
          "Cannot get all recipients at the moment.Kindly try again",
          {
            style: {
              border: "1px solid #B92043",
              backgroundColor: "#B92043",
              color: "#FFFFFF",
              fontSize: 14,
              maxWidth: 400,
            },
          }
        );
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error(
          "Cannot get all recipients at the moment.Kindly try again",
          {
            style: {
              border: "1px solid #B92043",
              backgroundColor: "#B92043",
              color: "#FFFFFF",
              fontSize: 14,
              maxWidth: 400,
            },
          }
        );
        return rejectWithValue(error);
      }
    }
  }
);

export const recipientsSearch = createAsyncThunk(
  "recipientsSearch",
  async (values: SearchRecipient, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${baseApiUrl}/recipients/search-recipients`,
        values,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": ` ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        let data = res.data;
        return data;
      }
    } catch (error: any) {
      if (error.response.status === 400 || error.response.status === 404) {
        toast.error(
          "There are no recipients matching the query",
          {
            style: {
              border: "1px solid #B92043",
              backgroundColor: "#B92043",
              color: "#FFFFFF",
              fontSize: 14,
              maxWidth: 400,
            },
            position: "top-right",
          }
        );
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error(
          "Cannot get all recipients at the moment.Kindly try again",
          {
            style: {
              border: "1px solid #B92043",
              backgroundColor: "#B92043",
              color: "#FFFFFF",
              fontSize: 14,
              maxWidth: 400,
            },
          }
        );
        return rejectWithValue(error);
      }
    }
  }
);

export const getAllTags = createAsyncThunk(
  "getAllTags",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${baseApiUrl}/recipient-tags`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": ` ${getToken()}`,
        },
      });
      if (res.status === 200) {
        let data = res.data;
        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        // toast.error("Something went wrong!");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        // toast.error("Something went wrong!");
        return rejectWithValue(error);
      }
    }
  }
);

export const getAllBanks = createAsyncThunk(
  "getAllBanks",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}auth/banks/`);
      if (res.status === 200) {
        let data = res.data;
        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        // toast.error("Something went wrong!");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        // toast.error("Something went wrong!");
        return rejectWithValue(error);
      }
    }
  }
);

export const verifyRecipientAccount = createAsyncThunk(
  "verifyRecipientAccount",
  async (values: ValuesType, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${baseApiUrl}/recipients/verify-account?account_number=${values?.account_number}&bank_code=${values?.bank_code}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": ` ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        let data = res.data;
        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 422) {
        toast(
          "Cannot verify account name. Kindly check the account number and try again",
          {
            style: {
              border: "1px solid #c9f7fc",
              backgroundColor: "#c9f7fc",
              color: "#0066af",

              fontSize: 14,
              maxWidth: 600,
            },
          }
        );

        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast(
          "Cannot verify account name. Kindly check the account number and try again",
          {
            style: {
              border: "1px solid #c9f7fc",
              backgroundColor: "#c9f7fc",
              color: "#0066af",
              fontSize: 14,
              maxWidth: 600,
            },
          }
        );
        return rejectWithValue(error);
      }
    }
  }
);

export const createRecipient = createAsyncThunk(
  "createRecipient",
  async (values: CreateRecipient, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(`${baseApiUrl}/recipients`, values, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": ` ${getToken()}`,
        },
      });
      if (res.status === 200) {
        let data = res.data;
        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Oops! Something went wrong while creating a recipient", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Oops! Something went wrong while creating a recipient", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      }
    }
  }
);

export const createRecipientTag = createAsyncThunk(
  "createRecipientTag",
  async (values: CreateRecipientTag, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(`${baseApiUrl}/recipient-tags`, values, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": ` ${getToken()}`,
        },
      });
      if (res.status === 200) {
        let data = res.data;
        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Oops! Something went wrong while creating a tag", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Oops! Something went wrong while creating a tag", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      }
    }
  }
);

export const deleteRecipient = createAsyncThunk(
  "deleteRecipient",
  async (id: DeleteRecipientType, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.delete(`${baseApiUrl}/recipients/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": ` ${getToken()}`,
        },
      });
      if (res.status === 200 || res.status === 201 || res.status === 202) {
        let data = res.data;
        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Cannot delete recipient at the moment.Kindly try again", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Cannot delete recipient at the moment.Kindly try again", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      }
    }
  }
);


export const checkReciepientExist = createAsyncThunk(
  "checkReciepientExist",
  async (credentials: {account_number: string}, { rejectWithValue, dispatch }) => {
    try { 
        const res = await axios.get(
          `${apiUrl}payment/recipient?account_number=${credentials.account_number}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Oops, Looks like the account number doesn't exist!");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Oops, Looks like the account number doesn't exist!");
        return rejectWithValue(error);
      }
    }
  }
);
