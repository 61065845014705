import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./Auth/auth";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import recipientReducer from "./Recipient/recipient";
import transactionsReducer from "./Transaction/transaction";
import accountReducer from "./Account/account";
import teammateReducer from "./Teammates/teammate";
import paymentReducer from "./Payment/payment";
import roleReducer from './Roles/role';
import dashboardReducer from "./Dashboard/dashboard";
import auditReducer from './Audit/audit'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    recipient: recipientReducer,
    transactions: transactionsReducer,
    account: accountReducer,
    teammate:teammateReducer,
    payment:paymentReducer,
    role: roleReducer,
    dashboard: dashboardReducer,
    audit: auditReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Create the persistor object
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
