import React from 'react'
import AuditLogTable from '../../tables/AuditLog';


const AuditLogPage = () => {
    return ( 
        <>
         <div className="bg-white border border-[#ECEFEC] rounded-[8px] px-4 py-4 flex justify-between items-center">
            <div>
            <p className="text-primary text-lg font-semibold leading-6">
                Audit Log
            </p>
            </div>
         </div>         
         <AuditLogTable />
        
        </>
     );
}
 
export default AuditLogPage;