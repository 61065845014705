import React from "react";
import { LineChart, ResponsiveContainer, XAxis, Tooltip, Line } from "recharts";
import { CustomTooltip } from "./ToolTip";
import moment from "moment";

type LineChartProps = {
  data: Record<string, any>[];
  height: number;
};

const LineCharts: React.FC<LineChartProps> = ({ data, height }) => {
  // Get all the unique keys in the array like the account names
  const keys = data?.reduce((acc: any, obj: any) => {
    Object.keys(obj)?.forEach((key) => {
      if (!acc.includes(key)) {
        acc.push(key);
      }
    });
    return acc;
  }, []);

  // For any acct missing, add it to the obj missing and set value to 0
  const formattedData = data?.map((obj: any) => {
    const newObj = { ...obj };
    keys?.forEach((key: string) => {
      if (!(key in newObj)) {
        newObj[key] = 0;
      }
    });

    if (newObj.date) {
      newObj.date = moment(newObj.date).format("DD MMM YYYY");
    }

    return newObj;
  });

  // GET RANDOM COLORS TO DISPLAY ON THE LINE
  const getRandomHexColor = () => {
    const letters = "0123456789ABCDEF".split("");
    let color = "#";
    letters.sort(() => Math.random() - 0.5);
    for (let i = 0; i < 6; i++) {
      color += letters[i];
    }
    return color;
  };

  // FORMAT DATE FOR THE DOMAIN PROPS ON X-AXIS
  const dates = formattedData.map((obj: any) => obj.date);

  return (
    <ResponsiveContainer width="100%" height={height}>
      <LineChart
        data={formattedData}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
      >
        <XAxis
          style={{
            fontSize: "12px",
            fontFamily: "Inter",
            fontWeight: 400,
            lineHeight: "18px",
            color: "#667085",
          }}
          axisLine={false}
          tickLine={false}
          dataKey="date"
          domain={[dates[0], dates[dates.length - 1]]}
          tickFormatter={(tick) =>
            moment(tick, "DD MMM YYYY").format("DD MMM YYYY")
          }
        />

        <Tooltip content={<CustomTooltip />} />

        {keys
          .filter((key: string) => key !== "date")
          .map((key: string, index: number) => {
            return (
              <Line
                key={index}
                type="monotone"
                dataKey={key}
                stroke={getRandomHexColor()}
                strokeWidth={2}
                dot={false}
              />
            );
          })}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineCharts;
