import { useAppSelector } from "../../../redux/store";
import { useEffect, useState } from "react";
import { categories } from "../../../types/PermissionsCategories/PermissionsCategories";

export const useTransactionPermissions = () => {
  const { userPermissions } = useAppSelector((state) => state.auth);
  const [permissions, setPermissions] = useState<any>({});

  interface TransactionPermissions {
    viewTransaction: boolean;
    downloadReceipt: boolean
    // Add more transaction permissions here if needed
}

  useEffect(() => {
    const transactionPermissions: TransactionPermissions = {
      viewTransaction: false,
      downloadReceipt: false
    // Add more transaction permissions here if needed
    };

    userPermissions.forEach((permission) => {
      if (permission.category === categories.TRANSACTION) {
        switch (permission.name) {
          case "Can View Transaction Records":
            transactionPermissions.viewTransaction = true;
            break;
          case "Can Download Transaction Receipt":
            transactionPermissions.downloadReceipt = true;
            break;
          default: 
             break;
          // Add cases for other transaction permissions here
        }
      }
    });

    setPermissions(transactionPermissions);
  }, [userPermissions]);

  return {
    transactionPermissions: permissions,
  };
};

  