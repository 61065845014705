import {
  ValueType,
  NameType,
} from "recharts/types/component/DefaultTooltipContent";
import { TooltipProps } from "recharts";

export const CustomTooltip = ({
  label,
  payload,
  active,
}: TooltipProps<ValueType, NameType>) => {
  if (active) {
    return (
      <div className="bg-[#161D17] rounded-lg p-3">
        <p className="text-xs font-semibold leading-4 text-[#FFFFFF]">
          {label}.
        </p>

        {payload?.map((item, index) => {
          return (
            <div
              key={index}
              className="flex items-center gap-1 mt-2 justify-between w-[195px]"
            >
              <div className="flex items-center gap-1">
                <div
                  className="w-2 h-2 rounded-full"
                  style={{ backgroundColor: `${item?.color}` }}
                ></div>
                <p className="text-xs font-semibold leading-4 text-[#FFFFFF] capitalize">
                  {item?.dataKey}
                </p>
              </div>
              <div>
                <p className="text-xs font-semibold leading-4 text-[#FFFFFF]">
                  ₦{item?.value?.toLocaleString()}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    );
  } else {
    return null;
  }
};
