import React, { useEffect, useState } from "react";
import DrawerComponent from "../../components/Drawers/drawer";
// import toast from "react-hot-toast";
import {
  Transaction,
  TransactionType,
} from "../../types/Transactions/Transactions";
import { getAllRecentTransactions } from "../../redux/Transaction/transactionActions";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import moment from "moment";

const DashboardTable = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { recentTransactions, recentTransactionsLoading } = useAppSelector(
    (state) => state.transactions
  );

  const [transaction, setTransaction] = useState<Transaction | null>(null);
  const [tab, setTab] = useState("payment_details");
  // const showPaymentInfo = () => {
  //   setIsOpen(!isOpen);
  // };

  const handleViewPayment = (transaction: Transaction) => {
    setTransaction(transaction);
    setIsOpen(true);
  };

  const formatNumber = (numberToFormat:string) =>{
    let res = Number(numberToFormat).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  }

  const handleClosePayment = () => {
    setIsOpen(false);
    setTab("payment_details");
    setTransaction(null);
  };

  useEffect(() => {
    dispatch(getAllRecentTransactions() as any);
  }, [dispatch]);

  return (
    <>
      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          <p className="text-primary font-semibold text-base leading-5">
            Recent Transactions
          </p>
          <p className="text-[#7C8D7E] text-sm font-medium mt-1">
            This shows a summary of the last 5 transactions
          </p>
        </div>

        {/* tables */}
        <div className="relative">
          {/* tables */}
          <div className="relative">
            {recentTransactionsLoading ? (
              <div>
                <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
                  <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                  <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                  <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                  <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
                </div>
              </div>
            ) : (
              <>
                {recentTransactions?.length === 0 ? (
                  <div>
                    <div className="w-full pb-[100px] mt-[100px] flex items-center justify-center">
                      <p>No transaction data available at the moment</p>
                    </div>
                  </div>
                ) : (
                  <section>
                    <table className="w-full text-left">
                      <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                        <tr>
                          <th scope="col" className="px-4 py-4 font-semibold ">
                            Date
                          </th>
                          <th scope="col" className="px-4 py-4 font-semibold">
                            Sender/Receiver
                          </th>
                          <th scope="col" className="px-4 py-4 font-semibold">
                            Payment Memo
                          </th>
                          <th scope="col" className="px-4 py-4 font-semibold">
                            Transaction Type
                          </th>
                          <th scope="col" className="px-4 py-4 font-semibold">
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {recentTransactions?.map((transaction, index) => {
                          return (
                            <tr
                              key={index}
                              onClick={() => handleViewPayment(transaction)}
                              className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                            >
                              <th
                                scope="row"
                                className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                              >
                                {moment(transaction?.created_at).format("lll")}
                              </th>
                              <td className="py-4 px-4 text-[#5C715E]">
                                {transaction?.counter_party || "N/A"}
                              </td>
                              <td className="py-4 px-4 text-[#5C715E]">
                                {transaction?.narration || "N/A"}
                              </td>
                              <td className="py-4 px-4 text-[#5C715E]">
                                <div className="flex capitalize">
                                  <div
                                    className={`${
                                      transaction?.transaction_type ===
                                      TransactionType.CREDIT
                                        ? "success-badge"
                                        : transaction?.transaction_type ===
                                          TransactionType.DEBIT
                                        ? "failed-badge"
                                        : transaction?.transaction_type ===
                                          TransactionType.REFUND
                                        ? "success-badge"
                                        : null
                                    }`}
                                  >
                                    <p>
                                      {transaction?.transaction_type || "N/A"}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td className="py-4 px-4 text-[#5C715E]">
                                ₦{transaction ? formatNumber(transaction?.amount): 0}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </section>
                )}
              </>
            )}
          </div>
        </div>

        {/* Drawer component */}
        <DrawerComponent title="" isOpen={isOpen} onClose={handleClosePayment}>
          <div className="mt-8 flex flex-col justify-center items-center">
            <div className="w-[48px] h-[48px] rounded-full bg-[#DBFFDF] text-[#2B8C34] flex justify-center font-medium items-center text-sm">
              {transaction?.sender?.charAt(0) || "N/A"}
            </div>
            <h4 className="text-[#344335] font-semibold text-2xl mt-2">
              ₦{transaction ? formatNumber(transaction?.amount): 0}
            </h4>
            <p className="text-[#5C715E] text-sm font-medium mt-1">
              {transaction?.sender || "N/A"}
            </p>
          </div>

          <ul className="flex justify-around mt-8 items-center gap-5">
            <li
              className={`flex gap-1 items-center ${
                tab === "payment_details"
                  ? "border-b-2 border-primary text-primary"
                  : "text-[#96A397]"
              }  pb-1 cursor-pointer`}
              onClick={() => setTab("payment_details")}
            >
              <p className=" text-sm font-semibold">Payment Details</p>
            </li>
            <li
              className={`flex gap-2 items-center ${
                tab === "parties_involved"
                  ? "border-b-2 border-primary text-primary"
                  : "text-[#96A397]"
              }  pb-1 cursor-pointer`}
              onClick={() => setTab("parties_involved")}
            >
              <p className=" text-sm font-semibold">Parties Involved</p>
            </li>
          </ul>

          {tab === "payment_details" ? (
            <section>
              <div className="mt-10">
                <div className="flex justify-between items-center">
                  <p className="text-[#5C715E] text-xs">Amount</p>
                  <p className="text-[#5C715E] text-xs">
                    ₦
                    {transaction ? formatNumber(transaction?.amount): 0}
                  </p>
                </div>
              </div>

              <div className="mt-5">
                <div className="flex justify-between items-center">
                  <p className="text-[#5C715E] text-xs">Date</p>
                  <p className="text-[#5C715E] text-xs">
                    {moment(transaction?.created_at).format("ll")} |
                    {" " + moment(transaction?.created_at).format("LT")}
                  </p>
                </div>
              </div>

              <div className="mt-5">
                <div className="flex justify-between items-center">
                  <p className="text-[#5C715E] text-xs">Sender/Receiver</p>
                  <p className="text-[#5C715E] text-xs">
                    {" "}
                    {transaction?.counter_party || "N/A"}
                  </p>
                </div>
              </div>

              <div className="mt-5">
                <div className="flex justify-between items-center">
                  <p className="text-[#5C715E] text-xs">Source Account</p>
                  <p className="text-[#5C715E] text-xs">
                    {transaction?.c2c_account?.account_name || "N/A"}
                  </p>
                </div>
              </div>

              <div className="mt-5">
                <div className="flex justify-between items-center">
                  <p className="text-[#5C715E] text-xs">Transaction Type</p>
                  <div
                    className={`capitalize ${
                      transaction?.transaction_type === TransactionType.CREDIT
                        ? "flex bg-[#DBFFDF] text-[#2B8C34] rounded-[4px] px-2 py-1 text-xs font-medium"
                        : transaction?.transaction_type ===
                          TransactionType.DEBIT
                        ? "flex bg-[#fdded4] text-[#b92043] rounded-[4px] px-2 py-1 text-xs font-medium"
                        : transaction?.transaction_type ===
                          TransactionType.REFUND
                        ? "flex bg-[#DBFFDF] text-[#2B8C34] rounded-[4px] px-2 py-1 text-xs font-medium"
                        : null
                    }`}
                  >
                    {transaction?.transaction_type || "N/A"}
                  </div>
                </div>
              </div>

              <div className="mt-5">
                <div className="flex justify-between items-center">
                  <p className="text-[#5C715E] text-xs">
                    Transaction Description
                  </p>
                  <p className="text-[#5C715E] text-xs">
                    {" "}
                    {transaction?.trans_type || "N/A"}
                  </p>
                </div>
              </div>

              <div className="mt-5">
                <div className="flex justify-between items-center">
                  <p className="text-[#5C715E] text-xs">Bank Name</p>
                  <p className="text-[#5C715E] text-xs">
                    {" "}
                    {transaction?.payment?.recipient?.bank?.name || "N/A"}
                  </p>
                </div>
              </div>

              <div className="mt-5">
                <div className="flex justify-between items-center">
                  <p className="text-[#5C715E] text-xs">Account Number</p>
                  <p className="text-[#5C715E] text-xs">
                    {" "}
                    {transaction?.payment?.recipient?.account_number || "N/A"}
                  </p>
                </div>
              </div>

              <div className="mt-5">
                <div className="flex justify-between items-center">
                  <p className="text-[#5C715E] text-xs">Payment ID</p>
                  <p className="text-[#5C715E] text-xs">
                    {" "}
                    {transaction?.payment?.id || "N/A"}
                  </p>
                </div>
              </div>

              <div className="mt-5">
                <div className="flex justify-between items-center">
                  <p className="text-[#5C715E] text-xs">Transaction Memo</p>
                  <p className="text-[#5C715E] text-xs">
                    {" "}
                    {transaction?.narration || "N/A"}
                  </p>
                </div>
              </div>
            </section>
          ) : (
            <section>
              <div className="mt-6">
                <div className="flex flex-col gap-[7px]">
                  <p className="text-xs leading-4 text-[#5C715E]">
                    Payment Initiated by:
                  </p>
                  <div className="flex gap-1 items-center">
                    <div className="w-[24px] h-[24px] capitalize rounded-full bg-[#DBFFDF] text-[#2B8C34] flex justify-center font-medium items-center text-[10px]">
                      {transaction?.payment?.initiated_by?.charAt(0) || "N/A"}
                    </div>
                    <p className="text-sm font-medium leading-[18px] text-[#5C715E]">
                      {transaction?.payment?.initiated_by || "N/A"}
                    </p>
                  </div>
                  <p className="text-sm font-medium leading-[18px] text-[#5C715E]">
                    {moment(transaction?.payment?.created_at).format("ll")} |{" "}
                    {moment(transaction?.payment?.created_at).format("LT")}
                  </p>
                </div>
              </div>

              <div className="mt-6">
                <div className="flex flex-col gap-[7px]">
                  <p className="text-xs leading-4 text-[#5C715E]">
                    Payment Approved by:
                  </p>
                  <div className="flex gap-1 items-center">
                    <div className="w-[24px] h-[24px] rounded-full capitalize bg-[#DBFFDF] text-[#2B8C34] flex justify-center font-medium items-center text-[10px]">
                      {transaction?.payment?.approved_by?.charAt(0) || "N/A"}
                    </div>
                    <p className="text-sm font-medium leading-[18px] text-[#5C715E]">
                      {transaction?.payment?.approved_by || "N/A"}
                    </p>
                  </div>
                  <p className="text-sm font-medium leading-[18px] text-[#5C715E]">
                    {moment(transaction?.payment?.updated).format("ll")} |{" "}
                    {moment(transaction?.payment?.updated).format("LT")}
                  </p>
                </div>
              </div>
            </section>
          )}
        </DrawerComponent>
      </div>
    </>
  );
};

export default DashboardTable;
