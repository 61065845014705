import { useEffect } from "react";
import Header from "../../components/Header";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { fetchRoles } from "../../redux/Roles/roleActions";

import GoBack from "../../components/GoBack";
import RolesTeammatesTable from "../../tables/Roles/RolesTeammatesTable";

const RolesTeammates = () => {
  const dispatch = useAppDispatch();
  const { selectedAccount } = useAppSelector((state) => state.auth);

  useEffect(() => {
    dispatch(fetchRoles({ id: selectedAccount?.id }));
  }, [dispatch, selectedAccount]);

  return (
    <>
      <div className="mb-5">
        <GoBack />
      </div>

      <Header text={"Teammates"}></Header>

      {/* tables */}
      <RolesTeammatesTable />
    </>
  );
};

export default RolesTeammates;
