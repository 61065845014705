import {PayloadAction, createSlice} from '@reduxjs/toolkit'
import { fetchAuditLog, filterAuditLog } from './auditActions'
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';


type auditAccountType = {
    created_at: string;
    account_name: string;
    account_id: string;
}

export type auditRoleType = {
    id: string;
    name: string;
    details: string;
  };

export type auditLogsType = {
    id: number;
    user: string;
    email: string;
    detail: string;
    created_at: string;
    timestamp: string;
    path: string;
    activity: string;
    ip_address: string;
    browser: string;
    is_pc: boolean;
    account: auditAccountType,
    role: auditRoleType
}


type AuditState = {
    loading: boolean,
    total: number,
    auditlogs: auditLogsType[]
}

const initialState: AuditState = { 
    loading: false,
    total: 0,
    auditlogs: []
} 

// Configure Redux Persist
const auditPersistConfig = {
    key: 'audit',
    storage,
    blacklist: []
    // Add any additional configuration options as needed
  };

  export const auditSlice = createSlice({
    name: 'audit',
    initialState,
    reducers:{
        logOut: () =>{
            localStorage.setItem("token", "");
            localStorage.clear();

            return initialState
        },
    },
    extraReducers: (builder) =>{

        // get all audit logs for an account
        builder.addCase(fetchAuditLog.pending, (state) =>{
            state.loading = true
        })
        builder.addCase(fetchAuditLog.fulfilled, (state, action: PayloadAction<any>) =>{
            state.loading = false 
            state.auditlogs = action.payload.data
            state.total = action.payload.total_pages;

        })
        builder.addCase(fetchAuditLog.rejected, (state) =>{
            state.loading = false
        })
        // filter audit logs for an account
        builder.addCase(filterAuditLog.pending, (state) =>{
            state.loading = true
        })
        builder.addCase(filterAuditLog.fulfilled, (state, action: PayloadAction<any>) =>{
            state.loading = false 
            state.auditlogs = action.payload.data
            state.total = action.payload.total_pages;

        })
        builder.addCase(filterAuditLog.rejected, (state) =>{
            state.loading = false
        })
      
        
    },
})

const auditReducer = persistReducer(auditPersistConfig, auditSlice.reducer);
export const {logOut} = auditSlice.actions

export default auditReducer