import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  getUserAccountPrivilege,
  joinTeam,
  user2faEmailResendCode,
  user2faVerify,
  userChangePassword,
  userEmailSetup,
  userEmailSetupResendCode,
  userEmailVerify,
  userForgotPassword,
  userGoogleAuthSetup,
  userGoogleAuthVerify,
  userLogin,
  userRefreshToken,
  userResetPassword,
} from "./authActions";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { jwtDecode } from "jwt-decode";

type userPermissionType = {
  createdAt: string;
  id: string;
  name: string;
  updatedAt: string;
  category: string;
};

type AuthState = {
  isAuth: boolean;
  loading: boolean;
  is_2fa_verified: boolean;
  token: string;
  refreshToken: string;
  is_2fa_enabled: boolean;
  otp_method: string;
  profile: {
    token_type: string;
    exp: string;
    user_id: string;
    email: string;
    first_name: string;
    last_name: string;
    is_admin: boolean;
    is_superuser: boolean;
  };
  selectedAccount: {
    id: number;
    name: string;
    type: string;
    account_id: string;
  };
  userPermissions: userPermissionType[];
  qr_code: string;
  manual_code: string;
};

interface MyToken {
  token_type: string;
  exp: string;
  user_id: string;
  email: string;
  first_name: string;
  last_name: string;
  is_admin: boolean;
  is_superuser: boolean;
  is_2fa_enabled: boolean;
  otp_method: string;
}

const initialState: AuthState = {
  isAuth: false,
  loading: false,
  token: "",
  refreshToken: "",
  is_2fa_verified: false,
  is_2fa_enabled: false,
  otp_method: "",
  profile: {
    token_type: "",
    exp: "",
    user_id: "",
    email: "",
    first_name: "",
    last_name: "",
    is_admin: false,
    is_superuser: false,
  },
  selectedAccount: {
    id: 0,
    name: "",
    type: "",
    account_id: "",
  },
  userPermissions: [],
  qr_code: "",
  manual_code: ""
};

// Configure Redux Persist
const authPersistConfig = {
  key: "auth",
  storage,
  blacklist: ["loading"],
  // Add any additional configuration options as needed
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logOut: () => {
      localStorage.setItem("token", "");
      localStorage.clear();

      return initialState;
    },
    logIn: (state, action: PayloadAction<string>) => {
      state.isAuth = true;
    },
    reset2fa: () =>{
      localStorage.setItem("token", "");
      localStorage.clear();
      
      return initialState;
    },
    handleSaveAccount: (state, payload) => {
      state.selectedAccount = payload?.payload;
    },
  },
  extraReducers: (builder) => {
    //refresh token
    builder.addCase(userRefreshToken.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      userRefreshToken.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.isAuth = true;
        localStorage.setItem("token", action.payload.access);
        state.token = action.payload.access;
        let decodedUserInfo = jwtDecode<MyToken>(action.payload.access);
        state.profile = decodedUserInfo;
        state.is_2fa_enabled = decodedUserInfo.is_2fa_enabled
        state.otp_method = decodedUserInfo.otp_method

      }
    );
    builder.addCase(userRefreshToken.rejected, (state) => {
      state.loading = false;
      state.isAuth = false;
    });

    // login user
    builder.addCase(userLogin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      userLogin.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.isAuth = true;
        localStorage.setItem("token", action.payload.access);
        localStorage.setItem("refreshToken", action.payload.refresh);
        state.token = action.payload.access;
        state.refreshToken = action.payload.refresh;
        let decodedUserInfo = jwtDecode<MyToken>(action.payload.access);
        state.profile = decodedUserInfo;
        state.is_2fa_enabled = decodedUserInfo.is_2fa_enabled
        state.otp_method = decodedUserInfo.otp_method
      }
    );
    builder.addCase(userLogin.rejected, (state) => {
      state.loading = false;
    });

    // 2fa login verify
    builder.addCase(user2faVerify.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(user2faVerify.fulfilled, (state) => {
      state.loading = false;
      state.is_2fa_verified = true
    });
    builder.addCase(user2faVerify.rejected, (state) => {
      state.loading = false;
    });
  
    // 2fa email resend code
    builder.addCase(user2faEmailResendCode.pending, (state) =>{

    });
    builder.addCase(user2faEmailResendCode.fulfilled, (state) =>{

    });
    builder.addCase(user2faEmailResendCode.rejected, (state) =>{

    });

    // forgot password
    builder.addCase(userForgotPassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(userForgotPassword.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(userForgotPassword.rejected, (state) => {
      state.loading = false;
    });
    // reset password
    builder.addCase(userResetPassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(userResetPassword.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(userResetPassword.rejected, (state) => {
      state.loading = false;
    });
    // change password password
    builder.addCase(userChangePassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(userChangePassword.fulfilled, (state) => {
      state.loading = false;
      state.isAuth = false;
      localStorage.setItem("token", "");
    });
    builder.addCase(userChangePassword.rejected, (state) => {
      state.loading = false;
    });
    // Join Team
    builder.addCase(joinTeam.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(joinTeam.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(joinTeam.rejected, (state) => {
      state.loading = false;
    });
    // get user account privileges
    builder.addCase(getUserAccountPrivilege.pending, (state) => {});
    builder.addCase(
      getUserAccountPrivilege.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.userPermissions = action.payload.data;
      }
    );
    builder.addCase(getUserAccountPrivilege.rejected, (state) => {});
    // google auth setup
    builder.addCase(userGoogleAuthSetup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      userGoogleAuthSetup.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.qr_code = action.payload.data.qr_code;
        state.manual_code = action.payload.data.manual_code;
        state.loading = false;
      }
    );
    builder.addCase(userGoogleAuthSetup.rejected, (state) => {
      state.loading = false
    });
    // google auth verify
    builder.addCase(userGoogleAuthVerify.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      userGoogleAuthVerify.fulfilled,
      (state, action: PayloadAction<any>) => {
          state.is_2fa_enabled = true
          state.otp_method = "auth"
          state.loading = false;
      }
    );
    builder.addCase(userGoogleAuthVerify.rejected, (state) => {
      state.loading = false
    });

    // email setup
    builder.addCase(userEmailSetup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      userEmailSetup.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;

      }
    );
    builder.addCase(userEmailSetup.rejected, (state) => {
      state.loading = false
    });

     // 2fa email resend code
     builder.addCase(userEmailSetupResendCode.pending, (state) =>{

     });
     builder.addCase(userEmailSetupResendCode.fulfilled, (state) =>{
 
     });
     builder.addCase(userEmailSetupResendCode.rejected, (state) =>{
 
     });

    // email verify
    builder.addCase(userEmailVerify.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      userEmailVerify.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.is_2fa_enabled = true
        state.otp_method = "email"
        state.loading = false
      }
    );
    builder.addCase(userEmailVerify.rejected, (state) => {
      state.loading = false
    })


  },
  
});

const authReducer = persistReducer(authPersistConfig, authSlice.reducer);
export const { logOut, logIn, handleSaveAccount,reset2fa } = authSlice.actions;

export default authReducer;
