import { useAppSelector } from "../../../redux/store";
import { useEffect, useState } from "react";

export const useRecipientPermissions = () => {
  const { userPermissions } = useAppSelector((state) => state.auth);
  const [permissions, setPermissions] = useState<any>({});

  interface RecipientPermissions {
    createRecipient: boolean;
    createTag: boolean;
    deleteRecipient: boolean;
    editRecipient: boolean;
    viewRecipient: boolean;
    // Add more overview permissions here if needed
  }

  useEffect(() => {
    const newPermissions: RecipientPermissions = {
      createRecipient: false,
      createTag: false,
      deleteRecipient: false,
      editRecipient: false,
      viewRecipient: false,
      // Add more overview permissions here if needed
    };

    userPermissions.forEach((permission) => {
      if (permission.category === "Recipient") {
        switch (permission.name) {
          case "Can Create Recipient":
            newPermissions.createRecipient = true;
            break;
          case "Can Create Tag":
            newPermissions.createTag = true;
            break;
          case "Can Delete Recipient":
            newPermissions.deleteRecipient = true;
            break;
          case "Can Edit Recipient":
            newPermissions.editRecipient = true;
            break;
          case "Can View Recipients":
            newPermissions.viewRecipient = true;
            break;
          // Add cases for other overview permissions here
        }
      }
    });

    setPermissions(newPermissions);
  }, [userPermissions]);

  return {
    recipientPermissions: permissions,
  };
};
