import React,{useEffect, useState} from "react";
import logo from "../../assets/icons/Logo_Big.svg";
import warning from "../../assets/icons/warning.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, FormikHelpers } from "formik";
import { resetPasswordValidator } from "../../validationSchema/validator";
import eye_off from "../../assets/icons/eye-off.svg";
import eye from "../../assets/icons/eye.svg";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { userResetPassword, verifyResetToken } from "../../redux/Auth/authActions";


const ResetPasswordPage = () => {
  interface Values {
    password: string;
    confirm_password: string;
  }


  const search = useLocation().search;
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordConfirmShown, setPasswordConfirmShown] = useState(false)
  const dispatch = useAppDispatch()
  const {loading} = useAppSelector((state) => state.auth)
  const navigate = useNavigate();


  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const toggleConfirmPasswordVisiblity = () => {
    setPasswordConfirmShown(passwordConfirmShown ? false : true)
  }

  const [passwordErrors, setErrors] = useState({
    minValueValidation: false,
    numberValidation: false,
    capitalLetterValidation: false,
    specialCharacterValidation: false,
  });

  const validatePassword = (password:string) => {
    setErrors({
      minValueValidation: password.length >= 8,
      numberValidation: /\d/.test(password),
      capitalLetterValidation: /[A-Z]/.test(password),
      specialCharacterValidation: /[^A-Za-z0-9]/.test(password),
    });
  };


  const handleSubmit = (values: Values) => {
    const code:string|null = new URLSearchParams(search).get("code");
    dispatch(userResetPassword({
      code: code,
      password: values.password
    }))

    setTimeout(()=>{
      navigate("/");
    },3000)
  };

  // check to verify token
  useEffect(()=>{
    const code:string|null = new URLSearchParams(search).get("code");
    dispatch(verifyResetToken({
      code: code
    }))
  },[search, dispatch])

  return (
    <>
      <div className="relative flex overflow-hidden">
        <div className="hidden md:block absolute -top-20 -right-24 bg-[#DBFFDF] w-[350px] h-[350px] rounded-full blur-[150px]"></div>
        <div className="w-[40%] bg-primary min-h-screen relative hidden md:block">
          <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
            <img src={logo} alt="logo" />
          </div>
        </div>
        <div className="w-full md:w-[60%] px-4 md:px-0 flex justify-center flex-col min-h-screen">
          <div className="min-w-full md:min-w-[32rem] mx-auto">
            <h6 className="text-primary font-semibold text-lg">
              Reset Password?
            </h6>
            <p className="text-[#5C715E] text-sm font-medium max-w-lg">
            Enter the new password you’d like for your account.
            </p>
            {/* form */}
            <Formik
              initialValues={{
                password: "",
                confirm_password: ""
              }}
              validationSchema={resetPasswordValidator}
              onSubmit={(
                values: Values,
                { setSubmitting }: FormikHelpers<Values>
              ) => {
                handleSubmit(values);
              }}
            >
              {({
                handleChange,
                isSubmitting,
                handleSubmit,
                handleBlur,
                values,
                touched,
                errors,
                dirty,
                isValid
              }) => (
                <Form className="mt-8" onSubmit={handleSubmit}>
                
                <div className="">
                    <label
                      htmlFor="password"
                      className="block mb-2 text-secondary text-sm font-normal"
                    >
                      New Password
                    </label>
                    <div className="relative">
                      <input
                        type={passwordShown ? "text" : "password"}
                        name="password"
                        placeholder="Enter your New Password"
                        className={
                          touched.password && errors.password
                            ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335]text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                            : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                        }
                        value={values.password}
                        onChange={(e) => {
                          handleChange(e);
                          validatePassword(e.currentTarget.value);
                          }}
                        onBlur={handleBlur}
                      />
                      <img
                        onClick={togglePasswordVisiblity}
                        src={passwordShown ? eye_off : eye}
                        alt="eye"
                        className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                      />
                    </div>
                    {touched.password && errors.password ? (
                      <div className="flex">
                        <img src={warning} className="" alt="warning" />
                        <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          {touched.password && errors.password}
                        </small>
                      </div>
                    ) : null}
                  </div>
                  <div className="mt-3">
                    <h6 className='text-[#5C715E] text-sm font-semibold'>Your Password Must Contain At Least:</h6>
                    <ul className='mt-1'>

                    {Object.entries(passwordErrors).map(([key, value]) => {
                                return (
                                  <li className={`${value ? 'text-[#ABB6AC] line-through' : 'text-[#5C715E]'} text-sm font-medium`}>
                                    {key === 'minValueValidation' && 'Eight Characters'}
                                    {key === 'numberValidation' && 'One Numeric Character'}
                                    {key === 'capitalLetterValidation' && 'One Uppercase Character'}
                                    {key === 'specialCharacterValidation' && 'One Special Character'}
                                 </li>
                             )
                          })}

                    </ul>

                  </div>

                  <div className="mt-5">
                    <label
                      htmlFor="password"
                      className="block mb-2 text-secondary text-sm font-normal"
                    >
                      Confirm Password
                    </label>
                    <div className="relative">
                      <input
                        type={passwordConfirmShown ? "text" : "password"}
                        name="confirm_password"
                        placeholder="Confirm your New Password"
                        className={
                          touched.confirm_password && errors.confirm_password
                            ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335]text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                            : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                        }
                        value={values.confirm_password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <img
                        onClick={toggleConfirmPasswordVisiblity}
                        src={passwordConfirmShown ? eye_off : eye}
                        alt="eye"
                        className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                      />
                    </div>
                    {touched.confirm_password && errors.confirm_password ? (
                      <div className="flex">
                        <img src={warning} className="" alt="warning" />
                        <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          {touched.confirm_password && errors.confirm_password}
                        </small>
                      </div>
                    ) : null}
                  </div>

                  <div className="mt-8">
                    <button
                    disabled={!(isValid && dirty) || loading || !passwordErrors.minValueValidation || !passwordErrors.numberValidation || !passwordErrors.capitalLetterValidation || !passwordErrors.specialCharacterValidation}
                      type="submit"
                      className="bg-primary 
                            disabled:bg-[#ABB6AC] 
                            rounded-lg w-full py-4 text-white font-medium hover:bg-[#24752B] transition-all"
                    >
                      Submit
                    </button>
                  </div>

                  <div className="text-center mt-3">
                    <p className="text-[#344335] text-sm font-semibold">
                      Remember password?{" "}
                      <Link
                        to="/"
                        className="text-primary font-semibold text-sm"
                      >
                        Back to Login
                      </Link>{" "}
                    </p>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordPage;
