import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../config";
import axios from "axios";
import toast from "react-hot-toast";
import moment from 'moment'

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

type fetchAuditLogType = {
  account_id: string;
  page: number;
  activity?: string;
  start_date?: string;
  end_date?: string;
  user_id?: string;
};

export const fetchAuditLog = createAsyncThunk(
  "fetchAuditLog",
  async (creds: fetchAuditLogType, { rejectWithValue, dispatch }) => {
      try {
        const res = await axios.get(
          `${apiUrl}log?account_id=${creds.account_id}&page=${creds.page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          toast.error("Oops, an error occured fetching audit logs!");
          return rejectWithValue(error);
        } else if (error.response.status === 401) {
          dispatch({ type: "auth/logOut" });
          return rejectWithValue(error);
        } else {
          toast.error("Oops, an error occured fetching audit logs!");
          return rejectWithValue(error);
        }
      }
  }
);


export const filterAuditLog = createAsyncThunk(
  "filterAuditLog",
  async (creds: fetchAuditLogType, { rejectWithValue, dispatch }) => {
      try {
        
        // check for activity, user_id, startDate and endDate if empty
        if(creds.activity === "" && creds.user_id === "" && creds.start_date === "" && creds.end_date === ""){
          const res = await axios.get(
            `${apiUrl}log?account_id=${creds.account_id}&page=${creds.page}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
              },
            }
          );
          if (res.status === 200) {
            return res.data;
          }
        }

        // check for user_id and activity
        if(creds.user_id !== "" && creds.activity !== "" && creds.start_date === "" && creds.end_date === ""){
          const res = await axios.get(
            `${apiUrl}log?account_id=${creds.account_id}&page=${creds.page}&activity=${creds.activity}&user_id=${creds.user_id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
              },
            }
          );
          if (res.status === 200) {
            return res.data;
          }
        }

        // check for user_id
        if(creds.user_id !== "" && creds.activity === "" && creds.start_date !== "" && creds.end_date !== ""){
          const res = await axios.get(
            `${apiUrl}log?account_id=${creds.account_id}&page=${creds.page}&start_date=${moment(creds.start_date).format()}&end_date=${moment(creds.end_date).format()}&user_id=${creds.user_id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
              },
            }
          );
          if (res.status === 200) {
            return res.data;
          }
        }

        // check for activity
        if(creds.user_id === "" && creds.activity !== "" && creds.start_date !== "" && creds.end_date !== ""){
          const res = await axios.get(
            `${apiUrl}log?account_id=${creds.account_id}&page=${creds.page}&start_date=${moment(creds.start_date).format()}&end_date=${moment(creds.end_date).format()}&activity=${creds.activity}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
              },
            }
          );
          if (res.status === 200) {
            return res.data;
          }
        }

        // check for user id alone
        if(creds.user_id !== "" && creds.activity === "" && creds.start_date === "" && creds.end_date === ""){
          const res = await axios.get(
            `${apiUrl}log?account_id=${creds.account_id}&page=${creds.page}&user_id=${creds.user_id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
              },
            }
          );
          if (res.status === 200) {
            return res.data;
          }
        }

        // check for activity alone
        if(creds.user_id === "" && creds.activity !== "" && creds.start_date === "" && creds.end_date === ""){
          const res = await axios.get(
            `${apiUrl}log?account_id=${creds.account_id}&page=${creds.page}&activity=${creds.activity}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
              },
            }
          );
          if (res.status === 200) {
            return res.data;
          }
        }

        // check for startDate and endDate
        if(creds.user_id === "" && creds.activity === "" && creds.start_date !== "" && creds.end_date !== ""){
          const res = await axios.get(
            `${apiUrl}log?account_id=${creds.account_id}&page=${creds.page}&start_date=${moment(creds.start_date).format()}&end_date=${moment(creds.end_date).format()}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
              },
            }
          );
          if (res.status === 200) {
            return res.data;
          }
        }

        
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          toast.error("Oops, an error occured fetching audit logs!");
          return rejectWithValue(error);
        } else if (error.response.status === 401) {
          dispatch({ type: "auth/logOut" });
          return rejectWithValue(error);
        } else {
          toast.error("Oops, an error occured fetching audit logs!");
          return rejectWithValue(error);
        }
      }
  }
);
