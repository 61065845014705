import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetchBalanceAcrossAccounts, fetchGraphData, fetchLatestTransaction } from "./dashboardActions";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

type LatestTransactionType = {
  transaction_type: string;
  sender: string;
  amount: number;
  created_at: string;
  receiver: string;
  id: string;
  balance: string;
};

type DashboardState = {
  loading: boolean;
  latestTransaction: LatestTransactionType;
  graph: [];
  total_balance: number;
};

const initialState: DashboardState = {
  loading: false,
  latestTransaction: {
    transaction_type: "",
    sender: "",
    amount: 0,
    created_at: "",
    receiver: "",
    id: "",
    balance: "",
  },
  total_balance: 0,
  graph: [],
};

// Configure Redux Persist
const dashboardPersistConfig = {
  key: "dashboard",
  storage,
  blacklist: [],
  // Add any additional configuration options as needed
};

export const dashbardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    logOut: () => {
      localStorage.setItem("token", "");
      localStorage.clear();

      return initialState;
    },
  },
  extraReducers: (builder) => {
    // get all roles
    builder.addCase(fetchLatestTransaction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchLatestTransaction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.latestTransaction = action.payload.data;
      }
    );
    builder.addCase(fetchLatestTransaction.rejected, (state) => {
      state.loading = false;
    });

    // fetch graph data
    builder.addCase(fetchGraphData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchGraphData.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.graph = action.payload.data;
      }
    );
    builder.addCase(fetchGraphData.rejected, (state) => {
      state.loading = false;
    });

    // fetch balance across all accounts
    builder.addCase(fetchBalanceAcrossAccounts.pending, (state) => {
        
    })
    builder.addCase(fetchBalanceAcrossAccounts.fulfilled,(state, action: PayloadAction<any>) =>{
        state.total_balance = action.payload.data.total_balance
    })
    builder.addCase(fetchBalanceAcrossAccounts.rejected, (state) => {
        
    })
  },
});

const dashboardReducer = persistReducer(
  dashboardPersistConfig,
  dashbardSlice.reducer
);
export const { logOut } = dashbardSlice.actions;

export default dashboardReducer;
