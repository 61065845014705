import React,{useState, useEffect} from 'react'
import TeamInvitesTable from '../../tables/Teammate/teamInvitesTable';
import { fetchInvitee } from '../../redux/Teammates/teammateActions';
import { useAppSelector, useAppDispatch } from "../../redux/store";

const TeamInvites = () => {

    const dispatch = useAppDispatch()
    const { selectedAccount } = useAppSelector((state) => state.auth);
    const [typeID, setTypeID] = useState(1);
  const inviteTypes = [
    {
      id: 1,
      title: "Pending Invites",
    },
    {
      id: 2,
      title: "Cancelled Invites",
    },
    {
        id: 3,
        title: "Expired Invites",
    },
  ];

  const toggleType = (id: number) => {
    setTypeID(id);
    switch(id){
        case 1:
          dispatch(fetchInvitee({ account_id: selectedAccount?.id,status: 'Pending' }));
          break;
        case 2:
          dispatch(fetchInvitee({ account_id: selectedAccount?.id,status: 'Cancelled' }));
          break;
        case 3:
          dispatch(fetchInvitee({ account_id: selectedAccount?.id,status: 'Expired' }));
          break;
        default:
          break;
    }
  }


  useEffect(()=>{
    if(selectedAccount?.type === "sub_account"){
        switch(typeID){
            case 1:
              dispatch(fetchInvitee({ account_id: selectedAccount?.id,status: 'Pending' }));
              break;
            case 2:
              dispatch(fetchInvitee({ account_id: selectedAccount?.id,status: 'Cancelled' }));
              break;
            case 3:
              dispatch(fetchInvitee({ account_id: selectedAccount?.id,status: 'Expired' }));
              break;
            default:
              break;
        }
    }
  },[dispatch, selectedAccount, typeID])

    return ( 
        <>
            <div className="bg-white px-4 py-5 rounded-[8px]">
                <div className=" flex gap-[8px]">
                    <p className="text-primary text-lg font-semibold leading-6">View Invites</p>
                </div>
                
                <ul className="flex mt-5 gap-4 items-center">
                    {inviteTypes.map((item) => (
                    <li
                        key={item.id}
                        onClick={() => toggleType(item.id)}
                        className={
                        typeID === item.id
                            ? "flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer relative"
                            : "flex gap-1 items-center pb-1 cursor-pointer relative"
                        }
                    >
                        <p
                        className={
                            typeID === item.id
                            ? "text-primary text-sm font-semibold"
                            : "text-[#96A397] text-sm font-semibold"
                        }
                        >
                        {item.title}
                        </p>
                    </li>
                    ))}
                </ul>
            </div>

            <TeamInvitesTable inviteTabId={typeID} />
            
        </>
     );
}
 
export default TeamInvites;