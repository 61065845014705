import React,{useEffect, useState} from 'react'
import logo from "../../assets/icons/Logo_Big.svg";
import eye_off from "../../assets/icons/eye-off.svg";
import eye from "../../assets/icons/eye.svg";
import warning from "../../assets/icons/warning.svg";
import { Formik, Form, FormikHelpers } from "formik";
import { joinTeamValidator } from "../../validationSchema/validator";
import {Link, useLocation, useNavigate} from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { joinTeam } from '../../redux/Auth/authActions';
import { acceptInvite } from '../../redux/Teammates/teammateActions';
import toast from "react-hot-toast";

const JoinTeamPage = () => {

   const search = useLocation().search
    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordConfirmShown, setPasswordConfirmShown] = useState(false)
    const [email] = useState(new URLSearchParams(search).get('email'))
    const [token] = useState(new URLSearchParams(search).get('token'))
    const [isNew] = useState(new URLSearchParams(search).get('isNew'))
    const dispatch = useAppDispatch()
    const {loading} = useAppSelector((state) => state.auth)
    const navigate = useNavigate()

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
      };
   

  const toggleConfirmPasswordVisiblity = () => {
     setPasswordConfirmShown(passwordConfirmShown ? false : true)
    }

    const [passwordErrors, setErrors] = useState({
      minValueValidation: false,
      numberValidation: false,
      capitalLetterValidation: false,
      specialCharacterValidation: false,
    });

    const validatePassword = (password:string) => {
      setErrors({
        minValueValidation: password.length >= 8,
        numberValidation: /\d/.test(password),
        capitalLetterValidation: /[A-Z]/.test(password),
        specialCharacterValidation: /[^A-Za-z0-9]/.test(password),
      });
    };
    

    interface Values {
        first_name: string;
        last_name:string;
        password: string;
        confirm_password: string;
    }



    const handleSubmit = (values: Values) => {
        // e.preventDefault()
        dispatch(joinTeam({
            first_name: values.first_name,
            last_name: values.last_name,
            password: values.password,
            email: email,
            token: token,
        })).then((res) => {
          if(res?.type === "joinTeam/fulfilled"){
              navigate("/")
          }
        })
    };

    useEffect(() => {
      dispatch(acceptInvite({
        token: token,
        status: isNew
      })).then((res)=>{
        if(res?.type === "acceptInvite/fulfilled"){
          // convert isNew to a boolean value from a string
          const isNewValue = isNew === 'true' ? true : false
          if(!isNewValue){
            toast.success("Kindly login to your account to have access to the subaccount!", {
              style: {
                border: '1px solid #2B8C34',
                backgroundColor: '#2B8C34',
                color: '#FFFFFF',
                fontSize: 14,
              },
            });
            setTimeout(() => {
              navigate('/')
            },800)
          }
          else{
            toast.success("Invite accepted", {
              style: {
                border: '1px solid #2B8C34',
                backgroundColor: '#2B8C34',
                color: '#FFFFFF',
                fontSize: 14,
              },
              position: "top-right",
            });
          }
        }
        else if(res?.type === "acceptInvite/rejected"){
          setTimeout(() => {
            navigate('/')
          },800)
        }
      })
    },[dispatch, token, isNew, navigate])

    return ( 
        <>
            <div className="relative flex overflow-hidden">
                <div className="hidden md:block absolute -top-20 -right-24 bg-[#DBFFDF] w-[350px] h-[350px] rounded-full blur-[150px]"></div>
                    <div className="w-[40%] bg-primary min-h-screen relative hidden md:block">
                    <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                        <img src={logo} alt="logo" />
                    </div>
               </div>
               <div className="w-full md:w-[60%] px-4 md:px-0 flex justify-center flex-col min-h-screen">
                <div className="min-w-full md:min-w-[32rem] mx-auto">
                    <h6 className="text-primary font-semibold text-lg">Create Account</h6>
                    <p className="text-[#5C715E] text-sm font-medium max-w-lg">
                    Create an account to access all our features
                        </p>
                         {/* form */}
            <Formik
              initialValues={{
                first_name: "",
                last_name: "",
                password: "",
                confirm_password: ""
              }}
              validationSchema={joinTeamValidator}
              onSubmit={(
                values: Values,
                { setSubmitting }: FormikHelpers<Values>
              ) => {
                handleSubmit(values);
              }}
            >
              {({
                handleChange,
                isSubmitting,
                handleSubmit,
                handleBlur,
                values,
                touched,
                errors,
                dirty,
                isValid
              }) => (
                <Form className="mt-8" onSubmit={handleSubmit}>
                  <div>
                    <label
                      htmlFor="email"
                      className="block mb-2 text-[#344335] text-sm font-normal"
                    >
                      Email Address
                    </label>
                    <input
                      type="text"
                      name="email"
                      disabled
                      placeholder="Enter your email address"
                      className='appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#ABB6AC] bg-[#fff] disabled:bg-[#F7F7F7] disabled:text-[#ABB6AC] py-3 px-4'
                      value={email || ''} 
                    />
                   
                  </div>

                  <div className='mt-5 grid grid-cols-2 gap-4'>
                    <div>
                        <label
                        htmlFor="first_name"
                        className="block mb-2 text-[#344335] text-sm font-normal"
                        >
                        First name 
                        </label>
                        <input
                        type="text"
                        name="first_name"
                        placeholder="Enter your first name"
                        className={
                            touched.first_name && errors.first_name
                            ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                            : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                        }
                        value={values.first_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        />
                        {touched.first_name && errors.first_name ? (
                        <div className="flex">
                            <img src={warning} className="" alt="warning" />
                            <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                            {touched.first_name && errors.first_name}
                            </small>
                        </div>
                        ) : null}
                    </div>
                    <div>
                        <label
                        htmlFor="last_name"
                        className="block mb-2 text-[#344335] text-sm font-normal"
                        >
                        Last name 
                        </label>
                        <input
                        type="text"
                        name="last_name"
                        placeholder="Enter your last name"
                        className={
                            touched.last_name && errors.last_name
                            ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                            : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                        }
                        value={values.last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        />
                        {touched.last_name && errors.last_name ? (
                        <div className="flex">
                            <img src={warning} className="" alt="warning" />
                            <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                            {touched.last_name && errors.last_name}
                            </small>
                        </div>
                        ) : null}
                    </div>
                  </div>

                  <div className="mt-5">
                    <label
                      htmlFor="password"
                      className="block mb-2 text-secondary text-sm font-normal"
                    >
                      Password
                    </label>
                    <div className="relative">
                      <input
                        type={passwordShown ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        className={
                          touched.password && errors.password
                            ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335]text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                            : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                        }
                        value={values.password}
                        onChange={(e) => {
                          handleChange(e);
                          validatePassword(e.currentTarget.value);
                          }}
                        onBlur={handleBlur}
                      />
                      <img
                        onClick={togglePasswordVisiblity}
                        src={passwordShown ? eye_off : eye}
                        alt="eye"
                        className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                      />
                    </div>
                    {touched.password && errors.password ? (
                      <div className="flex">
                        <img src={warning} className="" alt="warning" />
                        <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          {touched.password && errors.password}
                        </small>
                      </div>
                    ) : null}
                  </div>
                  <div className="mt-3">
                    <h6 className='text-[#5C715E] text-sm font-semibold'>Your Password Must Contain At Least:</h6>
                    <ul className='mt-1'>

                    {Object.entries(passwordErrors).map(([key, value]) => {
                                return (
                                  <li className={`${value ? 'text-[#ABB6AC] line-through' : 'text-[#5C715E]'} text-sm font-medium`}>
                                    {key === 'minValueValidation' && 'Eight Characters'}
                                    {key === 'numberValidation' && 'One Numeric Character'}
                                    {key === 'capitalLetterValidation' && 'One Uppercase Character'}
                                    {key === 'specialCharacterValidation' && 'One Special Character'}
                                 </li>
                             )
                          })}

                    </ul>

                  </div>

                  <div className="mt-5">
                    <label
                      htmlFor="confirm_password "
                      className="block mb-2 text-secondary text-sm font-normal"
                    >
                     Confirm Password
                    </label>
                    <div className="relative">
                      <input
                        type={passwordConfirmShown ? "text" : "password"}
                        name="confirm_password"
                        placeholder="Password"
                        className={
                          touched.confirm_password && errors.confirm_password
                            ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335]text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                            : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                        }
                        value={values.confirm_password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <img
                        onClick={toggleConfirmPasswordVisiblity}
                        src={passwordConfirmShown ? eye_off : eye}
                        alt="eye"
                        className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                      />
                    </div>
                    {touched.confirm_password && errors.confirm_password ? (
                      <div className="flex">
                        <img src={warning} className="" alt="warning" />
                        <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          {touched.confirm_password && errors.confirm_password}
                        </small>
                      </div>
                    ) : null}
                  </div>
                 
                  <div className="mt-6">
                    <button
                      type="submit"
                      disabled={!(isValid && dirty) || loading || !passwordErrors.minValueValidation || !passwordErrors.numberValidation || !passwordErrors.capitalLetterValidation || !passwordErrors.specialCharacterValidation}
                      className="bg-primary 
                                disabled:bg-[#ABB6AC] 
                                rounded-lg w-full py-4 text-white font-medium hover:bg-[#24752B] transition-all"
                    >
                      Create Account
                    </button>
                  </div>
                  <div className="mt-3 text-center">
                    <p
                      className="text-secondary font-semibold text-sm"
                    >
                      Already have an account? <Link to="/" className='text-[#2B8C34]'>Log In</Link>
                    </p>
                  </div>
                </Form>
                )}
                </Formik>
                 </div>
              </div>
         </div>
        </>
     );
}
 
export default JoinTeamPage;