import React, { useEffect, useState } from "react";
import {
  ExpandMore,
  ExpandLess,
  Wallpaper,
} from "@mui/icons-material";
import { CardWithBorderStatus } from "../../components/Cards";
import AccountsTable from "../../tables/Accounts";
import copy_icon from "../../assets/icons/content_copy.svg";
import add_circle_icon from "../../assets/icons/add_circle_outline.svg";
import reply_icon from "../../assets/icons/reply.svg";
import download_icon from "../../assets/icons/download.svg";
import settings_icon from "../../assets/icons/settings.svg";
import warning from "../../assets/icons/warning.svg";
import ModalComponent from "../../components/Modals/modal";
import AreaChart from "../../components/AreaChart/AreaChart";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  createAccount,
  downloadStatement,
  fetchAccountById,
  fetchAccountTransactions,
  fetchAccounts,
  fetchMainAccount,
} from "../../redux/Account/accountActions";
import { Formik, Form, FormikHelpers } from "formik";
import {
  createAcctValidator,
  moveFundsValidator,
  generateStatementValidator,
} from "../../validationSchema/validator";
import toast from "react-hot-toast";
import { moveFundsToAccount } from "../../redux/Account/accountActions";
import moment from "moment";
import { useAccountPermissions } from "../../helpers/permissionsHelper/accountPermissions";
import { fetchGraphData } from "../../redux/Dashboard/dashboardActions";

const Account = () => {
  const [isMove, setIsMove] = useState(false);
  const [isCreateSub, setIsCreateSub] = useState(false);
  const [isStatementModal, setIsStatementModal] = useState(false);
  const { accounts, accountInfo, mainAccount } = useAppSelector(
    (state) => state.account
  );
  const { accountPermissions } = useAccountPermissions();
  const [loading, setLoading] = useState(false);
  const { selectedAccount } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [loader, setLoader] = useState(false);
  const { graph } = useAppSelector((state) => state.dashboard);

  interface Values {
    account: string;
  }

  interface Values1 {
    amount: string;
    narration: string;
  }

  interface Values2 {
    email: string;
  }

  const formatNumber = (numberToFormat: string) => {
    let res = Number(numberToFormat).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  const handleSubmit = (values: Values) => {
    setLoading(true);
    dispatch(
      createAccount({
        account_name: values.account,
      })
    ).then((res) => {
      if (res?.type === "createAccount/fulfilled") {
        setLoading(false);
        dispatch(fetchAccounts());
        setIsCreateSub(false);
      } else {
        setLoading(false);
      }
    });
  };

  const handleMoveFunds = (values: Values1) => {
    setLoading(true);
    if (selectedSubAcct && selectedSubAcct.name) {
      let res = {
        amount: Number(values?.amount),
        narration: values?.narration,
        recipient_account_id: selectedSubAcct.id,
        source_account_id: selectedAccount?.account_id,
      };
      dispatch(moveFundsToAccount(res)).then((res) => {
        if (res?.type === "moveFundsToAccount/fulfilled") {
          setLoading(false);
          setIsMove(false);
          setSelectedSubAcct({
            name: "",
            id: "",
          });
          // get account info
          dispatch(
            fetchAccountById({
              account_id: selectedAccount?.account_id,
            })
          );
          // get account transactions
          dispatch(
            fetchAccountTransactions({
              page: 1,
              id: selectedAccount?.account_id,
              type: selectedAccount?.type,
            })
          );
        }
      });
    } else {
      toast.error("Please select an account!");
    }
  };

  const showMoveModal = () => {
    setSelectedSubAcct({
      name: "",
      id: "",
    });
    setIsMove(!isMove);
  };

  const showStatementModal = () => {
    setIsStatementModal(!isStatementModal);
  };

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const dateranges = [
    {
      id: "1",
      name: "Custom",
    },
    {
      id: "2",
      name: "7 Days",
    },
    {
      id: "3",
      name: "2 Weeks",
    },
    {
      id: "4",
      name: "Last Month",
    },
    {
      id: "5",
      name: "3 Months",
    },
    {
      id: "6",
      name: "6 Months",
    },
    {
      id: "7",
      name: "Last Year",
    },
  ];

  const formatTypes = [
    {
      id: "1",
      name: "PDF (.pdf)",
      tag: "pdf",
    },
    {
      id: "2",
      name: "Excel (.xlsx)",
      tag: "csv",
    },
  ];

  const showCreateSubModal = () => {
    setIsCreateSub(!isCreateSub);
  };

  const [isToggleSubAcct, setIsToggleSubAcct] = useState(false);
  const [selectedSubAcct, setSelectedSubAcct] = useState<{
    name: string;
    id: string;
  }>({
    name: "",
    id: "",
  });
  const [isToggleDateRange, setIsToggleDateRange] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState<{
    name: string;
    id: string;
  }>({
    name: "",
    id: "",
  });

  const [isToggleStatement, setIsToggleStatement] = useState(false);
  const [selectedStatementType, setSelectedStatementType] = useState<{
    name: string;
    id: string;
    tag: string;
  }>({
    name: "",
    id: "",
    tag: "",
  });

  const handleSelectedSubAcct = (name: string, id: string) => {
    setSelectedSubAcct({
      name: name,
      id: id,
    });
    setIsToggleSubAcct(false);
  };

  const handleSelectedDate = (name: string, id: string) => {
    setSelectedDateRange({
      name: name,
      id: id,
    });
    setIsToggleDateRange(false);
    // logic to set the from and to date from the ranges selected using moment
    let start_date;
    let end_date;
    switch (name) {
      case "7 Days":
        start_date = moment().subtract(7, "days").format();
        end_date = moment().format();
        setStartDate(moment(start_date).format("YYYY-MM-DD"));
        setEndDate(moment(end_date).format("YYYY-MM-DD"));
        break;
      case "2 Weeks":
        start_date = moment().subtract(14, "days").format();
        end_date = moment().format();
        setStartDate(moment(start_date).format("YYYY-MM-DD"));
        setEndDate(moment(end_date).format("YYYY-MM-DD"));
        break;
      case "Last Month":
        start_date = moment().subtract(1, "months").startOf("month").format();
        end_date = moment().subtract(1, "months").endOf("month").format();
        setStartDate(moment(start_date).format("YYYY-MM-DD"));
        setEndDate(moment(end_date).format("YYYY-MM-DD"));
        break;
      case "3 Months":
        start_date = moment().subtract(3, "months").startOf("month").format();
        end_date = moment().subtract(1, "months").endOf("month").format();
        setStartDate(moment(start_date).format("YYYY-MM-DD"));
        setEndDate(moment(end_date).format("YYYY-MM-DD"));
        break;
      case "6 Months":
        start_date = moment().subtract(6, "months").startOf("month").format();
        end_date = moment().subtract(1, "months").endOf("month").format();
        setStartDate(moment(start_date).format("YYYY-MM-DD"));
        setEndDate(moment(end_date).format("YYYY-MM-DD"));
        break;
      case "Last Year":
        start_date = moment().subtract(1, "years").startOf("year").format();
        end_date = moment().subtract(1, "years").endOf("year").format();
        setStartDate(moment(start_date).format("YYYY-MM-DD"));
        setEndDate(moment(end_date).format("YYYY-MM-DD"));
        break;
      default:
        break;
    }
  };

  const handleSelectedStatement = (name: string, id: string, tag: string) => {
    setSelectedStatementType({
      name: name,
      id: id,
      tag: tag,
    });
    setIsToggleStatement(false);
  };

  const tags = [
    {
      name: "Tomilola",
      id: 1,
    },
    {
      name: "Eniola",
      id: 2,
    },
    {
      name: "Micheal Ogundare",
      id: 3,
    },
    {
      name: "Emem Essien",
      id: 4,
    },
    {
      name: "Goodness Eshett",
      id: 5,
    },
  ];

  const [isToggleTag, setIsToggleTag] = useState(false);
  const [selectedTags, setSelectedTags] = useState<
    Array<{
      name: string;
      id: number;
    }>
  >([]);

  const handleSelectedTag = (name: string, id: number) => {
    const isTagSelected = selectedTags.some((tag) => tag.name === name);

    if (isTagSelected) {
      setSelectedTags((prevTags) =>
        prevTags.filter((tag) => tag.name !== name)
      );
    } else {
      setSelectedTags((prevTags) => [...prevTags, { name, id }]);
    }
  };

  const handleGenerateStatement = (values: Values2) => {
    // check for date range, from and to date or if format is selected
    if (
      !selectedDateRange?.name ||
      !selectedStatementType?.name ||
      !startDate ||
      !endDate
    ) {
      toast.error("Please fill in all required fields!");
    } else {
      let res = {
        ...values,
        from: moment(startDate).format(),
        to: moment(endDate).format(),
        format: selectedStatementType?.tag,
        account_id: selectedAccount?.account_id,
      };
      setLoader(true);
      dispatch(downloadStatement(res)).then((res) => {
        if (res?.type === "downloadStatement/fulfilled") {
          setLoader(false);
          setIsStatementModal(false);
          setSelectedDateRange({ name: "", id: "" });
          setSelectedStatementType({ name: "", id: "", tag: "" });
          setStartDate("");
          setEndDate("");
        } else if (res?.type === "downloadStatement/rejected") {
          setLoader(false);
        }
      });
    }
  };

  const handleCopyNumber = (number: string) => {
    navigator.clipboard.writeText(
      `Account Number: ${number}, Bank Name: Wema Bank`
    );
    toast.success(`Copied successfully`, {
      style: {
        border: "1px solid #2b8c34",
        backgroundColor: "#2b8c34",
        color: "#FFFFFF",
        fontSize: 14,
        maxWidth: 400,
      },
    });
  };

  useEffect(() => {
    dispatch(fetchAccounts());
    if (selectedAccount.type === "main_account") {
      dispatch(fetchMainAccount());
    } else {
      dispatch(
        fetchAccountById({
          account_id: selectedAccount?.account_id,
        })
      );
    }
  }, [dispatch, selectedAccount]);

  useEffect(() => {
    dispatch(
      fetchGraphData({
        id: selectedAccount.account_id,
      })
    );
  }, [dispatch, selectedAccount.account_id]);

  return (
    <>
      {/* Move Funds Modal */}
      <ModalComponent
        title="Move Funds"
        subtitle=""
        isOpen={isMove}
        onClose={showMoveModal}
      >
        {/* form */}
        <Formik
          initialValues={{
            amount: "",
            narration: "",
          }}
          validationSchema={moveFundsValidator}
          onSubmit={(
            values: Values1,
            { setSubmitting }: FormikHelpers<Values1>
          ) => {
            handleMoveFunds(values);
          }}
        >
          {({
            handleChange,
            isSubmitting,
            handleSubmit,
            handleBlur,
            values,
            touched,
            errors,
          }) => (
            <Form className="mt-6" onSubmit={handleSubmit}>
              {/* Subaccount */}
              <div className="mt-5">
                <label
                  htmlFor="subaccount"
                  className="block mb-2 text-[#344335] text-sm font-normal"
                >
                  Subaccount
                </label>
                <div className="relative">
                  <div
                    onClick={() => setIsToggleSubAcct(!isToggleSubAcct)}
                    className={`flex cursor-pointer justify-between items-center w-full rounded-lg border border-[#96A397] bg-white py-3 px-4 text-[#96A397] text-sm`}
                    style={
                      isToggleSubAcct
                        ? { boxShadow: "0px 0px 0px 2px #D5E8D6" }
                        : {}
                    }
                  >
                    <p
                      className={`${
                        selectedSubAcct?.name
                          ? "text-[#344335] text-sm"
                          : "text-[#96A397] text-sm"
                      }`}
                    >
                      {selectedSubAcct?.name || "Select an account"}
                    </p>
                    {isToggleSubAcct ? (
                      <ExpandLess
                        style={{ fontSize: "20px", color: "#344335" }}
                      />
                    ) : (
                      <ExpandMore
                        style={{ fontSize: "20px", color: "#344335" }}
                      />
                    )}
                  </div>

                  {selectedSubAcct?.name ? null : (
                    <div className="flex">
                      <img src={warning} className="" alt="warning" />
                      <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                        Account is required
                      </small>
                    </div>
                  )}

                  {/*  SELECT OPTIONS */}
                  {isToggleSubAcct && (
                    <div
                      className=" rounded-[18px] absolute py-2 bg-white w-full mt-2 px-2 max-h-[300px] overflow-scroll "
                      style={{
                        boxShadow:
                          "0px 16px 32px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      {/* filter main account and also the selected account name */}

                      {accounts
                        ?.filter(
                          (account) =>
                            account?.account_type !== "main_account" &&
                            account?.account_name !== selectedAccount?.name
                        )
                        .map((val, index) => {
                          return (
                            <div
                              key={index}
                              onClick={() =>
                                handleSelectedSubAcct(
                                  val?.account_name,
                                  val?.account_id
                                )
                              }
                              className={`px-4 py-3  text-sm font-normal cursor-pointer hover:bg-[#EDF7EE] hover:rounded-[8px] ${
                                index < accounts.length - 1 ? "mb-1" : ""
                              } ${
                                selectedSubAcct?.name === val?.account_name
                                  ? "bg-[#EDF7EE] border-[1px] border-[#2B8C34] rounded-[8px] text-[#24752B]"
                                  : "text-[#344335] bg-white"
                              }`}
                            >
                              <p className=" leading-5 text-sm font-medium">
                                {val?.account_name}
                              </p>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-4">
                <label
                  htmlFor="amount"
                  className="block mb-2 text-[#344335] text-sm font-normal"
                >
                  Amount
                </label>
                <input
                  type="text"
                  name="amount"
                  placeholder="Enter an amount"
                  value={values.amount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    touched.amount && errors.amount
                      ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-sm focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                      : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-sm focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                  }
                />
                {touched.amount && errors.amount ? (
                  <div className="flex">
                    <img src={warning} className="" alt="warning" />
                    <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                      {touched.amount && errors.amount}
                    </small>
                  </div>
                ) : null}
              </div>

              <div className="mt-4">
                <label
                  htmlFor="narration"
                  className="block mb-2 text-[#344335] text-sm font-normal"
                >
                  Narration
                </label>
                <textarea
                  name="narration"
                  rows={4}
                  placeholder="Narration"
                  value={values.narration}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    touched.narration && errors.narration
                      ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-sm focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4 resize-none"
                      : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-sm focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4 resize-none"
                  }
                />
                {touched.narration && errors.narration ? (
                  <div className="flex">
                    <img src={warning} className="" alt="warning" />
                    <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                      {touched.narration && errors.narration}
                    </small>
                  </div>
                ) : null}
              </div>

              <div className="mt-5">
                <button
                  disabled={loading}
                  type="submit"
                  className="bg-primary 
                      disabled:bg-[#ABB6AC] w-full
                    rounded-lg px-6 py-4 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
                >
                  Move Funds
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalComponent>

      {/* Generate Statement Modal */}
      <ModalComponent
        title="Generate Account Statement"
        subtitle=""
        isOpen={isStatementModal}
        onClose={showStatementModal}
      >
        {/* form */}
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={generateStatementValidator}
          onSubmit={(
            values: Values2,
            { setSubmitting }: FormikHelpers<Values2>
          ) => {
            handleGenerateStatement(values);
          }}
        >
          {({
            handleChange,
            isSubmitting,
            handleSubmit,
            handleBlur,
            values,
            touched,
            errors,
          }) => (
            <Form className="mt-6" onSubmit={handleSubmit}>
              {/* Date Range */}
              <div className="mt-5">
                <label
                  htmlFor="date_range"
                  className="block mb-2 text-[#344335] text-sm font-normal"
                >
                  Date Range
                </label>
                <div className="relative">
                  <div
                    onClick={() => setIsToggleDateRange(!isToggleDateRange)}
                    className={`flex cursor-pointer justify-between items-center w-full rounded-lg border border-[#96A397] bg-white py-3 px-4 text-[#96A397] text-sm`}
                    style={
                      isToggleDateRange
                        ? { boxShadow: "0px 0px 0px 2px #D5E8D6" }
                        : {}
                    }
                  >
                    <p
                      className={`${
                        selectedDateRange?.name
                          ? "text-[#344335] text-sm"
                          : "text-[#96A397] text-sm"
                      }`}
                    >
                      {selectedDateRange?.name || "Select a Date Range"}
                    </p>
                    {isToggleDateRange ? (
                      <ExpandLess
                        style={{ fontSize: "20px", color: "#344335" }}
                      />
                    ) : (
                      <ExpandMore
                        style={{ fontSize: "20px", color: "#344335" }}
                      />
                    )}
                  </div>

                  {selectedDateRange?.name ? null : (
                    <div className="flex">
                      <img src={warning} className="" alt="warning" />
                      <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                        Date Range is required
                      </small>
                    </div>
                  )}

                  {/*  SELECT OPTIONS */}
                  {isToggleDateRange && (
                    <div
                      className=" rounded-[18px] absolute py-2 bg-white w-full mt-2 px-2 max-h-[300px] overflow-scroll z-10"
                      style={{
                        boxShadow:
                          "0px 16px 32px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      {dateranges.map((val, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() =>
                              handleSelectedDate(val?.name, val?.id)
                            }
                            className={`px-4 py-3  text-sm font-normal cursor-pointer hover:bg-[#EDF7EE] hover:rounded-[8px] ${
                              index < dateranges.length - 1 ? "mb-1" : ""
                            } ${
                              selectedDateRange?.name === val?.name
                                ? "bg-[#EDF7EE] border-[1px] border-[#2B8C34] rounded-[8px] text-[#24752B]"
                                : "text-[#344335] bg-white"
                            }`}
                          >
                            <p className=" leading-5 text-sm font-medium">
                              {val?.name}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-4 grid grid-cols-2 gap-4">
                <div>
                  <label
                    htmlFor="from_date"
                    className="block mb-2 text-[#344335] text-sm font-normal"
                  >
                    From:
                  </label>
                  <input
                    type="date"
                    name="from_date"
                    max={moment().format("YYYY-MM-DD")}
                    placeholder="DD/MM/YYYY"
                    value={startDate}
                    onChange={(event) => setStartDate(event.target.value)}
                    disabled={selectedDateRange?.name !== "Custom"}
                    className="appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-sm focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                  />

                  {selectedDateRange?.name === "Custom" && startDate === "" ? (
                    <div className="flex">
                      <img src={warning} className="" alt="warning" />
                      <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                        From Date is required
                      </small>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div>
                  <label
                    htmlFor="to_date"
                    className="block mb-2 text-[#344335] text-sm font-normal"
                  >
                    To:
                  </label>
                  <input
                    type="date"
                    name="to_date"
                    max={moment().format("YYYY-MM-DD")}
                    placeholder="DD/MM/YYYY"
                    value={endDate}
                    onChange={(event) => setEndDate(event.target.value)}
                    disabled={selectedDateRange?.name !== "Custom"}
                    className="appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-sm focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                  />

                  {selectedDateRange?.name === "Custom" && endDate === "" ? (
                    <div className="flex">
                      <img src={warning} className="" alt="warning" />
                      <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                        To Date is required
                      </small>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="mt-4">
                <label
                  htmlFor="statement"
                  className="block mb-2 text-[#344335] text-sm font-normal"
                >
                  Statement Format
                </label>
                <div className="relative">
                  <div
                    onClick={() => setIsToggleStatement(!isToggleStatement)}
                    className={`flex cursor-pointer justify-between items-center w-full rounded-lg border border-[#96A397] bg-white py-3 px-4 text-[#96A397] text-sm`}
                    style={
                      isToggleStatement
                        ? { boxShadow: "0px 0px 0px 2px #D5E8D6" }
                        : {}
                    }
                  >
                    <p
                      className={`${
                        selectedStatementType?.name
                          ? "text-[#344335] text-sm"
                          : "text-[#96A397] text-sm"
                      }`}
                    >
                      {selectedStatementType?.name || "Select a Statement Type"}
                    </p>
                    {isToggleStatement ? (
                      <ExpandLess
                        style={{ fontSize: "20px", color: "#344335" }}
                      />
                    ) : (
                      <ExpandMore
                        style={{ fontSize: "20px", color: "#344335" }}
                      />
                    )}
                  </div>

                  {selectedStatementType?.name ? null : (
                    <div className="flex">
                      <img src={warning} className="" alt="warning" />
                      <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                        Format is required
                      </small>
                    </div>
                  )}

                  {/*  SELECT OPTIONS */}
                  {isToggleStatement && (
                    <div
                      className="rounded-[18px] absolute py-2 bg-white w-full mt-2 px-2 max-h-[300px] overflow-scroll z-10"
                      style={{
                        boxShadow:
                          "0px 16px 32px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      {formatTypes.map((val, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() =>
                              handleSelectedStatement(
                                val?.name,
                                val?.id,
                                val?.tag
                              )
                            }
                            className={`px-4 py-3  text-sm font-normal cursor-pointer hover:bg-[#EDF7EE] hover:rounded-[8px] ${
                              index < formatTypes.length - 1 ? "mb-1" : ""
                            } ${
                              selectedStatementType?.name === val?.name
                                ? "bg-[#EDF7EE] border-[1px] border-[#2B8C34] rounded-[8px] text-[#24752B]"
                                : "text-[#344335] bg-white"
                            }`}
                          >
                            <p className=" leading-5 text-sm font-medium">
                              {val?.name}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-4">
                <label
                  htmlFor="email"
                  className="block mb-2 text-[#344335] text-sm font-normal"
                >
                  Email Address
                </label>
                <input
                  type="text"
                  name="email"
                  placeholder="Enter an email address"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    touched.email && errors.email
                      ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-sm focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                      : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-sm focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                  }
                />
                {touched.email && errors.email ? (
                  <div className="flex">
                    <img src={warning} className="" alt="warning" />
                    <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                      {touched.email && errors.email}
                    </small>
                  </div>
                ) : null}
              </div>

              <div className="mt-5">
                <button
                  type="submit"
                  disabled={loader}
                  className="bg-primary 
                      disabled:bg-[#ABB6AC] w-full
                    rounded-lg px-6 py-4 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
                >
                  Generate Statement
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalComponent>

      {/* Create new account Modal */}
      <ModalComponent
        title="Create new subaccount"
        subtitle=""
        isOpen={isCreateSub}
        onClose={showCreateSubModal}
      >
        {/* form */}
        <Formik
          initialValues={{
            account: "",
          }}
          validationSchema={createAcctValidator}
          onSubmit={(
            values: Values,
            { setSubmitting }: FormikHelpers<Values>
          ) => {
            handleSubmit(values);
          }}
        >
          {({
            handleChange,
            isSubmitting,
            handleSubmit,
            handleBlur,
            values,
            touched,
            errors,
          }) => (
            <Form className="mt-8" onSubmit={handleSubmit}>
              <div className="mt-5">
                <label
                  htmlFor="account"
                  className="block mb-2 text-[#344335] text-sm font-normal"
                >
                  Account Alias
                </label>
                <input
                  type="text"
                  name="account"
                  placeholder="e.g Salary"
                  value={values.account}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    touched.account && errors.account
                      ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                      : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                  }
                />
                {touched.account && errors.account ? (
                  <div className="flex">
                    <img src={warning} className="" alt="warning" />
                    <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                      {touched.account && errors.account}
                    </small>
                  </div>
                ) : null}
              </div>

              {/* User Access */}
              <div className="mt-4">
                <label
                  htmlFor="user"
                  className="block mb-2 text-[#344335] text-sm font-normal"
                >
                  User Access (Optional)
                </label>
                <div className="relative">
                  <div
                    className={`flex cursor-pointer  items-center w-full rounded-lg border border-[#96A397] bg-white py-3 px-4 text-[#96A397] text-sm`}
                    style={
                      isToggleTag
                        ? { boxShadow: "0px 0px 0px 2px #D5E8D6" }
                        : {}
                    }
                    onClick={() => setIsToggleTag(!isToggleTag)}
                  >
                    <div className="flex flex-wrap  gap-2 items-center w-[95%]">
                      {selectedTags?.length !== 0 ? (
                        <>
                          {selectedTags?.map((tag, index) => {
                            return (
                              <div
                                className="rounded-[4px] text-sm bg-[#DBFFDF] px-1 py-[2px] text-[#2B8C34] flex items-center gap-2"
                                key={index}
                              >
                                <p> {tag?.name}</p>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        "Select user access"
                      )}
                    </div>

                    <div className=" w-[5%]">
                      {isToggleTag ? (
                        <ExpandLess
                          style={{ fontSize: "20px", color: "#344335" }}
                        />
                      ) : (
                        <ExpandMore
                          style={{ fontSize: "20px", color: "#344335" }}
                        />
                      )}
                    </div>
                  </div>

                  {/*  SELECT OPTIONS */}
                  {isToggleTag && (
                    <div
                      className="max-h-[300px] py-2 overflow-scroll rounded-[18px] bg-white w-full mt-2 px-2"
                      style={{
                        boxShadow:
                          "0px 16px 32px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      {tags?.map((tag, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() =>
                              handleSelectedTag(tag?.name, tag?.id)
                            }
                            className={`px-4 py-3  flex gap-2  items-center text-sm font-normal cursor-pointer hover:bg-[#EDF7EE] hover:rounded-[8px] 
                            ${index < tags.length - 1 ? "mb-1" : ""}
                            ${
                              selectedTags.some(
                                (selectedTag) => selectedTag.name === tag?.name
                              )
                                ? "bg-[#EDF7EE] border-[1px] border-[#2B8C34] rounded-[8px] text-[#24752B]"
                                : "text-[#344335] bg-white"
                            }`}
                          >
                            <input
                              className="accent-[#2B8C34] w-[15px] h-[15px]"
                              type="checkbox"
                              id={tag?.name}
                              checked={selectedTags.some(
                                (selectedTag) => selectedTag.name === tag?.name
                              )}
                            />
                            <p className="leading-5 text-sm font-medium">
                              {tag?.name}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-5">
                <button
                  type="submit"
                  disabled={loading}
                  className="bg-primary 
                      disabled:bg-[#ABB6AC] w-full
                    rounded-lg px-6 py-4 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
                >
                  Create Account
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalComponent>

      <div className="bg-white rounded-[8px] px-4 py-4 flex justify-between items-center">
        <div>
          <p className="text-primary text-lg font-semibold leading-6">
            Account
          </p>
        </div>
        {/* BUTTON */}
        {accountPermissions.createAccount && (
          <div className="flex gap-2">
            <div>
              <button
                onClick={() => showCreateSubModal()}
                className="w-full py-3 flex items-center justify-center px-5 bg-[#EDF7EE] rounded-[8px] text-[#2B8C34] text-sm font-medium  transition-all"
              >
                Create new account
              </button>
            </div>
          </div>
        )}
      </div>

      {accountPermissions.accountSummary && (
        <div className="grid grid-cols-4 gap-4 mt-5">
          <CardWithBorderStatus
            title="Account Balance"
            subtitle={
              selectedAccount.type === "main_account"
                ? `₦${
                    mainAccount
                      ? formatNumber(mainAccount?.total_balance.toString())
                      : 0
                  }`
                : `₦${
                    accountInfo ? formatNumber(accountInfo?.account_balance) : 0
                  }`
            }
            color="border-l-[#2B8C34]"
            status="Urgent"
          />
          <CardWithBorderStatus
            title="Monthly Budget"
            subtitle={
              selectedAccount.type === "main_account"
                ? `₦${mainAccount?.total_budget?.toFixed(2)}`
                : `₦${
                    accountInfo ? formatNumber(accountInfo?.monthly_budget) : 0
                  }`
            }
            color="border-l-[#2B8C34]"
            status="Urgent"
          />
          <CardWithBorderStatus
            title="Spent So Far"
            subtitle="₦0.00"
            color="border-l-[#2B8C34]"
            status="Urgent"
          />
          <CardWithBorderStatus
            title="Burn Rate"
            subtitle="0%"
            color="border-l-[#2B8C34]"
            status="Urgent"
          />
        </div>
      )}

      <div className="grid grid-cols-2 gap-4 mt-5">
        <div className="bg-[#fff] rounded-[8px] py-4 px-4 h-fit">
          {/* <div className="flex justify-center items-center gap-1">
            <ArrowDropUp
              className="cursor-pointer"
              style={{ color: "#2B8C34", fontSize: "20px" }}
            />
            <p className="text-[15px] font-semibold leading-[24px] text-[#344335]">
              ₦0.00 Today, {moment().format("MMMM Do")}
            </p>
          </div> */}
          <div className="mt-10">
            <AreaChart
              data={graph}
              height={180}
              selectedAcct={selectedAccount}
            />
          </div>
        </div>

        <>
          <>
            {selectedAccount.type === "main_account" ? (
              <div className="bg-[#fff] rounded-[8px] py-4 px-4 flex flex-col justify-center items-center">
                {/*  No farm details  */}
                <Wallpaper
                  className="text-[#7C8D7E]"
                  style={{ fontSize: "40px" }}
                />
                <p className="text-sm text-[#344335] font-medium mt-2 text-center">
                  No activity has been recorded with this account
                </p>
              </div>
            ) : (
              <div className="bg-[#fff] rounded-[8px] py-6 px-6">
                <div className="flex gap-4 justify-between items-center">
                  <div className="flex gap-2 items-center">
                    <p className="text-[#344335] text-[15px] font-semibold">
                      Account Type:
                    </p>
                    <p className="text-[#7C8D7E] text-sm font-semibold">
                      {accountInfo
                        ? accountInfo.account_type === "main_account"
                          ? "Main Account"
                          : "Sub Account"
                        : "N/A"}
                    </p>
                  </div>
                  <div></div>
                </div>

                <div className="flex gap-4 justify-between items-center mt-6">
                  <div className="flex gap-2 items-center">
                    <p className="text-[#344335] text-[15px] font-semibold">
                      Account Name/Alias:
                    </p>
                    <p className="text-[#7C8D7E] text-sm font-semibold">
                      {accountInfo?.account_name}
                    </p>
                  </div>
                  <div></div>
                </div>

                <div className="flex gap-4 justify-between items-center mt-6">
                  <div className="flex gap-2 items-center">
                    <p className="text-[#344335] text-[15px] font-semibold">
                      Bank Name:
                    </p>
                    <p className="text-[#7C8D7E] text-sm font-semibold">
                      Wema Bank
                    </p>
                  </div>
                  <div></div>
                </div>

                <div className="flex gap-4 justify-between items-center mt-6">
                  <div className="flex gap-2 items-center">
                    <p className="text-[#344335] text-[15px] font-semibold">
                      Account Number:
                    </p>
                    <p className="text-[#7C8D7E] text-sm font-semibold">
                      {accountInfo?.account_number}
                    </p>
                  </div>
                  <div
                    onClick={() =>
                      handleCopyNumber(accountInfo?.account_number)
                    }
                    className="flex gap-1 items-center cursor-pointer"
                  >
                    <img src={copy_icon} alt="copy icon" />
                    <p className="text-[#7C8D7E] text-sm font-semibold">Copy</p>
                  </div>
                </div>

                <div className="flex justify-between items-center mt-12">
                  <div className="flex gap-1 items-center">
                    <img src={add_circle_icon} alt="sdd circle icon" />
                    <p className="text-[#344335] text-sm font-semibold">Fund</p>
                  </div>
                  {accountPermissions?.moveFunds && (
                    <div
                      onClick={() => showMoveModal()}
                      className="flex gap-1 items-center cursor-pointer"
                    >
                      <img src={reply_icon} alt="reply icon" />
                      <p className="text-[#344335] text-sm font-semibold">
                        Move Funds
                      </p>
                    </div>
                  )}
                  {accountPermissions?.requestStatement && (
                    <div
                      onClick={() => showStatementModal()}
                      className="flex gap-1 items-center cursor-pointer"
                    >
                      <img src={download_icon} alt="download icon" />
                      <p className="text-[#344335] text-sm font-semibold">
                        Statement
                      </p>
                    </div>
                  )}

                  <div className="flex gap-1 items-center">
                    <img src={settings_icon} alt="settings icon" />
                    <p className="text-[#344335] text-sm font-semibold">
                      Settings
                    </p>
                  </div>
                </div>
              </div>
            )}
          </>
        </>
      </div>
      <AccountsTable />
    </>
  );
};

export default Account;
