import React, { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../redux/store";
import { Navigate, useLocation } from "react-router-dom";
import { userRefreshToken } from "../../redux/Auth/authActions";
import { jwtDecode } from "jwt-decode";
import { useIdleTimer } from "react-idle-timer";
import { logOut } from "../../redux/Auth/auth";
import toast from "react-hot-toast";

type LayoutProps = {
  children: React.ReactNode;
};

const UserRoute = ({ children }: LayoutProps) => {
  const { isAuth } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const onPrompt = () => {
    console.log("User's session is about to expire");
  };

  const onIdle = () => {
    toast.success("Session expired!", {
      style: {
        border: "1px solid #2B8C34",
        backgroundColor: "#2B8C34",
        color: "#FFFFFF",
        fontSize: 14,
      },
      position: "top-right",
    });
    dispatch(logOut());
  };

  useIdleTimer({
    onIdle,
    onPrompt,
    timeout: 1000 * 60 * 20,
    promptBeforeIdle: 1000 * 60 * 15,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
      "focus",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });

  useEffect(() => {
    const checkTokenExpiration = () => {
      const token = localStorage.getItem("token");
      if (token) {
        const { exp } = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Get current time in seconds
        const timeUntilExpiration = exp ? exp - currentTime : 0 - currentTime; // Time remaining until token expiration in seconds
        const refreshThreshold = 300; // Refresh the token if it's about to expire within 5 minutes (300 seconds)
        if (timeUntilExpiration < refreshThreshold) {
          // Trigger token refresh
          dispatch(
            userRefreshToken({
              token: localStorage.getItem("refreshToken"),
            })
          );
        }
      }
    };

    // Set up interval to periodically check token expiration
    const interval = setInterval(checkTokenExpiration, 60000); // Check every minute
    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <>
      {!isAuth ? (
        <Navigate to="/" replace state={{ path: location.pathname }} />
      ) : (
        children
      )}
    </>
  );
};

export default UserRoute;
