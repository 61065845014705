import React, {useState } from "react";
import { useAppSelector, useAppDispatch } from "../../redux/store";
import { Form, Formik, FormikHelpers } from "formik";
import { changePasswordValidator, emailAuthValidator } from "../../validationSchema/validator";
import warning from "../../assets/icons/warning.svg";
import eye_off from "../../assets/icons/eye-off.svg";
import eye from "../../assets/icons/eye.svg";
import copy_icon from "../../assets/icons/copy-icon.svg";
import { userChangePassword, userEmailSetup, userEmailVerify, userGoogleAuthSetup, userGoogleAuthVerify, userEmailSetupResendCode } from "../../redux/Auth/authActions";
import { logOut } from "../../redux/Auth/auth";
import { useNavigate } from "react-router-dom";
import {useProfilePermissions} from "../../helpers/permissionsHelper/profilePermissions";
import ModalComponent from "../../components/Modals/modal";
import toast from "react-hot-toast";
import QRCode from "react-qr-code";


const ProfilePage = () => {
  const { profile, loading, is_2fa_enabled, otp_method, qr_code, manual_code } = useAppSelector(
    (state) => state.auth
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [firstname, setFirstname] = useState(
    profile ? profile.first_name : "Test"
  );
  const [lastname, setLastname] = useState(
    profile ? profile.last_name : "User"
  );
  const [email, setEmail] = useState(profile ? profile.email : "");
  const [department] = useState("Finance");
  const { profilePermissions } = useProfilePermissions();

  const [selectedType, setSelectedType] = useState(otp_method ? otp_method : "");
  const [secretValue, setSecretKey] = useState(manual_code ? manual_code: '');

  const [isEmailModal, setIsEmailModal] = useState(false);
  const [isGoogleAuthModal, setIsGoogleAuthModal] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [loader, setLoader] = useState(false);

  const showEmailAuthModal = () =>{
    setIsEmailModal(!isEmailModal)
  }

  const showGoogleAuthModal = () =>{
    setIsGoogleAuthModal(!isGoogleAuthModal)
  }

  const copySecretkey = () =>{
    navigator.clipboard.writeText(secretValue);
    toast.success("QR Code copied successfully!");
  }

  const handleType = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.value === "email") {
      setSelectedType(event.target.value)
    }
    else{
      setSelectedType(event.target.value);
    
    }
  };

  const handle2faSetup = () =>{
    if(selectedType === "email"){
      dispatch(userEmailSetup()).then((res)=>{
        if(res?.type === "userEmailSetup/fulfilled"){
          setIsEmailModal(true);
        }
      })
    }
    else if(selectedType === "auth"){
      dispatch(userGoogleAuthSetup()).then((res)=>{
        if(res?.type === "userGoogleAuthSetup/fulfilled"){
          setIsGoogleAuthModal(true);
          setSecretKey(res?.payload?.data?.manual_code);
        }
      })  
    }
    else{
      toast.error("Kindly select an authentication type to proceed!", {
        position: "top-right",
      });
    }
  }

  const verifyAuthCode = () =>{
    dispatch(userGoogleAuthVerify({
      token: verificationCode
    })).then((res)=>{
      if(res?.type === "userGoogleAuthVerify/fulfilled"){
        setIsGoogleAuthModal(false);
        setVerificationCode("");
      }
    })
  }



  const [typeId, setTypeID] = useState(1);
  const type = [
    {
      id: 1,
      title: "Account Information",
    },
    {
      id: 2,
      title: "Password",
    },
    {
      id: 3,
      title: "Two-Factor Authentication",
    },
  ];

  const toggleType = (id: number) => {
    setTypeID(id);
  };

  const [oldpasswordShown, setOldPasswordShown] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmpasswordShown, setConfirmPasswordShown] = useState(false);

 

  const toggleOldPasswordVisiblity = () => {
    setOldPasswordShown(oldpasswordShown ? false : true);
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(confirmpasswordShown ? false : true);
  };

  interface Values {
    oldpassword: string;
    password: string;
    password_confirmation: string;
  }

  interface Values1 {
    code: string;
  }

  const handleEmailAuthActivate = (values: Values1) => {
    dispatch(userEmailVerify({ token: values.code })).then((res) => {
      if (res?.type === "userEmailVerify/fulfilled") {
        setIsEmailModal(false);
      }
    })
  };
  
  const handleResendCode = () =>{
    setLoader(true)
    dispatch(userEmailSetupResendCode()).then((res)=>{
      if(res?.type === "userEmailSetupResendCode/fulfilled"){
        setLoader(false)
      }
      else if(res?.type === "userEmailSetupResendCode/rejected"){
        setLoader(false)
      }
    })
  }

  const handleSubmit = (values: Values) => {
    dispatch(
      userChangePassword({
        oldpassword: values.oldpassword,
        newpassword: values.password,
      })
    ).then((res) => {
      if (res?.type === "userChangePassword/fulfilled") {
        dispatch(logOut());
        navigate("/");
      }
    });
  };



  return (
    <>
    {/* email auth modal */}
      <ModalComponent
        title="Email Authentication"
        subtitle=""
        isOpen={isEmailModal}
        onClose={showEmailAuthModal}
      >
          <div className="mt-2">
            <p className="text-sm text-[#5C715E] font-medium">A verification code has been sent to {profile && profile.email ? profile.email : "admin@gmail.com"}. <br/> Check your email and enter the code below</p>

             {/* form */}
        <Formik
          initialValues={{
            code: "",
          }}
          validationSchema={emailAuthValidator}
          onSubmit={(
            values: Values1,
            { setSubmitting }: FormikHelpers<Values1>
          ) => {
            handleEmailAuthActivate(values);
          }}
        >
          {({
            handleChange,
            isSubmitting,
            handleSubmit,
            handleBlur,
            values,
            touched,
            errors,
            dirty,
            isValid
          }) => (
            <Form className="mt-6" onSubmit={handleSubmit}>
             
              <div>
                <label
                  htmlFor="code"
                  className="block mb-2 text-[#344335] text-sm font-normal"
                >
                  Verification Code
                </label>
                <input
                  type="text"
                  name="code"
                  placeholder="Enter Verification Code"
                  value={values.code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    touched.code && errors.code
                      ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-sm focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                      : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-sm focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                  }
                />
                {touched.code && errors.code ? (
                  <div className="flex">
                    <img src={warning} className="" alt="warning" />
                    <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                      {touched.code && errors.code}
                    </small>
                  </div>
                ) : null}
              </div>

              <div className="mt-3">
                    <p className="text-[#5C715E] text-sm font-medium">
                    Didn’t get authentication code?
                      <span
                        onClick={handleResendCode}
                        className={`text-primary ${loader ? 'pointer-events-none opacity-[0.5]': ''} cursor-pointer font-semibold text-sm ml-1`}
                      >
                       Resend Code
                      </span>
                    </p>
                  </div>

             

              <div className="mt-5">
                <button
                  disabled={!(isValid && dirty) || loading}
                  type="submit"
                  className="bg-[#EDF7EE] 
                      disabled:bg-[#ECEFEC] disabled:text-[#96A397] w-full
                    rounded-lg px-6 py-4 text-[#2B8C34] text-sm font-medium transition-all"
                >
                  Authenticate Code
                </button>
              </div>
            </Form>
          )}
        </Formik>

          </div>
      </ModalComponent>
      {/* google authenticator modal */}
      <ModalComponent
        title="Google Authenticator"
        subtitle=""
        isOpen={isGoogleAuthModal}
        onClose={showGoogleAuthModal}
      >
          <div className="mt-1">
            <p className="text-sm text-[#5C715E] font-medium">
            Open an authentication app on your mobile device and scan the code below; then enter the 6-digit code provided. 
            </p>

            <div className="flex justify-center">
              <div className="mt-4 rounded-[8px] border border-[#5C715E] p-2">
                   <QRCode
                    size={191}
                    value={qr_code ? qr_code : 'otpauth://totp/SecretKey?secret=LNWVQ22DOBHUKY3MIJFEUKB6Idemosecretkey'}
                  />
                
              </div>
            </div>
            <div className="flex justify-center mt-3">
                <p className="text-[#5C715E] text-sm font-medium">Use the Google Authenticator app to scan the QR code</p>
            </div>

            <div className="mt-4">
                <label
                  htmlFor="secretkey"
                  className="block mb-2 text-[#344335] text-sm font-normal"
                >
                 If you are having problem scanning the QR Code, you can input it manually.
                </label>
                <div className="relative">
                <input
                  type="text"
                  name="secretkey"
                  placeholder="Secret key"
                  value={secretValue}
                  disabled
                  className='appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-sm focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                />
                  <img onClick={copySecretkey} src={copy_icon} alt="copy icon" className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer" />
                </div>
                
              </div>

            <div className="mt-4">
                <label
                  htmlFor="code"
                  className="block mb-2 text-[#344335] text-sm font-normal"
                >
                  Verification Code
                </label>
                <input
                  type="text"
                  name="code"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  placeholder="Enter Verification Code"
                  className='appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-sm focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                />
              </div>

              <div className="mt-5">
                <button
                  type="submit"
                  disabled={!verificationCode || loading}
                  onClick={verifyAuthCode}
                  className="bg-[#EDF7EE] 
                      disabled:bg-[#ECEFEC] disabled:text-[#96A397] w-full
                    rounded-lg px-6 py-4 text-[#2B8C34] text-sm font-medium transition-all"
                >
                  Authenticate Code
                </button>
              </div>
            

          </div>
      </ModalComponent>

      <div className="bg-white border border-[#ECEFEC] rounded-lg py-5 px-4">
        <div className="flex justify-between items-center">
          <div>
            <h6 className="text-primary font-semibold text-lg">Profile</h6>
          </div>
        </div>

        <ul className="flex gap-4 items-center mt-5">
          {type.map((val) => (
            <li
              key={val.id}
              onClick={() => toggleType(val.id)}
              className="cursor-pointer"
            >
              <p
                className={
                  typeId === val.id
                    ? "text-primary text-sm font-semibold border-b-2 border-primary"
                    : "text-[#96A397] text-sm font-semibold"
                }
              >
                {val.title}
              </p>
            </li>
          ))}
        </ul>
      </div>

      {/* Tab One */}
      <div className="grid grid-cols-3 gap-4 mt-5">
        <div className="bg-white rounded-lg py-5 px-4 h-fit">
          <div className="flex justify-center">
            <div className="flex justify-center items-center w-[48px] h-[48px] rounded-full bg-primary">
              <p className="text-white font-medium text-sm">
                {profile ? profile.first_name.charAt(0).toUpperCase() : "T"}
                {profile ? profile.last_name.charAt(0).toUpperCase() : "U"}
              </p>
            </div>
          </div>
          <div className="mt-3 text-center">
            <h6 className="text-[#344335] font-semibold text-base">
              {profile && profile.first_name ? profile.first_name : "Test"}{" "}
              {profile && profile.last_name ? profile.last_name : "User"}
            </h6>
            <p className="text-[#344335] font-medium text-sm">
              {" "}
              {profile && profile.email ? profile.email : "admin@gmail.com"}
            </p>
          </div>
        </div>
        <div className="col-span-2 bg-white rounded-lg pt-5 pb-8 px-5">
          {typeId === 1 && (
            <>
              <p className="text-[#7C8D7E] text-sm font-semibold">
                Account Information
              </p>
              <div className="max-w-2xl mt-4">
                <div className="flex gap-4">
                  <div className="flex-1">
                    <label
                      htmlFor="firstname"
                      className="block mb-2 text-[#344335] text-sm font-normal"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      disabled
                      name="firstname"
                      placeholder="First name"
                      onChange={(e) => setFirstname(e.target.value)}
                      value={firstname}
                      className="appearance-none disabled:border-[#ABB6AC] w-full disabled:bg-[#F7F7F7] placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                    />
                  </div>
                  <div className="flex-1">
                    <label
                      htmlFor="lastname"
                      className="block mb-2 text-[#344335] text-sm font-normal"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      disabled
                      name="lastname"
                      placeholder="Last name"
                      onChange={(e) => setLastname(e.target.value)}
                      value={lastname}
                      className="appearance-none disabled:border-[#ABB6AC] w-full disabled:bg-[#F7F7F7] placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                    />
                  </div>
                </div>

                <div className="flex gap-4 mt-4">
                  <div className="flex-1">
                    <label
                      htmlFor="email"
                      className="block mb-2 text-[#344335] text-sm font-normal"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      name="email"
                      disabled
                      placeholder="Email address"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      className="appearance-none w-full disabled:border-[#ABB6AC] disabled:bg-[#F7F7F7] placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                    />
                  </div>

                  <div className="flex-1">
                    <label
                      htmlFor="email"
                      className="block mb-2 text-[#344335] text-sm font-normal"
                    >
                      Department
                    </label>
                    <input
                      type="text"
                      name="department"
                      disabled
                      placeholder="Department"
                      value={department}
                      className="appearance-none w-full disabled:border-[#ABB6AC] disabled:bg-[#F7F7F7] placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {/* tab two control */}
          {typeId === 2 && (
            <>
              <p className="text-[#7C8D7E] text-sm font-semibold">
                Change Password
              </p>

              <Formik
                initialValues={{
                  oldpassword: "",
                  password: "",
                  password_confirmation: "",
                }}
                validationSchema={changePasswordValidator}
                onSubmit={(
                  values: Values,
                  { setSubmitting }: FormikHelpers<Values>
                ) => {
                  handleSubmit(values);
                }}
              >
                {({
                  handleChange,
                  isSubmitting,
                  handleSubmit,
                  handleBlur,
                  values,
                  touched,
                  errors,
                }) => (
                  <Form className="mt-4 max-w-xl" onSubmit={handleSubmit}>
                    <div className="">
                      <label
                        htmlFor="oldpassword"
                        className="block mb-2 text-secondary text-sm font-normal"
                      >
                        Old Password
                      </label>
                      <div className="relative">
                        <input
                          type={oldpasswordShown ? "text" : "password"}
                          name="oldpassword"
                          placeholder="Enter your Old Password"
                          className={
                            touched.oldpassword && errors.oldpassword
                              ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335]text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                              : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                          }
                          value={values.oldpassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <img
                          onClick={toggleOldPasswordVisiblity}
                          src={oldpasswordShown ? eye_off : eye}
                          alt="eye"
                          className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                        />
                      </div>
                      {touched.oldpassword && errors.oldpassword ? (
                        <div className="flex">
                          <img src={warning} className="" alt="warning" />
                          <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                            {touched.oldpassword && errors.oldpassword}
                          </small>
                        </div>
                      ) : null}
                    </div>

                    <div className="mt-5">
                      <label
                        htmlFor="password"
                        className="block mb-2 text-secondary text-sm font-normal"
                      >
                        New Password
                      </label>
                      <div className="relative">
                        <input
                          type={passwordShown ? "text" : "password"}
                          name="password"
                          placeholder="Enter your New Password"
                          className={
                            touched.password && errors.password
                              ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335]text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                              : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                          }
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <img
                          onClick={togglePasswordVisiblity}
                          src={passwordShown ? eye_off : eye}
                          alt="eye"
                          className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                        />
                      </div>
                      {touched.password && errors.password ? (
                        <div className="flex">
                          <img src={warning} className="" alt="warning" />
                          <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                            {touched.password && errors.password}
                          </small>
                        </div>
                      ) : null}
                    </div>

                    <div className="mt-5">
                      <label
                        htmlFor="password_confirmation"
                        className="block mb-2 text-secondary text-sm font-normal"
                      >
                        Confirm Password
                      </label>
                      <div className="relative">
                        <input
                          type={confirmpasswordShown ? "text" : "password"}
                          name="password_confirmation"
                          placeholder="Confirm your New Password"
                          className={
                            touched.password_confirmation &&
                            errors.password_confirmation
                              ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335]text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                              : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                          }
                          value={values.password_confirmation}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <img
                          onClick={toggleConfirmPasswordVisiblity}
                          src={confirmpasswordShown ? eye_off : eye}
                          alt="eye"
                          className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                        />
                      </div>
                      {touched.password_confirmation &&
                      errors.password_confirmation ? (
                        <div className="flex">
                          <img src={warning} className="" alt="warning" />
                          <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                            {touched.password_confirmation &&
                              errors.password_confirmation}
                          </small>
                        </div>
                      ) : null}
                    </div>

                    <div className="mt-6">
                      <button
                        type="submit"
                        disabled={loading || !profilePermissions.changePassword}
                        className="bg-primary 
                            disabled:bg-[#ABB6AC] 
                            rounded-lg w-full py-4 text-sm text-white font-medium hover:bg-[#24752B] transition-all"
                      >
                        Change Password
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          )}

          {/* two fa */}
          {typeId === 3 && (
            <>
            <div className="flex justify-between">
                 <div>
                  <h6 className="text-[#344335] font-semibold text-sm">Two-Factor Authentication</h6>
                  <p className="text-[#344335] font-medium text-sm mt-1 max-w-xl">Add an extra layer of protection for accessing your Titanium account with email or google authentication app </p>
                 </div>
                 <div className={`${is_2fa_enabled ? 'bg-[#DBFFDF] text-[#2B8C34]': 'bg-[#FDDED4] text-[#B92020]'}  rounded-[4px] ] text-xs font-medium py-1 px-2 h-fit`}>
                    {is_2fa_enabled ? 'Enabled': 'Disabled'} 
                 </div>
            </div>

            <div className="mt-5">
                <div className="flex items-center">
                <input
                    id="default-radio-1"
                    type="radio"
                    value="email"
                    name="email"
                    checked={selectedType === "email"}
                    onChange={handleType}
                    className="w-4 h-4 text-[#2B8C34] cursor-pointer bg-gray-100 border-gray-300 focus:ring-[#2B8C34] focus:ring-0 "
                  />
                  <label
                    htmlFor="default-radio-1"
                    className={`ml-2 text-sm font-medium ${
                      selectedType === "email"
                        ? "text-[#344335]"
                        : "text-[#5C715E]"
                    } text-[#344335]`}
                  >
                  Email Authentication
                  </label>
                </div>
                  <p className={`${selectedType === "email"  ? "text-[#344335]" : "text-[#5C715E]"}  text-sm font-urbanist font-medium mt-1`}>We will send a randomized 6-digit code to your email.</p>
              </div>
              <div className="mt-5">
                <div className="flex items-center">
                <input
                  id="default-radio-2"
                  type="radio"
                  value="auth"
                  name="auth"
                  checked={selectedType === "auth"}
                  onChange={handleType}
                  className="w-4 h-4 text-[#2B8C34] cursor-pointer bg-gray-100 border-gray-300 focus:ring-[#2B8C34] focus:ring-0 "
                />
                <label
                  htmlFor="default-radio-2"
                  className={`ml-2 text-sm font-medium ${
                    selectedType === "auth"
                      ? "text-[#344335]"
                      : "text-[#5C715E]"
                  } text-[#344335]`}
                >
                 Google Authenticator
                </label>
                </div>
                <p className={`${selectedType === "auth"  ? "text-[#344335]" : "text-[#5C715E]"}  text-sm font-urbanist font-medium mt-1`}>Keep your account extra secure with a second login step.</p>
              </div>
              {/* i want the button to show only when there is atoggle on the radio */}
             {selectedType !== otp_method || !is_2fa_enabled ?
               <div className="mt-6">
                <button
                        type="submit"
                        onClick={handle2faSetup}
                        disabled={loading}
                        className="bg-primary 
                            disabled:bg-[#ABB6AC] 
                            rounded-lg px-6 py-3 text-sm text-white font-medium hover:bg-[#24752B] transition-all"
                      >
                        Continue
                      </button>
              </div>
              : ""
              }
            </>
            )}
        </div>
      </div>
    </>
  );
};

export default ProfilePage;
