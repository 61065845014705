import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../config";
import axios from "axios";
import toast from "react-hot-toast";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

export const fetchAccounts = createAsyncThunk(
  "fetchAccounts",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}account/get-account`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200 || res.status === 201) {
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Oops, an error occured fetching all accounts!");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Oops, an error occured fetching all accounts!");
        return rejectWithValue(error);
      }
    }
  }
);

export const fetchMainAccount = createAsyncThunk(
  "fetchMainAccount",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}transaction/account-total/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200 || res.status === 201) {
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Oops, an error occured fetching main account!");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Oops, an error occured fetching all account!");
        return rejectWithValue(error);
      }
    }
  }
);

interface fetchAccountByIdType {
  account_id: string;
}
export const fetchAccountById = createAsyncThunk(
  "fetchAccountById",
  async (
    { account_id }: fetchAccountByIdType,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.get(
        `${apiUrl}account/get-account/?account_id=${account_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200 || res.status === 201) {
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Oops, an error occured fetching this accounts!");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Oops, an error occured fetching this accounts!");
        return rejectWithValue(error);
      }
    }
  }
);

type TransactionsParams = {
  page: number;
  id: string;
  type: string;
};
export const fetchAccountTransactions = createAsyncThunk(
  "fetchAccountTransactions",
  async ({ page, id, type }: TransactionsParams, { rejectWithValue, dispatch }) => {
    if( type === "main_account"){
      try {
        const res = await axios.get(
          `${apiUrl}transaction/all?page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          let data = res.data;
          return data;
        }
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          toast.error("An error occured while getting account transactions", {
            style: {
              border: "1px solid #B92043",
              backgroundColor: "#B92043",
              color: "#FFFFFF",
              fontSize: 14,
              maxWidth: 400,
            },
          });
          return rejectWithValue(error);
        } else if (error.response.status === 401) {
          dispatch({ type: "auth/logOut" });
          return rejectWithValue(error);
        } else {
          toast.error("An error occured while getting account transactions", {
            style: {
              border: "1px solid #B92043",
              backgroundColor: "#B92043",
              color: "#FFFFFF",
              fontSize: 14,
              maxWidth: 400,
            },
          });
          return rejectWithValue(error);
        }
      }
    }
    else{
      try {
        const res = await axios.get(
          `${apiUrl}transaction/all?page=${page}&account_id=${id} `,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          let data = res.data;
          return data;
        }
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          toast.error("An error occured while getting account transactions", {
            style: {
              border: "1px solid #B92043",
              backgroundColor: "#B92043",
              color: "#FFFFFF",
              fontSize: 14,
              maxWidth: 400,
            },
          });
          return rejectWithValue(error);
        } else if (error.response.status === 401) {
          dispatch({ type: "auth/logOut" });
          return rejectWithValue(error);
        } else {
          toast.error("An error occured while getting account transactions", {
            style: {
              border: "1px solid #B92043",
              backgroundColor: "#B92043",
              color: "#FFFFFF",
              fontSize: 14,
              maxWidth: 400,
            },
          });
          return rejectWithValue(error);
        }
      }
    }
   
  }
);

interface createAccountType {
  account_name: string;
}

export const createAccount = createAsyncThunk(
  "createAccount",
  async (credentials: createAccountType, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}account/create-account/`,
        {
          account_name: credentials.account_name,
          account_type: "sub_account",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 201) {
        let data = res.data;
        toast.success("Account created successfully!", {
          style: {
            border: "1px solid #2b8c34",
            backgroundColor: "#2b8c34",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Ooops, an error occured creating this account!");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Ooops, an error occured creating this account!");
        return rejectWithValue(error);
      }
    }
  }
);

type moveFundsType = {
  amount: number;
  source_account_id: string;
  recipient_account_id: string;
  narration: string;
};

export const moveFundsToAccount = createAsyncThunk(
  "moveFundsToAccount",
  async (credentials: moveFundsType, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(`${apiUrl}account/transfer`, credentials, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200 || res.status === 201) {
        let data = res.data;
        toast.success("Funds moved successfully!", {
          style: {
            border: "1px solid #2b8c34",
            backgroundColor: "#2b8c34",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        // toast.error("Something went wrong!");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        // toast.error("Something went wrong!");
        return rejectWithValue(error);
      }
    }
  }
);


type downloadStatementType = {
  from: string,
  to: string,
  account_id : string,
  email: string,
  format: string
};

export const downloadStatement = createAsyncThunk(
  "downloadStatement",
  async (credentials: downloadStatementType, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(`${apiUrl}transaction/statement/`, credentials, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200 || res.status === 201) {
        let data = res.data;
        toast.success(`${res.data.data.message}`, {
          style: {
            border: "1px solid #2b8c34",
            backgroundColor: "#2b8c34",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        // toast.error("Something went wrong!");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        // toast.error("Something went wrong!");
        return rejectWithValue(error);
      }
    }
  }
);