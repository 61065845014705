import React, { useEffect, useState } from "react";
import more_icon from "../../assets/icons/more_vert.svg";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import PaginationComponent from "../../components/Pagination/Pagination";
import moment from "moment";
import DrawerComponent from "../../components/Drawers/drawer";
import { useNavigate } from "react-router-dom";
import { fetchPrivileges } from "../../redux/Roles/roleActions";
import { categories } from "../../types/PermissionsCategories/PermissionsCategories";
import { CheckCircleOutline, HighlightOffOutlined } from "@mui/icons-material";
import { handleSelectedRole, roleType } from "../../redux/Roles/role";
import { useRolesAndPrivilegePermissions } from "../../helpers/permissionsHelper/roles&privileges";

const RolesTable = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { rolesAndPrivilegePermissions } = useRolesAndPrivilegePermissions();
  const { roles, loading, privileges } = useAppSelector((state) => state.role);
  const [selectedRole, setSelectedRole] = useState<roleType>({
    createdAt: "",
    detail: "",
    name: "",
    type: "",
    id: 0,
    updatedAt: "",
    privileges: [],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [openDropdownId, setOpenDropdownId] = useState<number | null>(null);
  const [viewRole, setViewRole] = useState(false);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const toggleDropdown = (id: number) => {
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };

  const handleSelectRole = (role: roleType) => {
    dispatch(handleSelectedRole(role));
  };

  useEffect(() => {
    dispatch(fetchPrivileges());
  }, [dispatch]);

  return (
    <>
      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          <p className="text-primary font-semibold text-base leading-5">
            Available Roles
          </p>
        </div>

        {/* tables */}

        <div className=" relative">
          {loading ? (
            <div>
              <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            </div>
          ) : (
            <>
              {!rolesAndPrivilegePermissions.viewRoles ? (
                <div>
                  <div className="w-full pb-[80px] mt-[80px] flex items-center justify-center">
                    <p>You don't have access to this data</p>
                  </div>
                </div>
              ) : (
                <>
                  {roles?.length === 0 ? (
                    <div>
                      <div className="w-full pb-[80px] mt-[80px] flex items-center justify-center">
                        <p>There are no roles added yet</p>
                      </div>
                    </div>
                  ) : (
                    <section>
                      <table className="w-full text-left relative">
                        <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                          <tr>
                            <th
                              scope="col"
                              className="px-4 py-4 font-semibold "
                            >
                              Role Name
                            </th>
                            <th
                              scope="col"
                              className="px-4 py-4 font-semibold "
                            >
                              Type
                            </th>
                            <th
                              scope="col"
                              className="px-4 py-4 font-semibold "
                            >
                              Created By
                            </th>
                            <th scope="col" className="px-4 py-4 font-semibold">
                              Teammates
                            </th>
                            <th scope="col" className="px-4 py-4 font-semibold">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {roles?.map((role, index) => {
                            return (
                              <tr
                                key={index}
                                className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                              >
                                <th
                                  scope="row"
                                  className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                                >
                                  {role.name}
                                </th>
                                <td className="py-4 px-4 text-[#5C715E]">
                                  {role.type}
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                  {moment(role?.createdAt).format("ll")} |
                                  {" " + moment(role?.createdAt).format("LT")}
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                  <div className="flex">
                                    <div className="bg-[#DBFFDF] text-primary text-sm py-3 px-3 rounded-[4px] flex justify-center items-center w-[8px] h-[8px] font-medium">
                                      <p>{role.teammateCount}</p>
                                    </div>
                                  </div>
                                </td>
                                <td className="py-4 px-4 relative text-[#5C715E]">
                                  <div className="">
                                    <img
                                      src={more_icon}
                                      alt="more icon"
                                      onClick={() => toggleDropdown(role?.id)}
                                    />

                                    {openDropdownId === role?.id && (
                                      <ul className="absolute mt-5 bg-white z-[6] rounded-lg shadow-dropdown py-2 px-2 right-10 top-6 min-w-[187px]">
                                        <li
                                          onClick={() => {
                                            setSelectedRole(role);
                                            setOpenDropdownId(null);
                                            setViewRole(true);
                                          }}
                                          className="py-3 px-2 cursor-pointer hover:bg-[#EDF7EE]"
                                        >
                                          <p className="text-[#344335] text-sm font-medium">
                                            View Role
                                          </p>
                                        </li>

                                        <li className="py-3 px-2 cursor-pointer hover:bg-[#EDF7EE]">
                                          <p
                                            onClick={() => {
                                              navigate(
                                                `/role/teammates/${role?.id}`
                                              );
                                            }}
                                            className="text-[#344335] text-sm font-medium"
                                          >
                                            View Teammates
                                          </p>
                                        </li>

                                        {rolesAndPrivilegePermissions.editRole &&
                                          role.type !== "Default" && (
                                            <li className="py-3 px-2 cursor-pointer hover:bg-[#EDF7EE]">
                                              <p
                                                onClick={() => {
                                                  handleSelectRole(role);
                                                  navigate("/edit-role");
                                                }}
                                                className="text-[#344335] text-sm font-medium"
                                              >
                                                Edit Role Actions
                                              </p>
                                            </li>
                                          )}
                                      </ul>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>

                      <div>
                        <PaginationComponent
                          page={currentPage}
                          onPageChange={(page) => onPageChange(1)}
                          totalItems={10}
                          pageSize={10}
                        />
                      </div>
                    </section>
                  )}
                </>
              )}
            </>
          )}

          {/* Drawer component */}
          <DrawerComponent
            title=""
            isOpen={viewRole}
            onClose={() => setViewRole(false)}
            width={`w-[926px]`}
            overflow={false}
          >
            <div>
              <div className="flex justify-between h-[90vh]">
                <div className="w-1/2 border-r border-r-[#DDE2DE] h-[90vh] pr-6">
                  <p className="font-semibold text-lg leading-[32px] text-[#344335]">
                    Role Details
                  </p>
                  <p className="mt-2 font-medium  text-sm leading-[18px] text-[#5C715E]">
                    This is the name assigned to a role
                  </p>

                  <div className="mt-7">
                    <div className="mb-4">
                      <p className="text-sm leading-[18px] text-[#344335] mb-2">
                        Role Name
                      </p>
                      <input
                        disabled
                        value={selectedRole?.name}
                        className="w-full disabled:text-[#5C715E] h-[44px] disabled:bg-white  text-sm font-medium leading-5 resize-none border border-[#96A397] rounded-lg outline-none px-3 flex justify-center items-center"
                      />
                    </div>

                    <div>
                      <p className="text-sm leading-[18px] text-[#344335] mb-2">
                        Role Description
                      </p>
                      <textarea
                        disabled
                        value={selectedRole?.detail}
                        className="w-full h-[140px]  disabled:text-[#5C715E] disabled:bg-white text-sm font-medium leading-5 resize-none border border-[#96A397] rounded-lg outline-none px-3 py-3"
                      />
                    </div>
                  </div>
                </div>

                <div className="w-1/2 border-l border-l-[#DDE2DE] h-[90vh] overflow-auto remove-scroll pl-6 relative">
                  <div className="fixed w-full bg-white">
                    <p className="font-semibold text-lg leading-[32px] text-[#344335]">
                      {selectedRole?.name} Role Privileges
                    </p>
                    <p className="mt-2 font-medium  text-sm leading-[18px] text-[#5C715E] mb-6">
                      {selectedRole?.name} will have the following privileges:
                    </p>
                  </div>

                  <div className="h-full overflow-auto remove-scroll mt-[20%] pr-4">
                    {/* PROFILE */}
                    <div className="mb-6">
                      <p className="text-[#344335] text-base font-semibold leading-[32px] mb-2">
                        {categories.PROFILE}
                      </p>
                      {privileges
                        ?.filter(
                          (privilege) =>
                            privilege?.category === categories.PROFILE
                        )
                        .map((privilege, index) => {
                          const isPrivilegeIncluded = selectedRole?.privileges
                            ?.map((p) => p.id)
                            .includes(privilege?.id);

                          return (
                            <React.Fragment key={index}>
                              <div className="flex justify-between items-center mb-2">
                                <p className="text-[#5C715E] text-sm font-medium">
                                  {privilege?.name || "Nill"}
                                </p>
                                <div>
                                  {isPrivilegeIncluded ? (
                                    <CheckCircleOutline
                                      style={{
                                        color: "#2B8C34",
                                        fontSize: "18px",
                                      }}
                                    />
                                  ) : (
                                    <HighlightOffOutlined
                                      style={{
                                        color: "#B92020",
                                        fontSize: "18px",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                    </div>

                    {/* PAYMENT */}
                    <div className="mb-6">
                      <p className="text-[#344335] text-base font-semibold leading-[32px] mb-2">
                        {categories.PAYMENT}
                      </p>
                      {privileges
                        ?.filter(
                          (privilege) =>
                            privilege?.category === categories.PAYMENT
                        )
                        .map((privilege, index) => {
                          const isPrivilegeIncluded = selectedRole?.privileges
                            ?.map((p) => p.id)
                            .includes(privilege?.id);

                          return (
                            <React.Fragment key={index}>
                              <div className="flex justify-between items-center mb-2">
                                <p className="text-[#5C715E] text-sm font-medium">
                                  {privilege?.name || "Nill"}
                                </p>
                                <div>
                                  {isPrivilegeIncluded ? (
                                    <CheckCircleOutline
                                      style={{
                                        color: "#2B8C34",
                                        fontSize: "18px",
                                      }}
                                    />
                                  ) : (
                                    <HighlightOffOutlined
                                      style={{
                                        color: "#B92020",
                                        fontSize: "18px",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                    </div>

                    {/* TRANSACTION */}
                    <div className="mb-6">
                      <p className="text-[#344335] text-base font-semibold leading-[32px] mb-2">
                        {categories.TRANSACTION}
                      </p>
                      {privileges
                        ?.filter(
                          (privilege) =>
                            privilege?.category === categories.TRANSACTION
                        )
                        .map((privilege, index) => {
                          const isPrivilegeIncluded = selectedRole?.privileges
                            ?.map((p) => p.id)
                            .includes(privilege?.id);

                          return (
                            <React.Fragment key={index}>
                              <div className="flex justify-between items-center mb-2">
                                <p className="text-[#5C715E] text-sm font-medium">
                                  {privilege?.name || "Nill"}
                                </p>
                                <div>
                                  {isPrivilegeIncluded ? (
                                    <CheckCircleOutline
                                      style={{
                                        color: "#2B8C34",
                                        fontSize: "18px",
                                      }}
                                    />
                                  ) : (
                                    <HighlightOffOutlined
                                      style={{
                                        color: "#B92020",
                                        fontSize: "18px",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                    </div>

                    {/* ACCOUNT */}
                    <div className="mb-6">
                      <p className="text-[#344335] text-base font-semibold leading-[32px] mb-2">
                        {categories.ACCOUNT}
                      </p>
                      {privileges
                        ?.filter(
                          (privilege) =>
                            privilege?.category === categories.ACCOUNT
                        )
                        .map((privilege, index) => {
                          const isPrivilegeIncluded = selectedRole?.privileges
                            ?.map((p) => p.id)
                            .includes(privilege?.id);

                          return (
                            <React.Fragment key={index}>
                              <div className="flex justify-between items-center mb-2">
                                <p className="text-[#5C715E] text-sm font-medium">
                                  {privilege?.name || "Nill"}
                                </p>
                                <div>
                                  {isPrivilegeIncluded ? (
                                    <CheckCircleOutline
                                      style={{
                                        color: "#2B8C34",
                                        fontSize: "18px",
                                      }}
                                    />
                                  ) : (
                                    <HighlightOffOutlined
                                      style={{
                                        color: "#B92020",
                                        fontSize: "18px",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                    </div>

                    {/* RECIPIENT */}
                    <div className="mb-6">
                      <p className="text-[#344335] text-base font-semibold leading-[32px] mb-2">
                        {categories.RECIPIENT}
                      </p>
                      {privileges
                        ?.filter(
                          (privilege) =>
                            privilege?.category === categories.RECIPIENT
                        )
                        .map((privilege, index) => {
                          const isPrivilegeIncluded = selectedRole?.privileges
                            ?.map((p) => p.id)
                            .includes(privilege?.id);

                          return (
                            <React.Fragment key={index}>
                              <div className="flex justify-between items-center mb-2">
                                <p className="text-[#5C715E] text-sm font-medium">
                                  {privilege?.name || "Nill"}
                                </p>
                                <div>
                                  {isPrivilegeIncluded ? (
                                    <CheckCircleOutline
                                      style={{
                                        color: "#2B8C34",
                                        fontSize: "18px",
                                      }}
                                    />
                                  ) : (
                                    <HighlightOffOutlined
                                      style={{
                                        color: "#B92020",
                                        fontSize: "18px",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                    </div>

                    {/* TEAMMATES */}
                    <div className="mb-6">
                      <p className="text-[#344335] text-base font-semibold leading-[32px] mb-2">
                        {categories.TEAMMATES}
                      </p>
                      {privileges
                        ?.filter(
                          (privilege) =>
                            privilege?.category === categories.TEAMMATES
                        )
                        .map((privilege, index) => {
                          const isPrivilegeIncluded = selectedRole?.privileges
                            ?.map((p) => p.id)
                            .includes(privilege?.id);

                          return (
                            <React.Fragment key={index}>
                              <div className="flex justify-between items-center mb-2">
                                <p className="text-[#5C715E] text-sm font-medium">
                                  {privilege?.name || "Nill"}
                                </p>
                                <div>
                                  {isPrivilegeIncluded ? (
                                    <CheckCircleOutline
                                      style={{
                                        color: "#2B8C34",
                                        fontSize: "18px",
                                      }}
                                    />
                                  ) : (
                                    <HighlightOffOutlined
                                      style={{
                                        color: "#B92020",
                                        fontSize: "18px",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                    </div>

                    {/* ROLES_PRIVILEDGES */}
                    <div className="mb-6">
                      <p className="text-[#344335] text-base font-semibold leading-[32px] mb-2">
                        {categories.ROLES_PRIVILEDGES?.replace("_", " ")}
                      </p>
                      {privileges
                        ?.filter(
                          (privilege) =>
                            privilege?.category === categories.ROLES_PRIVILEDGES
                        )
                        .map((privilege, index) => {
                          const isPrivilegeIncluded = selectedRole?.privileges
                            ?.map((p) => p.id)
                            .includes(privilege?.id);

                          return (
                            <React.Fragment key={index}>
                              <div className="flex justify-between items-center mb-2">
                                <p className="text-[#5C715E] text-sm font-medium">
                                  {privilege?.name || "Nill"}
                                </p>
                                <div>
                                  {isPrivilegeIncluded ? (
                                    <CheckCircleOutline
                                      style={{
                                        color: "#2B8C34",
                                        fontSize: "18px",
                                      }}
                                    />
                                  ) : (
                                    <HighlightOffOutlined
                                      style={{
                                        color: "#B92020",
                                        fontSize: "18px",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                    </div>

                    {/* SETTINGS */}
                    <div className="mb-6">
                      <p className="text-[#344335] text-lg font-semibold leading-[32px] mb-2">
                        {categories.SETTINGS}
                      </p>
                      {privileges
                        ?.filter(
                          (privilege) =>
                            privilege?.category === categories.SETTINGS
                        )
                        .map((privilege, index) => {
                          const isPrivilegeIncluded = selectedRole?.privileges
                            ?.map((p) => p.id)
                            .includes(privilege?.id);

                          return (
                            <React.Fragment key={index}>
                              <div className="flex justify-between items-center mb-2">
                                <p className="text-[#5C715E] text-sm font-medium">
                                  {privilege?.name || "Nill"}
                                </p>
                                <div>
                                  {isPrivilegeIncluded ? (
                                    <CheckCircleOutline
                                      style={{
                                        color: "#2B8C34",
                                        fontSize: "18px",
                                      }}
                                    />
                                  ) : (
                                    <HighlightOffOutlined
                                      style={{
                                        color: "#B92020",
                                        fontSize: "18px",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DrawerComponent>
        </div>
      </div>
    </>
  );
};

export default RolesTable;
