import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { createRoles, fetchPrivileges } from "../../redux/Roles/roleActions";
import { categories } from "../../types/PermissionsCategories/PermissionsCategories";
import { useNavigate } from "react-router-dom";
import GoBack from "../../components/GoBack";

const CreateRolePage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { privileges, creationLoading } = useAppSelector((state) => state.role);
  const { selectedAccount } = useAppSelector((state) => state.auth);
  const [permisions, setPermissions] = useState<string[]>([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const handleSelectPriviledge = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const value = event.target.value;
    if (value === "yes") {
      setPermissions((permisions) => [...permisions, id]);
    } else {
      const filteredPermission = permisions?.filter(
        (permision) => permision !== id
      );
      setPermissions(filteredPermission);
    }
  };

  const handleCreateRole = () => {
    const values = {
      name,
      type: "Custom",
      account_id: selectedAccount?.id,
      detail: description,
      privileges: permisions,
    };

    dispatch(createRoles(values)).then((res) => {
      if (res.type === "createRoles/fulfilled") {
        navigate("/roles");
      }
    });
  };

  useEffect(() => {
    dispatch(fetchPrivileges());
  }, [dispatch]);

  return (
    <>
      <div className="mb-5">
        <GoBack />
      </div>
      <Header text={"New Role"} />
      <div className="mt-3 bg-[#FFFFFF] rounded-[8px] py-6 px-4">
        {/* name */}
        <div className="grid grid-cols-3 gap-4">
          <div>
            <p className="text-[#344335] text-sm font-semibold">Role Name</p>
          </div>
          <div className="col-span-2">
            <div className="max-w-[500px]">
              <label
                htmlFor="email"
                className="block mb-2 text-[#344335] text-sm font-normal"
              >
                What do you want to call this role?
              </label>
              <input
                onChange={(event) => setName(event.target.value)}
                type="text"
                name="email"
                placeholder="Role Name"
                className="appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-sm focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
              />
            </div>
          </div>
        </div>

        {/* description */}
        <div className="grid grid-cols-3 gap-4 mt-5">
          <div>
            <p className="text-[#344335] text-sm font-semibold">
              Role Description
            </p>
          </div>
          <div className="col-span-2">
            <div className="max-w-[500px]">
              <label
                htmlFor="email"
                className="block mb-2 text-[#344335] text-sm font-normal"
              >
                Give more description to this role
              </label>
              <textarea
                onChange={(event) => setDescription(event.target.value)}
                placeholder="Role Description"
                rows={4}
                className="appearance-none resize-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-sm focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
              />
            </div>
          </div>
        </div>

        {/* permisions */}

        {privileges.length !== 0 && (
          <>
          
            {/* PROFILE */}
            <div className="grid grid-cols-3 gap-4 mt-8">
              <div>
                <p className="text-[#344335] text-sm font-semibold">
                  {categories.PROFILE}
                </p>
              </div>

              <div className="grid grid-cols-2 col-span-2 gap-4">
                {privileges
                  ?.filter(
                    (privilege) => privilege?.category === categories.PROFILE
                  )
                  .map((privilege, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div>
                          <p className="text-[#344335] text-sm font-semibold">
                            {privilege?.name || "Nill"}
                          </p>
                        </div>

                        <div className="flex gap-8 mb-3">
                          <div className="flex items-center">
                            <input
                              onChange={(event) =>
                                handleSelectPriviledge(event, privilege.id)
                              }
                              type="radio"
                              value="yes"
                              name={privilege.name}
                              className="w-4 h-4 text-blue-600 cursor-pointer bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-0 "
                            />
                            <label
                              htmlFor="default-radio-1"
                              className="ml-2 text-sm font-medium text-[#344335]"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              onChange={(event) =>
                                handleSelectPriviledge(event, privilege.id)
                              }
                              type="radio"
                              value="no"
                              name={privilege.name}
                              className="w-4 h-4 text-blue-600  cursor-pointer bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-0 "
                            />
                            <label
                              htmlFor="default-radio-2"
                              className="ml-2 text-sm font-normal text-[#5C715E]"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
              </div>
            </div>

            {/* PAYMENT */}
            <div className="grid grid-cols-3 gap-4 mt-8">
              <div>
                <p className="text-[#344335] text-sm font-semibold">
                  {categories.PAYMENT}
                </p>
              </div>

              <div className="grid grid-cols-2 col-span-2 gap-4">
                {privileges
                  ?.filter(
                    (privilege) => privilege?.category === categories.PAYMENT
                  )
                  .map((privilege, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div>
                          <p className="text-[#344335] text-sm font-semibold">
                            {privilege?.name || "Nill"}
                          </p>
                        </div>

                        <div className="flex gap-8 mb-3">
                          <div className="flex items-center">
                            <input
                              onChange={(event) =>
                                handleSelectPriviledge(event, privilege.id)
                              }
                              // id="default-radio-1"
                              type="radio"
                              value="yes"
                              name={privilege.name}
                              className="w-4 h-4 text-blue-600 cursor-pointer bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-0 "
                            />
                            <label
                              htmlFor="default-radio-1"
                              className="ml-2 text-sm font-medium text-[#344335]"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              onChange={(event) =>
                                handleSelectPriviledge(event, privilege.id)
                              }
                              type="radio"
                              value="no"
                              name={privilege.name}
                              className="w-4 h-4 text-blue-600  cursor-pointer bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-0 "
                            />
                            <label
                              htmlFor="default-radio-2"
                              className="ml-2 text-sm font-normal text-[#5C715E]"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
              </div>
            </div>

            {/* TRANSACTION */}
            <div className="grid grid-cols-3 gap-4 mt-8">
              <div>
                <p className="text-[#344335] text-sm font-semibold">
                  {categories.TRANSACTION}
                </p>
              </div>

              <div className="grid grid-cols-2 col-span-2 gap-4">
                {privileges
                  ?.filter(
                    (privilege) =>
                      privilege?.category === categories.TRANSACTION
                  )
                  .map((privilege, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div>
                          <p className="text-[#344335] text-sm font-semibold">
                            {privilege?.name || "Nill"}
                          </p>
                        </div>

                        <div className="flex gap-8 mb-3">
                          <div className="flex items-center">
                            <input
                              onChange={(event) =>
                                handleSelectPriviledge(event, privilege.id)
                              }
                              // id="default-radio-1"
                              type="radio"
                              value="yes"
                              name={privilege.name}
                              className="w-4 h-4 text-blue-600 cursor-pointer bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-0 "
                            />
                            <label
                              htmlFor="default-radio-1"
                              className="ml-2 text-sm font-medium text-[#344335]"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              onChange={(event) =>
                                handleSelectPriviledge(event, privilege.id)
                              }
                              type="radio"
                              value="no"
                              name={privilege.name}
                              className="w-4 h-4 text-blue-600  cursor-pointer bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-0 "
                            />
                            <label
                              htmlFor="default-radio-2"
                              className="ml-2 text-sm font-normal text-[#5C715E]"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
              </div>
            </div>

            {/* ACCOUNTS */}
            <div className="grid grid-cols-3 gap-4 mt-8">
              <div>
                <p className="text-[#344335] text-sm font-semibold">
                  {categories.ACCOUNT}
                </p>
              </div>

              <div className="grid grid-cols-2 col-span-2 gap-4">
                {privileges
                  ?.filter(
                    (privilege) => privilege?.category === categories.ACCOUNT
                  )
                  .map((privilege, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div>
                          <p className="text-[#344335] text-sm font-semibold">
                            {privilege?.name || "Nill"}
                          </p>
                        </div>

                        <div className="flex gap-8 mb-3">
                          <div className="flex items-center">
                            <input
                              onChange={(event) =>
                                handleSelectPriviledge(event, privilege.id)
                              }
                              // id="default-radio-1"
                              type="radio"
                              value="yes"
                              name={privilege.name}
                              className="w-4 h-4 text-blue-600 cursor-pointer bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-0 "
                            />
                            <label
                              htmlFor="default-radio-1"
                              className="ml-2 text-sm font-medium text-[#344335]"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              onChange={(event) =>
                                handleSelectPriviledge(event, privilege.id)
                              }
                              type="radio"
                              value="no"
                              name={privilege.name}
                              className="w-4 h-4 text-blue-600  cursor-pointer bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-0 "
                            />
                            <label
                              htmlFor="default-radio-2"
                              className="ml-2 text-sm font-normal text-[#5C715E]"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
              </div>
            </div>

            {/* Recipient */}
            <div className="grid grid-cols-3 gap-4 mt-8">
              <div>
                <p className="text-[#344335] text-sm font-semibold">
                  {categories.RECIPIENT}
                </p>
              </div>

              <div className="grid grid-cols-2 col-span-2 gap-4">
                {privileges
                  ?.filter(
                    (privilege) => privilege?.category === categories.RECIPIENT
                  )
                  .map((privilege, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div>
                          <p className="text-[#344335] text-sm font-semibold">
                            {privilege?.name || "Nill"}
                          </p>
                        </div>

                        <div className="flex gap-8 mb-3">
                          <div className="flex items-center">
                            <input
                              onChange={(event) =>
                                handleSelectPriviledge(event, privilege.id)
                              }
                              // id="default-radio-1"
                              type="radio"
                              value="yes"
                              name={privilege.name}
                              className="w-4 h-4 text-blue-600 cursor-pointer bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-0 "
                            />
                            <label
                              htmlFor="default-radio-1"
                              className="ml-2 text-sm font-medium text-[#344335]"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              onChange={(event) =>
                                handleSelectPriviledge(event, privilege.id)
                              }
                              type="radio"
                              value="no"
                              name={privilege.name}
                              className="w-4 h-4 text-blue-600  cursor-pointer bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-0 "
                            />
                            <label
                              htmlFor="default-radio-2"
                              className="ml-2 text-sm font-normal text-[#5C715E]"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
              </div>
            </div>

            {/* TEAMMATES */}
            <div className="grid grid-cols-3 gap-4 mt-8">
              <div>
                <p className="text-[#344335] text-sm font-semibold">
                  {categories.TEAMMATES}
                </p>
              </div>

              <div className="grid grid-cols-2 col-span-2 gap-4">
                {privileges
                  ?.filter(
                    (privilege) => privilege?.category === categories.TEAMMATES
                  )
                  .map((privilege, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div>
                          <p className="text-[#344335] text-sm font-semibold">
                            {privilege?.name || "Nill"}
                          </p>
                        </div>

                        <div className="flex gap-8 mb-3">
                          <div className="flex items-center">
                            <input
                              onChange={(event) =>
                                handleSelectPriviledge(event, privilege.id)
                              }
                              // id="default-radio-1"
                              type="radio"
                              value="yes"
                              name={privilege.name}
                              className="w-4 h-4 text-blue-600 cursor-pointer bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-0 "
                            />
                            <label
                              htmlFor="default-radio-1"
                              className="ml-2 text-sm font-medium text-[#344335]"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              onChange={(event) =>
                                handleSelectPriviledge(event, privilege.id)
                              }
                              type="radio"
                              value="no"
                              name={privilege.name}
                              className="w-4 h-4 text-blue-600  cursor-pointer bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-0 "
                            />
                            <label
                              htmlFor="default-radio-2"
                              className="ml-2 text-sm font-normal text-[#5C715E]"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
              </div>
            </div>

            {/* ROLES PRIVILDEGES */}
            <div className="grid grid-cols-3 gap-4 mt-8">
              <div>
                <p className="text-[#344335] text-sm font-semibold">
                  {categories.ROLES_PRIVILEDGES?.replace("_", " ")}
                </p>
              </div>

              <div className="grid grid-cols-2 col-span-2 gap-4">
                {privileges
                  ?.filter(
                    (privilege) =>
                      privilege?.category === categories.ROLES_PRIVILEDGES
                  )
                  .map((privilege, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div>
                          <p className="text-[#344335] text-sm font-semibold">
                            {privilege?.name || "Nill"}
                          </p>
                        </div>

                        <div className="flex gap-8 mb-3">
                          <div className="flex items-center">
                            <input
                              onChange={(event) =>
                                handleSelectPriviledge(event, privilege.id)
                              }
                              // id="default-radio-1"
                              type="radio"
                              value="yes"
                              name={privilege.name}
                              className="w-4 h-4 text-blue-600 cursor-pointer bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-0 "
                            />
                            <label
                              htmlFor="default-radio-1"
                              className="ml-2 text-sm font-medium text-[#344335]"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              onChange={(event) =>
                                handleSelectPriviledge(event, privilege.id)
                              }
                              type="radio"
                              value="no"
                              name={privilege.name}
                              className="w-4 h-4 text-blue-600  cursor-pointer bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-0 "
                            />
                            <label
                              htmlFor="default-radio-2"
                              className="ml-2 text-sm font-normal text-[#5C715E]"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
              </div>
            </div>

            {/* SETTING */}
            <div className="grid grid-cols-3 gap-4 mt-8">
              <div>
                <p className="text-[#344335] text-sm font-semibold">
                  {categories.SETTINGS}
                </p>
              </div>

              <div className="grid grid-cols-2 col-span-2 gap-4">
                {privileges
                  ?.filter(
                    (privilege) => privilege?.category === categories.SETTINGS
                  )
                  .map((privilege, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div>
                          <p className="text-[#344335] text-sm font-semibold">
                            {privilege?.name || "Nill"}
                          </p>
                        </div>

                        <div className="flex gap-8 mb-3">
                          <div className="flex items-center">
                            <input
                              onChange={(event) =>
                                handleSelectPriviledge(event, privilege.id)
                              }
                              // id="default-radio-1"
                              type="radio"
                              value="yes"
                              name={privilege.name}
                              className="w-4 h-4 text-blue-600 cursor-pointer bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-0 "
                            />
                            <label
                              htmlFor="default-radio-1"
                              className="ml-2 text-sm font-medium text-[#344335]"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              onChange={(event) =>
                                handleSelectPriviledge(event, privilege.id)
                              }
                              type="radio"
                              value="no"
                              name={privilege.name}
                              className="w-4 h-4 text-blue-600  cursor-pointer bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-0 "
                            />
                            <label
                              htmlFor="default-radio-2"
                              className="ml-2 text-sm font-normal text-[#5C715E]"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
              </div>
            </div>
          </>
        )}

        {/* cta buttons */}
        <div className="flex justify-end gap-4 mt-10">
          <div>
            <button
              type="submit"
              className="bg-[#FEF0EC] 
                                disabled:bg-[#ABB6AC] 
                                rounded-lg w-full py-3 px-6 text-[#B92020] text-sm font-medium transition-all"
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              disabled={
                name !== "" &&
                description !== "" &&
                permisions.length !== 0 &&
                creationLoading === false
                  ? false
                  : true
              }
              onClick={handleCreateRole}
              type="submit"
              className="bg-primary 
                                disabled:bg-[#ABB6AC] 
                                rounded-lg w-full py-3 px-6 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
            >
              Save Role
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateRolePage;
