import React from "react";
import Header from "../../components/Header";
import TransactionsTable from "../../tables/Transactions";

const Transaction = () => {
  return (
    <>
      <Header text={"Transactions"} />

      {/* tables */}
      <TransactionsTable />
    </>
  );
};

export default Transaction;
