import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { getAllTransactions } from "../../redux/Transaction/transactionActions";
import moment from "moment";
import {
  Transaction,
  TransactionType,
} from "../../types/Transactions/Transactions";
import DrawerComponent from "../../components/Drawers/drawer";
import PaginationComponent from "../../components/Pagination/Pagination";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const TransactionsTable = () => {
  const dispatch = useAppDispatch();
  const { transactions, loading, total } = useAppSelector(
    (state) => state.transactions
  );
  const { selectedAccount } = useAppSelector((state) => state.auth);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isOpen, setIsOpen] = useState(false);
  const [transaction, setTransaction] = useState<Transaction | null>(null);
  const [tab, setTab] = useState("payment_details");
  const [isToggleAmount, setIsToggleAmount] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [minAmt, setMinAmt] = useState(0);
  const [maxAmt, setMaxAmt] = useState(0);

  const handleViewPayment = (transaction: Transaction) => {
    setTransaction(transaction);
    setIsOpen(true);
  };

  const onPageSizeChange = (page: number) =>{
    setPageSize(page)
 }

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    const start = moment(startDate).format();
    const end = moment(endDate).format();
    dispatch(
      getAllTransactions({
        page: page,
        per_page: pageSize,
        start: start ? start : null,
        end: end ? end : null,
        min: minAmt === 0 ? null : minAmt,
        max: maxAmt === 0 ? null : maxAmt,
        id:
          selectedAccount?.type === "main_account"
            ? null
            : selectedAccount?.account_id,
      })
    );
  };

  const handleClosePayment = () => {
    setIsOpen(false);
    setTab("payment_details");
    setTransaction(null);
  };

  const formatNumber = (numberToFormat: string) => {
    let res = Number(numberToFormat).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  const handleFilter = () => {
    const start = moment(startDate).format();
    const end = moment(endDate).format();
    setIsToggleAmount(false);
    dispatch(
      getAllTransactions({
        page: 1,
        per_page: pageSize,
        start: start,
        end: end,
        min: minAmt === 0 ? null : minAmt,
        max: maxAmt === 0 ? null : maxAmt,
        id:
          selectedAccount?.type === "main_account"
            ? null
            : selectedAccount?.account_id,
      })
    );
  };

  const handleClearFilter = () => {
    setStartDate("");
    setEndDate("");
    setMinAmt(0);
    setMaxAmt(0);
    setIsToggleAmount(false);

    dispatch(
      getAllTransactions({
        page: 1,
        per_page: pageSize,
        start: null,
        end: null,
        min: null,
        max: null,
        id:
          selectedAccount?.type === "main_account"
            ? null
            : selectedAccount?.account_id,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getAllTransactions({
        page: 1,
        per_page: pageSize,
        start: null,
        end: null,
        min: null,
        max: null,
        id:
          selectedAccount?.type === "main_account"
            ? null
            : selectedAccount?.account_id,
      })
    );
  }, [dispatch, selectedAccount, pageSize]);

  return (
    <>
      <div className="mt-5 bg-white rounded-[8px] min-h-[100px]">
        <div className="flex justify-between items-center w-full">
          <div className="flex gap-6 p-4">
            {/* START DATE */}
            <div>
              <p className="pb-1 text-sm leading-[18px] text-[#344335]">
                Start Date
              </p>
              <input
                type="date"
                max={moment().format("YYYY-MM-DD")}
                value={startDate}
                onChange={(event) => setStartDate(event.target.value)}
                className="px-4 py-3 rounded-lg h-[45px] text-sm text-[#344335] border-[1px] uppercase border-[#96A397] cursor-pointer outline-none focus:border-[#2B8C34] focus:shadow-[0px_0px_0px_2px_#D5E8D6]"
              />
            </div>

            {/* END DATE */}
            <div>
              <p className="pb-1 text-sm leading-[18px] text-[#344335]">
                End Date
              </p>
              <input
                type="date"
                max={moment().format("YYYY-MM-DD")}
                value={endDate}
                onChange={(event) => setEndDate(event.target.value)}
                className="px-4 py-3 rounded-lg h-[45px] text-sm border-[1px] text-[#344335] uppercase border-[#96A397] focus:border-[#2B8C34] focus:shadow-[0px_0px_0px_2px_#D5E8D6] cursor-pointer outline-none"
              />
            </div>

            {/* AMOUNT */}
            <div>
              <p className="pb-1 text-sm leading-[18px] text-[#344335]">
                Amount
              </p>
              <div className="relative">
                <div
                  className={`flex cursor-pointer  h-[45px] justify-between gap-2 items-center w-full rounded-lg border border-[#96A397] bg-white py-3 px-4 text-[#7C8D7E] text-sm`}
                  style={
                    isToggleAmount
                      ? { boxShadow: "0px 0px 0px 2px #D5E8D6" }
                      : {}
                  }
                  onClick={() => setIsToggleAmount(!isToggleAmount)}
                >
                  <div className="flex flex-wrap  gap-2 items-center ">
                    <p className="rounded-[4px] text-sm  px-1 py-[2px] text-[#344335] flex items-center gap-2">
                      ₦{minAmt?.toLocaleString()} - ₦{maxAmt?.toLocaleString()}
                    </p>
                  </div>

                  <div className="">
                    {isToggleAmount ? (
                      <ExpandLess
                        style={{ fontSize: "20px", color: "#344335" }}
                      />
                    ) : (
                      <ExpandMore
                        style={{ fontSize: "20px", color: "#344335" }}
                      />
                    )}
                  </div>
                </div>

                {isToggleAmount && (
                  <div
                    style={{
                      boxShadow:
                        "0px 16px 32px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.25)",
                    }}
                    className="absolute mt-[11px] bg-white p-2 flex gap-3 items-center justify-between rounded-lg z-30"
                  >
                    <div className="">
                      <p className="pb-1 text-sm leading-[18px] text-[#344335]">
                        Minimum Amount
                      </p>

                      <div className="px-4 py-3 rounded-lg border-[1px] border-[#96A397] bg-white flex items-center text-sm leading-[18px] text-[#5C715E]">
                        <p className="">₦</p>
                        <input
                          min={0}
                          type="number"
                          onChange={(event) =>
                            setMinAmt(Number(event.target.value))
                          }
                          value={minAmt === 0 ? "" : minAmt}
                          className="border-none outline-none max-w-[120px]"
                        />
                      </div>
                    </div>
                    <div className="">
                      <p className="text-xl font-semibold leading-[32px] text-[#344335]">
                        -
                      </p>
                    </div>
                    <div className="">
                      <p className="pb-1 text-sm leading-[18px] text-[#344335]">
                        Maximum Account
                      </p>

                      <div className="px-4 py-3 rounded-lg border-[1px] border-[#96A397] bg-white flex items-center text-sm leading-[18px] text-[#5C715E]">
                        <p className="">₦</p>
                        <input
                          min={0}
                          type="number"
                          onChange={(event) =>
                            setMaxAmt(Number(event.target.value))
                          }
                          value={maxAmt === 0 ? "" : maxAmt}
                          className="border-none outline-none max-w-[120px]"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex gap-2 items-center mr-4">
            <div
              className={`rounded-lg px-6 py-3 bg-[#EDF7EE] cursor-pointer  `}
              onClick={handleFilter}
            >
              <p className=" text-sm font-medium leading-[20px] text-[#2B8C34]">
                Apply Filter
              </p>
            </div>

            <div
              className="rounded-lg px-6 py-3 bg-none cursor-pointer"
              onClick={handleClearFilter}
            >
              <p className=" text-sm font-medium leading-[20px] text-[#2B8C34]">
                Clear Filter
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4 bg-white rounded-[8px] relative">
        <div className="p-4">
          <p className="text-primary font-semibold text-base leading-5">
            Transactions
          </p>
        </div>

        {/* tables */}
        <div className="relative">
          {loading ? (
            <div>
              <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            </div>
          ) : (
            <>
              {transactions?.length === 0 ? (
                <div>
                  <div className="w-full pb-[80px] mt-[80px] flex items-center justify-center">
                    <p>There's no transaction data yet</p>
                  </div>
                </div>
              ) : (
                <section>
                  <table className="w-full text-left">
                    <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                      <tr>
                        <th scope="col" className="px-4 py-4 font-semibold ">
                          Date
                        </th>
                        <th scope="col" className="px-4 py-4 font-semibold">
                          Sender/Receiver
                        </th>
                        <th scope="col" className="px-4 py-4 font-semibold">
                          Payment Memo
                        </th>
                        <th scope="col" className="px-4 py-4 font-semibold">
                          Transaction Type
                        </th>
                        <th scope="col" className="px-4 py-4 font-semibold">
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactions?.map((transaction, index) => {
                        return (
                          <tr
                            key={index}
                            onClick={() => handleViewPayment(transaction)}
                            className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                          >
                            <th
                              scope="row"
                              className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                            >
                              {moment(transaction?.created_at).format("lll")}
                            </th>
                            <td className="py-4 px-4 text-[#5C715E]">
                              {transaction?.counter_party || "N/A"}
                            </td>
                            <td className="py-4 px-4 text-[#5C715E]">
                              {transaction?.narration || "N/A"}
                            </td>
                            <td className="py-4 px-4 text-[#5C715E]">
                              <div className="flex capitalize">
                                <div
                                  className={`${
                                    transaction?.transaction_type ===
                                    TransactionType.CREDIT
                                      ? "success-badge"
                                      : transaction?.transaction_type ===
                                        TransactionType.DEBIT
                                      ? "failed-badge"
                                      : transaction?.transaction_type ===
                                        TransactionType.REFUND
                                      ? "success-badge"
                                      : null
                                  }`}
                                >
                                  <p>
                                    {transaction?.transaction_type || "N/A"}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td className="py-4 px-4 text-[#5C715E]">
                              ₦
                              {transaction
                                ? formatNumber(transaction?.amount)
                                : 0}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  <div>
                    <PaginationComponent
                      page={currentPage}
                      onPageChange={(page) => onPageChange(page)}
                      totalItems={total}
                      pageSize={pageSize}
                      onPageSizeChange={(page) => onPageSizeChange(page)}
                      pageSizeShow
                    />
                  </div>
                </section>
              )}
            </>
          )}
        </div>
      </div>

      {/* Drawer component */}
      <DrawerComponent title="" isOpen={isOpen} onClose={handleClosePayment}>
        <div className="mt-8 flex flex-col justify-center items-center">
          <div className="w-[48px] h-[48px] rounded-full bg-[#DBFFDF] text-[#2B8C34] flex justify-center font-medium items-center text-sm">
            {transaction?.sender?.charAt(0) || "N/A"}
          </div>
          <h4 className="text-[#344335] font-semibold text-2xl mt-2">
            ₦{transaction?.amount ? formatNumber(transaction?.amount) : "N/A"}
          </h4>
          <p className="text-[#5C715E] text-sm font-medium mt-1">
            {transaction?.sender || "N/A"}
          </p>
        </div>

        <ul className="flex justify-around mt-8 items-center gap-5">
          <li
            className={`flex gap-1 items-center ${
              tab === "payment_details"
                ? "border-b-2 border-primary text-primary"
                : "text-[#96A397]"
            }  pb-1 cursor-pointer`}
            onClick={() => setTab("payment_details")}
          >
            <p className=" text-sm font-semibold">Payment Details</p>
          </li>
          <li
            className={`flex gap-2 items-center ${
              tab === "parties_involved"
                ? "border-b-2 border-primary text-primary"
                : "text-[#96A397]"
            }  pb-1 cursor-pointer`}
            onClick={() => setTab("parties_involved")}
          >
            <p className=" text-sm font-semibold">Parties Involved</p>
          </li>
        </ul>

        {tab === "payment_details" ? (
          <section>
            <div className="mt-10">
              <div className="flex justify-between items-center">
                <p className="text-[#5C715E] text-xs">Amount</p>
                <p className="text-[#5C715E] text-xs">
                  ₦
                  {transaction?.amount
                    ? formatNumber(transaction?.amount)
                    : "N/A"}
                </p>
              </div>
            </div>

            <div className="mt-5">
              <div className="flex justify-between items-center">
                <p className="text-[#5C715E] text-xs">Date</p>
                <p className="text-[#5C715E] text-xs">
                  {moment(transaction?.created_at).format("ll")} |
                  {" " + moment(transaction?.created_at).format("LT")}
                </p>
              </div>
            </div>

            <div className="mt-5">
              <div className="flex justify-between items-center">
                <p className="text-[#5C715E] text-xs">Sender/Receiver</p>
                <p className="text-[#5C715E] text-xs">
                  {" "}
                  {transaction?.counter_party || "N/A"}
                </p>
              </div>
            </div>

            <div className="mt-5">
              <div className="flex justify-between items-center">
                <p className="text-[#5C715E] text-xs">Bank Name</p>
                <p className="text-[#5C715E] text-xs">
                  {" "}
                  {transaction?.payment?.recipient?.bank?.name || "N/A"}
                </p>
              </div>
            </div>

            <div className="mt-5">
              <div className="flex justify-between items-center">
                <p className="text-[#5C715E] text-xs">Account Number</p>
                <p className="text-[#5C715E] text-xs">
                  {" "}
                  {transaction?.payment?.recipient?.account_number || "N/A"}
                </p>
              </div>
            </div>

            <div className="mt-5">
              <div className="flex justify-between items-center">
                <p className="text-[#5C715E] text-xs">Source Account</p>
                <p className="text-[#5C715E] text-xs">
                  {" "}
                  {transaction?.c2c_account?.account_name || "N/A"}
                </p>
              </div>
            </div>

            <div className="mt-5">
              <div className="flex justify-between items-center">
                <p className="text-[#5C715E] text-xs">Transaction Type</p>
                <div
                  className={`${
                    transaction?.transaction_type === TransactionType.CREDIT
                      ? "success-badge capitalize"
                      : transaction?.transaction_type === TransactionType.DEBIT
                      ? "failed-badge capitalize"
                      : transaction?.transaction_type === TransactionType.REFUND
                      ? "success-badge"
                      : null
                  }`}
                >
                  {transaction?.transaction_type || "N/A"}
                </div>
              </div>
            </div>

            <div className="mt-5">
              <div className="flex justify-between items-center">
                <p className="text-[#5C715E] text-xs">Payment ID</p>
                <p className="text-[#5C715E] text-xs">
                  {" "}
                  {transaction?.payment?.id || "N/A"}
                </p>
              </div>
            </div>

            {/* <div className="mt-5">
              <div className="flex justify-between items-center">
                <p className="text-[#5C715E] text-xs">Ledger ID</p>
                <p className="text-[#5C715E] text-xs">{"N/A"}</p>
              </div>
            </div> */}

            <div className="mt-5">
              <div className="flex justify-between items-center">
                <p className="text-[#5C715E] text-xs">Transaction Memo</p>
                <p className="text-[#5C715E] text-xs">
                  {" "}
                  {transaction?.narration || "N/A"}
                </p>
              </div>
            </div>
          </section>
        ) : (
          <section>
            <div className="mt-6">
              <div className="flex flex-col gap-[7px]">
                <p className="text-xs leading-4 text-[#5C715E]">
                  Payment Initiated by:
                </p>
                <div className="flex gap-1 items-center">
                  <div className="w-[24px] h-[24px] capitalize rounded-full bg-[#DBFFDF] text-[#2B8C34] flex justify-center font-medium items-center text-[10px]">
                    {transaction?.payment?.initiated_by?.charAt(0) || "N/A"}
                  </div>
                  <p className="text-sm font-medium leading-[18px] text-[#5C715E]">
                    {transaction?.payment?.initiated_by || "N/A"}
                  </p>
                </div>
                <p className="text-sm font-medium leading-[18px] text-[#5C715E]">
                  {moment(transaction?.payment?.created_at).format("ll")} |{" "}
                  {moment(transaction?.payment?.created_at).format("LT")}
                </p>
              </div>
            </div>

            <div className="mt-6">
              <div className="flex flex-col gap-[7px]">
                <p className="text-xs leading-4 text-[#5C715E]">
                  Payment Approved by:
                </p>
                <div className="flex gap-1 items-center">
                  <div className="w-[24px] h-[24px] rounded-full capitalize bg-[#DBFFDF] text-[#2B8C34] flex justify-center font-medium items-center text-[10px]">
                    {transaction?.payment?.approved_by?.charAt(0) || "N/A"}
                  </div>
                  <p className="text-sm font-medium leading-[18px] text-[#5C715E]">
                    {transaction?.payment?.approved_by || "N/A"}
                  </p>
                </div>
                <p className="text-sm font-medium leading-[18px] text-[#5C715E]">
                  {moment(transaction?.payment?.updated).format("ll")} |{" "}
                  {moment(transaction?.payment?.updated).format("LT")}
                </p>
              </div>
            </div>
          </section>
        )}
      </DrawerComponent>
    </>
  );
};

export default TransactionsTable;
