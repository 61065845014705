import React, { useState, useEffect } from "react";
import logo from "../../assets/images/c2c-logo.png";

import notification_icon from "../../assets/icons/notifications.svg";
import chevron_down from "../../assets/icons/chevron_down.svg";
import chevron_up from "../../assets/icons/chevron_up.svg";
import logout_icon from "../../assets/icons/logout.svg";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  ExpandLess,
  ExpandMore,
  Group,
  Payments,
  SupervisedUserCircle,
  ReceiptLong
} from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { handleSaveAccount, logOut } from "../../redux/Auth/auth";
import { fetchAccounts } from "../../redux/Account/accountActions";
// import { AccountsType } from "../../types/Accounts/Accounts";
import { ThreeDots } from "react-loader-spinner";
// import { handleSaveAllAccounts } from "../../redux/Account/account";
import { getUserAccountPrivilege } from "../../redux/Auth/authActions";
import { useRolesAndPrivilegePermissions } from "../../helpers/permissionsHelper/roles&privileges";
import { useRecipientPermissions } from "../../helpers/permissionsHelper/recipientPermissions";
import { useTransactionPermissions } from "../../helpers/permissionsHelper/transactionPermissions";
import { useTeammatesPermissions } from "../../helpers/permissionsHelper/teammatesPermissions";

type sidebarType = {
  children: React.ReactNode;
  title: string;
};

const Sidebar = ({ children, title }: sidebarType) => {
  let location = useLocation();
  const { selectedAccount } = useAppSelector((state) => state.auth);
  const { teammatesPermissions } = useTeammatesPermissions();
  const { rolesAndPrivilegePermissions } = useRolesAndPrivilegePermissions();
  const { recipientPermissions } = useRecipientPermissions();
  const { transactionPermissions } = useTransactionPermissions();
  const [loading, setLoading] = useState(false);
  const [isAdministrationHover, setIsAdministrationHover] = useState(true);
  const [isTransactionHover, setIsTransactionHover] = useState(false);
  const [isRecipientHover, setIsRecipientHover] = useState(false);
  const [isRolesHover, setIsRolesHover] = useState(false);
  const [isToggleAccount, setIsToggleAccount] = useState(false);
  const [selectedAccountState, setSelectedAccountState] = useState<{
    id: number;
    name: string;
    type: string;
    account_id: string;
  }>({
    id: 0,
    name: "",
    type: "",
    account_id: "",
  });
  const dispatch = useAppDispatch();
  const [isNavMenu, setNavMenu] = React.useState(false);
  const navigate = useNavigate();
  const { profile } = useAppSelector((state) => state.auth);
  const { accounts } = useAppSelector((state) => state.account);

  const toggleNavMenu = () => {
    setNavMenu(!isNavMenu);
  };

  const handleSelectAcct = (
    id: number,
    name: string,
    type: string,
    account_id: string
  ) => {
    // setDefaultAcct(id)

    setIsToggleAccount(false);
    // find account selected
    // let acct = accounts.find((val) => val.id === id)?.account_name;
    // setSelectedAccountState(acct ? acct : null);
    setSelectedAccountState({ id, name, type, account_id });
    const savedAccount = {
      id,
      name,
      type,
      account_id,
    };
    if (type === "sub_account") {
      dispatch(
        getUserAccountPrivilege({
          account_id: account_id,
        })
      );
    }
    dispatch(handleSaveAccount(savedAccount));
  };

  const handleLogout = () => {
    dispatch(logOut());
    navigate("/");
  };

  useEffect(() => {
    setLoading(true);
    dispatch(fetchAccounts()).then((res) => {
      if (res?.type === "fetchAccounts/fulfilled") {
        if (selectedAccount?.name === "") {
          // const accounts = res?.payload?.data;
          // const filteredAcct = accounts?.filter(
          //   (account: AccountsType) => account?.account_type === "main_account"
          // );
          // setSelectedAccountState({
          //   id: filteredAcct[0]?.account_id,
          //   name: filteredAcct[0]?.account_name,
          //   type: filteredAcct[0]?.account_type,
          // });
          // const savedAccount = {
          //   account_id: "",
          //   id: 0,
          //   name: "Select an account",
          //   type: "main_account",
          // };
          // setSelectedAccountState(savedAccount);
          setLoading(false);
          // dispatch(handleSaveAccount(savedAccount));
        } else {
          setSelectedAccountState(selectedAccount);
          setLoading(false);
        }
      } else if (res?.type === "fetchAccounts/rejected") {
        setLoading(false);
      }
    });
  }, [dispatch, selectedAccount]);

  useEffect(() => {
    //ADD NEW  MAIN ACCOUNT TO ACCOUNTS STATE
    const filteredAcct = accounts?.filter(
      (account) => account?.account_type === "main_account"
    );
    if (filteredAcct.length < 1) {
      // let newacct = {
      //   id: "2024-02-05T13:17:42.914845Z",
      //   created: "",
      //   updated: "",
      //   account_name: "Titanium",
      //   account_number: "",
      //   account_id: "1",
      //   account_type: "main_account",
      //   account_balance: "",
      //   monthly_budget: "",
      // };
      // dispatch(handleSaveAllAccounts(newacct));
    }
  }, [accounts, dispatch]);

  return (
    <div className="hidden lg:flex h-screen bg-[#f5f5f5]">
      <div className="md:w-64 bg-primary flex-shrink-0 overflow-y-scroll pt-[30px] sidebar_scroll">
        <img src={logo} alt="logo" className="pl-2  overflow-y-scroll" />

        <div className="my-[26px] mx-2 relative">
          <div
            className={`py-3 px-4 flex justify-between items-center bg-[#24752B]  cursor-pointer ${
              isToggleAccount ? "rounded-b-none rounded-t-lg " : "rounded-lg"
            }`}
            onClick={() => setIsToggleAccount(!isToggleAccount)}
          >
            {loading ? (
              <div>
                {" "}
                <ThreeDots
                  visible={true}
                  height="20"
                  width="20"
                  color="#fff"
                  radius="9"
                />
              </div>
            ) : (
              <p className="text-sm font-medium leading-5 text-white">
                {selectedAccountState?.name || "Select an account"}
              </p>
            )}

            {isToggleAccount ? (
              <ExpandLess style={{ color: "white", fontSize: "20px" }} />
            ) : (
              <ExpandMore style={{ color: "white", fontSize: "20px" }} />
            )}
          </div>

          {isToggleAccount && (
            <div className="absolute bg-[#24752B] rounded-b-lg w-full py-2 px-3 flex flex-col gap-3 cursor-pointer max-h-[250px] overflow-y-scroll remove-scroll">
              {accounts?.map((account, index) => {
                return (
                  <>
                    {selectedAccount?.name !== account?.account_name && (
                      <p
                        className="text-sm font-medium py-1 px-1 text-white"
                        key={index}
                        onClick={() =>
                          handleSelectAcct(
                            account.id,
                            account?.account_name,
                            account?.account_type,
                            account.account_id
                          )
                        }
                      >
                        {account.account_name}
                      </p>
                    )}
                  </>
                );
              })}
            </div>
          )}
        </div>

        <div className=" cursor-pointer mt-[40px]">
          {/* OVERVIEW AND NOTIFICATIONS SECTION */}
          <section>
            <NavLink
              to="/dashboard"
              className={
                location.pathname === "/dashboard"
                  ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-primary bg-white w-[98%] rounded-r-[8px]"
                  : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-white hover:text-primary hover:bg-white w-[98%] rounded-r-[8px]"
              }
            >
              <DashboardIcon style={{ fontSize: "18px" }} />
              <p className="font-semibold text-sm capitalize leading-[18px]">
                Dashboard
              </p>
            </NavLink>

            <NavLink
              to="/payment"
              className={
                location.pathname.includes("/payment")
                  ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-primary bg-white w-[98%] rounded-r-[8px]"
                  : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-white hover:text-primary hover:bg-white w-[98%] rounded-r-[8px]"
              }
            >
              <Payments style={{ fontSize: "18px" }} />
              <p className="font-semibold text-sm capitalize leading-[18px]">
                Payment
              </p>
            </NavLink>

            {transactionPermissions?.viewTransaction && (
              <NavLink
                to="/transaction"
                className={
                  location.pathname === "/transaction"
                    ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-primary bg-white w-[98%] rounded-r-[8px]"
                    : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-white hover:text-primary hover:bg-white w-[98%] rounded-r-[8px]"
                }
                onMouseEnter={() => setIsTransactionHover(true)}
                onMouseLeave={() => setIsTransactionHover(false)}
              >
                <>
                  {location.pathname.includes("/transaction") ||
                  isTransactionHover ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_158_15129)">
                        <path
                          d="M11.6668 10C11.6668 10.9192 10.9193 11.6667 10.0001 11.6667C9.08098 11.6667 8.33348 10.9192 8.33348 10C8.33348 9.08083 9.08098 8.33333 10.0001 8.33333C10.9193 8.33333 11.6668 9.08083 11.6668 10ZM20.001 12.9567C20.001 14.6758 18.9176 16.2433 17.306 16.8575C16.1885 17.2842 15.1326 17.5 14.166 17.5C12.8043 17.5 11.5843 17.1675 10.4051 16.8458C9.28181 16.5392 8.22181 16.25 7.08348 16.25C5.77431 16.25 4.94848 16.3342 4.23431 16.5408C3.22931 16.83 2.17264 16.635 1.33431 16.0042C0.48681 15.3667 0.000976562 14.3933 0.000976562 13.3333V7.04333C0.000976562 5.32417 1.08348 3.75667 2.69514 3.1425C3.81264 2.71583 4.86931 2.5 5.83514 2.5C7.19681 2.5 8.41598 2.8325 9.59598 3.15417C10.7193 3.46083 11.7793 3.75 12.9176 3.75C14.226 3.75 15.0526 3.66583 15.7668 3.45917C16.7735 3.17 17.8301 3.365 18.6676 3.99583C19.5151 4.63333 20.001 5.60667 20.001 6.66667V12.9567ZM5.00098 11.6667C5.00098 11.2067 4.62764 10.8333 4.16764 10.8333C3.70764 10.8333 3.33431 11.2067 3.33431 11.6667C3.33431 12.1267 3.70764 12.5 4.16764 12.5C4.62764 12.5 5.00098 12.1267 5.00098 11.6667ZM5.00098 6.66667C5.00098 6.20667 4.62764 5.83333 4.16764 5.83333C3.70764 5.83333 3.33431 6.20667 3.33431 6.66667C3.33431 7.12667 3.70764 7.5 4.16764 7.5C4.62764 7.5 5.00098 7.12667 5.00098 6.66667ZM13.3343 10C13.3343 8.16167 11.8393 6.66667 10.001 6.66667C8.16264 6.66667 6.66764 8.16167 6.66764 10C6.66764 11.8383 8.16264 13.3333 10.001 13.3333C11.8393 13.3333 13.3343 11.8383 13.3343 10ZM16.6676 13.3333C16.6676 12.8733 16.2943 12.5 15.8343 12.5C15.3743 12.5 15.001 12.8733 15.001 13.3333C15.001 13.7933 15.3743 14.1667 15.8343 14.1667C16.2943 14.1667 16.6676 13.7933 16.6676 13.3333ZM16.6676 8.33333C16.6676 7.87333 16.2943 7.5 15.8343 7.5C15.3743 7.5 15.001 7.87333 15.001 8.33333C15.001 8.79333 15.3743 9.16667 15.8343 9.16667C16.2943 9.16667 16.6676 8.79333 16.6676 8.33333Z"
                          fill="#2B8C34"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_158_15129">
                          <rect width="20" height="20" fill="#2B8C34" />
                        </clipPath>
                      </defs>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_158_15129)">
                        <path
                          d="M11.6668 10C11.6668 10.9192 10.9193 11.6667 10.0001 11.6667C9.08098 11.6667 8.33348 10.9192 8.33348 10C8.33348 9.08083 9.08098 8.33333 10.0001 8.33333C10.9193 8.33333 11.6668 9.08083 11.6668 10ZM20.001 12.9567C20.001 14.6758 18.9176 16.2433 17.306 16.8575C16.1885 17.2842 15.1326 17.5 14.166 17.5C12.8043 17.5 11.5843 17.1675 10.4051 16.8458C9.28181 16.5392 8.22181 16.25 7.08348 16.25C5.77431 16.25 4.94848 16.3342 4.23431 16.5408C3.22931 16.83 2.17264 16.635 1.33431 16.0042C0.48681 15.3667 0.000976562 14.3933 0.000976562 13.3333V7.04333C0.000976562 5.32417 1.08348 3.75667 2.69514 3.1425C3.81264 2.71583 4.86931 2.5 5.83514 2.5C7.19681 2.5 8.41598 2.8325 9.59598 3.15417C10.7193 3.46083 11.7793 3.75 12.9176 3.75C14.226 3.75 15.0526 3.66583 15.7668 3.45917C16.7735 3.17 17.8301 3.365 18.6676 3.99583C19.5151 4.63333 20.001 5.60667 20.001 6.66667V12.9567ZM5.00098 11.6667C5.00098 11.2067 4.62764 10.8333 4.16764 10.8333C3.70764 10.8333 3.33431 11.2067 3.33431 11.6667C3.33431 12.1267 3.70764 12.5 4.16764 12.5C4.62764 12.5 5.00098 12.1267 5.00098 11.6667ZM5.00098 6.66667C5.00098 6.20667 4.62764 5.83333 4.16764 5.83333C3.70764 5.83333 3.33431 6.20667 3.33431 6.66667C3.33431 7.12667 3.70764 7.5 4.16764 7.5C4.62764 7.5 5.00098 7.12667 5.00098 6.66667ZM13.3343 10C13.3343 8.16167 11.8393 6.66667 10.001 6.66667C8.16264 6.66667 6.66764 8.16167 6.66764 10C6.66764 11.8383 8.16264 13.3333 10.001 13.3333C11.8393 13.3333 13.3343 11.8383 13.3343 10ZM16.6676 13.3333C16.6676 12.8733 16.2943 12.5 15.8343 12.5C15.3743 12.5 15.001 12.8733 15.001 13.3333C15.001 13.7933 15.3743 14.1667 15.8343 14.1667C16.2943 14.1667 16.6676 13.7933 16.6676 13.3333ZM16.6676 8.33333C16.6676 7.87333 16.2943 7.5 15.8343 7.5C15.3743 7.5 15.001 7.87333 15.001 8.33333C15.001 8.79333 15.3743 9.16667 15.8343 9.16667C16.2943 9.16667 16.6676 8.79333 16.6676 8.33333Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_158_15129">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  )}
                </>
                <p className="font-semibold text-sm capitalize leading-[18px]">
                  Transaction
                </p>
              </NavLink>
            )}

            <NavLink
              to="/account"
              className={
                location.pathname === "/account"
                  ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-primary bg-white w-[98%] rounded-r-[8px]"
                  : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-white hover:text-primary hover:bg-white w-[98%] rounded-r-[8px]"
              }
            >
              <Group style={{ fontSize: "18px" }} />
              <p className="font-semibold text-sm capitalize leading-[18px]">
                Account
              </p>
            </NavLink>

            {recipientPermissions?.viewRecipient && (
              <NavLink
                to="/recipient"
                className={
                  location.pathname === "/recipient"
                    ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-primary bg-white w-[98%] rounded-r-[8px]"
                    : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-white hover:text-primary hover:bg-white w-[98%] rounded-r-[8px]"
                }
                onMouseEnter={() => setIsRecipientHover(true)}
                onMouseLeave={() => setIsRecipientHover(false)}
              >
                <>
                  {location.pathname.includes("/recipient") ||
                  isRecipientHover ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M9.9998 11.666C5.8248 11.666 2.4248 14.466 2.4248 17.916C2.4248 18.1493 2.60814 18.3327 2.84147 18.3327H17.1581C17.3915 18.3327 17.5748 18.1493 17.5748 17.916C17.5748 14.466 14.1748 11.666 9.9998 11.666Z"
                        fill="#2B8C34"
                      />
                      <path
                        d="M10.0002 1.66602C9.01683 1.66602 8.11683 2.00768 7.40016 2.58268C6.44183 3.34102 5.8335 4.51602 5.8335 5.83268C5.8335 6.61602 6.05016 7.34935 6.44183 7.97435C7.1585 9.18268 8.47516 9.99935 10.0002 9.99935C11.0502 9.99935 12.0085 9.61602 12.7418 8.95768C13.0668 8.68268 13.3502 8.34935 13.5668 7.97435C13.9502 7.34935 14.1668 6.61602 14.1668 5.83268C14.1668 3.53268 12.3002 1.66602 10.0002 1.66602ZM12.1585 5.38268L9.9335 7.43268C9.7835 7.57435 9.59183 7.64102 9.40016 7.64102C9.20016 7.64102 9.00016 7.56602 8.85016 7.41602L7.82516 6.38268C7.51683 6.07435 7.51683 5.58268 7.82516 5.27435C8.1335 4.96602 8.62516 4.96602 8.9335 5.27435L9.42516 5.76602L11.1002 4.22435C11.4168 3.93268 11.9085 3.94935 12.2002 4.26602C12.4918 4.59102 12.4752 5.09102 12.1585 5.38268Z"
                        fill="#2B8C34"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M9.9998 11.666C5.8248 11.666 2.4248 14.466 2.4248 17.916C2.4248 18.1493 2.60814 18.3327 2.84147 18.3327H17.1581C17.3915 18.3327 17.5748 18.1493 17.5748 17.916C17.5748 14.466 14.1748 11.666 9.9998 11.666Z"
                        fill="white"
                      />
                      <path
                        d="M10.0002 1.66602C9.01683 1.66602 8.11683 2.00768 7.40016 2.58268C6.44183 3.34102 5.8335 4.51602 5.8335 5.83268C5.8335 6.61602 6.05016 7.34935 6.44183 7.97435C7.1585 9.18268 8.47516 9.99935 10.0002 9.99935C11.0502 9.99935 12.0085 9.61602 12.7418 8.95768C13.0668 8.68268 13.3502 8.34935 13.5668 7.97435C13.9502 7.34935 14.1668 6.61602 14.1668 5.83268C14.1668 3.53268 12.3002 1.66602 10.0002 1.66602ZM12.1585 5.38268L9.9335 7.43268C9.7835 7.57435 9.59183 7.64102 9.40016 7.64102C9.20016 7.64102 9.00016 7.56602 8.85016 7.41602L7.82516 6.38268C7.51683 6.07435 7.51683 5.58268 7.82516 5.27435C8.1335 4.96602 8.62516 4.96602 8.9335 5.27435L9.42516 5.76602L11.1002 4.22435C11.4168 3.93268 11.9085 3.94935 12.2002 4.26602C12.4918 4.59102 12.4752 5.09102 12.1585 5.38268Z"
                        fill="white"
                      />
                    </svg>
                  )}
                </>
                <p className="font-semibold text-sm capitalize leading-[18px]">
                  Recipient
                </p>
              </NavLink>
            )}

            <section>
        
                <div
                  className="flex justify-between items-center pl-[26.6px] cursor-pointer pb-[8px]"
                  onClick={() =>
                    setIsAdministrationHover(!isAdministrationHover)
                  }
                >
                  <p className="font-bold text-xs leading-[18px] text-[#EDF7EE] uppercase">
                    Administration
                  </p>
                  {isAdministrationHover ? (
                    <ExpandLess
                      style={{
                        fontSize: "17px",
                        marginRight: "20px",
                        color: "#FFF",
                      }}
                    />
                  ) : (
                    <ExpandMore
                      style={{
                        fontSize: "17px",
                        marginRight: "20px",
                        color: "#FFF",
                      }}
                    />
                  )}
                </div>
      

              {isAdministrationHover && (
                <>
                  {teammatesPermissions.viewTeammates && (
                    <>
                      <>
                        <NavLink
                          to="/teammates"
                          className={
                            location.pathname === "/teammates" ||
                            location.pathname === "/teammates/invites"
                              ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-primary bg-white w-[98%] rounded-r-[8px]"
                              : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-white hover:text-primary hover:bg-white w-[98%] rounded-r-[8px]"
                          }
                        >
                          <SupervisedUserCircle style={{ fontSize: "18px" }} />
                          <p className="font-semibold text-sm capitalize leading-[18px]">
                            Teammates
                          </p>
                        </NavLink>
                      </>
                    </>
                  )}

                  {rolesAndPrivilegePermissions.viewRoles && (
                    <NavLink
                      to="/roles"
                      className={
                        location.pathname.includes("/role") ||
                        location.pathname === "/roles" ||
                        location.pathname === "/edit-role"
                          ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-primary bg-white w-[98%] rounded-r-[8px]"
                          : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-white hover:text-primary hover:bg-white w-[98%] rounded-r-[8px]"
                      }
                      onMouseEnter={() => setIsRolesHover(true)}
                      onMouseLeave={() => setIsRolesHover(false)}
                    >
                      <>
                        {location.pathname.includes("/role") ||
                        location.pathname === "/edit-role" ||
                        isRolesHover ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M9.99984 15.4167V16.15C9.99984 17.7083 9.37484 18.3333 7.80817 18.3333H3.84984C2.6415 18.3333 1.6665 17.3583 1.6665 16.15V12.1917C1.6665 10.625 2.2915 10 3.84984 10H4.58317V12.9167C4.58317 14.3 5.69984 15.4167 7.08317 15.4167H9.99984Z"
                              fill="#2B8C34"
                            />
                            <path
                              d="M14.1668 11.2507V11.9757C14.1668 13.184 13.1835 14.1673 11.9752 14.1673H8.01683C6.4585 14.1673 5.8335 13.5423 5.8335 11.9757V8.01732C5.8335 6.80898 6.8085 5.83398 8.01683 5.83398H8.75016V8.75065C8.75016 10.134 9.86683 11.2507 11.2502 11.2507H14.1668Z"
                              fill="#2B8C34"
                            />
                            <path
                              d="M18.3333 3.84935V7.80768C18.3333 9.37435 17.7083 9.99935 16.1417 9.99935H12.1833C10.625 9.99935 10 9.37435 10 7.80768V3.84935C10 2.29102 10.625 1.66602 12.1833 1.66602H16.1417C17.7083 1.66602 18.3333 2.29102 18.3333 3.84935Z"
                              fill="#2B8C34"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M9.99984 15.4167V16.15C9.99984 17.7083 9.37484 18.3333 7.80817 18.3333H3.84984C2.6415 18.3333 1.6665 17.3583 1.6665 16.15V12.1917C1.6665 10.625 2.2915 10 3.84984 10H4.58317V12.9167C4.58317 14.3 5.69984 15.4167 7.08317 15.4167H9.99984Z"
                              fill="white"
                            />
                            <path
                              d="M14.1668 11.2507V11.9757C14.1668 13.184 13.1835 14.1673 11.9752 14.1673H8.01683C6.4585 14.1673 5.8335 13.5423 5.8335 11.9757V8.01732C5.8335 6.80898 6.8085 5.83398 8.01683 5.83398H8.75016V8.75065C8.75016 10.134 9.86683 11.2507 11.2502 11.2507H14.1668Z"
                              fill="white"
                            />
                            <path
                              d="M18.3333 3.84935V7.80768C18.3333 9.37435 17.7083 9.99935 16.1417 9.99935H12.1833C10.625 9.99935 10 9.37435 10 7.80768V3.84935C10 2.29102 10.625 1.66602 12.1833 1.66602H16.1417C17.7083 1.66602 18.3333 2.29102 18.3333 3.84935Z"
                              fill="white"
                            />
                          </svg>
                        )}
                      </>
                      <p className="font-semibold text-sm capitalize leading-[18px]">
                        Roles and Privileges
                      </p>
                    </NavLink>
                  )}
              
                    <NavLink
                      to="/audit-log"
                      className={
                        location.pathname.includes("/audit")
                          ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-primary bg-white w-[98%] rounded-r-[8px]"
                          : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-white hover:text-primary hover:bg-white w-[98%] rounded-r-[8px]"
                      }
                    >
                      <ReceiptLong style={{fontSize: "18px"}} />
                      <p className="font-semibold text-sm capitalize leading-[18px]">
                       Audit Log
                      </p>
                    </NavLink> 
                
                </>
              )}
            </section>
          </section>
        </div>
      </div>

      <div className="py-4 flex-grow flex-auto flex-shrink overflow-y-scroll">
        {/* NAV BAR */}
        <div className="flex px-4 md:px-6 justify-between items-center">
          <div>
            <p className="text-sm font-semibold leading-4 text-[#344335]">
              {title}
            </p>
          </div>
          <div className="flex gap-4 items-center">
            <div className="bg-[#2B8C34] flex justify-center items-center w-[32px] h-[32px] rounded-full relative">
              <img src={notification_icon} alt="notification" />
              <div className="bg-[#B92043] w-[10px] h-[10px] rounded-full absolute -right-1 top-1 border-white border-2"></div>
            </div>
            <div className="bg-[#2B8C34] flex justify-center items-center w-[32px] h-[32px] rounded-full">
              <p className="text-white text-xs font-medium">
                {profile ? profile.first_name.charAt(0).toUpperCase() : "I"}
                {profile ? profile.last_name.charAt(0).toUpperCase() : "B"}
              </p>
            </div>
            <div
              className="flex items-center gap-2 cursor-pointer relative"
              onClick={toggleNavMenu}
            >
              <p className="text-[#5C715E] text-sm font-medium">
                {profile && profile.first_name ? profile.first_name : "Isaac"}{" "}
                {profile && profile.last_name ? profile.last_name : "Bluman"}
              </p>
              <img
                src={isNavMenu ? chevron_up : chevron_down}
                alt="chevron arrow"
              />

              <ul
                className={
                  isNavMenu
                    ? "absolute top-10 transition-all right-0 bg-[#fff] rounded-[10px] min-w-[162px] border shadow-drop z-10"
                    : "absolute hidden transition-all top-20 right-0 bg-[#fff] rounded-[10px] min-w-[162px] border shadow-drop"
                }
              >
                <Link
                  to="/profile"
                  className="px-6 py-3 hover:bg-[#EDF7EE] flex hover:rounded-tr-[10px] hover:rounded-tl-[10px] transition-all"
                >
                  <p className="text-[#344335] text-sm font-medium">
                    View Profile
                  </p>
                </Link>
                <li
                  onClick={handleLogout}
                  className="px-6 py-3 hover:bg-[#EDF7EE] hover:rounded-bl-[10px] hover:rounded-br-[10px] transition-all flex item-center gap-2"
                >
                  <img src={logout_icon} alt="logout" />
                  <p className="text-[#344335] text-sm font-medium">Log Out</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="px-4 md:px-6 py-6">{children}</div>
      </div>
    </div>
  );
};

export default Sidebar;
