import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseApiUrl, apiUrl } from "../config";
import axios from "axios";
import toast from "react-hot-toast";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

type fetchTeammateType = {
  account_id: number;
  role_id?: string;
};

export const fetchTeammates = createAsyncThunk(
  "fetchTeammates",
  async (creds: fetchTeammateType, { rejectWithValue, dispatch }) => {
    if (creds.role_id) {
      try {
        const res = await axios.get(
          `${baseApiUrl}/teammates?account_id=${creds.account_id}&role_id=${creds.role_id}`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": `${getToken()}`,
            },
          }
        );
        if (res.status === 200 || res.status === 201) {
          return res.data;
        }
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          toast.error("Oops, an error occured fetching all accounts!");
          return rejectWithValue(error);
        } else if (error.response.status === 401) {
          dispatch({ type: "auth/logOut" });
          return rejectWithValue(error);
        } else {
          toast.error("Oops, an error occured fetching all accounts!");
          return rejectWithValue(error);
        }
      }
    } else {
      try {
        const res = await axios.get(
          `${baseApiUrl}/teammates?account_id=${creds.account_id}`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": `${getToken()}`,
            },
          }
        );
        if (res.status === 200 || res.status === 201) {
          return res.data;
        }
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          toast.error("Oops, an error occured fetching all accounts!");
          return rejectWithValue(error);
        } else if (error.response.status === 401) {
          dispatch({ type: "auth/logOut" });
          return rejectWithValue(error);
        } else {
          toast.error("Oops, an error occured fetching all accounts!");
          return rejectWithValue(error);
        }
      }
    }
  }
);

type acceptInviteType = {
  token: string | null;
  status: string | null;
};

export const acceptInvite = createAsyncThunk(
  "acceptInvite",
  async (creds: acceptInviteType, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${baseApiUrl}/teammates/accept-invite?token=${creds.token}&isNew=${creds.status}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Sorry, the link has expired. Contact  administrator");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Sorry, the link has expired. Contact  administrator");
        return rejectWithValue(error);
      }
    }
  }
);

type inviteTeammateType = {
  email: string;
  role: number;
  account_id: number;
};

export const inviteTeammate = createAsyncThunk(
  "inviteTeammate",
  async (creds: inviteTeammateType, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${baseApiUrl}/teammates/invite-teammate`,
        {
          email: creds.email,
          role: creds.role,
          account_id: creds.account_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": `${getToken()}`,
          },
        }
      );
      if (res.status === 200 || res.status === 201) {
        toast.success("Invite sent to teammate successfully!😊", {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
          },
        });
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Oops, looks like this user has already been invited to this account!");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Oops, an error sending invite!");
        return rejectWithValue(error);
      }
    }
  }
);

type fetchInviteeType = {
  account_id: number;
  status: string;
};
export const fetchInvitee = createAsyncThunk(
  "fetchInvitee",
  async (creds: fetchInviteeType, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${baseApiUrl}/view-all-invites?account_id=${creds.account_id}&status=${creds.status}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": `${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Oops, an error occured fecthing all invites!");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Oops, an error occured fecthing all invites!");
        return rejectWithValue(error);
      }
    }
  }
);

type inviteIDType = {
  id: string | null;
};
export const cancelInvite = createAsyncThunk(
  "cancelInvite",
  async (creds: inviteIDType, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${baseApiUrl}/teammates/cancel-invite/${creds.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": `${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        toast.success("Invite has been cancelled successfully!", {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
          },
          position: "top-right",
        });
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Oops, an error occured cancelling invite");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Oops, an error occured cancelling invite");
        return rejectWithValue(error);
      }
    }
  }
);

export const resendInvite = createAsyncThunk(
  "resendInvite",
  async (creds: inviteIDType, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${baseApiUrl}/teammates/resend-invite/${creds.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": `${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        toast.success("Invite sent to teammate successfully!😊", {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
          },
        });
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Oops, an error occured resending invite");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Oops, an error occured resending invite");
        return rejectWithValue(error);
      }
    }
  }
);

type updateTeammateStatusType = {
  id: string;
  is_active: boolean;
};

export const updateTeammateStatus = createAsyncThunk(
  "updateTeammateStatus",
  async (creds: updateTeammateStatusType, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.patch(
        `${baseApiUrl}/teammates/update-status`,
        {
          id: creds.id,
          is_active: creds.is_active,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": `${getToken()}`,
          },
        }
      );
      if (res.status === 200 || res.status === 201) {
        toast.success("Teammate's status updated successfully!", {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
          },
        });
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Oops, an error performing this action!");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Oops, an error performing this action!");
        return rejectWithValue(error);
      }
    }
  }
);


type updateTeammateRoleType = {
  id: string;
  roleId: number,
  accountId: number;
  limit: number;
};
export const updateTeammateRole = createAsyncThunk(
  "updateTeammateRole",
  async (creds: updateTeammateRoleType, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.patch(
        `${baseApiUrl}/teammates/update-teammate/${creds.id}`,
        {
          roleId: creds.roleId,
          accountId: creds.accountId,
          limit: creds.limit
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": `${getToken()}`,
          },
        }
      );
      if (res.status === 200 || res.status === 201) {
        toast.success("Teammate's info updated successfully!", {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
          },
        });
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Oops, an error performing this action!");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Oops, an error performing this action!");
        return rejectWithValue(error);
      }
    }
  }
);


type fetchRolePrivilegesType = {
  account_id: string;
  role_id : string;
};

export const fetchRolePrivileges = createAsyncThunk(
  "fetchRolePrivileges",
  async (creds: fetchRolePrivilegesType, { rejectWithValue, dispatch }) => {
      try {
        const res = await axios.get(
          `${apiUrl}account/role-privilege?account_id=${creds.account_id}&role_id=${creds.role_id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          toast.error("Oops, an error occured fetching role privileges!");
          return rejectWithValue(error);
        } else if (error.response.status === 401) {
          dispatch({ type: "auth/logOut" });
          return rejectWithValue(error);
        } else {
          toast.error("Oops, an error occured fetching role privileges!");
          return rejectWithValue(error);
        }
      }
  }
);
