import { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../redux/store";
import { fetchTeammates } from "../../redux/Teammates/teammateActions";
import PaginationComponent from "../../components/Pagination/Pagination";
import { fetchPrivileges } from "../../redux/Roles/roleActions";
import { useParams } from "react-router-dom";

const RolesTeammatesTable = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { loading, teammates } = useAppSelector((state) => state.teammate);
  const { selectedAccount } = useAppSelector((state) => state.auth);
  const [currentPage, setCurrentPage] = useState(1);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const capitalizeFirstLetter = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  const getStatusBg = (val: boolean) => {
    let result;
    switch (val) {
      case false:
        result = "failed-badge";
        break;
      case true:
        result = "success-badge";
        break;
      default:
        result = "failed-badge";
        break;
    }
    return result;
  };

  useEffect(() => {
    dispatch(fetchPrivileges());
  }, [dispatch]);

  useEffect(() => {
    if (selectedAccount?.type === "sub_account") {
      dispatch(
        fetchTeammates({
          account_id: selectedAccount?.id,
          role_id: id,
        })
      );
    }
  }, [dispatch, selectedAccount, id]);

  return (
    <>
      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          <p className="text-primary font-semibold text-base leading-5">
            List of Teammates
          </p>
        </div>

        {/* tables */}
        <div className="relative">
          {loading ? (
            <div>
              <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            </div>
          ) : (
            <>
              {teammates?.length === 0 ? (
                <div>
                  <div className="w-full pb-[80px] mt-[80px] flex items-center justify-center">
                    <p>There are no teammates added yet</p>
                  </div>
                </div>
              ) : (
                <section>
                  <table className="w-full text-left relative">
                    <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                      <tr>
                        <th scope="col" className="px-4 py-4 font-semibold ">
                          First Name
                        </th>
                        <th scope="col" className="px-4 py-4 font-semibold">
                          Last Name
                        </th>
                        <th scope="col" className="px-4 py-4 font-semibold">
                          Email
                        </th>
                        <th scope="col" className="px-4 py-4 font-semibold">
                          Role
                        </th>
                        <th scope="col" className="px-4 py-4 font-semibold">
                          Status
                        </th>
                        {/* <th scope="col" className="px-4 py-4 font-semibold">
                          Actions
                        </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {teammates?.map((teammate, index) => {
                        return (
                          <tr
                            key={index}
                            className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                          >
                            <th
                              scope="row"
                              className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                            >
                              {teammate.teammateUser.first_name}
                            </th>
                            <td className="py-4 px-4 text-[#5C715E]">
                              {teammate.teammateUser.last_name}
                            </td>
                            <td className="py-4 px-4 text-[#5C715E]">
                              {teammate.teammateUser.email}
                            </td>
                            <td className="py-4 px-4 text-[#5C715E]">
                              {teammate.rolePrivilege.role.name && capitalizeFirstLetter(
                                teammate.rolePrivilege.role.name
                              )}
                            </td>
                            <td className="py-4 px-4 text-[#5C715E]">
                              <div className="flex">
                                <div
                                  className={getStatusBg(
                                    teammate?.is_active || false
                                  )}
                                >
                                  <p>
                                    {teammate?.is_active
                                      ? "Active"
                                      : "Inactive"}
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  <div>
                    <PaginationComponent
                      page={currentPage}
                      onPageChange={(page) => onPageChange(1)}
                      totalItems={10}
                      pageSize={10}
                    />
                  </div>
                </section>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default RolesTeammatesTable;
