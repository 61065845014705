import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import upload_icon from "../../assets/icons/upload.svg";
import upload_fileicon from "../../assets/icons/upload_file.svg";
import fileicon from "../../assets/icons/Fileicon.svg";
import clear_icon from "../../assets/icons/clear.svg";
import ModalComponent from "../../components/Modals/modal";
import { ExpandLess, ExpandMore, Info } from "@mui/icons-material";
import { useAppSelector, useAppDispatch } from "../../redux/store";
import SearchComponent from "../../components/Search";
import { checkReciepientExist, createRecipient, getAllBanks, getAllRecipients, getAllTags, recipientsSearch, verifyRecipientAccount } from "../../redux/Recipient/recipientActions";
import toast from "react-hot-toast";
import { InitiatePayment } from "../../redux/Payment/paymentActions";
import { clearIsInitiated, uploadDocumentFile } from "../../redux/Payment/payment";
import { useNavigate } from "react-router-dom";
import warning from "../../assets/icons/warning.svg";
import { fetchAccountById } from "../../redux/Account/accountActions";
import { ThreeDots } from "react-loader-spinner";
import { handleRecipientDetails, resetRecipientStatus } from "../../redux/Recipient/recipient";
import { debounce } from "lodash";
import { SimCardAlertOutlined } from "@mui/icons-material";
import BulkPaymentPage from "./BulkPayment";

const NewPaymentPage = () => {
  const [selectedType, setSelectedType] = useState("Instant");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const fileRef = useRef<HTMLInputElement>(null);
  const [previewUrl, setPreviewUrl] = useState<string>("");
  const [imgLoader, setImgLoader] = useState(false)
  const handleType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedType(event.target.value);
  };

  const { recipient, is_recipient, banks,tags, recipients } = useAppSelector((state) => state.recipient);
  const { loader, isInitiated } = useAppSelector((state) => state.payment);
  const { selectedAccount } = useAppSelector((state) => state.auth);
  // const [recipientsState, setRecipientState] = useState(recipients);
  const { accountInfo } = useAppSelector((state) => state.account);
  const [typeID, setTypeID] = useState(1);
  
  const paymentTypes = [
    {
      id: 1,
      title: "Single Payment",
    },
    // {
    //   id: 2,
    //   title: "Bulk Payment",
    // },
  ];

  const toggleType = (id: number) => {
    setTypeID(id);
  }


  const [isOpen, setIsOpen] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState(false);
  const [memo, setMemo] = useState("");
  const [searchLoader, setSearchLoader] = useState(false)
  const [recipientSearchLoader, setRecipientSearchLoader] = useState(false)
  const [progress, setProgress] = useState(0);
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("")
  const [accountNumber, setAccountNumber] = useState("")
  const [banksState, setBanksState] = useState(banks);
  const [isToggleBank, setIsToggleBank] = useState(false);
  const [isToggleTag, setIsToggleTag] = useState(false);
  const [recipientAccountNameLoading, setRecipientAccountNameLoading] = useState(false);
  const [recipientName, setRecipientName] = useState("")
  const [isManualVerifyShow, setManualVerifyShow] = useState(false)
  const [recipientId, setRecipientId] = useState("")
  const [recipientCreateLoader, setRecipientCreateLoader] = useState(false)
  const [isToggleRecipient, setIsToggleRecipient] = useState(false);

  const [selectedRecipient, setSelectedRecipient] = useState<{
    name: string;
    id: string;
  }>({
    name: "",
    id: "",
  });

  const handleSelectedRecipient = (name: string, id: string,) => {
    setSelectedRecipient({
      name: name,
      id: id,
    });
    setIsToggleRecipient(false);
    setSelectedBank({
      name: "",
      id: "",
      bankCode: null,
    })

    // find a recipient details from the id
    const recipientDetails = recipients.find(
      (recipient) => recipient.id === id
    );
    if (recipientDetails) {
      // Do something with recipientDetails
      setRecipientId(recipientDetails?.id)
      setAccountNumber(recipientDetails?.account_number)
      dispatch(handleRecipientDetails(recipientDetails))
      setRecipientName(recipientDetails?.recipient_name);
    }
  };

  const [selectedBank, setSelectedBank] = useState<{
    name: string;
    id: string;
    bankCode: number | null;
  }>({
    name: "",
    id: "",
    bankCode: null,
  });

  const [selectedTags, setSelectedTags] = useState<{
    tag: string;
    id: number;
  }>({
    tag: "",
    id: 0,
  });

  
  const showPaymentModal = () => {
    setIsOpen(!isOpen);
  };

  // validate amount with a regex function for numbers alone without decimals
  // const handleAmount = (amount: string) => {
  //   const regex = /^[0-9]*\.?[0-9]*$/;
  //   // test if amount is a number and set amount if valid
  //   if (regex.test(amount)) {
  //     setAmountError(false);
  //     setAmount(amount);
  //   } else {
  //     setAmountError(true);
  //     // toast("The amount entered is not valid!", {
  //     //   style: {
  //     //     border: '1px solid #c9f7fc',
  //     //     backgroundColor: '#c9f7fc',
  //     //     color: '#0066af',
  //     //     fontSize: 14,
  //     //   },
  //     //   position: "top-right",
  //     // });
  //   }
  // };

  const showApproveModal = () => {
    setIsApprove(!isApprove);
  };


  const handleRecipientCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setAccountNumber(event.target.value);
    if (value?.length === 10){
      setSearchLoader(true)
      setSelectedBank({
        name: "",
        id: "",
        bankCode: null,
      })
      setBanksState(banks);
      dispatch(checkReciepientExist({
        account_number : value
      })).then((res)=>{
        if(res?.type === "checkReciepientExist/fulfilled"){
          setSearchLoader(false)
          if(res?.payload?.is_recipient){
            setRecipientName(res?.payload?.data.recipient_name)
            setManualVerifyShow(false)
          }
          else{
            setRecipientName("") 
          }
         
        }
        else if(res?.type === "checkReciepientExist/rejected"){
          setManualVerifyShow(false)
          setSearchLoader(false)
          setRecipientName("")        
        }
      })
    }
    if(value.length === 0){
      dispatch(resetRecipientStatus())
      dispatch(getAllRecipients({ page: 1, per_page: 10 }));
      setRecipientSearchValue("")
      setManualVerifyShow(false)
      setSelectedBank({
        name: "",
        id: "",
        bankCode: null,
      })
      setBanksState(banks);
      setSelectedRecipient({
        id: "",
        name:""
      })
    }
  }

  const handlePayment = () => {
    // check if amount, selected sub account or selected recipient is entered
    if (recipientName === "") {
      toast.error("Oops, looks like you haven't entered a recipient");
    }
    else if(!is_recipient && selectedTags?.id === 0){ 
      toast.error("Please select a tag");
    }
    else if (amount === "" || parseFloat(amount) < 500) {
      setAmountError(true);
      toast("The least amount you can transact is 500 naira.", {
        style: {
          border: "1px solid #c9f7fc",
          backgroundColor: "#c9f7fc",
          color: "#0066af",
          fontSize: 14,
        },
        position: "top-right",
      });
    } else if (selectedAccount?.type === "main_account") {
      // Your code here for when the selected account type is main account
      toast(
        "You cannot send a payment from this account, kindly switch to another account.",
        {
          style: {
            border: "1px solid #c9f7fc",
            backgroundColor: "#c9f7fc",
            color: "#0066af",
            fontSize: 14,
          },
          position: "top-right",
        }
      );
    } else {
      setAmountError(false);

      if(recipientId){
        // Code to handle when all inputs are entered
        setIsApprove(true);
      }
      else{
        // check if account number is 10, selected bank is filled and recipient name is filled, create recipient with tags selected
        if (accountNumber.length === 10 && selectedBank?.id && recipientName) {
          setRecipientCreateLoader(true)
          dispatch(createRecipient({
            account_number: accountNumber,
            bank_code: selectedBank?.id,
            tag_id: Number(selectedTags?.id),
            recipient_name: recipientName,
            bank_id: selectedBank?.bankCode,
          })).then((res)=>{
            if (res?.type === "createRecipient/fulfilled") {
              setRecipientCreateLoader(false)
              setIsApprove(true);
            }
            else if(res?.type === "createRecipient/rejected"){
              setRecipientCreateLoader(false)
            }
          })
        }
      }
      
    }
  };

  

  const [searchValue, setSearchValue] = useState("");
  const [recipientSearchValue, setRecipientSearchValue] = useState("");

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   let value = e.target.value;
  //   setSearchValue(value);
  //   if (value === "") {
  //     setRecipientState(recipients);
  //   } else {
  //     const filteredData = recipients.filter((recipient) =>
  //       recipient?.recipient_name?.toLowerCase().includes(value?.toLowerCase())
  //     );
  //     setRecipientState(filteredData);
  //   }
  // };

  const getToken = () => {
    const token = localStorage.getItem("token");
    return token;
  };

  const handleSearch = useMemo(
    () =>
      debounce((val: string) => {
        let values = {
          query: val,
        };
        if (val?.length === 0) {
          setRecipientSearchLoader(true)
          dispatch(getAllRecipients({ page: 1, per_page: 10 })).then((res) => {
            if (res?.type === "getAllRecipients/fulfilled") {
              setRecipientSearchLoader(false)
            }
            else if (res?.type === "getAllRecipients/rejected") {
              setRecipientSearchLoader(false)
            }
          });
        } else {
          setRecipientSearchLoader(true)
          dispatch(recipientsSearch(values)).then((res) => {
            if (res?.type === "recipientsSearch/fulfilled") {
              setRecipientSearchLoader(false)
            }
            else if (res?.type === "recipientsSearch/rejected") {
              setRecipientSearchLoader(false)
            }
          });
        }
      }, 750),
    [dispatch]
  );


  const handleRecipientSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let val = e.target.value;
      setRecipientSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    setSearchValue(value);
    if (value === "") {
      setBanksState(banks);
    } else {
      const filteredData = banks.filter((bank) =>
        bank?.name?.toLowerCase().includes(value?.toLowerCase())
      );
      setBanksState(filteredData);
    }
  };

  const changedHandler = () => {
    if (fileRef.current?.files && fileRef.current.files.length > 0) {
        const selectedFile = fileRef.current.files[0];
        setFileName(selectedFile?.name);
        setFileType(selectedFile.type);
        // checks
        if (!['image/jpeg', 'application/pdf', 'image/png'].includes(selectedFile.type)) {
          toast.error("Please select a PDF, JPEG or PNG image.",{
            style: {
              border: "1px solid #B92043",
              backgroundColor: "#B92043",
              color: "#FFFFFF",
              fontSize: 14,
              maxWidth: 400,
            },
           });
          return;
       }
  
      if (selectedFile.size > 10 * 1024 * 1024) {
          toast.error("File size exceeds 10MB.",{
            style: {
              border: "1px solid #B92043",
              backgroundColor: "#B92043",
              color: "#FFFFFF",
              fontSize: 14,
              maxWidth: 400,
            },
            });
          return;
      }
  
      // const image = new Image();
      // image.onload = () => {
         
          setImgLoader(true)

          const xhr = new XMLHttpRequest();
          xhr.open('POST', `${process.env.REACT_APP_TITANIUM_URL}payment/upload-document/`);
          xhr.setRequestHeader("Authorization", `Bearer ${getToken()}`);
       

          xhr.upload.addEventListener('progress', (event) => {
              const percentComplete = Math.round((event.loaded * 100.0) / event.total)
              console.log(percentComplete); // Log the progress to the console
              // You can use 'percentComplete' to update your progress loader
              setProgress(percentComplete)
          });

          xhr.onload = () => {
              if (xhr.status === 201) {
                toast.success("Attachment uploaded successfully", {
                  style: {
                    border: "1px solid #2B8C34",
                    backgroundColor: "#2B8C34",
                    color: "#FFFFFF",
                    fontSize: 14,
                  },
                  position: "top-right",
                });
                 setImgLoader(false)
                 setProgress(0)
                 setPreviewUrl(URL.createObjectURL(selectedFile));
                 const response = JSON.parse(xhr.responseText);
                 dispatch(uploadDocumentFile({
                   document: response?.data,
                 }))
              } else {
                toast.error(
                  "Error while uploading attachment!"
                );
              }
          };

          const formData = new FormData();
          formData.append('payment_file', selectedFile);
          xhr.send(formData);
      // };
      // image.src = URL.createObjectURL(selectedFile);

    }
  }


  const handleRemoveImg = () =>{
    setPreviewUrl("")
  }

  const handleInitiatePayment = () => {
    dispatch(
      InitiatePayment({
        amount: parseFloat(amount),
        narration: memo,
        recipient: recipientId,
      })
    );
  };


  const handleSelectedTag = (name: string, id: number) => {
    setSelectedTags({
      tag: name,
      id: id,
    });
    setIsToggleTag(false);

    // check if account number is 10, selected bank is filled and recipient name is filled, create recipient
    if (accountNumber.length === 10 && selectedBank?.id && recipientName) {
      setRecipientCreateLoader(true)
      dispatch(createRecipient({
        account_number: accountNumber,
        bank_code: selectedBank?.id,
        tag_id: Number(id),
        recipient_name: recipientName,
        bank_id: selectedBank?.bankCode,
      })).then((res)=>{
        if (res?.type === "createRecipient/fulfilled") {
          setRecipientCreateLoader(false)
        }
        else if(res?.type === "createRecipient/rejected"){
          setRecipientCreateLoader(false)
        }
      })
    }
   
  };

  const handleSelectedBank = (name: string, id: string, bankId: number) => {
    setSelectedBank({
      name: name,
      id: id,
      bankCode: bankId,
    });
    setIsToggleBank(false);
    setSearchValue("");
  };

  
  useEffect(() => {
    if (recipient) {
      setRecipientId(recipient?.id)
    } 
  },[recipient])


  useEffect(() => {
    if (isInitiated) {
      // reset all values, receipient, amount and memo
      setAmount("");
      setMemo("");
      setIsApprove(false);
      setAccountNumber("")
      setRecipientId("")
      setRecipientName("")
      setTimeout(() => {
        dispatch(clearIsInitiated());
      }, 2000);
      navigate(`/payment`);
    }
  }, [isInitiated, dispatch, navigate]);



  useEffect(() => {
    const values = {
      account_number: accountNumber,
      bank_code: selectedBank?.id,
      bank_id: selectedBank?.bankCode,
    };
    if (accountNumber?.length === 10 && selectedBank?.name !== "") {
      setRecipientAccountNameLoading(true);
      dispatch(verifyRecipientAccount(values)).then((res) => {
        if (res?.type === "verifyRecipientAccount/fulfilled") {
          setRecipientAccountNameLoading(false);
          setRecipientName(res?.payload?.data?.account_name);
          setManualVerifyShow(true)
        } else if (res?.type === "verifyRecipientAccount/rejected") {
          setRecipientAccountNameLoading(false);
          setManualVerifyShow(false)
        }
      });
    }
  }, [accountNumber, selectedBank, dispatch]);

  useEffect(() => {
    dispatch(getAllBanks());
    dispatch(getAllTags());
    dispatch(resetRecipientStatus())
    dispatch(getAllRecipients({ page: 1, per_page: 10 }));

    if (selectedAccount.type !== "main_account") {
      dispatch(
        fetchAccountById({
          account_id: selectedAccount?.account_id,
        })
      );
    }
  }, [dispatch, selectedAccount]);

  return (
    <>
      {/* Confirmation Modal */}
      <ModalComponent
        title="Payment Initiation"
        subtitle=""
        isOpen={isApprove}
        onClose={showApproveModal}
      >
        <div>
          <p className="mt-[10px] text-sm font-mediumn leading-5 text-[#5C715E] mb-8">
            Are you sure you want to initiate this payment?
          </p>

          <div className="flex justify-end">
            <div className="flex gap-[40px] items-center cursor-pointer">
              <p
                className=" text-sm font-mediumn leading-5 text-[#5C715E]"
                onClick={() => setIsApprove(false)}
              >
                Cancel
              </p>
              <button
                disabled={loader}
                onClick={handleInitiatePayment}
                className="outline-none text-white rounded-lg bg-[#2B8C34]  hover:bg-[#24752B] disabled:bg-[#ABB6AC] text-sm font-medium leading-5 px-[20px] py-[10px]"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </ModalComponent>

      {/* Payment Approval Modal */}
      <ModalComponent
        title="Payment Approval"
        subtitle="The payment will be processed when at least one of the following team mate(s) approves this payment"
        isOpen={isOpen}
        onClose={showPaymentModal}
      >
        <div className="mt-8 flex justify-between items-center">
          <div className="flex gap-2 items-center">
            <div className="w-[20px] h-[20px] rounded-full bg-[#2B8C34] text-white flex justify-center items-center text-[9px]">
              MO
            </div>
            <p className="text-[#344335] text-[15px] font-semibold">
              Micheal Ogundare
            </p>
          </div>
          <div>
            <p className="text-[#5C715E] text-sm font-medium">Owner</p>
          </div>
        </div>

        <div className="mt-4 flex justify-between items-center">
          <div className="flex gap-2 items-center">
            <div className="w-[20px] h-[20px] rounded-full bg-[#2B8C34] text-white flex justify-center items-center text-[9px]">
              JO
            </div>
            <p className="text-[#344335] text-[15px] font-semibold">
              Jemima Osunde
            </p>
          </div>
          <div>
            <p className="text-[#5C715E] text-sm font-medium">Admin</p>
          </div>
        </div>

        <div className="mt-8 flex justify-end">
          <div className="flex gap-3">
            <button
              onClick={() => setIsOpen(false)}
              type="submit"
              className="bg-transparent 
                    disabled:bg-[#ABB6AC]
                    rounded-lg px-6 py-3 text-[#5C715E] text-sm font-medium transition-all"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-primary 
                    disabled:bg-[#ABB6AC]
                    rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
            >
              Confirm
            </button>
          </div>
        </div>
      </ModalComponent>
      

      <div className="bg-white border border-[#ECEFEC] px-4 py-4 rounded-[8px]">
        <div>
          <p className="text-primary text-lg font-semibold leading-6">
            New Payment
          </p>
        </div>
        <div className="mt-4">
          <p className="text-[#5C715E] text-sm font-semibold">
            Create a one time, scheduled or recurring payment
          </p>
        </div>

        <ul className="flex mt-2 gap-5 items-center">
            {paymentTypes.map((item) => (
              <li
                key={item.id}
                onClick={() => toggleType(item.id)}
                className={
                  typeID === item.id
                    ? "flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer relative"
                    : "flex gap-1 items-center pb-1 cursor-pointer relative"
                }
              >
                <p
                  className={
                    typeID === item.id
                      ? "text-primary text-sm font-semibold"
                      : "text-[#96A397] text-sm font-semibold"
                  }
                >
                  {item.title}
                </p>
              </li>
            ))}
          </ul>
      </div>

      {/* payment info section */}

      {
        typeID === 1 &&
        <>
        <div className="mt-4 bg-white rounded-[8px] py-6 px-6">
          <div className="grid grid-cols-3 gap-4">
            <div>
              <label
                htmlFor="Subaccount"
                className="block mb-2 text-[#344335] text-sm font-normal"
              >
                Subaccount
              </label>
              <input
                type="text"
                name="Subaccount"
                placeholder="Subaccount"
                value={selectedAccount ? selectedAccount.name : ""}
                disabled
                className="appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-sm focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
              />
              {selectedAccount?.type !== "main_account" && (
                <div className="flex items-center gap-1 mt-1">
                  <Info style={{ fontSize: "16px", color: "#344335" }} />
                  <small className="text-[#344335] font-medium text-xs">
                    Account balance:{" "}
                    {`₦${
                      accountInfo
                        ? accountInfo?.account_balance?.toLocaleString()
                        : 0
                    }`}
                  </small>
                </div>
              )}
            </div>


            <div>
              <label
                htmlFor="accountnumber"
                className="block mb-2 text-[#344335] text-sm font-normal"
              >
                Account Number
              </label>
              <input
                type="text"
                name="accountnumber"
                placeholder="Search with an account number"
                onChange={(e) => handleRecipientCheck(e)}
                value={accountNumber}
                className="appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-sm focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
              />
              {searchLoader &&
              <div className="flex gap-2 items-center">
                {" "}
                <ThreeDots
                  visible={true}
                  height="25"
                  width="25"
                  color="#2B8C34"
                  radius="9"
                />
                <p className="text-xs font-medium leading-4 text-[#5C715E]">
                Fetching account details
                </p>
              </div>
              }
            </div>

          {/* BANK */}
            <div>
                <label
                  htmlFor="bank"
                  className="block mb-2 text-[#344335] text-sm font-normal"
                >
                  Bank
                </label>

                {
                  is_recipient ?
                  <input
                    type="text"
                    name="bank"
                    placeholder="Bank"
                    value={recipient && recipient?.bank ? recipient?.bank.name : ""} 
                    disabled
                    className="appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-sm focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                  />
                  :
                <div className="relative">
                  <div
                    onClick={() => setIsToggleBank(!isToggleBank)}
                    className={`flex cursor-pointer justify-between items-center w-full rounded-lg border border-[#96A397] bg-white py-3 px-4 text-[#96A397] text-sm relative`}
                    style={
                      isToggleBank ? { boxShadow: "0px 0px 0px 2px #D5E8D6" } : {}
                    }
                  >
                    <p
                      className={`${
                        selectedBank?.name ? "text-[#344335]" : "text-[#96A397]"
                      }`}
                    >
                      {selectedBank?.name || "Select a bank"}
                    </p>
                    {isToggleBank ? (
                      <ExpandLess style={{ fontSize: "20px", color: "#344335" }} />
                    ) : (
                      <ExpandMore style={{ fontSize: "20px", color: "#344335" }} />
                    )}
                  </div>
      
                  {/*  SELECT OPTIONS */}
                  {isToggleBank && (
                    <div
                      className="absolute rounded-[18px] py-2 bg-white w-full mt-2 px-2 max-h-[300px] overflow-scroll "
                      style={{
                        boxShadow:
                          "0px 16px 32px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      <div className="w-full mb-2">
                        <SearchComponent
                          placeholder="Search"
                          searchValue={searchValue}
                          handleChange={handleChange}
                        />
                      </div>
      
                      {banksState?.map((bank, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() =>
                              handleSelectedBank(bank?.name, bank?.code, bank?.id)
                            }
                            className={`px-4 py-3  text-sm font-normal cursor-pointer hover:bg-[#EDF7EE] hover:rounded-[8px] mb-1 ${
                              selectedBank?.name === bank?.name
                                ? "bg-[#EDF7EE] border-[1px] border-[#2B8C34] rounded-[8px] text-[#24752B]"
                                : "text-[#344335] bg-white"
                            }`}
                          >
                            <p className=" leading-5 text-sm font-medium">
                              {bank?.name}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
                }
                  
              </div>
          

          </div>

          <div className="grid grid-cols-3 gap-4 mt-5">
          
          {/* recipient */}
          <div className="">
              <label
                htmlFor="recipient"
                className="block mb-2 text-[#344335] text-sm font-normal"
              >
                Recipient
              </label>
              {
                is_recipient && !isManualVerifyShow &&
                <input
                type="text"
                name="recipient"
                placeholder="Recipient"
                value={recipientName}
                disabled
                className="appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-sm focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                />
              }

              {
                isManualVerifyShow && !is_recipient &&
                <input
                type="text"
                name="recipient"
                placeholder="Recipient"
                value={recipientName}
                disabled
                className="appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-sm focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                />
              }

              {
                !is_recipient && !isManualVerifyShow &&
                <>
                  <div className="">
                <div className="relative">
                  <div
                    onClick={() => setIsToggleRecipient(!isToggleRecipient)}
                    className={`flex cursor-pointer justify-between items-center w-full rounded-lg border border-[#96A397] bg-white py-3 px-4 text-[#96A397] text-sm`}
                    style={
                      isToggleRecipient
                        ? { boxShadow: "0px 0px 0px 2px #D5E8D6" }
                        : {}
                    }
                  >
                    <p
                      className={`${
                        selectedRecipient?.name
                          ? "text-[#344335]"
                          : "text-[#96A397]"
                      }`}
                    >
                      {selectedRecipient?.name || "Select a recipient"}
                    </p>
                    {isToggleRecipient ? (
                      <ExpandLess style={{ fontSize: "20px", color: "#344335" }} />
                    ) : (
                      <ExpandMore style={{ fontSize: "20px", color: "#344335" }} />
                    )}
                  </div>

                  {/*  SELECT OPTIONS */}
                  {isToggleRecipient && (
                    <div
                      className="absolute z-10 rounded-[18px] py-2 bg-white w-full mt-2 px-2 max-h-[300px] overflow-scroll "
                      style={{
                        boxShadow:
                          "0px 16px 32px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      <div className="w-full mb-2">
                        <SearchComponent
                          placeholder="Search"
                          searchValue={recipientSearchValue}
                          handleChange={handleRecipientSearch}
                        />
                      </div>

                    {recipientSearchLoader &&
                    <div className="px-2">
                      <ThreeDots
                        visible={true}
                        height="25"
                        width="25"
                        color="#2B8C34"
                        radius="9"
                      />
                      </div>
                      }

                      {recipients?.length === 0 && (
                          <div className="w-full flex flex-col items-center justify-center min-h-[200px]">
                              <div className='text-center'>
                                  <div>
                                      <SimCardAlertOutlined style={{ fontSize: "40px", color: "#344335" }} />
                                  </div>
                              <h3 className='text-xl text-[#344335] font-semibold mt-2'>No Results!</h3>
                              <p className='text-sm text-center max-w-md font-medium text-[#000000] mt-2'>
                              There are no recipients matching the query
                              </p>
                            </div>                           
                          </div>
                        )
                      }

                      {recipients?.length ? recipients?.map((val, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() =>
                              handleSelectedRecipient(val?.recipient_name, val?.id)
                            }
                            className={`px-4 py-3  text-sm font-normal cursor-pointer hover:bg-[#EDF7EE] hover:rounded-[8px] ${
                              index < recipients.length - 1 ? "mb-1" : ""
                            } ${
                              selectedRecipient?.name === val?.recipient_name
                                ? "bg-[#EDF7EE] border-[1px] border-[#2B8C34] rounded-[8px] text-[#24752B]"
                                : "text-[#344335] bg-white"
                            }`}
                          >
                            <p className=" leading-5 text-sm font-medium">
                              {val?.recipient_name}
                            </p>
                            <p className="leading-5 text-xs font-medium">
                              {val?.account_number} - {val?.bank.name}
                            </p>
                          </div>
                        );
                      })
                      :
                      null
                    }
                    </div>
                  )}
                </div>
                </div>
              </>
              }

              

              {recipientAccountNameLoading &&
              <div className="flex gap-2 items-center">
                {" "}
                <ThreeDots
                  visible={true}
                  height="25"
                  width="25"
                  color="#2B8C34"
                  radius="9"
                />
                <p className="text-xs font-medium leading-4 text-[#5C715E]">
                Resolving account name
                </p>
              </div>
              }
              
            </div>

            <div>
              <label
                htmlFor="recipienttag"
                className="block mb-2 text-[#344335] text-sm font-normal"
              >
                Recipient Tag
              </label>

            {
              is_recipient ?
              <div
                className={`flex items-center w-full rounded-lg border border-[#96A397] bg-white ${
                  recipient?.recipient_tag ? "py-2" : "py-3"
                } px-4 text-[#96A397] text-sm`}
              >
                <div className="flex flex-wrap gap-2 items-center">
                  {recipient?.recipient_tag ? (
                    <>
                      <div className="capitalize rounded-[4px] text-sm bg-[#DBFFDF] px-2 py-[4px] text-[#2B8C34] flex items-center gap-2">
                        <p>
                          {recipient ? recipient.recipient_tag.tag : ""}
                        </p>
                      </div>
                    </>
                  ) : (
                    <p> Recipient Tag</p>
                  )}
                </div>
              </div>
              :
              <div className="relative">
              <div
                className={`flex cursor-pointer  items-center w-full rounded-lg border border-[#96A397] bg-white ${
                  selectedTags?.tag ? "py-2" : "py-3"
                }  px-4 text-[#96A397] text-sm`}
                style={
                  isToggleTag ? { boxShadow: "0px 0px 0px 2px #D5E8D6" } : {}
                }
                onClick={() => setIsToggleTag(!isToggleTag)}
              >
                <div className="flex flex-wrap  gap-2 items-center w-[95%]">
                  {selectedTags?.tag ? (
                    <>
                      <div className="rounded-[4px] capitalize text-sm bg-[#DBFFDF] px-2 py-[4px] text-[#2B8C34] flex items-center gap-2">
                        <p> {selectedTags?.tag}</p>
                      </div>
                    </>
                  ) : (
                    <p> Select a tag</p>
                  )}
                </div>

                <div className=" w-[5%]">
                  {isToggleTag ? (
                    <ExpandLess style={{ fontSize: "20px", color: "#344335" }} />
                  ) : (
                    <ExpandMore style={{ fontSize: "20px", color: "#344335" }} />
                  )}
                </div>
              </div>

              {/*  SELECT OPTIONS */}
              {isToggleTag && (
                <div
                  className="absolute max-h-[300px] py-2 overflow-scroll rounded-[18px] bg-white w-full mt-2 px-2"
                  style={{
                    boxShadow:
                      "0px 16px 32px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  {tags?.map((tag, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => handleSelectedTag(tag?.tag, tag?.id)}
                        className={`px-4 py-3  text-sm font-normal cursor-pointer hover:bg-[#EDF7EE] hover:rounded-[8px] mb-1 ${
                          selectedTags?.tag === tag?.tag
                            ? "bg-[#EDF7EE] border-[1px] border-[#2B8C34] rounded-[8px] text-[#24752B]"
                            : "text-[#344335] bg-white"
                        }`}
                      >
                        <p className="capitalize leading-5 text-sm font-medium">
                          {tag?.tag}
                        </p>
                      </div>
                    );
                  })}
                </div>
              )}
              </div>
              }
            </div>

            <div>
              <label
                htmlFor="amount"
                className="block mb-2 text-[#344335] text-sm font-normal"
              >
                Amount
              </label>
              <input
                type="number"
                name="amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder="Enter an amount"
                className="appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-sm focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
              />
              {amountError && (
                <div className="flex">
                  <img src={warning} className="" alt="warning" />
                  <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                  The least amount you can transact is 500 naira.
                  </small>
                </div>
              )}
            </div>
          </div>

          <div className="grid grid-cols-3 gap-4 mt-5">
            <div>
              <label
                htmlFor="type"
                className="block mb-2 text-[#344335] text-sm font-normal"
              >
                Payment Type
              </label>

              <div className="mt-4 ml-4">
                <div className="flex items-center">
                  <input
                    id="default-radio-1"
                    type="radio"
                    value="Instant"
                    name="default-radio"
                    checked={selectedType === "Instant"}
                    onChange={handleType}
                    className="w-4 h-4 text-[#2B8C34] cursor-pointer bg-gray-100 border-gray-300 focus:ring-[#2B8C34] focus:ring-0 "
                  />
                  <label
                    htmlFor="default-radio-1"
                    className={`ml-2 text-sm font-medium ${
                      selectedType === "Instant"
                        ? "text-[#344335]"
                        : "text-[#ABB6AC]"
                    } text-[#344335]`}
                  >
                    Instant Payment
                  </label>
                </div>

                <div className="flex items-center mt-4">
                  <input
                    id="default-radio-2"
                    type="radio"
                    disabled
                    checked={selectedType === "Recurrring"}
                    value="Recurrring"
                    name="default-radio"
                    onChange={handleType}
                    className="w-4 h-4 text-[#2B8C34]  bg-gray-100 border-gray-300 focus:ring-[#2B8C34] focus:ring-0 "
                  />
                  <label
                    htmlFor="default-radio-2"
                    className={`ml-2 text-sm font-medium ${
                      selectedType === "Recurrring"
                        ? "text-[#344335]"
                        : "text-[#ABB6AC]"
                    }`}
                  >
                    Recurring Payment
                  </label>
                  <div className="ml-2 rounded-[4px] text-xs bg-[#c9f7fc] px-1 py-[3px] text-[#0066af] flex items-center gap-2">
                    <p>Coming soon</p>
                  </div>
                </div>

                <div className="flex items-center mt-4">
                  <input
                    id="default-radio-3"
                    type="radio"
                    value="Scheduled"
                    disabled
                    name="default-radio"
                    checked={selectedType === "Scheduled"}
                    onChange={handleType}
                    className="w-4 h-4 text-[#2B8C34] bg-gray-100 border-gray-300 focus:ring-[#2B8C34] focus:ring-0 "
                  />
                  <label
                    htmlFor="default-radio-3"
                    className={`ml-2 text-sm font-medium ${
                      selectedType === "Scheduled"
                        ? "text-[#344335]"
                        : "text-[#ABB6AC]"
                    }`}
                  >
                    Scheduled Payment
                  </label>
                  <div className="ml-2 rounded-[4px] text-xs bg-[#c9f7fc] px-1 py-[3px] text-[#0066af] flex items-center gap-2">
                    <p>Coming soon</p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <label
                htmlFor="memo"
                className="block mb-2 text-[#344335] text-sm font-normal"
              >
                Payment Memo
              </label>
              <textarea
                name="memo"
                rows={6}
                value={memo}
                onChange={(e) => setMemo(e.target.value)}
                placeholder="Enter a payment memo"
                className="appearance-none resize-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-sm focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
              />
            </div>
            <div>
              <label
                htmlFor="attachment"
                className="flex items-center gap-2 mb-2 text-[#344335] text-sm font-normal"
              >
                Attachment (Optional)
              </label>

              {!imgLoader && fileType.includes('pdf') && previewUrl 
                &&
                <div className='relative max-w-full w-full border border-[#A6A08C] flex flex-col justify-center items-center rounded-[8px] h-[150px]'>
                <div className="text-center">
                  <img src={fileicon} alt="file icon" className='' />
                </div>
                <div>
                  <p className="text-[#344335] text-sm font-medium mt-1">{fileName}</p>
                </div>
                  
                    <div onClick={() => handleRemoveImg()} className="absolute right-0 -top-2 cursor-pointer bg-[#f7f7f7] border border-[#dde2de] w-[30px] h-[30px] rounded-full flex justify-center items-center">
                      <img src={clear_icon} alt="clear icon" />
                    </div>
                </div>
                }
                
                {!imgLoader && !fileType.includes('pdf') && previewUrl &&
                <div className='relative'>
                    <img src={previewUrl} alt="Preview" className='max-w-full w-full object-cover rounded-[8px] h-[150px]' />

                    <div onClick={() => handleRemoveImg()} className="absolute right-0 -top-2 cursor-pointer bg-[#f7f7f7] border border-[#dde2de] w-[30px] h-[30px] rounded-full flex justify-center items-center">
                      <img src={clear_icon} alt="clear icon" />
                    </div>
                </div>
              }           

              {!imgLoader && !previewUrl &&
                <label className="bg-white border border-[#A6A08C] rounded-[8px] cursor-pointer flex flex-col justify-center items-center min-h-[150px]">
                  <input 
                        ref={fileRef}
                        type="file"
                        accept="application/pdf, image/jpeg, image/png"  
                        onChange={() => changedHandler()}
                        className='hidden'/>
                    <div>
                    <img src={upload_icon} alt="upload icon" />
                  </div>
                  <div>
                    <p className="text-[#2B8C34] text-sm font-semibold mt-2">
                      Choose a file to Upload
                    </p>
                    <p className="text-[#344335] text-sm mt-1">
                      Upload a PDF, PNG or JPEG file
                    </p>
                    <p className="text-[#344335] text-center text-sm mt-1">
                      Max file size: 10MB
                    </p>
                  </div>
                </label>
              }

          {imgLoader &&
            <div className="bg-white border border-[#A6A08C] rounded-[8px] px-6 flex flex-col justify-center items-center min-h-[150px]">
                <div>
                  <img src={upload_fileicon} alt="upload icon" />
                </div>
                <div>
                  <p className="text-[#344335] text-sm font-medium mt-2">
                    Uploading Attachment...
                  </p>
                </div>
                <div className="bg-[#ECEFEC] rounded-[8px] h-[10px] mt-3 w-full">
                  <div className={`bg-[#2B8C34] rounded-[8px] h-[10px]`} style={{width: `${progress}%`}}></div>
                </div>
            </div>
            }


            </div>
          </div>

        </div>

         <div className="flex justify-end mt-5">
        <div className="flex gap-3">
          <button className="w-full py-3 flex items-center justify-center px-5 bg-white border border-primary rounded-[8px] text-[#2B8C34] text-sm font-medium  transition-all">
            Cancel
          </button>
          <button
            disabled={recipientCreateLoader}
            onClick={() => handlePayment()}
            className="w-full py-3 flex items-center disabled:bg-[#ABB6AC] hover:bg-[#24752B] justify-center px-5 bg-[#2B8C34] rounded-[8px] text-[#fff] text-sm font-medium  transition-all"
          >
            Proceed
          </button>
        </div>
         </div>

        </>
      }

      {
        typeID === 2 &&
        <BulkPaymentPage />
      }

   
    </>
  );
};

export default NewPaymentPage;
