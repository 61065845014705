import React,{useState} from 'react'
import DrawerComponent from '../../components/Drawers/drawer';


const PendingApprovalTable = () => {

    const [isOpen, setIsOpen] = useState(false);

    const showPaymentInfo = () => {
        setIsOpen(!isOpen);
    };


  const getSidebarStatusBg = (val: string) => {
    let result;
    switch (val) {
      case "Failed":
        result =
          "flex bg-[#fdded4] text-[#b92043] rounded-[4px] px-2 py-1 text-xs font-medium";
        break;
      case "Pending":
        result =
          "flex bg-[#fff3ce] text-[#d5a407] rounded-[4px] px-2 py-1 text-xs font-medium";
        break;
      case "Successful":
        result =
          "flex bg-[#DBFFDF] text-[#2B8C34] rounded-[4px] px-2 py-1 text-xs font-medium";
        break;
      default:
        result =
          "flex bg-[#fff3ce] text-[#d5a407] rounded-[4px] px-2 py-1 text-xs font-medium";
        break;
    }
    return result;
  };

  const handlePaymentInfo = (id: number) => {
    setIsOpen(!isOpen);
  };

    return ( 
        <>
          <div className="bg-white rounded-[8px]">
          <div className="p-4">
            <p className="text-primary font-semibold text-base leading-5">
              Pending Approval
            </p>
            <p className="text-[#7C8D7E] text-sm font-medium mt-1">
              This shows a list of last 5 transactions that requires approval
            </p>
          </div>

          {/* tables */}
          <div className="overflow-x-auto relative">
            <table className="w-full text-left">
              <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                <tr>
                  <th scope="col" className="px-4 py-4 font-semibold ">
                    Date
                  </th>
                  <th scope="col" className="px-4 py-4 font-semibold">
                    Recipient
                  </th>
                  <th scope="col" className="px-4 py-4 font-semibold">
                    Amount
                  </th>
                  <th scope="col" className="px-4 py-4 font-semibold">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr onClick={() => handlePaymentInfo(1)} className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]">
                  <th
                    scope="row"
                    className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                  >
                    Jan 30, 2024 4:23 PM
                  </th>
                  <td className="py-4 px-4 text-[#5C715E]">Olawale Stephen</td>
                  <td className="py-4 px-4 text-[#5C715E]">₦3000</td>

                  <td className="py-4 px-4 text-[#5C715E]">
                    <div className="flex">
                      <div className="pending-badge">
                        <p>Pending</p>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]">
                  <th
                    scope="row"
                    className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                  >
                    Jan 30, 2024 4:23 PM
                  </th>
                  <td className="py-4 px-4 text-[#5C715E]">Olawale Stephen</td>
                  <td className="py-4 px-4 text-[#5C715E]">₦300</td>

                  <td className="py-4 px-4 text-[#5C715E]">
                    <div className="flex">
                      <div className="pending-badge">
                        <p>Pending</p>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]">
                  <th
                    scope="row"
                    className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                  >
                    Jan 30, 2024 4:23 PM
                  </th>
                  <td className="py-4 px-4 text-[#5C715E]">Olawale Stephen</td>
                  <td className="py-4 px-4 text-[#5C715E]">₦230</td>

                  <td className="py-4 px-4 text-[#5C715E]">
                    <div className="flex">
                      <div className="pending-badge">
                        <p>Pending</p>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]">
                  <th
                    scope="row"
                    className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                  >
                    Jan 30, 2024 4:23 PM
                  </th>
                  <td className="py-4 px-4 text-[#5C715E]">Olawale Stephen</td>
                  <td className="py-4 px-4 text-[#5C715E]">₦230</td>

                  <td className="py-4 px-4 text-[#5C715E]">
                    <div className="flex">
                      <div className="pending-badge">
                        <p>Pending</p>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]">
                  <th
                    scope="row"
                    className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                  >
                    Jan 30, 2024 4:23 PM
                  </th>
                  <td className="py-4 px-4 text-[#5C715E]">Olawale Stephen</td>
                  <td className="py-4 px-4 text-[#5C715E]">₦230</td>

                  <td className="py-4 px-4 text-[#5C715E]">
                    <div className="flex">
                      <div className="pending-badge">
                        <p>Pending</p>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

     {/* Drawer component */}
      <DrawerComponent title="" isOpen={isOpen} onClose={showPaymentInfo}>
        <div className="mt-8 flex flex-col justify-center items-center">
          <div className="w-[48px] h-[48px] rounded-full bg-[#DBFFDF] text-[#2B8C34] flex justify-center font-medium items-center text-sm">
            K
          </div>
          <h4 className="text-[#344335] font-semibold text-2xl mt-2">
            ₦3,000
          </h4>
          <p className="text-[#5C715E] text-sm font-medium mt-1">
            Ola Adeleke
          </p>
        </div>

        <div className="mt-10">
          <div className="flex justify-between items-center">
            <p className="text-[#5C715E] text-xs">Amount</p>
            <p className="text-[#5C715E] text-xs">
              ₦3000
            </p>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex justify-between items-center">
            <p className="text-[#5C715E] text-xs">Date</p>
            <p className="text-[#5C715E] text-xs">
             20th, Aug 2024
            </p>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex justify-between items-center">
            <p className="text-[#5C715E] text-xs">Initiated By</p>
            <p className="text-[#5C715E] text-xs">
              Shola
            </p>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex justify-between items-center">
            <p className="text-[#5C715E] text-xs">Bank Name</p>
            <p className="text-[#5C715E] text-xs">
             FCMB
            </p>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex justify-between items-center">
            <p className="text-[#5C715E] text-xs">Account Number</p>
            <p className="text-[#5C715E] text-xs">
              0148800246
            </p>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex justify-between items-center">
            <p className="text-[#5C715E] text-xs">Account Name</p>
            <p className="text-[#5C715E] text-xs">
             Gbenga Olusola
            </p>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex justify-between items-center">
            <p className="text-[#5C715E] text-xs">Source Account</p>
            <p className="text-[#5C715E] text-xs">Salary Account</p>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex justify-between items-center">
            <p className="text-[#5C715E] text-xs">Status</p>
            <div className={getSidebarStatusBg("Pending")}>
                Pending
            </div>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex justify-between items-center">
            <p className="text-[#5C715E] text-xs">Payment ID</p>
            <p className="text-[#5C715E] text-xs">N/A</p>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex justify-between items-center">
            <p className="text-[#5C715E] text-xs">Approval Status</p>
            <div
              className={
                false
                  ? "flex bg-[#DBFFDF] text-[#2B8C34] rounded-[4px] px-2 py-1 text-xs font-medium"
                  : "flex bg-[#fff3ce] text-[#d5a407] rounded-[4px] px-2 py-1 text-xs font-medium"
              }
            >
              {false ? "Approved" : "Not Approved"}
            </div>
          </div>
        </div>
        <div className="mt-5">
          <div className="flex justify-between items-center">
            <p className="text-[#5C715E] text-xs">Payment Memo</p>
            <p className="text-[#5C715E] text-xs">
              
               N/A
            </p>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex justify-between items-center">
            <p className="text-[#5C715E] text-xs">Payment Type</p>
            <div className="flex bg-[#DBFFDF] text-[#2B8C34] rounded-[4px] px-2 py-1 text-xs font-medium">
              Instant
            </div>
          </div>
        </div>

       
            <div className="flex gap-3 justify-center mt-10">
              <div>
                <button
                  type="submit"
                  className="bg-[#B92043] 
                      disabled:bg-[#B92043] hover:bg-opacity-[0.9]
                        rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#B92043] transition-all"
                >
                  Reject Payment
                </button>
              </div>
              <div>
                <button
                  type="submit"
                  
                  className="bg-primary 
                        disabled:bg-[#ABB6AC]
                        rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
                >
                  Approve Payment
                </button>
              </div>
            </div>
        
      </DrawerComponent>


        </div>
        </>
     ); 
}
 
export default PendingApprovalTable;