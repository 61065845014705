import React from 'react'

type headerType = {
    text: string;
    children?: React.ReactNode
}

const Header = ({text, children}: headerType) => {
    return (  
        <>
       <div className="bg-white border border-[#ECEFEC] px-4 py-5 flex justify-between items-center rounded-[8px]">
            <div className=" flex gap-[8px]">
                <p className="text-primary text-lg font-semibold leading-6">{text}</p>
            </div>
            {children}
        </div>
        </>
    );
}
 
export default Header;