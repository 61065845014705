import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useMemo,
} from "react";
import more_icon from "../../assets/icons/more_vert.svg";
import SearchComponent from "../../components/Search";
import { FilterList } from "@mui/icons-material";
import ModalComponent from "../../components/Modals/modal";
import { useNavigate } from "react-router-dom";
import {
  deleteRecipient,
  getAllRecipients,
  recipientsSearch,
} from "../../redux/Recipient/recipientActions";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { toast } from "react-hot-toast";
import { debounce } from "lodash";
import PaginationComponent from "../../components/Pagination/Pagination";
import DrawerComponent from "../../components/Drawers/drawer";
import { RecipientType } from "../../types/Recipients/Recipients";
import { InitiatePayment } from "../../redux/Payment/paymentActions";
import { fetchAccountById } from "../../redux/Account/accountActions";
import { clearIsInitiated } from "../../redux/Payment/payment";
import { useRecipientPermissions } from "../../helpers/permissionsHelper/recipientPermissions";
import { usePaymentPermissions } from '../../helpers/permissionsHelper/paymentPermissions';

interface RecipientsTableMethods {
  refreshRecipient: () => void;
}

const RecipientsTable = forwardRef<RecipientsTableMethods>((props, ref) => {
  const navigate = useNavigate();
  const { recipientPermissions } = useRecipientPermissions();
  const { paymentPermissions } = usePaymentPermissions();
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isOpenViewRecipient, setIsOpenViewRecipient] = useState(false);
  const [isDeleteRecipient, setIsDeleteRecipient] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);
  const [recipient, setRecipient] = useState<RecipientType | null>(null);
  const dispatch = useAppDispatch();
  const { recipients, loading, total } = useAppSelector(
    (state) => state.recipient
  );
  const { loader, isInitiated } = useAppSelector((state) => state.payment);
  const { selectedAccount } = useAppSelector((state) => state.auth);
  const { accountInfo } = useAppSelector((state) => state.account);

  const [isShow, setIsShow] = useState(false);

  const [selectedType, setSelectedType] = useState("Instant");

  const [memo, setMemo] = useState("");

  const [amount, setAmount] = useState("0");

  // validate amount with a regex function for numbers alone without decimals
  const handleAmount = (amount: string) => {
    const regex = /^[0-9]*\.?[0-9]*$/;
    // test if amount is a number and set amount if valid
    if (regex.test(amount)) {
      setAmount(amount);
    } else {
      toast("The amount entered is not valid!", {
        style: {
          border: "1px solid #c9f7fc",
          backgroundColor: "#c9f7fc",
          color: "#0066af",
          fontSize: 14,
        },
        position: "top-right",
      });
    }
  };

  const handleType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedType(event.target.value);
  };

  const showModal = () => {
    setIsShow(!isShow);
  };

  const toggleDropdown = (id: string) => {
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };

  const refreshRecipientFunc = () => {
    dispatch(getAllRecipients({ page: currentPage, per_page: pageSize }));
  };

  useImperativeHandle(ref, () => ({
    refreshRecipient: refreshRecipientFunc,
  }));

  const handleDeleteRecipient = () => {
    const id = openDropdownId;
    setDeleteLoading(true);
    setOpenDropdownId("");
    dispatch(deleteRecipient(id)).then((res) => {
      if (res?.type === "deleteRecipient/fulfilled") {
        setDeleteLoading(false);
        toast.success("Recipient deleted successfully!", {
          style: {
            border: "1px solid #2b8c34",
            backgroundColor: "#2b8c34",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });

        setIsDeleteRecipient(false);
        refreshRecipientFunc();
        setOpenDropdownId(null);
      } else if (res?.type === "deleteRecipient/rejected") {
        setDeleteLoading(false);
        toast.error("Something went wrong! Try again");
      }
    });
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    dispatch(getAllRecipients({ page: page, per_page:pageSize }));
  };

  const onPageSizeChange = (page: number) =>{
    setPageSize(page)
 }

  const handleSearch = useMemo(
    () =>
      debounce((val: string) => {
        let values = {
          query: val,
        };
        if (val?.length === 0) {
          dispatch(getAllRecipients({ page: currentPage, per_page: pageSize }));
        } else {
          dispatch(recipientsSearch(values));
        }
      }, 750),
    [dispatch, currentPage, pageSize]
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  const handleViewRecipient = (recipient: RecipientType) => {
    setRecipient(recipient);
    setIsOpenViewRecipient(true);
  };

  const handleCloseRecipient = () => {
    setIsOpenViewRecipient(false);
    setOpenDropdownId(null);
    setRecipient(null);
  };

  const handleSendPayment = (recipient: RecipientType) => {
    // check if selected account type is main account
    if (selectedAccount?.type === "main_account") {
      // Your code here for when the selected account type is main account
      toast(
        "You cannot send a payment from this account, kindly switch to another account.",
        {
          style: {
            border: "1px solid #c9f7fc",
            backgroundColor: "#c9f7fc",
            color: "#0066af",
            fontSize: 14,
          },
          position: "top-right",
        }
      );
    } else {
      // Your code here for when the selected account type is not main account
      setRecipient(recipient);
      setOpenDropdownId(null);
      setIsShow(true);
    }
  };

  const handleInitiatePayment = () => {
    if (amount === "" || parseFloat(amount) < 500) {
      toast("The least amount you can transact is 500 naira.", {
        style: {
          border: "1px solid #c9f7fc",
          backgroundColor: "#c9f7fc",
          color: "#0066af",
          fontSize: 14,
        },
        position: "top-right",
      });
    } else {
      dispatch(
        InitiatePayment({
          amount: parseFloat(amount),
          narration: memo,
          recipient: recipient ? recipient.id : "N/A",
        })
      );
    }
  };

  useEffect(() => {
    if (isInitiated) {
      // reset all values, receipient, amount and memo
      setAmount("0");
      setMemo("");
      setIsShow(false);
      setRecipient(null);
      setTimeout(() => {
        dispatch(clearIsInitiated());
      }, 2000);
      navigate(`/payment`);
    }
  }, [isInitiated, dispatch, navigate]);

  useEffect(() => {
    if (selectedAccount.type !== "main_account") {
      dispatch(
        fetchAccountById({
          account_id: selectedAccount?.account_id,
        })
      );
    }
  }, [selectedAccount, dispatch]);

  useEffect(() => {
    dispatch(getAllRecipients({ page: 1, per_page: pageSize }));
    // setCurrentPage(1);
  }, [dispatch, pageSize]);

  return (
    <>
      <div className="mt-4 bg-white relative rounded-[8px]">
        <div className="p-4">
          <p className="text-primary font-semibold text-base leading-5">
            Recipients Details
          </p>

          {/* search */}
          <div className="flex gap-4 mt-3">
            <div className="w-full">
              <SearchComponent
                placeholder="Search"
                searchValue={searchValue}
                handleChange={handleChange}
              />
            </div>
            <div>
              <div className="flex justify-center items-center gap-2 border border-primary rounded-[8px] py-3 px-6 text-primary min-h-[50px] text-sm font-medium leading-5 cursor-pointer">
                <p className="">Filter</p>
                <FilterList style={{ fontSize: "18px" }} />
              </div>
            </div>
          </div>
        </div>

        {/* tables */}
        <div className=" relative">
          {loading ? (
            <div>
              <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            </div>
          ) : (
            <>
              {!recipientPermissions.viewRecipient ? (
                <div className="w-full pb-[80px] mt-[80px] flex items-center justify-center">
                  <p>You don't have access to view this data</p>
                </div>
              ) : (
                <>
                  {recipients?.length === 0 ? (
                    <div>
                      <div className="w-full pb-[80px] mt-[80px] flex items-center justify-center">
                        <p>There's no recipient data yet</p>
                      </div>
                    </div>
                  ) : (
                    <section>
                      <table className="w-full text-left relative">
                        <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                          <tr>
                            <th
                              scope="col"
                              className="px-4 py-4 font-semibold "
                            >
                              Account Name
                            </th>
                            <th scope="col" className="px-4 py-4 font-semibold">
                              Bank
                            </th>
                            <th scope="col" className="px-4 py-4 font-semibold">
                              Account Number
                            </th>
                            <th scope="col" className="px-4 py-4 font-semibold">
                              Tag
                            </th>
                            <th scope="col" className="px-4 py-4 font-semibold">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {recipients?.map((recipient, index) => {
                            return (
                              <tr
                                key={index}
                                className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                              >
                                <th
                                  scope="row"
                                  className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap capitalize "
                                >
                                  {recipient?.recipient_name?.toLowerCase() ||
                                    "N/A"}
                                </th>
                                <td className="py-4 px-4 text-[#5C715E]">
                                  {" "}
                                  {recipient?.bank?.name || "N/A"}
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                  {" "}
                                  {recipient?.account_number || "N/A"}
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                  <div className="flex">
                                    <div className="bg-[#DBFFDF] text-primary text-sm capitalize py-1 px-2 rounded-[4px] flex justify-center items-center font-medium">
                                      <p>
                                        {" "}
                                        {recipient?.recipient_tag?.tag || "N/A"}
                                      </p>
                                    </div>
                                  </div>
                                </td>

                                <td className="py-4  relative px-4 text-[#5C715E]">
                                  <div className="">
                                    <img
                                      src={more_icon}
                                      alt="more icon"
                                      onClick={() =>
                                        toggleDropdown(recipient?.id)
                                      }
                                    />

                                    {openDropdownId === recipient?.id && (
                                      <ul className="absolute mt-5 bg-white z-[6] rounded-lg shadow-dropdown py-2 px-2 right-10 top-6 min-w-[187px]">
                                        <li
                                          onClick={() =>
                                            handleViewRecipient(recipient)
                                          }
                                          className="py-3 px-2 cursor-pointer hover:bg-[#EDF7EE]"
                                        >
                                          <p className="text-[#344335] text-sm font-medium">
                                            View Details
                                          </p>
                                        </li>

                                      {paymentPermissions.initiatePayment &&
                                        <li
                                          onClick={() =>
                                            handleSendPayment(recipient)
                                          }
                                          className="py-3 px-2 cursor-pointer hover:bg-[#EDF7EE]"
                                        >
                                          <p className="text-[#344335] text-sm font-medium">
                                            Initiate Payment
                                          </p>
                                        </li>
                                        }
                                        
                                        {recipientPermissions.deleteRecipient && (
                                          <li
                                            className="py-3 px-2 cursor-pointer hover:bg-[#EDF7EE]"
                                            onClick={() =>
                                              setIsDeleteRecipient(true)
                                            }
                                          >
                                            <p className="text-[#344335] text-sm font-medium">
                                              Delete Recipient
                                            </p>
                                          </li>
                                        )}
                                      </ul>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>

                      <div>
                        <PaginationComponent
                          page={currentPage}
                          onPageChange={(page) => onPageChange(page)}
                          totalItems={total}
                          pageSize={pageSize}
                          onPageSizeChange={(page) => onPageSizeChange(page)}
                          pageSizeShow
                        />
                        
                      </div>
                    </section>
                  )}
                </>
              )}
            </>
          )}
        </div>

        {/* DELETE RECIPIENT MODALS */}
        <ModalComponent
          title="Delete Recipient"
          subtitle=""
          isOpen={isDeleteRecipient}
          onClose={() => setIsDeleteRecipient(false)}
        >
          <div>
            <p className="mt-[10px] text-sm font-mediumn leading-5 text-[#5C715E] mb-8">
              Recipient’s data would be lost when deleted, are you sure you want
              to delete recipient?
            </p>

            <div className="flex justify-end">
              <div className="flex gap-[40px] items-center cursor-pointer">
                <p
                  className=" text-sm font-mediumn leading-5 text-[#5C715E]"
                  onClick={() => setIsDeleteRecipient(false)}
                >
                  Cancel
                </p>
                <button
                  disabled={deleteLoading}
                  onClick={handleDeleteRecipient}
                  className=" outline-none text-white rounded-lg disabled:bg-[#ABB6AC] bg-[#B92043] text-sm font-mediumn leading-5 px-[20px] py-[10px]"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </ModalComponent>

        {/* Drawer component */}
        <DrawerComponent
          title="Recipients Details"
          isOpen={isOpenViewRecipient}
          onClose={handleCloseRecipient}
        >
          <div className="mt-[40px]">
            <div className="p-4 flex justify-between items-center rounded-lg border-[1px] border-[#EEEDEA] bg-[#F7F7F7]">
              <div className="flex gap-2 w-[70%]">
                <div className="w-[48px] h-[48px] capitalize rounded-full bg-[#2B8C34] text-[#FFFFFF] flex justify-center font-medium items-center text-sm">
                  {recipient?.recipient_name?.charAt(0) || "N/A"}
                </div>
                <div className="flex flex-col  gap-1">
                  <p className="text-sm font-semibold capitalize leading-5 text-[#344335]">
                    {recipient?.recipient_name?.toLowerCase() || "N/A"}
                  </p>
                  <div className="rounded-[4px] border-[1px] capitalize border-[#2B8C34] bg-white text-[#2B8C34] text-sm font-medium leading-5 flex justify-center items-center px-2 w-fit">
                    <p> {recipient?.recipient_tag?.tag || "N/A"}</p>
                  </div>
                </div>
                <div></div>
              </div>
              <div className="flex  justify-end  w-[30%]">
                <p className="text-sm font-semibold leading-5 text-[#5C715E]">
                  {recipient?.bank?.name || "N/A"} | {recipient?.account_number}
                </p>
              </div>
            </div>
          </div>
        </DrawerComponent>

        {/* SEND PAYMENT MODAL */}
        <ModalComponent
          title="Initiate Payment"
          subtitle=""
          isOpen={isShow}
          onClose={showModal}
        >
          <div className="border-[#EEEDEA] flex justify-between items-center border bg-white rounded-lg py-4 px-4 mt-5">
            <div className="flex gap-2 w-[70%]">
              <div className="w-[48px] h-[48px] capitalize rounded-full bg-[#2B8C34] text-[#FFFFFF] flex justify-center font-medium items-center text-sm">
                {recipient?.recipient_name?.charAt(0) || "N/A"}
              </div>
              <div className="flex flex-col  gap-1">
                <p className="text-sm font-semibold capitalize leading-5 text-[#344335]">
                  {recipient?.recipient_name?.toLowerCase() || "N/A"}
                </p>
                <div className="rounded-[4px] border-[1px] border-[#2B8C34] bg-white text-[#2B8C34] text-sm font-medium leading-5 flex justify-center items-center px-2 w-fit">
                  <p> {recipient?.recipient_tag?.tag || "N/A"}</p>
                </div>
              </div>
              <div></div>
            </div>
            <div className="flex justify-end w-[30%]">
              <p className="text-sm font-semibold leading-5 text-[#5C715E]">
                {recipient?.bank?.name || "N/A"} | {recipient?.account_number}
              </p>
            </div>
          </div>

          <div className="mt-4 border border-[#ECEEEC] flex flex-col items-center bg-[#F7F7F7] py-4 rounded-lg">
            <div>
              <p className="text-[#5C715E] text-sm font-medium">Amount</p>
            </div>
            <div className="mt-2">
              <input
                type="number"
                name="amount"
                placeholder="0.00"
                value={amount}
                disabled={loader}
                onChange={(e) => {
                  handleAmount(e.target.value);
                }}
                className="appearance-none max-w-xs mx-auto text-center mt-1 font-semibold placeholder:text-[#96A397] placeholder:text-4xl text-[#344335] text-4xl border-none focus:outline-none bg-transparent"
              />
            </div>
            <div className="mt-5 rounded-[4px] border border-[#344335] px-1 py-1">
              <p className="text-[#344335] text-xs font-medium">
                Available Balance:{" "}
                {`₦${
                  accountInfo
                    ? accountInfo?.account_balance?.toLocaleString()
                    : 0
                }`}{" "}
              </p>
            </div>
          </div>

          <div className="mt-5">
            <label
              htmlFor="memo"
              className="block mb-2 text-[#5C715E] text-sm font-normal"
            >
              Payment type
            </label>
            <div className="mt-4 flex gap-4 flex-wrap">
              <div className="flex items-center">
                <input
                  id="default-radio-1"
                  type="radio"
                  value="Instant"
                  name="default-radio"
                  checked={selectedType === "Instant"}
                  onChange={handleType}
                  className="w-4 h-4 text-[#2B8C34] cursor-pointer bg-gray-100 border-gray-300 focus:ring-[#2B8C34] focus:ring-0 "
                />
                <label
                  htmlFor="default-radio-1"
                  className={`ml-2 text-sm font-medium ${
                    selectedType === "Instant"
                      ? "text-[#344335]"
                      : "text-[#5C715E]"
                  } text-[#344335]`}
                >
                  Instant Payment
                </label>
              </div>

              <div className="flex items-center">
                <input
                  id="default-radio-2"
                  type="radio"
                  disabled
                  checked={selectedType === "Recurrring"}
                  value="Recurrring"
                  name="default-radio"
                  onChange={handleType}
                  className="w-4 h-4 text-[#2B8C34]  bg-gray-100 border-gray-300 focus:ring-[#2B8C34] focus:ring-0 "
                />
                <label
                  htmlFor="default-radio-2"
                  className={`ml-2 text-sm font-medium ${
                    selectedType === "Recurrring"
                      ? "text-[#344335]"
                      : "text-[#5C715E]"
                  }`}
                >
                  Recurring Payment
                </label>
                <div className="ml-2 rounded-[4px] text-xs bg-[#c9f7fc] px-1 py-[3px] text-[#0066af] flex items-center gap-2">
                  <p>Coming soon</p>
                </div>
              </div>

              <div className="flex items-center">
                <input
                  id="default-radio-3"
                  type="radio"
                  value="Scheduled"
                  disabled
                  name="default-radio"
                  checked={selectedType === "Scheduled"}
                  onChange={handleType}
                  className="w-4 h-4 text-[#2B8C34] bg-gray-100 border-gray-300 focus:ring-[#2B8C34] focus:ring-0 "
                />
                <label
                  htmlFor="default-radio-3"
                  className={`ml-2 text-sm font-medium ${
                    selectedType === "Scheduled"
                      ? "text-[#344335]"
                      : "text-[#5C715E]"
                  }`}
                >
                  Scheduled Payment
                </label>
                <div className="ml-2 rounded-[4px] text-xs bg-[#c9f7fc] px-1 py-[3px] text-[#0066af] flex items-center gap-2">
                  <p>Coming soon</p>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <label
              htmlFor="memo"
              className="block mb-2 text-[#344335] text-sm font-normal"
            >
              Payment Memo(Optional)
            </label>
            <textarea
              name="memo"
              rows={6}
              value={memo}
              onChange={(e) => setMemo(e.target.value)}
              placeholder="Enter a payment memo"
              className="appearance-none resize-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-sm focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
            />
          </div>

         {paymentPermissions.initiatePayment &&
          <button
            onClick={handleInitiatePayment}
            disabled={loader}
            type="submit"
            className="bg-primary 
                    disabled:bg-[#ABB6AC]
                    rounded-lg px-6 py-3 text-white w-full mt-4 text-sm font-medium hover:bg-[#24752B] transition-all"
          >
            Initiate Payment
          </button>
          }
        </ModalComponent>
      </div>
    </>
  );
});

export default RecipientsTable;
