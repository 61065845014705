import {PayloadAction, createSlice} from '@reduxjs/toolkit'
import { acceptInvite, cancelInvite, fetchInvitee, fetchRolePrivileges, fetchTeammates, inviteTeammate, resendInvite, updateTeammateRole, updateTeammateStatus  } from './teammateActions'
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { TeammatesType,TeamInvitesType } from '../../types/Teammates/Teammates';



type TeammateState = {
    loading: boolean,
    teammates: TeammatesType[]
    teaminvites: TeamInvitesType[]
}

const initialState: TeammateState = { 
    loading: false,
    teammates: [],
    teaminvites: []
} 

// Configure Redux Persist
const teammatePersistConfig = {
    key: 'teammate',
    storage,
    blacklist: []
    // Add any additional configuration options as needed
  };

  export const teammateSlice = createSlice({
    name: 'teammate',
    initialState,
    reducers:{
        logOut: () =>{
            localStorage.setItem("token", "");
            localStorage.clear();

            return initialState
        },
    },
    extraReducers: (builder) =>{
        // get all teammates
        builder.addCase(fetchTeammates.pending, (state) =>{
            state.loading = true
        })
        builder.addCase(fetchTeammates.fulfilled, (state, action: PayloadAction<any>) =>{
            state.loading = false 
            state.teammates = action.payload.data

        })
        builder.addCase(fetchTeammates.rejected, (state) =>{
            state.loading = false
        })
        // accept invite
        builder.addCase(acceptInvite.pending, (state) =>{
            
        })
        builder.addCase(acceptInvite.fulfilled, (state, action: PayloadAction<any>) =>{
            
        })
        builder.addCase(acceptInvite.rejected, (state) =>{
            
        })
        // send invite
        builder.addCase(inviteTeammate.pending, (state) =>{
           
        })
        builder.addCase(inviteTeammate.fulfilled, (state, action: PayloadAction<any>) =>{
           
        })
        builder.addCase(inviteTeammate.rejected, (state) =>{
            
        })
        // fetch invitees
        builder.addCase(fetchInvitee.pending, (state) =>{
            state.loading = true
        })
        builder.addCase(fetchInvitee.fulfilled, (state, action: PayloadAction<any>) =>{
            state.loading = false
            state.teaminvites = action.payload.data
        })
        builder.addCase(fetchInvitee.rejected, (state) =>{
            state.loading = false
        })
        // cancel invite
        builder.addCase(cancelInvite.pending, (state) =>{
            
        })
        builder.addCase(cancelInvite.fulfilled, (state, action: PayloadAction<any>) =>{
            
        })
        builder.addCase(cancelInvite.rejected, (state) =>{
            
        })
        // resend invite
        builder.addCase(resendInvite.pending, (state) =>{
            
        })
        builder.addCase(resendInvite.fulfilled, (state, action: PayloadAction<any>) =>{
            
        })
        builder.addCase(resendInvite.rejected, (state) =>{
            
        })
        // update teammate status
        builder.addCase(updateTeammateStatus.pending, (state) =>{
            
        })
        builder.addCase(updateTeammateStatus.fulfilled, (state, action: PayloadAction<any>) =>{
            
        })
        builder.addCase(updateTeammateStatus.rejected, (state) =>{
            
        })
        // update teammate role
        builder.addCase(updateTeammateRole.pending, (state) =>{
            
        })
        builder.addCase(updateTeammateRole.fulfilled, (state, action: PayloadAction<any>) =>{
            
        })
        builder.addCase(updateTeammateRole.rejected, (state) =>{
            
        })

        // fetch role privileges
        builder.addCase(fetchRolePrivileges.pending, (state) =>{
            
        })
        builder.addCase(fetchRolePrivileges.fulfilled, (state, action: PayloadAction<any>) =>{
            
        })
        builder.addCase(fetchRolePrivileges.rejected, (state) =>{
            
        })
      
        
    },
})

const teammateReducer = persistReducer(teammatePersistConfig, teammateSlice.reducer);
export const {logOut} = teammateSlice.actions

export default teammateReducer