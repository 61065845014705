// import { useAppSelector } from "../../../redux/store";
// export const useRolesAndPrivilegePermissions = () => {
//   const { userPermissions } = useAppSelector((state) => state.auth);

//   interface RolesAndPrivilegePermissions {
// assignRole: boolean;
// createRole: boolean;
// editRole: boolean;
// viewRoles: boolean;

//     // Add more overview permissions here if needed
//   }
//   const rolesAndPrivilegePermissions = (): RolesAndPrivilegePermissions => {
//     const rolesAndPrivilegePermissions: RolesAndPrivilegePermissions = {
// assignRole: false,
// createRole: false,
// editRole: false,
// viewRoles: false,
//       // Initialize other overview permissions here
//     };

//     userPermissions.forEach((permission) => {
//       if (permission.category === "Roles_privileges") {
//         switch (permission.name) {
// case "Can assign Role":
//   rolesAndPrivilegePermissions.assignRole = true;
//   break;
// case "Can create Role":
//   rolesAndPrivilegePermissions.createRole = true;
//   break;
// case "Can edit Role":
//   rolesAndPrivilegePermissions.editRole = true;
//   break;
// case "Can view Roles":
//   rolesAndPrivilegePermissions.viewRoles = true;
//   break;
// // Add cases for other overview permissions here
//         }
//       }
//     });

//     return rolesAndPrivilegePermissions;
//   };

//   return {
//     rolesAndPrivilegePermissions: rolesAndPrivilegePermissions(),
//   };
// };

import { useAppSelector } from "../../../redux/store";
import { useEffect, useState } from "react";

export const useRolesAndPrivilegePermissions = () => {
  const { userPermissions } = useAppSelector((state) => state.auth);
  const [permissions, setPermissions] = useState<any>({});

  interface typeRolesAndPrivilegePermissions {
    assignRole: boolean;
    createRole: boolean;
    editRole: boolean;
    viewRoles: boolean;
    // Add more overview permissions here if needed
  }

  useEffect(() => {
    const rolesAndPrivilegePermissions: typeRolesAndPrivilegePermissions = {
      assignRole: false,
      createRole: false,
      editRole: false,
      viewRoles: false,
      // Add more overview permissions here if needed
    };

    userPermissions.forEach((permission) => {
      if (permission.category === "Roles_Privileges") {
        switch (permission.name) {
          case "Can Assign Role":
            rolesAndPrivilegePermissions.assignRole = true;
            break;
          case "Can Create Role":
            rolesAndPrivilegePermissions.createRole = true;
            break;
          case "Can Edit Role":
            rolesAndPrivilegePermissions.editRole = true;
            break;
          case "Can View Roles":
            rolesAndPrivilegePermissions.viewRoles = true;
            break;

          // Add cases for other overview permissions here
        }
      }
    });

    setPermissions(rolesAndPrivilegePermissions);
  }, [userPermissions]);

  return {
    rolesAndPrivilegePermissions: permissions,
  };
};
