import { BanksType } from "../Recipients/Recipients";

export type Payment = {
  amount: string;
  approval_status: boolean;
  approved_by: string;
  beneficiary: string;
  created_at: string;
  id: string;
  initiated_by: string;
  is_resolved: boolean;
  narration: string;
  recipient: Recipient;
  transaction: string;
  updated: string;
  status: string;
  sender: string;
  document: string;
  handled_by: string;
};

export type TransactionsState = {
  transactions: Transaction[];
  loading: boolean;
  total: number;
  recentTransactions: Transaction[];
  recentTransactionsLoading: boolean;
};

type Recipient = {
  account_number: string;
  bank: BanksType;
  created: string;
  id: number;
  recipient_name: string;
  updated: string;
};

type account = {
  account_balance: string;
  account_id: string;
  account_name: string;
  account_number: string;
  account_type: string;
  bank: string | null;
  created: string;
  id: 2;
  monthly_budget: string;
  updated: string;
};

export type Transaction = {
  amount: string;
  amount_with_sign: string;
  c2c_account: account;
  created_at: string;
  id: string;
  narration: string;
  payment?: Payment;
  sender: string;
  status: boolean;
  transaction_type: TransactionType;
  trans_type: string;
  updated: string;
  counter_party: string;
  handled_by: string;
};

export enum TransactionType {
  CREDIT = "credit",
  DEBIT = "debit",
  REFUND = "refund",
}

export type TransactionsParams = {
  page: number;
  per_page: number;
  start: string | null;
  end: string | null;
  min: number | null;
  max: number | null;
  id: string | null;
};
