import React from "react";
import logo from "../../assets/icons/Logo_Big.svg";
import warning from "../../assets/icons/warning.svg";
import { Link } from "react-router-dom";
import { Formik, Form, FormikHelpers } from "formik";
import { forgotPasswordValidator } from "../../validationSchema/validator";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { userForgotPassword } from "../../redux/Auth/authActions";

const ForgotPasswordPage = () => {

  const dispatch = useAppDispatch()
  const {loading} = useAppSelector((state) => state.auth)

  interface Values {
    email: string;
  }

  const handleSubmit = (values: Values) => {
    dispatch(userForgotPassword({
      email: values.email
    }))
  };

  return (
    <>
      <div className="relative flex overflow-hidden">
        <div className="hidden md:block absolute -top-20 -right-24 bg-[#DBFFDF] w-[350px] h-[350px] rounded-full blur-[150px]"></div>
        <div className="w-[40%] bg-primary min-h-screen relative hidden md:block">
          <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
            <img src={logo} alt="logo" />
          </div>
        </div>
        <div className="w-full md:w-[60%] px-4 md:px-0 flex justify-center flex-col min-h-screen">
          <div className="min-w-full md:min-w-[32rem] mx-auto">
            <h6 className="text-primary font-semibold text-lg">
              Forgotten Your Password?
            </h6>
            <p className="text-[#5C715E] text-sm font-medium max-w-lg">
              Don’t feel bad, it happens to the best of us. Enter your email
              address and we'll send you a link to reset your password.
            </p>
            {/* form */}
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={forgotPasswordValidator}
              onSubmit={(
                values: Values,
                { setSubmitting }: FormikHelpers<Values>
              ) => {
                handleSubmit(values);
              }}
            >
              {({
                handleChange,
                isSubmitting,
                handleSubmit,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <Form className="mt-8" onSubmit={handleSubmit}>
                  <div>
                    <label
                      htmlFor="email"
                      className="block mb-2 text-[#344335] text-sm font-normal"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      className={
                        touched.email && errors.email
                          ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                          : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                      }
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.email && errors.email ? (
                      <div className="flex">
                        <img src={warning} className="" alt="warning" />
                        <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          {touched.email && errors.email}
                        </small>
                      </div>
                    ) : null}
                  </div>

                  <div className="mt-8">
                    <button
                    disabled={loading}
                      type="submit"
                      className="bg-primary 
                            disabled:bg-[#ABB6AC] 
                            rounded-lg w-full py-4 text-white font-medium hover:bg-[#24752B] transition-all"
                    >
                      Send Reset Link
                    </button>
                  </div>

                  <div className="text-center mt-3">
                    <p className="text-[#344335] text-sm font-semibold">
                      Remember password?{" "}
                      <Link
                        to="/"
                        className="text-primary font-semibold text-sm"
                      >
                        Back to Login
                      </Link>{" "}
                    </p>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordPage;
