import React, { useEffect, useState } from "react";
import logo from "../../assets/icons/Logo_Big.svg";
import eye_off from "../../assets/icons/eye-off.svg";
import eye from "../../assets/icons/eye.svg";
import warning from "../../assets/icons/warning.svg";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, FormikHelpers } from "formik";
import { loginValidator } from "../../validationSchema/validator";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { userLogin } from "../../redux/Auth/authActions";

const LoginPage = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const {loading, isAuth, is_2fa_enabled, otp_method} = useAppSelector((state) => state.auth)
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  interface Values {
    email: string;
    password: string;
  }

  const handleSubmit = (values: Values) => {
    // e.preventDefault()
    dispatch(userLogin(values))
  };

  useEffect(()=>{
    if(isAuth){
      if(is_2fa_enabled){
        if(otp_method === "auth"){
          navigate("/googleauth-verify");
        }
        else{
          navigate("/email-verify");
        }
      }
      else{
        navigate("/dashboard");
      }
    }
  },[isAuth,is_2fa_enabled,otp_method,navigate])

  return (
    <>
      <div className="relative flex overflow-hidden">
        <div className="hidden md:block absolute -top-20 -right-24 bg-[#DBFFDF] w-[350px] h-[350px] rounded-full blur-[150px]"></div>
        <div className="w-[40%] bg-primary min-h-screen relative hidden md:block">
          <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
            <img src={logo} alt="logo" />
          </div>
        </div>
        <div className="w-full md:w-[60%] px-4 md:px-0 flex justify-center flex-col min-h-screen">
          <div className="min-w-full md:min-w-[32rem] mx-auto">
            <h6 className="text-primary font-semibold text-lg">Welcome Back</h6>
            <p className="text-[#5C715E] text-sm font-medium max-w-lg">
              Enter your Credentials below to login{" "}
            </p>
            {/* form */}
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={loginValidator}
              onSubmit={(
                values: Values,
                { setSubmitting }: FormikHelpers<Values>
              ) => {
                handleSubmit(values);
              }}
            >
              {({
                handleChange,
                isSubmitting,
                handleSubmit,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <Form className="mt-8" onSubmit={handleSubmit}>
                  <div>
                    <label
                      htmlFor="email"
                      className="block mb-2 text-[#344335] text-sm font-normal"
                    >
                      Email Address
                    </label>
                    <input
                      type="text"
                      name="email"
                      placeholder="Enter your email address"
                      className={
                        touched.email && errors.email
                          ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                          : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                      }
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.email && errors.email ? (
                      <div className="flex">
                        <img src={warning} className="" alt="warning" />
                        <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          {touched.email && errors.email}
                        </small>
                      </div>
                    ) : null}
                  </div>

                  <div className="mt-5">
                    <label
                      htmlFor="password"
                      className="block mb-2 text-secondary text-sm font-normal"
                    >
                      Password
                    </label>
                    <div className="relative">
                      <input
                        type={passwordShown ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        className={
                          touched.password && errors.password
                            ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335]text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                            : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                        }
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <img
                        onClick={togglePasswordVisiblity}
                        src={passwordShown ? eye_off : eye}
                        alt="eye"
                        className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                      />
                    </div>
                    {touched.password && errors.password ? (
                      <div className="flex">
                        <img src={warning} className="" alt="warning" />
                        <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          {touched.password && errors.password}
                        </small>
                      </div>
                    ) : null}
                  </div>
                  <div className="mt-2">
                    <Link
                      to="/forgotpassword"
                      className="text-secondary font-medium text-sm"
                    >
                      Forgot your password?
                    </Link>
                  </div>

                  <div className="mt-6">
                    <button
                      type="submit"
                      disabled={loading}
                      className="bg-primary 
                                disabled:bg-[#ABB6AC] 
                                rounded-lg w-full py-4 text-white font-medium hover:bg-[#24752B] transition-all"
                    >
                      Log In
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
