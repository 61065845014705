import * as Yup from "yup";

export const loginValidator = Yup.object({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export const joinTeamValidator = Yup.object({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("First name is required"),
  password: Yup.string().required("Password is required"),
  confirm_password: Yup.string()
    .required("Passwords must match")
    .oneOf([Yup.ref("password"), ""], "Passwords must match"),
});

export const forgotPasswordValidator = Yup.object({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
});

export const resetPasswordValidator = Yup.object({
  password: Yup.string().required("Password is required"),
  confirm_password: Yup.string()
    .required("Passwords must match")
    .oneOf([Yup.ref("password"), ""], "Passwords must match"),
});

export const createAcctValidator = Yup.object({
  account: Yup.string().required("Account Alias is required"),
});

export const inviteTeammatesValidator = Yup.object({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
});

export const moveFundsValidator = Yup.object({
  amount: Yup.string()
    .required("Enter an amount")
    .matches(/^[0-9]*\.?[0-9]*$/, "Enter a valid amount"),
  narration: Yup.string().required("Narration is required"),
});

export const generateStatementValidator = Yup.object({
  email: Yup.string()
  .email("Enter a valid email")
  .required("Email is required"),
});

export const changePasswordValidator = Yup.object({
  oldpassword: Yup.string().required("Password is required"),
  password: Yup.string().required("Enter a new password"),
  password_confirmation: Yup.string()
    .required("Passwords must match")
    .oneOf([Yup.ref("password"), ""], "Passwords must match"),
});

export const createTagValidator = Yup.object({
  name: Yup.string().required("Name is required"),
  description: Yup.string().required("Description is required"),
});

export const emailAuthValidator = Yup.object({
  code: Yup.string()
  .min(6, "Code cannot be less than 6 digits")
  .max(6, "Exceeded characters for code")
  .required("6 digits code is required")
});