import React, { useEffect } from "react";
import Header from "../../components/Header";
import RolesTable from "../../tables/Roles";
import { Add } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { fetchRoles } from "../../redux/Roles/roleActions";
import { useRolesAndPrivilegePermissions } from "../../helpers/permissionsHelper/roles&privileges";

const Roles = () => {
  const dispatch = useAppDispatch();
  const { selectedAccount } = useAppSelector((state) => state.auth);
  const { rolesAndPrivilegePermissions } = useRolesAndPrivilegePermissions();

  useEffect(() => {
    if (selectedAccount?.type === "sub_account") {
      dispatch(fetchRoles({ id: selectedAccount?.id }));
    }
  }, [dispatch, selectedAccount]);

  return (
    <>
      <Header text={"Roles and Privileges"}>
        {rolesAndPrivilegePermissions.createRole && (
          <div className="flex justify-end">
            <Link
              to="/role/create"
              className="flex items-center hover:bg-[#24752B] cursor-pointer gap-1 bg-primary text-white rounded-lg text-sm font-medium leading-[20px] px-[20px] py-[12px]"
            >
              <p>Create New Role</p>
              <Add style={{ fontSize: "18px" }} />
            </Link>
          </div>
        )}
      </Header>
      {/* tables */}
      <RolesTable />
    </>
  );
};

export default Roles;
