import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseApiUrl } from "../config";
import axios from "axios";
import toast from "react-hot-toast";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

type rolesValues = {
  name: string;
  type: string;
  account_id: number;
  detail: string;
  privileges: string[];
};

type updateRolesValues = {
  account_id: number;
  role_id: number;
  privileges: string[];
};

export const fetchPrivileges = createAsyncThunk(
  "fetchPrivileges",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${baseApiUrl}/privileges`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": `${getToken()}`,
        },
      });
      if (res.status === 200 || res.status === 201) {
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Oops, an error occured fetching all privileges!");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Oops, an error occured fetching all privileges!");
        return rejectWithValue(error);
      }
    }
  }
);

type fetchRolesType = {
  id: number;
};

export const fetchRoles = createAsyncThunk(
  "fetchRoles",
  async ({ id }: fetchRolesType, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${baseApiUrl}/roles?account_id=${id}`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": `${getToken()}`,
        },
      });
      if (res.status === 200 || res.status === 201) {
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Oops, an error occured fetching all roles!");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Oops, an error occured fetching all roles!");
        return rejectWithValue(error);
      }
    }
  }
);

export const createRoles = createAsyncThunk(
  "createRoles",
  async (values: rolesValues, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(`${baseApiUrl}/roles`, values, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": `${getToken()}`,
        },
      });
      if (res.status === 200 || res.status === 201) {
        toast.success("Role created successfully!");
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Oops, an error occured while creating a role!");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Oops, an error occured while creating a role!");
        return rejectWithValue(error);
      }
    }
  }
);
export const updateRoles = createAsyncThunk(
  "updateRoles",
  async (values: updateRolesValues, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${baseApiUrl}/roles/update-role-privileges`,
        values,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": `${getToken()}`,
          },
        }
      );
      if (res.status === 200 || res.status === 201) {
        toast.success("Role updated successfully!");
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Oops, an error occured while updating a role!");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Oops, an error occured while updating a role!");
        return rejectWithValue(error);
      }
    }
  }
);
