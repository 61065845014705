import { useAppSelector } from "../../../redux/store";
import { useEffect, useState } from "react";
import { categories } from "../../../types/PermissionsCategories/PermissionsCategories";

export const useAccountPermissions = () => {
  const { userPermissions } = useAppSelector((state) => state.auth);
  const [permissions, setPermissions] = useState<any>({});

  interface AccountPermissions {
    createAccount: boolean;
    downloadStatement: boolean;
    moveFunds: boolean;
    requestStatement: boolean;
    monthlyBudget: boolean;
    accountDetails: boolean;
    accountSummary: boolean;
    recentTransaction: boolean;
    // Add more overview permissions here if needed
  }

  useEffect(() => {
    const newPermissions: AccountPermissions = {
      createAccount: false,
      downloadStatement: false,
      moveFunds: false,
      requestStatement: false,
      monthlyBudget: false,
      accountDetails: false,
      accountSummary: false,
      recentTransaction: false,
      // Add more overview permissions here if needed
    };

    userPermissions.forEach((permission) => {
      if (permission.category === categories.ACCOUNT) {
        switch (permission.name) {
          case "Can Create Account":
            newPermissions.createAccount = true;
            break;
          case "Can Download Statement":
            newPermissions.downloadStatement = true;
            break;
          case "Can Move Funds":
            newPermissions.moveFunds = true;
            break;
          case "Can Request For Statement":
            newPermissions.requestStatement = true;
            break;
          case "Can Set Monthly Budget":
            newPermissions.monthlyBudget = true;
            break;

          case "Can View Account Summary":
            newPermissions.accountSummary = true;
            break;

          // Add cases for other overview permissions here
        }
      }
    });

    setPermissions(newPermissions);
  }, [userPermissions]);

  return {
    accountPermissions: permissions,
  };
};
