import React from 'react'
import c2c_logo from '../../assets/icons/c2c-logo.svg'

const StatementPage = () => {
    return (
        <>
            <div className='py-8 max-w-3xl mx-auto'>
                <div className='flex justify-between items-center'>
                    <div>
                        <img src={c2c_logo} alt="c2c logo" className='w-[150px]' />
                    </div>
                    <div>
                        <h6 className='text-[#344335] text-[15px] font-semibold'>Titanium Transaction Details</h6>
                    </div>
                    <div></div>
                </div>

                <div className='mt-6 flex justify-between items-center'>
                    <div>
                        <div className='flex gap-2 items-center'>
                            <p className='text-[#344335] font-semibold text-sm'>Name:</p>
                            <p className='text-[#344335] font-semibold text-sm'>Operations Account</p>
                        </div>
                        <div className='flex gap-2 items-center mt-2'>
                            <p className='text-[#344335] font-semibold text-sm'>Period:</p>
                            <p className='text-[#344335] font-semibold text-sm'>01/01/2024 - 15/02/2024</p>
                        </div>
                        <div className='flex gap-2 items-center mt-2'>
                            <p className='text-[#344335] font-semibold text-sm'>Request Date:</p>
                            <p className='text-[#344335] font-semibold text-sm'>15/02/2024</p>
                        </div>
                    </div>
                    <div>
                    <div className='flex gap-2 items-center'>
                            <p className='text-[#344335] font-semibold text-sm'>Account Number:</p>
                            <p className='text-[#344335] font-semibold text-sm'>0123456789</p>
                        </div>
                        <div className='flex gap-2 items-center mt-2'>
                            <p className='text-[#344335] font-semibold text-sm'>Total Credit:</p>
                            <p className='text-[#344335] font-semibold text-sm'>NGN500,000.98</p>
                        </div>
                        <div className='flex gap-2 items-center mt-2'>
                            <p className='text-[#344335] font-semibold text-sm'>Total Debit:</p>
                            <p className='text-[#344335] font-semibold text-sm'>NGN50,000.98</p>
                        </div>
                    </div>
                </div>

                <div className="mt-8">
                    <table className="w-full text-left">
                        <thead className="text-[#FFFFFF] text-sm bg-[#2B8C34]">
                        <tr>
                            <th scope="col" className="px-4 py-4 font-semibold ">
                            Trans Date
                            </th>
                            <th scope="col" className="px-4 py-4 font-semibold">
                            Trans Type 
                            </th>
                            <th scope="col" className="px-4 py-4 font-semibold">
                            Debit (NGN)
                            </th>
                            <th scope="col" className="px-4 py-4 font-semibold">
                            Credit (NGN)
                            </th>
                        
                            <th scope="col" className="px-4 py-4 font-semibold">
                            Balance (NGN)
                            </th>
                            <th scope="col" className="px-4 py-4 font-semibold">
                            Counter Party
                            </th>
                            <th scope="col" className="px-4 py-4 font-semibold">
                            Trans. ID
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr
                            className="bg-[#F7F7F7] text-sm cursor-pointer font-medium"
                            >
                                <th
                                scope="row"
                                className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                                >
                                15/02/2024
                                </th>
                                <td className="py-4 px-4 text-[#5C715E]">
                                Bank Transfer
                                </td>
                                <td className="py-4 px-4 font-medium text-[#B92020]">
                                -1500 
                                </td> 
                                <td className="py-4 px-4 text-[#5C715E]">
                                0.00
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                15,000.98
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                Jemima Osunde
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                #Abg67949
                                </td>
                            </tr>    
                            <tr
                            className="bg-[#fff] text-sm cursor-pointer font-medium"
                            >
                                <th
                                scope="row"
                                className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                                >
                                15/02/2024
                                </th>
                                <td className="py-4 px-4 text-[#5C715E]">
                                Bank Transfer
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                0.00 
                                </td> 
                                <td className="py-4 px-4 text-primary">
                                +15,000.98
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                15,000.98
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                Jemima Osunde
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                #Abg67949
                                </td>
                            </tr>  
                            <tr
                            className="bg-[#F7F7F7] text-sm cursor-pointer font-medium"
                            >
                                <th
                                scope="row"
                                className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                                >
                                15/02/2024
                                </th>
                                <td className="py-4 px-4 text-[#5C715E]">
                                Bank Transfer
                                </td>
                                <td className="py-4 px-4 text-[#B92020]">
                                -1500 
                                </td> 
                                <td className="py-4 px-4 text-[#5C715E]">
                                0.00
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                15,000.98
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                Jemima Osunde
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                #Abg67949
                                </td>
                            </tr>    
                            <tr
                            className="bg-[#fff] text-sm cursor-pointer font-medium"
                            >
                                <th
                                scope="row"
                                className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                                >
                                15/02/2024
                                </th>
                                <td className="py-4 px-4 text-[#5C715E]">
                                Bank Transfer
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                0.00 
                                </td> 
                                <td className="py-4 px-4 text-primary">
                                +15,000.98
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                15,000.98
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                Jemima Osunde
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                #Abg67949
                                </td>
                            </tr> 
                            <tr
                            className="bg-[#F7F7F7] text-sm cursor-pointer font-medium"
                            >
                                <th
                                scope="row"
                                className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                                >
                                15/02/2024
                                </th>
                                <td className="py-4 px-4 text-[#5C715E]">
                                Bank Transfer
                                </td>
                                <td className="py-4 px-4 text-[#B92020]">
                                -1500 
                                </td> 
                                <td className="py-4 px-4 text-[#5C715E]">
                                0.00
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                15,000.98
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                Jemima Osunde
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                #Abg67949
                                </td>
                            </tr>    
                            <tr
                            className="bg-[#fff] text-sm cursor-pointer font-medium"
                            >
                                <th
                                scope="row"
                                className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                                >
                                15/02/2024
                                </th>
                                <td className="py-4 px-4 text-[#5C715E]">
                                Bank Transfer
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                0.00 
                                </td> 
                                <td className="py-4 px-4 text-primary">
                                +15,000.98
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                15,000.98
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                Jemima Osunde
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                #Abg67949
                                </td>
                            </tr> 
                            <tr
                            className="bg-[#F7F7F7] text-sm cursor-pointer font-medium"
                            >
                                <th
                                scope="row"
                                className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                                >
                                15/02/2024
                                </th>
                                <td className="py-4 px-4 text-[#5C715E]">
                                Bank Transfer
                                </td>
                                <td className="py-4 px-4 text-[#B92020]">
                                -1500 
                                </td> 
                                <td className="py-4 px-4 text-[#5C715E]">
                                0.00
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                15,000.98
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                Jemima Osunde
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                #Abg67949
                                </td>
                            </tr>    
                            <tr
                            className="bg-[#fff] text-sm cursor-pointer font-medium"
                            >
                                <th
                                scope="row"
                                className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                                >
                                15/02/2024
                                </th>
                                <td className="py-4 px-4 text-[#5C715E]">
                                Bank Transfer
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                0.00 
                                </td> 
                                <td className="py-4 px-4 text-primary">
                                +15,000.98
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                15,000.98
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                Jemima Osunde
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                #Abg67949
                                </td>
                            </tr> 
                            <tr
                            className="bg-[#F7F7F7] text-sm cursor-pointer font-medium"
                            >
                                <th
                                scope="row"
                                className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                                >
                                15/02/2024
                                </th>
                                <td className="py-4 px-4 text-[#5C715E]">
                                Bank Transfer
                                </td>
                                <td className="py-4 px-4 text-[#B92020]">
                                -1500 
                                </td> 
                                <td className="py-4 px-4 text-[#5C715E]">
                                0.00
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                15,000.98
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                Jemima Osunde
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                #Abg67949
                                </td>
                            </tr>    
                            <tr
                            className="bg-[#fff] text-sm cursor-pointer font-medium"
                            >
                                <th
                                scope="row"
                                className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                                >
                                15/02/2024
                                </th>
                                <td className="py-4 px-4 text-[#5C715E]">
                                Bank Transfer
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                0.00 
                                </td> 
                                <td className="py-4 px-4 text-primary">
                                +15,000.98
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                15,000.98
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                Jemima Osunde
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                #Abg67949
                                </td>
                            </tr> 
                            
                         </tbody>
                    </table>
              </div>
                

            </div>
        </>
      );
}
 
export default StatementPage;