import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  createAccount,
  downloadStatement,
  fetchAccountById,
  fetchAccountTransactions,
  fetchAccounts,
  fetchMainAccount,
  moveFundsToAccount,
} from "./accountActions";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { AccountsType } from "../../types/Accounts/Accounts";
import { Transaction } from "../../types/Transactions/Transactions";


type mainAccountType ={
  total_balance: number,
  total_budget: number
}

type AccountState = {
  loading: boolean;
  loader: boolean;
  accounts: AccountsType[];
  accountInfo: AccountsType;
  acctTransactions: Transaction[];
  mainAccount: mainAccountType
};

const initialState: AccountState = {
  loading: false,
  loader: false,
  accounts: [],
  accountInfo: {
    id: 0,
    created: "",
    updated: "",
    account_name: "",
    account_number: "",
    account_id: "",
    account_type: "",
    account_balance: "",
    monthly_budget: "",
  },
  mainAccount: {
    total_balance: 0,
    total_budget: 0
  },
  acctTransactions: [],
};

// Configure Redux Persist
const accountPersistConfig = {
  key: "account",
  storage,
  blacklist: [],
  // Add any additional configuration options as needed
};

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    logOut: () => {
      localStorage.setItem("token", "");
      localStorage.clear();

      return initialState;
    },

    handleSaveAllAccounts: (state, payload) => {
      state.accounts = [payload?.payload, ...state.accounts];
    },
  },
  extraReducers: (builder) => {
    // get all accounts
    builder.addCase(fetchAccounts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchAccounts.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.accounts = action.payload.data;
      }
    );
    builder.addCase(fetchAccounts.rejected, (state) => {
      state.loading = false;
    });

    // fetch main account info
    builder.addCase(fetchMainAccount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchMainAccount.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.mainAccount = action.payload.data;
      }
    );
    builder.addCase(fetchMainAccount.rejected, (state) => {
      state.loading = false;
    });

    // fetch account transactions
    builder.addCase(fetchAccountTransactions.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(
      fetchAccountTransactions.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loader = false;
        state.acctTransactions = action.payload.data.data;
      }
    );
    builder.addCase(fetchAccountTransactions.rejected, (state) => {
      state.loader = false;
    });

    // get an account
    builder.addCase(fetchAccountById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchAccountById.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.accountInfo = action.payload.data;
      }
    );
    builder.addCase(fetchAccountById.rejected, (state) => {
      state.loading = false;
    });

    // create account
    builder.addCase(createAccount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createAccount.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createAccount.rejected, (state) => {
      state.loading = false;
    });
    // move funds from account to account
    builder.addCase(moveFundsToAccount.pending, (state) => {});
    builder.addCase(moveFundsToAccount.fulfilled, (state) => {});
    builder.addCase(moveFundsToAccount.rejected, (state) => {});
    // download statement of account
    builder.addCase(downloadStatement.pending, (state) => {});
    builder.addCase(downloadStatement.fulfilled, (state) => {});
    builder.addCase(downloadStatement.rejected, (state) => {});
  },
});

const accountReducer = persistReducer(
  accountPersistConfig,
  accountSlice.reducer
);
export const { logOut, handleSaveAllAccounts } = accountSlice.actions;

export default accountReducer;
