import React, { useEffect, useState } from "react";
import PaymentsTable from "../../tables/Payment";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../redux/store";
import { handleRecipientDetails } from "../../redux/Recipient/recipient";
import {usePaymentPermissions} from "../../helpers/permissionsHelper/paymentPermissions";

const PaymentPage = () => {
  
  const dispatch = useAppDispatch();
  const [typeID, setTypeID] = useState(1);
  const { paymentPermissions } = usePaymentPermissions();

  const paymentTypes = [
    {
      id: 1,
      title: "Single Payment",
    },
    // {
    //   id: 2,
    //   title: "Bulk Payment",
    // },
  ];

  const toggleType = (id: number) => {
    setTypeID(id);
  }
  

  useEffect(() => {
    dispatch(handleRecipientDetails({}));
  }, [dispatch]);
  return (
    <>
      <div className="bg-white px-4 py-5 rounded-[8px]  border border-[#ECEFEC]">
             <div className="flex justify-between items-center">
                <div>
                    <p className="text-primary text-lg font-semibold leading-6">Payment</p>
                </div>

                {paymentPermissions.initiatePayment && 
                  <div>
                    <Link
                      to="/payment/new"
                      className="w-full py-3 flex items-center justify-center px-5 bg-[#2B8C34] rounded-[8px] text-[#fff] text-sm font-medium  transition-all"
                    >
                      New Payment
                    </Link>
                </div> 
                }
            </div>

            <ul className="flex mt-3 gap-5 items-center">
            {paymentTypes.map((item) => (
              <li
                key={item.id}
                onClick={() => toggleType(item.id)}
                className={
                  typeID === item.id
                    ? "flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer relative"
                    : "flex gap-1 items-center pb-1 cursor-pointer relative"
                }
              >
                <p
                  className={
                    typeID === item.id
                      ? "text-primary text-sm font-semibold"
                      : "text-[#96A397] text-sm font-semibold"
                  }
                >
                  {item.title}
                </p>
              </li>
            ))}
          </ul>


      </div>

      {/* table */}
      <PaymentsTable paymentType={typeID} />
    </>
  );
};

export default PaymentPage;
