import React, { useState, useEffect } from "react";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import DashboardTable from "../../tables/Dashboard";
import more_icon from "../../assets/icons/more_vert.svg";
import LineCharts from "../../components/LineChart/LineChart";
import PendingApprovalTable from "../../tables/Dashboard/PendingApprovalTable";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  fetchBalanceAcrossAccounts,
  fetchGraphData,
  fetchLatestTransaction,
} from "../../redux/Dashboard/dashboardActions";

const Dashboard = () => {
  const [show] = useState(false);
  const dispatch = useAppDispatch();
  const { latestTransaction, graph, total_balance } = useAppSelector(
    (state) => state.dashboard
  );
  const { selectedAccount } = useAppSelector((state) => state.auth);

  const formatNumber = (numberToFormat: number) => {
    let res = numberToFormat?.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  useEffect(() => {
    dispatch(fetchLatestTransaction());
    dispatch(fetchBalanceAcrossAccounts());
    dispatch(fetchGraphData({ id: "" }));
  }, [dispatch, selectedAccount?.account_id]);

  return (
    <>
      <div className="bg-white border border-[#ECEFEC] rounded-[8px] px-4 py-4 flex justify-between items-center">
        <div>
          <p className="text-primary text-lg font-semibold leading-6">
            Dashboard
          </p>
        </div>
        <div className="flex"></div>
      </div>
      <div className="mt-5 p-4 bg-white rounded-[8px] min-h-[300px]">
        <div className="mt-3 flex flex-col justify-center gap-1 items-center mb-[34.5px] ">
          <p className="text-xl font-semibold text-[#344335]">Total Balance</p>
          <p className="text-2xl font-semibold text-[#2B8C34]">
            ₦{total_balance ? formatNumber(total_balance) : 0.0}{" "}
          </p>
          {latestTransaction?.amount !== null && (
            <div className="flex items-center gap-1">
              {latestTransaction?.transaction_type === "credit" ? (
                <ArrowDropUp
                  className="cursor-pointer"
                  style={{ color: "#2B8C34", fontSize: "20px" }}
                />
              ) : (
                <ArrowDropDown
                  className="cursor-pointer"
                  style={{ color: "#b92043", fontSize: "20px" }}
                />
              )}
              <p className="text-base font-semibold leading-[24px] text-[#344335]">
                ₦
                {latestTransaction
                  ? formatNumber(latestTransaction?.amount)
                  : 0.0}
                ,{" "}
                {latestTransaction
                  ? moment(latestTransaction?.created_at).calendar()
                  : moment().format("MMMM Do")}
              </p>
            </div>
          )}
        </div>
        <LineCharts data={graph} height={200} />
      </div>

      {show && (
        <div className="mt-5 grid grid-cols-2 gap-4">
          <PendingApprovalTable />

          <div className="bg-white rounded-[8px] h-fit">
            <div className="p-4">
              <p className="text-primary font-semibold text-base leading-5">
                Recipients
              </p>
              <p className="text-[#7C8D7E] text-sm font-medium mt-1">
                This shows a list of top 5 recipients
              </p>
            </div>

            {/* tables */}
            <div className="overflow-x-auto relative">
              <table className="w-full text-left">
                <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                  <tr>
                    <th scope="col" className="px-4 py-4 font-semibold ">
                      Account Name
                    </th>
                    <th scope="col" className="px-4 py-4 font-semibold">
                      Bank
                    </th>
                    <th scope="col" className="px-4 py-4 font-semibold">
                      Account Number
                    </th>
                    <th scope="col" className="px-4 py-4 font-semibold">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]">
                    <th
                      scope="row"
                      className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                    >
                      Jemima Osunde
                    </th>
                    <td className="py-4 px-4 text-[#5C715E]">FCMB</td>
                    <td className="py-4 px-4 text-[#5C715E]">0123456789</td>

                    <td className="py-4 px-4 text-[#5C715E]">
                      <div>
                        <img src={more_icon} alt="more icon" />
                      </div>
                    </td>
                  </tr>
                  <tr className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]">
                    <th
                      scope="row"
                      className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                    >
                      Jemima Osunde
                    </th>
                    <td className="py-4 px-4 text-[#5C715E]">First Bank</td>
                    <td className="py-4 px-4 text-[#5C715E]">0123456789</td>

                    <td className="py-4 px-4 text-[#5C715E]">
                      <div>
                        <img src={more_icon} alt="more icon" />
                      </div>
                    </td>
                  </tr>
                  <tr className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]">
                    <th
                      scope="row"
                      className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                    >
                      Jemima Osunde
                    </th>
                    <td className="py-4 px-4 text-[#5C715E]">Access Bank</td>
                    <td className="py-4 px-4 text-[#5C715E]">0123456789</td>

                    <td className="py-4 px-4 text-[#5C715E]">
                      <div>
                        <img src={more_icon} alt="more icon" />
                      </div>
                    </td>
                  </tr>
                  <tr className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]">
                    <th
                      scope="row"
                      className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                    >
                      Jemima Osunde
                    </th>
                    <td className="py-4 px-4 text-[#5C715E]">Access Bank</td>
                    <td className="py-4 px-4 text-[#5C715E]">0123456789</td>

                    <td className="py-4 px-4 text-[#5C715E]">
                      <div>
                        <img src={more_icon} alt="more icon" />
                      </div>
                    </td>
                  </tr>
                  <tr className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]">
                    <th
                      scope="row"
                      className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                    >
                      Jemima Osunde
                    </th>
                    <td className="py-4 px-4 text-[#5C715E]">Access Bank</td>
                    <td className="py-4 px-4 text-[#5C715E]">0123456789</td>

                    <td className="py-4 px-4 text-[#5C715E]">
                      <div>
                        <img src={more_icon} alt="more icon" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      <DashboardTable />
    </>
  );
};

export default Dashboard;
