import React, { useEffect, useState, useRef } from "react";
import RecipientsTable from "../../tables/Recipients";
import ModalComponent from "../../components/Modals/modal";
import { ExpandLess, ExpandMore, Add } from "@mui/icons-material";
import toast from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  createRecipient,
  createRecipientTag,
  getAllBanks,
  getAllTags,
  verifyRecipientAccount,
} from "../../redux/Recipient/recipientActions";
import SearchComponent from "../../components/Search";
import { Formik, Form, FormikHelpers } from "formik";
import { createTagValidator } from "../../validationSchema/validator";
import warning from "../../assets/icons/warning.svg";
import { useRecipientPermissions } from "../../helpers/permissionsHelper/recipientPermissions/index";

const Recipient = () => {
  const dispatch = useAppDispatch();
  const { recipientPermissions } = useRecipientPermissions();
  const refreshRecipientRef = useRef<RecipientsTableMethods | null>(null);
  const { tags, banks } = useAppSelector((state) => state.recipient);
  const [banksState, setBanksState] = useState(banks);
  const [recipientAccountNameLoading, setRecipientAccountNameLoading] =
    useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isCreateTag, setIsCreateTag] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isToggleBank, setIsToggleBank] = useState(false);
  // const [isCreateRecipient, setIsCreateRecipient] = useState(false);
  const [selectedBank, setSelectedBank] = useState<{
    name: string;
    id: string;
    bankCode: number | null;
  }>({
    name: "",
    id: "",
    bankCode: null,
  });

  const [isToggleTag, setIsToggleTag] = useState(false);

  interface RecipientsTableMethods {
    refreshRecipient: () => void;
  }

  const [selectedTags, setSelectedTags] = useState<{
    tag: string;
    id: number;
  }>({
    tag: "",
    id: 0,
  });

  const showModal = () => {
    setIsShow(!isShow);
  };

  const showCreateTagModal = () => {
    setIsCreateTag(!isCreateTag);
  };

  const handleCreateTagModal = () => {
    setIsShow(false);
    setTimeout(() => {
      setIsCreateTag(true);
    }, 800);
  };

  interface Values {
    name: string;
    description: string;
  }

  const handleSelectedBank = (name: string, id: string, bankId: number) => {
    setSelectedBank({
      name: name,
      id: id,
      bankCode: bankId,
    });
    setIsToggleBank(false);
    setSearchValue("");
  };

  const handleSelectedTag = (name: string, id: number) => {
    setSelectedTags({
      tag: name,
      id: id,
    });
    setIsToggleTag(false);
  };

  const hanldeRefreshRecipients = () => {
    refreshRecipientRef?.current?.refreshRecipient();
  };

  const handleCreateRecipient = () => {
    // setIsCreateRecipient(false);
    setLoading(true);

    const values = {
      recipient_name: accountName,
      account_number: accountNumber,
      bank_code: selectedBank?.id,
      tag_id: Number(selectedTags?.id),
      bank_id: selectedBank?.bankCode,
    };

    dispatch(createRecipient(values)).then((res) => {
      if (res?.type === "createRecipient/fulfilled") {
        setLoading(false);

        toast.success("New recipient successfully created!", {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
          },
        });

        setIsShow(false);
        setSelectedBank({
          name: "",
          id: "",
          bankCode: null,
        });
        setSelectedTags({
          tag: "",
          id: 0,
        });
        setAccountName("");
        setAccountNumber("");
        hanldeRefreshRecipients();
      } else if (res?.type === "createRecipient/rejected") {
        toast.error("Some error occurred while creating this recipient");
        setLoading(false);
      }
    });
  };

  const handleVerifyAccountNumber = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setAccountNumber(event.target.value);

    if (value?.length < 10 || value?.length > 10) {
      setAccountName("");
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    setSearchValue(value);
    if (value === "") {
      setBanksState(banks);
    } else {
      const filteredData = banks.filter((bank) =>
        bank?.name?.toLowerCase().includes(value?.toLowerCase())
      );
      setBanksState(filteredData);
    }
  };

  const handleCreateRecipientTag = (values: Values) => {
    setLoader(true);
    const resp = {
      tag: values.name,
      description: values.description,
    };
    dispatch(createRecipientTag(resp)).then((res) => {
      if (res?.type === "createRecipientTag/fulfilled") {
        setLoader(false);
        setIsCreateTag(false);
        setTimeout(() => {
          setIsShow(false);
        }, 800);
        toast.success("Tag successfully created!", {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
          },
        });
        dispatch(getAllTags());
      } else if (res?.type === "createRecipientTag/rejected") {
        setLoader(false);
      }
    });
  };

  useEffect(() => {
    dispatch(getAllTags());
    dispatch(getAllBanks());
  }, [dispatch]);

  useEffect(() => {
    setBanksState(banks);
    const values = {
      account_number: accountNumber,
      bank_code: selectedBank?.id,
    };
    if (accountNumber?.length === 10 && selectedBank?.name !== "") {
      setRecipientAccountNameLoading(true);
      dispatch(verifyRecipientAccount(values)).then((res) => {
        if (res?.type === "verifyRecipientAccount/fulfilled") {
          setAccountName(res?.payload?.data?.account_name);
          setRecipientAccountNameLoading(false);
        } else if (res?.type === "verifyRecipientAccount/rejected") {
          setRecipientAccountNameLoading(false);
        }
      });
    }
  }, [accountNumber, selectedBank, banks, dispatch]);

  return (
    <>
      {/* create recipient modal */}
      <ModalComponent
        title="Create Recipient"
        subtitle=""
        isOpen={isShow}
        onClose={showModal}
      >
        {/* ACCOUNT NUMBER */}
        <div className="mt-5">
          <label
            htmlFor="accountNumber"
            className="block mb-2 text-[#344335] text-sm font-normal"
          >
            Recipient Account Number
          </label>
          <input
            onChange={(e) => handleVerifyAccountNumber(e)}
            type="number"
            name="accountNumber"
            value={accountNumber}
            placeholder="Enter an account number"
            className="appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
          />
          {recipientAccountNameLoading ? (
            <div className="ml-1">
              {" "}
              <ThreeDots
                visible={true}
                height="25"
                width="25"
                color="#2B8C34"
                radius="9"
              />
            </div>
          ) : (
            <p className="text-sm font-medium leading-4 text-[#5C715E] mt-1 capitalize">
              {accountName?.toLowerCase()}
            </p>
          )}
        </div>

        {/* BANK */}
        <div className="mt-4">
          <label
            htmlFor="bank"
            className="block mb-2 text-[#344335] text-sm font-normal"
          >
            Bank
          </label>
          <div className="relative">
            <div
              onClick={() => setIsToggleBank(!isToggleBank)}
              className={`flex cursor-pointer justify-between items-center w-full rounded-lg border border-[#96A397] bg-white py-3 px-4 text-[#96A397] text-sm`}
              style={
                isToggleBank ? { boxShadow: "0px 0px 0px 2px #D5E8D6" } : {}
              }
            >
              <p
                className={`${
                  selectedBank?.name ? "text-[#344335]" : "text-[#96A397]"
                }`}
              >
                {selectedBank?.name || "Select a bank"}
              </p>
              {isToggleBank ? (
                <ExpandLess style={{ fontSize: "20px", color: "#344335" }} />
              ) : (
                <ExpandMore style={{ fontSize: "20px", color: "#344335" }} />
              )}
            </div>

            {/*  SELECT OPTIONS */}
            {isToggleBank && (
              <div
                className=" rounded-[18px] py-2 bg-white w-full mt-2 px-2 max-h-[300px] overflow-scroll "
                style={{
                  boxShadow:
                    "0px 16px 32px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.25)",
                }}
              >
                <div className="w-full mb-2">
                  <SearchComponent
                    placeholder="Search"
                    searchValue={searchValue}
                    handleChange={handleChange}
                  />
                </div>

                {banksState?.map((bank, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() =>
                        handleSelectedBank(bank?.name, bank?.code, bank?.id)
                      }
                      className={`px-4 py-3  text-sm font-normal cursor-pointer hover:bg-[#EDF7EE] hover:rounded-[8px] mb-1 ${
                        selectedBank?.name === bank?.name
                          ? "bg-[#EDF7EE] border-[1px] border-[#2B8C34] rounded-[8px] text-[#24752B]"
                          : "text-[#344335] bg-white"
                      }`}
                    >
                      <p className=" leading-5 text-sm font-medium">
                        {bank?.name}
                      </p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        {/* TAG */}
        <div className="mt-4">
          <label
            htmlFor="bank"
            className="block mb-2 text-[#344335] text-sm font-normal"
          >
            Tag
          </label>
          <div className="relative">
            <div
              className={`flex cursor-pointer  items-center w-full rounded-lg border border-[#96A397] bg-white py-3 px-4 text-[#96A397] text-sm`}
              style={
                isToggleTag ? { boxShadow: "0px 0px 0px 2px #D5E8D6" } : {}
              }
              onClick={() => setIsToggleTag(!isToggleTag)}
            >
              <div className="flex flex-wrap  gap-2 items-center w-[95%]">
                {selectedTags?.tag ? (
                  <>
                    <div className="rounded-[4px] capitalize text-sm bg-[#DBFFDF] px-1 py-[2px] text-[#2B8C34] flex items-center gap-2">
                      <p> {selectedTags?.tag}</p>
                    </div>
                  </>
                ) : (
                  <p> Select a tag</p>
                )}
              </div>

              <div className=" w-[5%]">
                {isToggleTag ? (
                  <ExpandLess style={{ fontSize: "20px", color: "#344335" }} />
                ) : (
                  <ExpandMore style={{ fontSize: "20px", color: "#344335" }} />
                )}
              </div>
            </div>

            {/*  SELECT OPTIONS */}
            {isToggleTag && (
              <div
                className="max-h-[300px] py-2 overflow-scroll rounded-[18px] bg-white w-full mt-2 px-2"
                style={{
                  boxShadow:
                    "0px 16px 32px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.25)",
                }}
              >
                {tags?.map((tag, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => handleSelectedTag(tag?.tag, tag?.id)}
                      className={`px-4 py-3  text-sm font-normal cursor-pointer hover:bg-[#EDF7EE] hover:rounded-[8px] mb-1 ${
                        selectedTags?.tag === tag?.tag
                          ? "bg-[#EDF7EE] border-[1px] border-[#2B8C34] rounded-[8px] text-[#24752B]"
                          : "text-[#344335] bg-white"
                      }`}
                    >
                      <p className="capitalize leading-5 text-sm font-medium">
                        {tag?.tag}
                      </p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="mt-2">
            {recipientPermissions?.createTag && (
              <button
                onClick={() => handleCreateTagModal()}
                className="w-full flex items-center gap-1 bg-transparent text-[#2B8C34] text-sm font-medium  transition-all"
              >
                <Add style={{ fontSize: "18px", color: "#2B8C34" }} />
                Create new tag
              </button>
            )}
          </div>
        </div>

        <div className="mt-5">
          {loading ? (
            <div
              className="bg-[#ABB6AC] w-full flex justify-center items-center
              rounded-lg px-6 py-3 text-white text-sm font-medium transition-all"
            >
              <ThreeDots
                visible={true}
                height="25"
                width="25"
                color="#FFF"
                radius="9"
              />
            </div>
          ) : (
            <button
              disabled={
                accountName !== "" ||
                selectedTags?.tag !== ""
                  ? false
                  : true
              }
              onClick={handleCreateRecipient}
              className="bg-primary 
                disabled:bg-[#ABB6AC] w-full
              rounded-lg px-6 py-4 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
            >
              Create Recipient
            </button>
          )}
        </div>
      </ModalComponent>

      {/* Create Tag Modal */}
      <ModalComponent
        title="Create New Tag"
        subtitle=""
        isOpen={isCreateTag}
        onClose={showCreateTagModal}
      >
        <Formik
          initialValues={{
            name: "",
            description: "",
          }}
          validationSchema={createTagValidator}
          onSubmit={(
            values: Values,
            { setSubmitting }: FormikHelpers<Values>
          ) => {
            handleCreateRecipientTag(values);
          }}
        >
          {({
            handleChange,
            isSubmitting,
            handleSubmit,
            handleBlur,
            values,
            touched,
            errors,
          }) => (
            <Form className="mt-8" onSubmit={handleSubmit}>
              {/* New tag */}
              <div className="mt-5">
                <label
                  htmlFor="name"
                  className="block mb-2 text-[#344335] text-sm font-normal"
                >
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  placeholder="Enter a new tag"
                  className={
                    touched.name && errors.name
                      ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                      : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                  }
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {touched.name && errors.name ? (
                  <div className="flex">
                    <img src={warning} className="" alt="warning" />
                    <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                      {touched.name && errors.name}
                    </small>
                  </div>
                ) : null}
              </div>

              <div className="mt-5">
                <label
                  htmlFor="narration"
                  className="block mb-2 text-[#344335] text-sm font-normal"
                >
                  Description
                </label>
                <textarea
                  name="description"
                  rows={4}
                  placeholder="Enter a description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    touched.description && errors.description
                      ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4 resize-none"
                      : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4 resize-none"
                  }
                />
                {touched.description && errors.description ? (
                  <div className="flex">
                    <img src={warning} className="" alt="warning" />
                    <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                      {touched.description && errors.description}
                    </small>
                  </div>
                ) : null}
              </div>

              <div className="mt-5 flex gap-2 justify-end">
                <button className="rounded-lg px-6 py-3 text-[#5C715E] text-sm font-medium transition-all">
                  Back
                </button>

                <button
                  disabled={loader}
                  className="bg-primary 
                disabled:bg-[#ABB6AC]
              rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
                >
                  Save
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalComponent>

      {/* CREATE RECIPIENT MODAL */}
      {/* <ModalComponent
        title="Create Recipient"
        subtitle=""
        isOpen={isCreateRecipient}
        onClose={() => setIsCreateRecipient(false)}
      >
        <div>
          <p className="mt-[10px] text-sm font-mediumn leading-5 text-[#5C715E] mb-8">
            Are you sure you want to create a new recipient?
          </p>

          <div className="flex justify-end">
            <div className="flex gap-[40px] items-center cursor-pointer">
              <p
                className=" text-sm font-mediumn leading-5 text-[#5C715E]"
                onClick={() => setIsCreateRecipient(false)}
              >
                Cancel
              </p>
              <button
                onClick={handleCreateRecipient}
                className=" outline-none text-white rounded-lg bg-[#2B8C34] text-sm font-mediumn leading-5 px-[20px] py-[10px]"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </ModalComponent> */}

      <div className="bg-white border border-[#ECEFEC] rounded-[8px] px-4 py-4 flex justify-between items-center">
        <div>
          <p className="text-primary text-lg font-semibold leading-6">
            Recipient
          </p>
        </div>
        <div>
          {recipientPermissions.createRecipient && (
            <button
              onClick={() => showModal()}
              className="w-full py-3 flex items-center justify-center px-5 bg-[#EDF7EE] rounded-[8px] text-[#2B8C34] text-sm font-medium  transition-all"
            >
              Create new recipient
            </button>
          )}
        </div>
      </div>
      {/* tables */}
      <RecipientsTable ref={refreshRecipientRef} />
    </>
  );
};

export default Recipient;
