import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import TeammateTable from "../../tables/Teammate";
import { Add, ExpandLess, ExpandMore } from "@mui/icons-material";
import { Formik, Form, FormikHelpers } from "formik";
import ModalComponent from "../../components/Modals/modal";
import warning from "../../assets/icons/warning.svg";
import { inviteTeammatesValidator } from "../../validationSchema/validator";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { fetchRoles } from "../../redux/Roles/roleActions";
import { inviteTeammate } from "../../redux/Teammates/teammateActions";
import { Link } from "react-router-dom";
import { useTeammatesPermissions } from "../../helpers/permissionsHelper/teammatesPermissions";

const Teammates = () => {
  interface Values {
    email: string;
  }
  const [isCreateSub, setIsCreateSub] = useState(false);
  const { teammatesPermissions } = useTeammatesPermissions();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { selectedAccount } = useAppSelector((state) => state.auth);
  const { roles } = useAppSelector((state) => state.role);

  const showCreateSubModal = () => {
    setIsCreateSub(!isCreateSub);
  };

  const [isToggleTag, setIsToggleTag] = useState(false);
  const [selectedRole, setSelectedRole] = useState<{
    name: string;
    id: number;
  }>({
    name: "",
    id: 0,
  });

  const handleSelectedRole = (name: string, id: number) => {
    setSelectedRole({
      name: name,
      id: id,
    });
    setIsToggleTag(false);
  };

  const handleSubmit = (values: Values) => {
    let res = {
      ...values,
      role_id: selectedRole.id,
    };
    setLoading(true);
    dispatch(
      inviteTeammate({
        email: res.email,
        role: res.role_id,
        account_id: selectedAccount?.id,
      })
    ).then((res) => {
      if (res?.type === "inviteTeammate/fulfilled") {
        setLoading(false);
        setIsCreateSub(false);
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (selectedAccount?.type === "sub_account") {
      dispatch(fetchRoles({ id: selectedAccount?.id }));
    }
  }, [dispatch, selectedAccount]);

  return (
    <>
      <Header text={"Teammates"}>
        <div className="flex items-center gap-4">
          <Link
            to="/teammates/invites"
            className="outline-none border-none bg-[#EDF7EE] text-[#2B8C34] text-sm font-medium leading-[20px] px-[24px] py-[12px] rounded-lg"
          >
            View Invites
          </Link>
          {teammatesPermissions.inviteTeammates && (
            <div
              onClick={() => setIsCreateSub(true)}
              className="flex items-center cursor-pointer gap-1 bg-primary text-white rounded-lg text-sm font-medium leading-[20px] px-[20px] py-[12px]"
            >
              <Add style={{ fontSize: "18px" }} />
              <p>Invite Teammate</p>
            </div>
          )}
        </div>
      </Header>

      {/* tables */}
      <TeammateTable />

      {/* Invite teammate Modal */}
      <ModalComponent
        title="Invite Teammates"
        subtitle=""
        isOpen={isCreateSub}
        onClose={showCreateSubModal}
      >
        {/* form */}
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={inviteTeammatesValidator}
          onSubmit={(
            values: Values,
            { setSubmitting }: FormikHelpers<Values>
          ) => {
            handleSubmit(values);
          }}
        >
          {({
            handleChange,
            isSubmitting,
            handleSubmit,
            handleBlur,
            values,
            touched,
            errors,
            isValid,
            dirty,
          }) => (
            <Form className="mt-4" onSubmit={handleSubmit}>
              <div className="mt-5">
                <label
                  htmlFor="email"
                  className="block mb-2 text-[#344335] text-sm font-normal"
                >
                  Email Address
                </label>
                <input
                  type="text"
                  name="email"
                  placeholder="jemimaosunde123@crop2cash.com.ng"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    touched.email && errors.email
                      ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                      : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                  }
                />
                {touched.email && errors.email ? (
                  <div className="flex">
                    <img src={warning} className="" alt="warning" />
                    <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                      {touched.email && errors.email}
                    </small>
                  </div>
                ) : null}
              </div>

              {/* User Access */}
              <div className="mt-4">
                <label
                  htmlFor="user"
                  className="block mb-2 text-[#344335] text-sm font-normal"
                >
                  Role
                </label>
                <div className="relative">
                  <div
                    className={`flex cursor-pointer  items-center w-full rounded-lg border border-[#96A397] bg-white py-3 px-4 text-[#96A397] text-sm`}
                    style={
                      isToggleTag
                        ? { boxShadow: "0px 0px 0px 2px #D5E8D6" }
                        : {}
                    }
                    onClick={() => setIsToggleTag(!isToggleTag)}
                  >
                    <div className="flex flex-wrap  gap-2 items-center w-[95%]">
                      {selectedRole?.name !== "" ? (
                        <>
                          <div className="rounded-[4px] text-sm bg-[#DBFFDF] px-1 py-[2px] text-[#2B8C34] flex items-center gap-2">
                            <p> {selectedRole?.name}</p>
                          </div>
                        </>
                      ) : (
                        "Select a role"
                      )}
                    </div>

                    <div className=" w-[5%]">
                      {isToggleTag ? (
                        <ExpandLess
                          style={{ fontSize: "20px", color: "#344335" }}
                        />
                      ) : (
                        <ExpandMore
                          style={{ fontSize: "20px", color: "#344335" }}
                        />
                      )}
                    </div>
                  </div>

                  {/*  SELECT OPTIONS */}
                  {isToggleTag && (
                    <div
                      className="max-h-[300px] py-2 overflow-scroll rounded-[18px] bg-white w-full mt-2 px-2"
                      style={{
                        boxShadow:
                          "0px 16px 32px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      {roles?.map((tag, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() =>
                              handleSelectedRole(tag?.name, tag?.id)
                            }
                            className={`px-4 py-3  flex gap-2  items-center text-sm font-normal cursor-pointer hover:bg-[#EDF7EE] hover:rounded-[8px] 
                            ${index < roles.length - 1 ? "mb-1" : ""}
                            ${
                              selectedRole.name === tag?.name
                                ? "bg-[#EDF7EE] border-[1px] border-[#2B8C34] rounded-[8px] text-[#24752B]"
                                : "text-[#344335] bg-white"
                            }`}
                          >
                            <p className="leading-5 text-sm font-medium">
                              {tag?.name}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-5">
                <button
                  type="submit"
                  disabled={
                    !(dirty && isValid) || loading || selectedRole.name === ""
                  }
                  className="bg-primary 
                      disabled:bg-[#ABB6AC] w-full
                    rounded-lg px-6 py-4 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
                >
                  Invite Teammate
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalComponent>
    </>
  );
};

export default Teammates;
