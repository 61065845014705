import { useAppSelector } from "../../../redux/store";
import { useEffect, useState } from "react";
import { categories } from "../../../types/PermissionsCategories/PermissionsCategories";

export const useProfilePermissions = () => {
  const { userPermissions } = useAppSelector((state) => state.auth);
  const [permissions, setPermissions] = useState<any>({});

  interface ProfilePermissions {
    changePassword: boolean;
    viewProfile: boolean;
    editProfile: boolean;
    // Add more profile permissions here if needed
  }

  useEffect(() => {
    const profilePermissions: ProfilePermissions = {
      changePassword: false,
      viewProfile: false,
      editProfile: false
      // Initialize other profile permissions here
    };

    userPermissions.forEach((permission) => {
      if (permission.category === categories.PROFILE) {
        switch (permission.name) {
          case "Can Change Password":
            profilePermissions.changePassword = true;
            break;
          case "Can View Profile":
            profilePermissions.viewProfile = true;
            break;
          case "Can Edit Profile":
            profilePermissions.editProfile = true;
            break;
          default: 
            break;
        }
      }
    });

    setPermissions(profilePermissions);
  }, [userPermissions]);

  return {
    profilePermissions: permissions,
  };
};

  