import React,{useEffect, useState} from 'react'
import { FilterList, PersonOutline, KeyboardArrowRight, ListAlt, CalendarToday } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { fetchAuditLog, filterAuditLog } from '../../redux/Audit/auditActions';
import moment from 'moment'
import { auditLogsType } from '../../redux/Audit/audit';
import { SimCardAlertOutlined } from "@mui/icons-material";
import PaginationComponent from '../../components/Pagination/Pagination';
import { fetchTeammates } from '../../redux/Teammates/teammateActions';
import cancel_icon from '../../assets/icons/cancel.svg'

const AuditLogTable = () => {

    const dispatch = useAppDispatch();
    const { auditlogs, loading, total } = useAppSelector((state) => state.audit)
    const { selectedAccount } = useAppSelector((state) => state.auth)
    const [currentPage, setCurrentPage] = useState(1);
    const { teammates } = useAppSelector((state) => state.teammate);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const [selectedAudit, setSelectedAudit] = useState<auditLogsType>({} as auditLogsType)

    const handleSelectAudit = (val: auditLogsType) =>{
        setSelectedAudit(val)
    }
    const onPageChange = (page: number) => {
        setCurrentPage(page);
    };

    const [isUsername, setIsUsername] = useState(false)
    const [isActivity, setIsActivity] = useState(false)
    const [isDate, setIsDate] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    
    type FilterType = {
        name: string;
    }
    const [selectedFilters, setSelectedFilters] = useState<FilterType[]>([])

    const activities = [
        {
            id: "1",
            tag: 'created-a-sub-account',
            name: 'Created a Sub-account'
        },
        {
            id: "2",
            tag: 'invited-a-teammate',
            name: 'Invited a Teammate'
        },
        {
            id: "3",
            tag: 'cancelled-invite',
            name: 'Cancelled Invite'
        },
        {
            id: "4",
            tag: 'added-a-recipient',
            name: 'Added a Recipient'
        },
        {
            id: "5",
            tag: 'approved-a-payment',
            name: 'Approved a Payment'
        },
        {
            id: "6",
            tag: 'rejected-a-payment',
            name: 'Rejected a Payment'
        },
        {
            id: "7",
            tag: 'moved-money-between-sub-account',
            name: 'Moved Money Between Sub-account'
        },
        {
            id: "8",
            tag: 'disabled-a-teammate',
            name: 'Disabled a Teammate'
        },
        {
            id: "9",
            tag: 'requested-for-statement-of-account',
            name: 'Requested for Statement of Account'
        },
        {
            id: "10",
            tag: 'changed-a-teammate-role',
            name: 'Changed a Teammate Role'
        },
        {
            id: "11",
            tag: 'updated-a-teammate-threshold',
            name: 'Updated a Teammate Threshold'
        }
    ]

    const removeFilter = (val:string) =>{
        setSelectedFilters(selectedFilters.filter((item)=> item.name !== val))
        if(val === "Username"){
            setUserSelected("")
        }
        else if(val === "Activity"){
            setActivitySelected("")
        }
        else{
            setStartDate("")
            setEndDate("")
        }
    }

    const toggleFilter = () =>{
        setIsOpen(!isOpen)
        setIsUsername(false)
        setIsActivity(false)
        setIsDate(false)
    }

    const handleFilterTabsToggle = (val:string) =>{
        if(val === "username"){
            setIsUsername(!isUsername)
            setIsActivity(false)
            setIsDate(false)

           
        }
        else if(val === "activity"){
            setIsUsername(false)
            setIsActivity(!isActivity)
            setIsDate(false)

           
        }
        else if(val === "date"){
            setIsUsername(false)
            setIsActivity(false)
            setIsDate(!isDate)
            
        }
    }   

    const [userSelected, setUserSelected] = useState("")
    const [activitySelected, setActivitySelected] = useState("")

    const handleTeammateClick = (id: string) => {
        // i want to remove and set the userselected id when clicked
        if(userSelected ===  id){
            setUserSelected("")
            setSelectedFilters(selectedFilters.filter((item)=> item.name !== "Username"))
        }
        else{
            setUserSelected(id)
            if(!selectedFilters.some(item => item.name === "Username")){
                setSelectedFilters([...selectedFilters, {name: "Username"}])
            }
        }
        
    }

    const handleActivityClick = (tag: string) => {
        // i want to remove and set the activityselected id when clicked
        if(activitySelected ===  tag){
            setActivitySelected("")
            setSelectedFilters(selectedFilters.filter((item)=> item.name !== "Activity"))
        }
        else{
            setActivitySelected(tag)
            if(!selectedFilters.some(item => item.name === "Activity")){
                setSelectedFilters([...selectedFilters, {name: "Activity"}])
            }
        }
    }

    const handleFromDateClick = () =>{
        if(!selectedFilters.some(item => item.name === "Date")){
            setSelectedFilters([...selectedFilters, {name: "Date"}])
        }
        if(endDate === ""){
            setEndDate(moment().format("YYYY-MM-DD"))
        }
       
    }


    const handleEndDateClick = () =>{
        if(!selectedFilters.some(item => item.name === "Date")){
            setSelectedFilters([...selectedFilters, {name: "Date"}])
        }
        if(startDate === ""){
         setStartDate(moment().format("YYYY-MM-DD"))
        }
    }

    // a useeffect to dispatch fetchauditlog when the filters are selected
    useEffect(()=>{    
        dispatch(filterAuditLog(
                {account_id: selectedAccount?.account_id,
                 page: 1,
                 user_id: userSelected,
                 activity: activitySelected,
                 start_date: startDate,
                 end_date: endDate
                })
        )
     setSelectedAudit({} as auditLogsType)
        
    },[userSelected,activitySelected, dispatch, selectedAccount, startDate, endDate])


    useEffect(()=>{
        if (selectedAccount?.type === "sub_account") {
            dispatch(fetchAuditLog(
                {account_id: selectedAccount?.account_id,
                 page: 1
                })
            )
          setSelectedAudit({} as auditLogsType)

          dispatch(
            fetchTeammates({
              account_id: selectedAccount?.id,
            })
          );
        }
    },[dispatch, selectedAccount])
    

    return ( 
        <>

        {/* Filter Layout */}
        <div className='mt-5 bg-white flex gap-4 rounded-[8px] py-4 px-4'>
              <div className='relative'>
                <div onClick={() => toggleFilter()} className="flex w-auto justify-center min-h-[52px] items-center gap-2 border border-primary rounded-[8px] py-3 px-6 text-primary text-sm font-medium leading-5 cursor-pointer">
                    <p className="">Filter</p>
                    <FilterList style={{ fontSize: "18px" }} />
                </div>
                
                {/* Filter Div */}
              {isOpen &&
                <ul className='bg-[#FFFFFF] rounded-[10px] shadow-dropdown min-w-[280px] py-2 px-2 absolute left-0 top-16 z-[1]'>
                    <li onClick={() => handleFilterTabsToggle("username")} 
                    className={`flex justify-between items-center py-3 px-4 hover:bg-[#EDF7EE] hover:rounded-[8px] cursor-pointer ${isUsername ? "bg-[#EDF7EE] border border-[#2B8C34] rounded-[8px]":  ""} `}>
                        <div className="flex gap-2 items-center">
                          <PersonOutline style={{ fontSize: "18px", color: '#344335' }} />
                            <p className='text-[#344335] font-medium text-sm'>Username</p>
                        </div>
                        <div>
                            <KeyboardArrowRight style={{fontSize: "18px",color: '#344335'}} />
                        </div>
                    </li>
                    <li onClick={() => handleFilterTabsToggle("activity")} 
                    className={`flex justify-between items-center py-3 px-4  hover:bg-[#EDF7EE] hover:rounded-[8px]  cursor-pointer ${isActivity ? "bg-[#EDF7EE] border border-[#2B8C34] rounded-[8px]":  ""}  `}>
                        <div className="flex gap-2 items-center">
                          <ListAlt style={{ fontSize: "18px", color: '#344335' }} />
                            <p className='text-[#344335] font-medium text-sm'>Activity</p>
                        </div>
                        <div>
                            <KeyboardArrowRight style={{fontSize: "18px",color: '#344335'}} />
                        </div>
                    </li>
                    <li onClick={() => handleFilterTabsToggle("date")} 
                    className={`flex justify-between items-center py-3 px-4 hover:bg-[#EDF7EE] hover:rounded-[8px]  cursor-pointer ${isDate ? "bg-[#EDF7EE] border border-[#2B8C34] rounded-[8px]":  ""} `}>
                        <div className="flex gap-2 items-center">
                          <CalendarToday style={{ fontSize: "18px", color: '#344335' }} />
                            <p className='text-[#344335] font-medium text-sm'>Date</p>
                        </div>
                        <div>
                            <KeyboardArrowRight style={{fontSize: "18px",color: '#344335'}} />
                        </div>
                    </li>
                </ul>
                }

                   {/* Username Filters Div */}
                {isUsername && 
                <div className='bg-[#FFFFFF] rounded-[10px] shadow-dropdown min-w-[350px] py-2 px-2 absolute left-72 top-32 z-[1]'>
                    <ul className=''>
                        {
                            teammates.map((val) => {
                                return (
                                    <li key={val.id} onClick={() => handleTeammateClick(val.user_id)} className={`flex justify-between items-center py-3 px-4 hover:bg-[#EDF7EE] hover:rounded-[8px] cursor-pointer ${userSelected === val.id ? "bg-[#EDF7EE] border border-[#2B8C34] rounded-[8px]":  ""}`}>
                                        <p className='text-[#344335] font-medium text-sm'>{val.teammateUser.first_name} {val.teammateUser.last_name}</p>
                                    </li>
                                )
                            })
                        }
                     </ul>
                </div>
                }

                 {/* activity filters */}
                {isActivity &&
                <div className='bg-[#FFFFFF] rounded-[10px] shadow-dropdown min-w-[350px] py-2 px-2 absolute left-72 top-32 z-[1] max-h-[300px] overflow-scroll'>
                <ul className=''>
                    {
                        activities.map((val) => {
                            return (
                                <li key={val.id} onClick={() => handleActivityClick(val.tag)} className={`flex justify-between items-center py-3 px-4 hover:bg-[#EDF7EE] hover:rounded-[8px] cursor-pointer  ${activitySelected === val.tag ? "bg-[#EDF7EE] border border-[#2B8C34] rounded-[8px]":  ""}`}>
                                    <p className='text-[#344335] font-medium text-sm'>{val.name}</p>
                                </li>
                            )
                        })
                    }
                     </ul>
                </div>
                }

                {/* Date Filters */}
                    {isDate && 
                    <div className='bg-[#FFFFFF] rounded-[10px] shadow-dropdown min-w-[350px] py-4 px-4 absolute left-72 top-32 z-[1]'>
                    <div className='grid grid-cols-2 gap-4 items-center'>
                            <div>
                                <label
                                    htmlFor="from_date"
                                    className="block mb-2 text-[#344335] text-xs font-normal"
                                >
                                    From:
                                </label>
                                <input
                                    type="date"
                                    name="from_date"
                                    max={moment().format("YYYY-MM-DD")}
                                    value={startDate}
                                    onChange={(event) => { 
                                       setStartDate(event.target.value)
                                       handleFromDateClick()
                                    }}
                                    placeholder="DD/MM/YYYY"
                                    className="appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-sm focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                                />
                            </div> 
                            <div>
                                <label
                                    htmlFor="to_date"
                                    className="block mb-2 text-[#344335] text-xs font-normal"
                                >
                                    To:
                                </label>
                                <input
                                    type="date"
                                    name="to_date"
                                    max={moment().format("YYYY-MM-DD")}
                                     value={endDate}
                                    onChange={(event) => { 
                                        setEndDate(event.target.value)
                                        handleEndDateClick()
                                    }}
                                    placeholder="DD/MM/YYYY"
                                    className="appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-sm focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                                />
                            </div>
                        </div>
                    </div>
                 }
              </div>
                <div className="w-full">
                    <div className="rounded-lg border border-[#96A397] flex gap-2 items-center min-h-[52px] bg-white py-2 px-4">
                        {
                            selectedFilters.map((val, index) => {
                                return (
                                    <div key={index} className='bg-[#DBFFDF] rounded-[4px] w-fit flex items-center gap-2 py-2 px-3'>
                                        <p className='text-[#2B8C34] font-medium text-xs'>{val.name}</p>
                                        <img onClick={() => removeFilter(val.name)} src={cancel_icon} alt="cancel" className='w-[18px] h-[18px] cursor-pointer' />
                                    </div>
                                )
                            })
                        }
                        
                    </div>
              </div>
             </div>

        
         <div className='mt-5 flex gap-4'>
            <div className="bg-white basis-[55%] rounded-[8px] h-fit">
              {/* tables */}
                <div className="overflow-x-auto relative">
                {loading ? (
                    <div>
                        <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
                            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                            <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
                        </div>
                     </div>
                    ) : (
                       <>
                        {auditlogs?.length === 0 ? (
                            <div className="w-full flex flex-col items-center justify-center min-h-[300px]">
                                <div className='text-center'>
                                    <div>
                                        <SimCardAlertOutlined style={{ fontSize: "40px", color: "#344335" }} />
                                    </div>
                                <h3 className='text-xl text-[#344335] font-semibold mt-2'>No Results!</h3>
                                <p className='text-sm text-center max-w-md font-medium text-[#000000] mt-2'>
                                Your filter selection returned no results. Please try another filter combination or reset the filter options
                                </p>
                             </div>                           
                            </div>
                            ) :
                            (
                            <>   
                            <table className="w-full text-left">
                            <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                                <tr>
                                <th scope="col" className="px-4 py-4 font-semibold ">
                                    Time
                                </th>
                                <th scope="col" className="px-4 py-4 font-semibold">
                                    Activity
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                {auditlogs?.map((val, index) => {
                                return (
                                    <tr key={val.id} onClick={()=>handleSelectAudit(val)} className={`text-sm ${selectedAudit?.id === val.id ? "bg-[#EDF7EE]": "bg-white"} cursor-pointer hover:bg-[#EDF7EE]`}>
                                    <th
                                        scope="row"
                                        className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                                    >
                                       {moment(val?.created_at).format("lll")}
                                    </th>
                                    <td className="py-4 px-4 text-[#5C715E]">
                                       {val.detail}
                                    </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                            </table>
                             <div>
                             <PaginationComponent
                               page={currentPage}
                               onPageChange={(page) => onPageChange(page)}
                               totalItems={total}
                               pageSize={10}
                             />
                           </div>
                           </>
                            )}
                       </> 
                    )}
                </div>
            </div>
            
            {/* second part */}
            {
            selectedAudit?.id ?
            <div className='bg-white basis-[45%] rounded-[8px] py-4 px-4'>
                <h6 className='text-[#344335] text-sm font-semibold'>About this activity</h6>
                
                <div className='mt-6 flex items-center'>
                    <div className='flex-1'>
                        <p className='text-[#5C715E] text-sm font-semibold'>User</p>
                        <p className='text-[#2B8C34] text-sm font-medium'>{selectedAudit ? selectedAudit?.user : "N/A"}</p>
                    </div>
                    <div className='flex-1'>
                     <p className='text-[#5C715E] text-sm font-semibold'>Email Address</p>
                        <p className='text-[#344335] text-sm font-medium'>{selectedAudit ? selectedAudit?.email : "N/A"}</p>
                    </div>
                </div>

                <div className='mt-6 flex items-center'>
                    <div className='flex-1'>
                        <p className='text-[#5C715E] text-sm font-semibold'>Role</p>
                        <p className='text-[#344335] text-sm font-medium'>{selectedAudit ? selectedAudit?.role?.name : "N/A"}</p>
                    </div>
                    <div className='flex-1'>
                    <p className='text-[#5C715E] text-sm font-semibold'>IP Address</p>
                        <p className='text-[#344335] text-sm font-medium'>{selectedAudit ? selectedAudit?.ip_address : "N/A"}</p>
                    </div>
                </div>

                <div className='mt-6 flex items-center'>
                    <div className='flex-1'>
                        <p className='text-[#5C715E] text-sm font-semibold'>Date</p>
                        <p className='text-[#344335] text-sm font-medium'>{selectedAudit ? moment(selectedAudit?.created_at).format("ll") : "N/A"}</p>
                    </div>
                    <div className='flex-1'>
                    <p className='text-[#5C715E] text-sm font-semibold'>Time</p>
                        <p className='text-[#344335] text-sm font-medium'>{selectedAudit ? moment(selectedAudit?.created_at).format("LT") : "N/A"}</p>
                    </div>
                </div>

                <div className='mt-6 flex items-center'>
                    <div className='flex-1'>
                        <p className='text-[#5C715E] text-sm font-semibold'>Browser</p>
                        <p className='text-[#344335] text-sm font-medium'>{selectedAudit ? selectedAudit?.browser : "N/A"}</p>
                    </div>
                    <div className='flex-1'>
                    <p className='text-[#5C715E] text-sm font-semibold'>Activity</p>
                        <p className='text-[#344335] text-sm font-medium'>{selectedAudit ? selectedAudit?.activity : "N/A"}</p>
                    </div>
                </div>

                <div className='mt-5'>
                    <button
                        className="py-3 px-5 bg-[#EDF7EE] rounded-[8px] text-[#2B8C34] text-sm font-medium  transition-all"
                        >
                        View
                    </button>
                </div>
               
            </div>
            :
            <div className='basis-[45%] flex flex-col justify-center items-center'>
                <p className='text-[#344335] font-medium text-sm'>Select a log to view details</p>
            </div>

            }
         

         </div>
           
        </>
     );
}
 
export default AuditLogTable;