import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../config";
import axios from "axios";
import toast from "react-hot-toast";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};
type dashboardType = {
  id?: string;
};

export const fetchLatestTransaction = createAsyncThunk(
  "fetchLatestTransaction",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}transaction/latest/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200 || res.status === 201) {
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Oops, an error occured fetching latest transactions!");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Oops, an error occured fetching latest transactions!");
        return rejectWithValue(error);
      }
    }
  }
);

export const fetchGraphData = createAsyncThunk(
  "fetchGraphData",
  async ({ id }: dashboardType, { rejectWithValue, dispatch }) => {
    if (id) {
      try {
        const res = await axios.get(
          `${apiUrl}transaction/track-transaction/?account_id=${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200 || res.status === 201) {
          return res.data;
        }
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          toast.error("Oops, an error occured fetching latest transactions!");
          return rejectWithValue(error);
        } else if (error.response.status === 401) {
          dispatch({ type: "auth/logOut" });
          return rejectWithValue(error);
        } else {
          toast.error("Oops, an error occured fetching latest transactions!");
          return rejectWithValue(error);
        }
      }
    } else {
      try {
        const res = await axios.get(`${apiUrl}transaction/track-transaction/`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
        if (res.status === 200 || res.status === 201) {
          return res.data;
        }
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          toast.error("Oops, an error occured fetching latest transactions!");
          return rejectWithValue(error);
        } else if (error.response.status === 401) {
          dispatch({ type: "auth/logOut" });
          return rejectWithValue(error);
        } else {
          toast.error("Oops, an error occured fetching latest transactions!");
          return rejectWithValue(error);
        }
      }
    }
  }
);


export const fetchBalanceAcrossAccounts = createAsyncThunk(
    "fetchBalanceAcrossAccounts",
    async (args, { rejectWithValue, dispatch }) => {
      try {
        const res = await axios.get(`${apiUrl}transaction/account-total/`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
        if (res.status === 200 || res.status === 201) {
          return res.data;
        }
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          toast.error("Oops, an error occured fetching balance across accounts!");
          return rejectWithValue(error);
        } else if (error.response.status === 401) {
          dispatch({ type: "auth/logOut" });
          return rejectWithValue(error);
        } else {
         toast.error("Oops, an error occured fetching balance across accounts!");
          return rejectWithValue(error);
        }
      }
    }
  );