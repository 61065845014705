import React from "react";
import { AreaChart, Area, XAxis, Tooltip, ResponsiveContainer } from "recharts";
import { CustomTooltip } from "./ToolTip";

interface AreaChartProps {
  data: Record<string, any>[];
  height: number;
  selectedAcct: any;
}

const Chart: React.FC<AreaChartProps> = ({ data, height, selectedAcct }) => {
  return (
    <div className="w-full">
      <ResponsiveContainer width="100%" height={height}>
        <AreaChart
          data={data}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop stopColor="#2B8C34" />
              <stop offset="1" stopColor="rgba(43, 140, 52, 0.00)" />
            </linearGradient>
          </defs>
          <XAxis
            style={{
              fontSize: "12px",
              fontFamily: "Inter",
              fontWeight: 400,
              lineHeight: "18px",
              color: "#667085",
            }}
            axisLine={false}
            tickLine={false}
            dataKey="date"
          />
          <Tooltip content={<CustomTooltip />} />

          <Area
            type="monotone"
            dataKey={selectedAcct?.name}
            stroke="#2B8C34"
            strokeWidth={2}
            fillOpacity={0.1}
            fill="url(#colorPv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
