import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  createRecipientTag,
  getAllBanks,
  getAllRecipients,
  getAllTags,
  recipientsSearch,
  verifyRecipientAccount,
  checkReciepientExist,
  createRecipient
} from "./recipientActions";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { RecipientState } from "../../types/Recipients/Recipients";

const initialState: RecipientState = {
  recipients: [],
  total: 0,
  loading: false,
  tags: [],
  banks: [],
  recipientAccountNameLoading: false,
  recipientAccountName: {
    account_number: "",
    account_name: "",
    bank_id: 0,
  },
  recipient: {
    account_number: "",
    bank: {
      code: "",
      name: "",
    },
    bank_id: 0,
    id: "",
    recipient_name: "",
    recipient_tag: {
      description: "",
      tag: "",
    },
    tag_id: 0,
  },
  is_recipient: false
};

// Configure Redux Persist
const authPersistConfig = {
  key: "recipient",
  storage,
  blacklist: ["loading"],
  // Add any additional configuration options as needed
};

export const recipientSlice = createSlice({
  name: "recipient",
  initialState,
  reducers: {
    handleRecipientDetails: (state, payload) => {
      state.recipient = payload?.payload;
      state.is_recipient = true
    },
    resetRecipientStatus: (state) =>{
      state.is_recipient = false
      state.recipient = initialState.recipient
    },
    setRecipient : (state) => {
      state.is_recipient = true
    }
  },
  extraReducers: (builder) => {
    // get all recipient
    builder.addCase(getAllRecipients.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getAllRecipients.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.recipients = action.payload.data;
        state.total = action.payload.total;
      }
    );
    builder.addCase(getAllRecipients.rejected, (state) => {
      state.loading = false;
    });

    // create receipient
    builder.addCase(createRecipient.pending, (state) => {
      
    });
    builder.addCase(
      createRecipient.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.recipient = {
          ...state.recipient,
          id: action.payload.data
        }
      }
    );
    builder.addCase(createRecipient.rejected, (state) => {
      
    });

    // create recipient tag
    builder.addCase(createRecipientTag.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      createRecipientTag.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
      }
    );
    builder.addCase(createRecipientTag.rejected, (state) => {
      state.loading = false;
    });

    // search all recipient
    builder.addCase(recipientsSearch.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      recipientsSearch.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.recipients = action.payload.data;
      }
    );
    builder.addCase(recipientsSearch.rejected, (state) => {
      state.loading = false;
      state.recipients = []
    });

    // get all tags
    builder.addCase(getAllTags.pending, (state) => {});
    builder.addCase(
      getAllTags.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.tags = action.payload.data;
      }
    );
    builder.addCase(getAllTags.rejected, (state) => {});

    // get all banks
    builder.addCase(getAllBanks.pending, (state) => {});
    builder.addCase(
      getAllBanks.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.banks = action.payload.data;
      }
    );
    builder.addCase(getAllBanks.rejected, (state) => {});

    // verify account number
    builder.addCase(verifyRecipientAccount.pending, (state) => {
      state.recipientAccountNameLoading = true;
    });
    builder.addCase(
      verifyRecipientAccount.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.recipientAccountNameLoading = false;
        state.recipientAccountName = action.payload.data;
      }
    );
    builder.addCase(verifyRecipientAccount.rejected, (state) => {
      state.recipientAccountNameLoading = false;
    });
     // check if recipient exist
     builder.addCase(checkReciepientExist.pending, (state) => {
          
     })
     builder.addCase(checkReciepientExist.fulfilled, (state, action: PayloadAction<any>) => {
         state.is_recipient = action.payload.is_recipient
         state.recipient = action.payload.data
     })
     builder.addCase(checkReciepientExist.rejected, (state) => {

     })
  },
});

const recipientReducer = persistReducer(
  authPersistConfig,
  recipientSlice.reducer
);
export const { handleRecipientDetails,resetRecipientStatus,setRecipient } = recipientSlice.actions;

export default recipientReducer;
