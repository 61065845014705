import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../config";
import axios from "axios";
import toast from "react-hot-toast";
import { TransactionsParams } from "../../types/Transactions/Transactions";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

export const getAllTransactions = createAsyncThunk(
  "getAllTransactions",
  async (
    { page, per_page, start, end, min, max, id }: TransactionsParams,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.get(
        `${apiUrl}transaction/all?page=${page}&page_size=${per_page}&start_date=${start}&end_date=${end}&min_amount=${min}&max_amount=${max}&account_id=${id} `,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        let data = res.data;
        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("An error occured while getting all transactions", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("An error occured while getting all transactions", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      }
    }
  }
);

export const getAllRecentTransactions = createAsyncThunk(
  "getAllRecentTransactions",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}transaction/account-recent/ `, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        let data = res.data;
        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("An error occured while getting recent transactions", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("An error occured while getting recent transactions", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      }
    }
  }
);
