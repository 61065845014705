export enum categories {
  OVERVIEW = "Overview",
  PROFILE = "Profile",
  PAYMENT = "Payment",
  TRANSACTION = "Transaction",
  ACCOUNT = "Account",
  RECIPIENT = "Recipient",
  TEAMMATES = "Teammates",
  ROLES_PRIVILEDGES = "Roles_Privileges",
  SETTINGS = "Settings",
}
