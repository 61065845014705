import React, { useState } from "react";
import PaginationComponent from "../../components/Pagination/Pagination";
import more_icon from "../../assets/icons/more_vert.svg";
import { useAppSelector, useAppDispatch } from "../../redux/store";
import {
  cancelInvite,
  fetchInvitee,
  resendInvite,
} from "../../redux/Teammates/teammateActions";
import moment from "moment";
import ModalComponent from "../../components/Modals/modal";
import { useTeammatesPermissions } from "../../helpers/permissionsHelper/teammatesPermissions";

type inviteTypeId = {
  inviteTabId: number;
};

const TeamInvitesTable = ({ inviteTabId }: inviteTypeId) => {
  const { teammatesPermissions } = useTeammatesPermissions();
  const { loading, teaminvites } = useAppSelector((state) => state.teammate);
  const { selectedAccount } = useAppSelector((state) => state.auth);
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const [invitee, setInvitee] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const toggleDropdown = (id: string) => {
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };

  const [isDeleteInvite, setIsDeleteInvite] = useState(false);
  const [isResendInvite, setIsResendInvite] = useState(false);

  const toggleCancelInvite = (email: string) => {
    setInvitee(email);
    setIsDeleteInvite(true);
  };

  const toggleResendInvite = (email: string) => {
    setInvitee(email);
    setIsResendInvite(true);
  };

  const getStatusBg = (val: string) => {
    let result;
    switch (val) {
      case "Expired":
        result = "failed-badge";
        break;
      case "Pending":
        result = "pending-badge";
        break;
      case "Accepted":
        result = "success-badge";
        break;
      default:
        result = "failed-badge";
        break;
    }
    return result;
  };

  const capitalizeFirstLetter = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  const handleDeleteInvite = () => {
    const id = openDropdownId;
    setDeleteLoading(true);
    setOpenDropdownId(null);
    dispatch(
      cancelInvite({
        id: id,
      })
    ).then((res) => {
      if (res?.type === "cancelInvite/fulfilled") {
        setDeleteLoading(false);
        setIsDeleteInvite(false);
        setInvitee("");
        if (selectedAccount?.type === "sub_account") {
          switch (inviteTabId) {
            case 1:
              dispatch(
                fetchInvitee({
                  account_id: selectedAccount?.id,
                  status: "Pending",
                })
              );
              break;
            case 2:
              dispatch(
                fetchInvitee({
                  account_id: selectedAccount?.id,
                  status: "Cancelled",
                })
              );
              break;
            case 3:
              dispatch(
                fetchInvitee({
                  account_id: selectedAccount?.id,
                  status: "Expired",
                })
              );
              break;
            default:
              break;
          }
        }
      } else {
        setDeleteLoading(false);
      }
    });
  };

  const handleResendInvite = () => {
    const id = openDropdownId;
    setResendLoading(true);
    setOpenDropdownId(null);
    dispatch(
      resendInvite({
        id: id,
      })
    ).then((res) => {
      if (res?.type === "resendInvite/fulfilled") {
        setResendLoading(false);
        setIsResendInvite(false);
        setInvitee("");
        if (selectedAccount?.type === "sub_account") {
          switch (inviteTabId) {
            case 1:
              dispatch(
                fetchInvitee({
                  account_id: selectedAccount?.id,
                  status: "Pending",
                })
              );
              break;
            case 2:
              dispatch(
                fetchInvitee({
                  account_id: selectedAccount?.id,
                  status: "Cancelled",
                })
              );
              break;
            case 3:
              dispatch(
                fetchInvitee({
                  account_id: selectedAccount?.id,
                  status: "Expired",
                })
              );
              break;
            default:
              break;
          }
        }
      } else {
        setResendLoading(false);
      }
    });
  };

  return (
    <>
      {/* Cancel Invite MODALS */}
      <ModalComponent
        title="Cancel invite"
        subtitle=""
        isOpen={isDeleteInvite}
        onClose={() => setIsDeleteInvite(false)}
      >
        <div>
          <p className="mt-[10px] text-sm font-mediumn leading-5 text-[#5C715E] mb-8">
            Are you sure you want to cancel the invite sent to:{" "}
            <span className="text-[#2B8C34] font-semibold">
              {invitee ? invitee : "this user"}
            </span>
          </p>

          <div className="flex justify-end">
            <div className="flex gap-[40px] items-center cursor-pointer">
              <p
                className=" text-sm font-mediumn leading-5 text-[#5C715E]"
                onClick={() => setIsDeleteInvite(false)}
              >
                No
              </p>
              <button
                onClick={handleDeleteInvite}
                disabled={deleteLoading}
                className=" outline-none text-white rounded-lg disabled:bg-[#ABB6AC] bg-[#B92043] text-sm font-mediumn leading-5 px-[20px] py-[10px]"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </ModalComponent>

      {/* Resend Invite Modals */}
      <ModalComponent
        title="Resend invite"
        subtitle=""
        isOpen={isResendInvite}
        onClose={() => setIsResendInvite(false)}
      >
        <div>
          <p className="mt-[10px] text-sm font-mediumn leading-5 text-[#5C715E] mb-8">
            Are you sure you want to resend the invite to:{" "}
            <span className="text-[#2B8C34] font-semibold">
              {invitee ? invitee : "this user"}
            </span>
          </p>

          <div className="flex justify-end">
            <div className="flex gap-[40px] items-center cursor-pointer">
              <p
                className=" text-sm font-mediumn leading-5 text-[#5C715E]"
                onClick={() => setIsResendInvite(false)}
              >
                Cancel
              </p>
              <button
                onClick={handleResendInvite}
                disabled={resendLoading}
                className=" outline-none text-white rounded-lg disabled:bg-[#ABB6AC] bg-[#2B8C34] text-sm font-mediumn leading-5 px-[20px] py-[10px]"
              >
                Resend Invite
              </button>
            </div>
          </div>
        </div>
      </ModalComponent>

      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          <p className="text-primary font-semibold text-base leading-5">
            List of Team Invites
          </p>
        </div>

        {/* tables */}
        <div className=" relative">
          {loading ? (
            <div>
              <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            </div>
          ) : (
            <>
              {teaminvites?.length === 0 ? (
                <div>
                  <div className="w-full pb-[80px] mt-[80px] flex items-center justify-center">
                    <p>There are no invites sent yet</p>
                  </div>
                </div>
              ) : (
                <section>
                  <table className="w-full text-left relative">
                    <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                      <tr>
                        <th scope="col" className="px-4 py-4 font-semibold ">
                          Date Invited
                        </th>
                       
                        <th scope="col" className="px-4 py-4 font-semibold">
                          Email
                        </th>
                        <th scope="col" className="px-4 py-4 font-semibold">
                          Role
                        </th>
                        <th scope="col" className="px-4 py-4 font-semibold">
                          Status
                        </th>
                        {inviteTabId !== 2 && (
                          <th scope="col" className="px-4 py-4 font-semibold">
                            Actions
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {teaminvites?.map((teammate, index) => {
                        return (
                          <tr
                            key={index}
                            className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                          >
                            <th
                              scope="row"
                              className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                            >
                              {moment(teammate?.created_at).format("lll")}
                            </th>
                            <td className="py-4 px-4 text-[#5C715E]">
                              {teammate.invitee}
                            </td>
                            <td className="py-4 px-4 text-[#5C715E]">
                              {capitalizeFirstLetter(teammate.role.name)}
                            </td>
                            <td className="py-4 px-4 text-[#5C715E]">
                              <div className="flex">
                                <div
                                  className={getStatusBg(
                                    teammate?.status || "N/A"
                                  )}
                                >
                                  <p>{teammate?.status || "N/A"}</p>
                                </div>
                              </div>
                            </td>

                            {inviteTabId !== 2 && (
                              <td className="py-4 px-4 relative text-[#5C715E]">
                                <div>
                                  <img
                                    src={more_icon}
                                    alt="more icon"
                                    onClick={() => toggleDropdown(teammate?.id)}
                                  />

                                  {openDropdownId === teammate?.id && (
                                    <ul className="absolute mt-5 bg-white z-[6] rounded-lg shadow-dropdown py-2 px-2 right-10 top-6 min-w-[187px]">
                                      {teammatesPermissions.resendInvite && (
                                        <>
                                          {teammate?.status !== "Cancelled" && (
                                            <li
                                              onClick={() =>
                                                toggleResendInvite(
                                                  teammate.invitee
                                                )
                                              }
                                              className="py-3 px-2 cursor-pointer hover:bg-[#EDF7EE]"
                                            >
                                              <p className="text-[#344335] text-sm font-medium">
                                                Resend Invite
                                              </p>
                                            </li>
                                          )}
                                        </>
                                      )}

                                      {teammatesPermissions.cancelInvite && (
                                        <>
                                          {teammate?.status !== "Expired" &&
                                            teammate?.status !==
                                              "Cancelled" && (
                                              <li
                                                className="py-3 px-2 cursor-pointer hover:bg-[#EDF7EE]"
                                                onClick={() =>
                                                  toggleCancelInvite(
                                                    teammate.invitee
                                                  )
                                                }
                                              >
                                                <p className="text-[#344335] text-sm font-medium">
                                                  Cancel Invite
                                                </p>
                                              </li>
                                            )}
                                        </>
                                      )}
                                    </ul>
                                  )}
                                </div>
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  <div>
                    <PaginationComponent
                      page={currentPage}
                      onPageChange={(page) => onPageChange(1)}
                      totalItems={10}
                      pageSize={10}
                    />
                  </div>
                </section>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default TeamInvitesTable;
