import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl, baseApiUrl } from "../config";
import axios from "axios";
import toast from "react-hot-toast";
import { RootState } from "../store";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

type fetchPaymentsCreds = {
  status: string;
  page: number;
  per_page: number;
  start: string | null;
  end: string | null;
  min: number | null;
  max: number | null;
  id: string | null;
  type: string;
};

export const fetchPayments = createAsyncThunk(
  "fetchPayments",
  async (credentials: fetchPaymentsCreds, { rejectWithValue, dispatch }) => {
    try {
      if (credentials.status === "all") {
        const res = await axios.get(
          `${apiUrl}payment/all?type=${credentials.type}&page=${credentials.page}&page_size=${credentials.per_page}&start_date=${credentials.start}&end_date=${credentials.end}&min_amount=${credentials.min}&max_amount=${credentials.max}&account_id=${credentials.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200 || res.status === 201) {
          return res.data;
        }
      } else {
        const res = await axios.get(
          `${apiUrl}payment/all?type=${credentials.type}&status=${credentials.status}&page=${credentials.page}&page_size=${credentials.per_page}&start_date=${credentials.start}&end_date=${credentials.end}&min_amount=${credentials.min}&max_amount=${credentials.max}&account_id=${credentials.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200 || res.status === 201) {
          return res.data;
        }
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Oops, an error occured fetching all payments!");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Oops, an error occured fetching all payments!");
        return rejectWithValue(error);
      }
    }
  }
);

interface ReceiptType {
  id: string | null;
}

export const downloadTransactionReceipt = createAsyncThunk(
  "downloadTransactionReceipt",
  async ({ id }: ReceiptType, { rejectWithValue, dispatch }) => {
    try {
      let url = `${apiUrl}payment/download/?id=${id}`;
      const res: any = await axios
        .get(`${apiUrl}payment/download/?id=${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          responseType: "blob",
        })
        .then((response) => {
          url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `trans${id}.pdf`); // Replace with the desired filename to save the PDF
          document.body.appendChild(link);
          link.click();
          link.remove();
          toast.success(`Receipt downloaded successfully!`, {
            position: "bottom-right",
          });
        });
      if (res.status === 200) {
        toast.success(`Receipt downloaded successfully!`, {
          position: "bottom-right",
        });
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Something went wrong!");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);


// check for existing session of a bulk file uploaded
export const checkExistingSession = createAsyncThunk(
  "checkExistingSession",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}payment/bulk/session-check/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// get saved session info
export const fetchSessionInfo = createAsyncThunk(
  "fetchSessionInfo",
  async (credentials:{id: string, is_new:string}, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}payment/bulk/session-data/?id=${credentials.id}&is_new=${credentials.is_new}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// download bulk payment sample template
export const downloadBulkTemplate = createAsyncThunk(
  "downloadBulkTemplate",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      let url = `${apiUrl}payment/bulk/download-template`;
      const res: any = await axios
        .get(`${apiUrl}payment/bulk/download-template`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          responseType: "blob",
        })
        .then((response) => {
          url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `ngn_amount_and_titanium_bulk_sample.csv`); // Replace with the desired filename to save the PDF
          document.body.appendChild(link);
          link.click();
          link.remove();
          toast.success(`Sample template downloaded successfully!`, {
            style: {
              border: "1px solid #2B8C34",
              backgroundColor: "#2B8C34",
              color: "#FFFFFF",
              fontSize: 14,
            },
            position: "bottom-right",
          });
        });
      if (res.status === 200) {
        toast.success(`Sample template downloaded successfully!`, {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
          },
          position: "bottom-right",
        });
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Something went wrong!");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// download sample bank template
export const downloadSampleBankTemplate = createAsyncThunk(
  "downloadSampleBankTemplate",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      let url = `${apiUrl}payment/bulk/bank-samples`;
      const res: any = await axios
        .get(`${apiUrl}payment/bulk/bank-samples`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          responseType: "blob",
        })
        .then((response) => {
          url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const timestamp = Date.now();
          link.setAttribute("download", `Banks_list_${timestamp}.csv`);
          document.body.appendChild(link);
          link.click();
          link.remove();
          toast.success(`Bank list downloaded successfully!`, {
            style: {
              border: "1px solid #2B8C34",
              backgroundColor: "#2B8C34",
              color: "#FFFFFF",
              fontSize: 14,
            },
            position: "bottom-right",
          });
        });
      if (res.status === 200) {
        toast.success(`Bank list downloaded successfully!`, {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
          },
          position: "bottom-right",
        });
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Something went wrong!");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// download invalid reciepients csv 
export const downloadInvalidRecipients = createAsyncThunk(
  "downloadInvalidRecipients",
  async (creds: {id: string}, { rejectWithValue, dispatch }) => {
    try {
      let url = `${apiUrl}payment/bulk/invalid-list/?id=${creds.id}`;
      const res: any = await axios
        .get(`${apiUrl}payment/bulk/invalid-list/?id=${creds.id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          responseType: "blob",
        })
        .then((response) => {
          url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const timestamp = Date.now();
          link.setAttribute("download", `Invalid_recipients_${timestamp}.csv`);
          document.body.appendChild(link);
          link.click();
          link.remove();
          toast.success(`Please find the invalid recipient file!`, {
            style: {
              border: "1px solid #2B8C34",
              backgroundColor: "#2B8C34",
              color: "#FFFFFF",
              fontSize: 14,
            },
            position: "bottom-right",
          });
          return res.data;
        });
      if (res.status === 200) {
        toast.success(`Please find the invalid recipient file!`, {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
          },
          position: "bottom-right",
        });
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Something went wrong!");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);


interface reUploadInvalidCSVType {
  file: File
  session_id: string;
}
// reupload invalid recipients csv
export const reUploadInvalidCSV = createAsyncThunk(
  'reUploadInvalidCSV',
  async ({ file, session_id } : reUploadInvalidCSVType, { rejectWithValue, dispatch }) => {
    let formData = new FormData()
    formData.append("csv_file", file);
    formData.append("session_id", session_id);
    try {
      const res = await axios({      
        method: "post",
        url: `${apiUrl}payment/bulk/reupload-csv/`,
        data: formData,
        headers: { 
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${getToken()}`
        },
      
    })
      if(res.status === 200){
          toast.success("CSV file processed successfully", {
            style: {
              border: "1px solid #2B8C34",
              backgroundColor: "#2B8C34",
              color: "#FFFFFF",
              fontSize: 14,
            },
            position: "bottom-right",
          });
          return res.data
      }
    } catch (error:any) {
      // return custom error message from API if any
      if (error.response && error.response.status === 400) {
        toast.error(
          "Error while uploading file!"
        );
        return rejectWithValue(error);
      } else {
        toast.error(
          "Error while uploading file!"
        );
        return rejectWithValue(error);
      }
    }
  }
)



interface EditInvalidRecipientType {
  session_id: string;
  row_id: string;
  account_number: string;
  bank: string;
  amount: number;
  narration: string;
  recipient_tag: string;
}


// Update an invalid recipient info
export const EditInvalidRecipient = createAsyncThunk(
  "EditInvalidRecipient",
  async (
    credentials: EditInvalidRecipientType,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const res = await axios.put(
        `${apiUrl}payment/bulk/edit-list/`,
        {
          session_id: credentials.session_id,
          row_id: credentials.row_id,
          updated_data: {
            account_number: credentials.account_number,
            bank: credentials.bank,
            amount: credentials.amount,
            narration: credentials.narration,
            recipient_tag: credentials.recipient_tag
          }
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        let data = res.data;
        toast.success("Recipient information updated successfully", {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
          },
        });
        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error(`${error.response.data.message}`,{
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        // toast.error("Something went wrong!");
        return rejectWithValue(error);
      }
    }
  }
);


interface deleteBulkRecipientType {
  session_id: string;
  row_id: string;
  list_type: string
}

// Delete a recipient(valid or invalid)
export const deleteBulkRecipient = createAsyncThunk(
  "deleteBulkRecipient",
  async (
    credentials: deleteBulkRecipientType,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.post(
        `${apiUrl}payment/bulk/delete-row/`,
        {
          session_id: credentials.session_id,
          row_id: credentials.row_id,
          list_type: credentials.list_type
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        let data = res.data;
        toast.success("Recipient deleted successfully", {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
          },
        });
        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error(`${error.response.data.message}`,{
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        // toast.error("Something went wrong!");
        return rejectWithValue(error);
      }
    }
  }
);

interface InitiateBulkPaymentType {
  session_id: string;
  account_id: string;
  valid_list: any
}

// Initiate Bulk Payment
export const InitiateBulkPayment = createAsyncThunk(
  "InitiateBulkPayment",
  async (
    credentials: InitiateBulkPaymentType,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.post(
        `${apiUrl}payment/bulk/initiate/`,
        {
          session_id: credentials.session_id,
          account_id: credentials.account_id,
          valid_list: credentials.valid_list
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 201) {
        let data = res.data;
        toast.success("Congratulations, your bulk payment is now been processed!", {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
          },
        });
        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error(`${error.response.data.message}`,{
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      }
      else if (error.response && error.response.status === 404) {
        toast.error(`Oops, an error occured while initiating this payment!`,{
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
         });
        return rejectWithValue(error);
      }
       else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        // toast.error("Something went wrong!");
        return rejectWithValue(error);
      }
    }
  }
);


interface InitiatePaymentType {
  amount: number;
  narration: string;
  initiated_by?: string;
  recipient: string;
  account_id?: string;
}

export const InitiatePayment = createAsyncThunk(
  "InitiatePayment",
  async (
    credentials: InitiatePaymentType,
    { rejectWithValue, dispatch, getState }
  ) => {
    const { auth, payment } = getState() as RootState;
    try {
      const res = await axios.post(
        `${baseApiUrl}/payments`,
        {
          amount: credentials.amount,
          narration: credentials.narration,
          initiated_by: `${auth.profile.first_name} ${auth.profile.last_name}`,
          recipient: credentials.recipient,
          account_id: auth.selectedAccount?.account_id,
          document: payment.attachUrl
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": ` ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        let data = res.data;
        toast.success("Payment initiated successfully", {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
          },
        });
        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error(`${error.response.data.message}`,{
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        // toast.error("Something went wrong!");
        return rejectWithValue(error);
      }
    }
  }
);

interface attachType {
  file: File
}

export const uploadAttachment = createAsyncThunk(
  'uploadAttachment',
  async ({ file } : attachType, { rejectWithValue, dispatch }) => {
    let formData = new FormData()
    formData.append("payment_file", file);
    try {
      const res = await axios({      
        method: "post",
        url: `${apiUrl}payment/upload-document/`,
        data: formData,
        headers: { 
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${getToken()}`
        },
      
    })
      if(res.status === 201){
          toast.success("Attachment uploaded successfully", {
            style: {
              border: "1px solid #2B8C34",
              backgroundColor: "#2B8C34",
              color: "#FFFFFF",
              fontSize: 14,
            },
            position: "top-right",
          });
          return res.data
      }
    } catch (error:any) {
      // return custom error message from API if any
      if (error.response && error.response.status === 400) {
        toast.error(
          "Error while uploading attachment!"
        );
        return rejectWithValue(error);
      } else {
        toast.error(
          "Error while uploading attachment!"
        );
        return rejectWithValue(error);
      }
    }
  }
)


interface approvePaymentTyoe {
  id: string;
}
export const approvePayment = createAsyncThunk(
  "approvePayment",
  async ({ id }: approvePaymentTyoe, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${baseApiUrl}/payments/approve`,
        {
          id: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": ` ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        let data = res.data;
        toast.success("Payment approved successfully", {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
          },
        });
        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error(`${error.response.data.message}`,{
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      } 
      else if(error.response.status === 404){
        toast.error(`${error.response.data.message}`);
        return rejectWithValue(error);
      }
      else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        // toast.error("Something went wrong!");
        return rejectWithValue(error);
      }
    }
  }
);

export const declinePayment = createAsyncThunk(
  "declinePayment",
  async ({ id }: approvePaymentTyoe, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(`${baseApiUrl}/payments/reject`,
      {
        id: id,
      }, 
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": ` ${getToken()}`,
        },
      });
      if (res.status === 200) {
        let data = res.data;
        toast.success(`Payment rejected successfully!`);
        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Oops looks like there is an issue rejecting this payment!");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        // toast.error("Something went wrong!");
        return rejectWithValue(error);
      }
    }
  }
);

export const retryPayment = createAsyncThunk(
  "retryPayment",
  async ({ id }: approvePaymentTyoe, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}payment/retry/`,
        {
          payment_id: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": ` ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        let data = res.data;
        toast.success("Your payment is now been processed!", {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
          },
        });

        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Oops looks like there is an issue rejecting this payment!");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        // toast.error("Something went wrong!");
        return rejectWithValue(error);
      }
    }
  }
);
