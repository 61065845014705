import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../config";
import axios from "axios";
import toast from "react-hot-toast";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

interface LoginCredentials {
  email: string;
  password: string;
}

interface RefreshCredentails {
  token: string | null;
}

export interface LoginApiResponse {
  // Define the properties you expect in the API response
  token: string;
  // Add other properties as needed
  profile: {
    id: string;
    username: string;
    email: string;
    isVerified: boolean;
    role: string;
    firstname: string;
    lastname: string;
    isEnabled: boolean;
  };
}

export const userLogin = createAsyncThunk(
  "login",
  async (credentials: LoginCredentials, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${apiUrl}auth/login/`, {
        email: credentials.email,
        password: credentials.password,
      });
      if (res.status === 200) {
        toast.success("Login successful!", {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
          },
          position: "bottom-right",
        });

        let data = res.data;
        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Invalid Credentials!");
        return rejectWithValue(error);
      } else {
        toast.error("Invalid Credentials!");
        return rejectWithValue(error);
      }
    }
  }
);

export const user2faVerify = createAsyncThunk(
  "user2faVerify",
  async (
    credentials: {token: string},
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.post(
        `${apiUrl}auth/login/verify/`,
        {
          token: credentials.token
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        toast.success("Your code is valid, Please proceed to your dashboard", {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
          },
          position: "top-right",
        });
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Oops, looks like you entered an invalid code.");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Oops, looks like you entered an invalid code.");
        return rejectWithValue(error);
      }
    }
  }
);

export const user2faEmailResendCode = createAsyncThunk(
  "user2faEmailResendCode",
  async (
    args,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.post(
        `${apiUrl}auth/request-otp/`,
        {
          
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        toast.success("A new otp has been sent to your email!.", {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
          },
          position: "top-right",
        });
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Oops, looks like an error occured.");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Oops, looks like an error occured.");
        return rejectWithValue(error);
      }
    }
  }
);

export const userRefreshToken = createAsyncThunk(
  "refreshToken",
  async (credentials: RefreshCredentails, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${apiUrl}auth/token/refresh/`, {
        refresh: credentials.token,
      });
      if (res.status === 200) {
        let data = res.data;
        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

interface ForgotCredentials {
  email: string;
}

export const userForgotPassword = createAsyncThunk(
  "forgot",
  async (credentials: ForgotCredentials, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${apiUrl}auth/forgot-password/`, {
        email: credentials.email,
      });
      if (res.status === 200) {
        toast.success(
          `Please check your email for the reset instructions sent`
        );
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Please check that the credentials entered is valid!");
        return rejectWithValue(error);
      } else {
        toast.error("Please check that the credentials entered is valid!");
        return rejectWithValue(error);
      }
    }
  }
);

type codeType = {
  code: string | null;
};

export const verifyResetToken = createAsyncThunk(
  "verifyResetToken",
  async (credentials: codeType, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${apiUrl}auth/check-resettoken/?token=${credentials.code}`
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error(
          "Oops, looks like the link has either expired or is invalid, please request again!"
        );
        return rejectWithValue(error);
      } else {
        toast.error(
          "Oops, looks like the link has either expired or is invalid, please request again"
        );
        return rejectWithValue(error);
      }
    }
  }
);

interface ResetCredentials {
  code: string | null;
  password: string;
}

export const userResetPassword = createAsyncThunk(
  "reset",
  async (credentials: ResetCredentials, { rejectWithValue }) => {
    try {
      const res = await axios.patch(`${apiUrl}auth/reset-password/`, {
        token: credentials.code,
        new_password: credentials.password,
      });
      if (res.status === 200) {
        toast.success(
          `Your password has been changed successfully, Kindly login again!`
        );
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error(
          "Oops, looks like the link has either expired or is invalid, please request again."
        );
        return rejectWithValue(error);
      } else {
        toast.error(
          "Oops, looks like the link has either expired or is invalid, please request again"
        );
        return rejectWithValue(error);
      }
    }
  }
);

interface ChangePasswordCredentials {
  oldpassword: string;
  newpassword: string;
}

export const userChangePassword = createAsyncThunk(
  "userChangePassword",
  async (
    credentials: ChangePasswordCredentials,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.post(
        `${apiUrl}auth/change-password/`,
        {
          old_password: credentials.oldpassword,
          new_password: credentials.newpassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        toast.success("Password successfully changed, Login to continue", {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
          },
          position: "top-right",
        });

        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Check that your old password is correct!.");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Check that your old password is correct!");
        return rejectWithValue(error);
      }
    }
  }
);

interface joinTeamType {
  email: string | null;
  first_name: string;
  last_name: string;
  password: string;
  token: string | null;
}

export const joinTeam = createAsyncThunk(
  "joinTeam",
  async (credentials: joinTeamType, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}auth/register/`,
        {
          email: credentials.email,
          first_name: credentials.first_name,
          last_name: credentials.last_name,
          password: credentials.password,
          token: credentials.token,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status === 201) {
        toast.success(
          "Registration successful, Welcome to Titanium, Kindly proceed to login",
          {
            style: {
              border: "1px solid #2B8C34",
              backgroundColor: "#2B8C34",
              color: "#FFFFFF",
              fontSize: 14,
            },
          }
        );

        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Oops, an error occured when signing up this user");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Oops, an error occured when signing up this user");
        return rejectWithValue(error);
      }
    }
  }
);

type userAccountPrivilegeType = {
  account_id: string;
};
export const getUserAccountPrivilege = createAsyncThunk(
  "getUserAccountPrivilege",
  async (creds: userAccountPrivilegeType, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}account/get-privilege?account_id=${creds.account_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Oops, an error occured getting user privileges");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Oops, an error occured getting user privileges");
        return rejectWithValue(error);
      }
    }
  }
);


export const userGoogleAuthSetup = createAsyncThunk(
  "userGoogleAuthSetup",
  async (
    arg,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.post(
        `${apiUrl}auth/2fa-setup/auth/`,
        {
         
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 201) {
        toast.success("Your 2fa has been added, please proceed to finalize the process!", {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
          },
          position: "top-right",
        });

        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Oops an error occured setting your 2fa");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Oops an error occured setting your 2fa");
        return rejectWithValue(error);
      }
    }
  }
);

export const userGoogleAuthVerify = createAsyncThunk(
  "userGoogleAuthVerify",
  async (
    credentials: {token: string},
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.post(
        `${apiUrl}auth/2fa-verify/auth/`,
        {
          token: credentials.token
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        toast.success("You have successfully activated your 2fa using the Google Authenticator!", {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
          },
          position: "top-right",
        });

        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Oops, looks like you entered an invalid code.");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Oops, looks like you entered an invalid code.");
        return rejectWithValue(error);
      }
    }
  }
);

export const userEmailSetup = createAsyncThunk(
  "userEmailSetup",
  async (
    arg,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.post(
        `${apiUrl}auth/2fa-setup/email/`,
        {
         
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        toast.success("A verification code has been sent to your registered email, Kindly proceed!", {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
          },
          position: "top-right",
        });

        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Oops an error occured setting your 2fa.");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Oops an error occured setting your 2fa.");
        return rejectWithValue(error);
      }
    }
  }
);

export const userEmailSetupResendCode = createAsyncThunk(
  "userEmailSetupResendCode",
  async (
    args,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.post(
        `${apiUrl}auth/2fa-setup/email/resend-otp/`,
        {
          
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        toast.success("A new otp has been sent to your email!.", {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
          },
          position: "top-right",
        });
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Oops, looks like an error occured.");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Oops, looks like an error occured.");
        return rejectWithValue(error);
      }
    }
  }
);

export const userEmailVerify = createAsyncThunk(
  "userEmailVerify",
  async (
    credentials: {token: string},
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.post(
        `${apiUrl}auth/2fa-verify/email/`,
        {
          token: credentials.token
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        toast.success("You have successfully activated your 2fa!", {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
          },
          position: "top-right",
        });

        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Oops, looks like you entered an invalid code.");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Oops, looks like you entered an invalid code.");
        return rejectWithValue(error);
      }
    }
  }
);
