import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/Auth/login";
import ForgotPasswordPage from "./pages/Auth/forgot";
import Sidebar from "./components/Sidebar";
import Dashboard from "./pages/dashboard/dashboard";
import Transaction from "./pages/Transaction/transaction";
import Account from "./pages/Account/account";
import Recipient from "./pages/Recipient/Recipient";
import Teammates from "./pages/Teammates/teammates";
import Roles from "./pages/Roles/roles";
import PaymentPage from "./pages/Payment/payment";
import NewPaymentPage from "./pages/Payment/NewPayment";
import ResetPasswordPage from "./pages/Auth/reset";
import UserRoute from "./components/PrivateRoutes/userRoute";
import ProfilePage from "./pages/Profile/profile";
import StatementPage from "./pages/Statement";
import CreateRolePage from "./pages/Roles/createRole";
import JoinTeamPage from "./pages/Auth/joinTeam";
import TeamInvites from "./pages/Teammates/teamInvites";
import RolesTeammates from "./pages/Roles/teammates";
import EditRolePage from "./pages/Roles/editRoles";
import AuditLogPage from "./pages/Audit";
import EmailLoginPage from "./pages/Auth/emailLogin";
import GoogleAuthLoginPage from "./pages/Auth/googleAuthLogin";

const App = () => {
  return (
    <>
      <div>
        <Router>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/email-verify" element={<EmailLoginPage />} />
            <Route path="/googleauth-verify" element={<GoogleAuthLoginPage />} />
            <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
            <Route path="/resetpassword" element={<ResetPasswordPage />} />
            <Route path="/join-team" element={<JoinTeamPage />} />
            <Route
              path="/dashboard"
              element={
                <UserRoute>
                  <Sidebar title={"Dashboard"} children={<Dashboard />} />
                </UserRoute>
              }
            />
            <Route
              path="/payment"
              element={
                <UserRoute>
                  <Sidebar title={"Payment"} children={<PaymentPage />} />
                </UserRoute>
              }
            />
            <Route
              path="/payment/new"
              element={
                <UserRoute>
                  <Sidebar
                    title={"New Payment"}
                    children={<NewPaymentPage />}
                  />
                </UserRoute>
              }
            />
            <Route
              path="/transaction"
              element={
                <UserRoute>
                  <Sidebar title={"Transaction"} children={<Transaction />} />
                </UserRoute>
              }
            />
            <Route
              path="/account"
              element={
                <UserRoute>
                  <Sidebar title={"Account"} children={<Account />} />
                </UserRoute>
              }
            />
            <Route
              path="/recipient"
              element={
                <UserRoute>
                  <Sidebar title={"Recipient"} children={<Recipient />} />
                </UserRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <UserRoute>
                  <Sidebar title={"Profile"} children={<ProfilePage />} />
                </UserRoute>
              }
            />
            <Route
              path="/teammates"
              element={
                <UserRoute>
                  <Sidebar title={"Teammates"} children={<Teammates />} />
                </UserRoute>
              }
            />
            <Route
              path="/teammates/invites"
              element={
                <UserRoute>
                  <Sidebar title={"View Invites"} children={<TeamInvites />} />
                </UserRoute>
              }
            />
            <Route
              path="/roles"
              element={
                <Sidebar title={"Roles and Priviledges"} children={<Roles />} />
              }
            />
            <Route
              path="/role/create"
              element={
                <Sidebar
                  title={"Create New Role"}
                  children={<CreateRolePage />}
                />
              }
            />
            <Route
              path="/role/teammates/:id"
              element={
                <Sidebar title={"Teammates"} children={<RolesTeammates />} />
              }
            />
            <Route
              path="/edit-role"
              element={
                <Sidebar
                  title={"Edit Role Actions"}
                  children={<EditRolePage />}
                />
              }
            />
             <Route
              path="/audit-log"
              element={
                <Sidebar
                  title={"Audit Log"}
                  children={<AuditLogPage />}
                />
              }
            />
            <Route path="/statement" element={<StatementPage />} />
          </Routes>
        </Router>
      </div>
    </>
  );
};

export default App;
