import { useAppSelector } from "../../../redux/store";
import { useEffect, useState } from "react";
import { categories } from "../../../types/PermissionsCategories/PermissionsCategories";

export const useTeammatesPermissions = () => {
  const { userPermissions } = useAppSelector((state) => state.auth);
  const [permissions, setPermissions] = useState<any>({});

  interface TeammatesPermissions {
    cancelInvite: boolean;
    deleteTeammates: boolean;
    editTeammates: boolean;
    inviteTeammates: boolean;
    resendInvite: boolean;
    viewTeammates: boolean;

    // Add more overview permissions here if needed
  }

  useEffect(() => {
    const newPermissions: TeammatesPermissions = {
      cancelInvite: false,
      deleteTeammates: false,
      editTeammates: false,
      inviteTeammates: false,
      resendInvite: false,
      viewTeammates: false,

      // Add more overview permissions here if needed
    };

    userPermissions.forEach((permission) => {
      if (permission.category === categories.TEAMMATES) {
        switch (permission.name) {
          case "Can Cancel Invite":
            newPermissions.cancelInvite = true;
            break;
          case "Can Delete Teammate":
            newPermissions.deleteTeammates = true;
            break;
          case "Can Edit Teammate":
            newPermissions.editTeammates = true;
            break;
          case "Can Invite Teammate":
            newPermissions.inviteTeammates = true;
            break;
          case "Can Resend invite":
            newPermissions.resendInvite = true;
            break;

          case "Can View Teammates":
            newPermissions.viewTeammates = true;
            break;

          // Add cases for other overview permissions here
        }
      }
    });

    setPermissions(newPermissions);
  }, [userPermissions]);

  return {
    teammatesPermissions: permissions,
  };
};
