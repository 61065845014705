import React, { useEffect, useState } from "react";
import more_icon from "../../assets/icons/more_vert.svg";
import DrawerComponent from "../../components/Drawers/drawer";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  approvePayment,
  declinePayment,
  fetchPayments,
  retryPayment,
} from "../../redux/Payment/paymentActions";
import moment from "moment";
import PaginationComponent from "../../components/Pagination/Pagination";
import { Payment } from "../../types/Transactions/Transactions";
import { downloadTransactionReceipt } from "../../redux/Payment/paymentActions";
import { clearIsApproved } from "../../redux/Payment/payment";
import ModalComponent from "../../components/Modals/modal";
import { usePaymentPermissions } from '../../helpers/permissionsHelper/paymentPermissions';


type PaymentTypeProps ={
  paymentType: number;
}


const PaymentsTable = ({paymentType}: PaymentTypeProps) => {


  const [typeID, setTypeID] = useState(1);
  const [isToggleAmount, setIsToggleAmount] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [minAmt, setMinAmt] = useState(0);
  const [maxAmt, setMaxAmt] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useAppDispatch();
  const { loading, loader, payments, total, isApproved } = useAppSelector(
    (state) => state.payment
  );
  const { selectedAccount } = useAppSelector((state) => state.auth);
 const [isShow, setIsShow] = useState(false);

 const showModal = () =>{
  setIsShow(!isShow)
 }


 const { paymentPermissions } = usePaymentPermissions();
  

  const callTypes = [
    {
      id: 1,
      title: "All",
    },
    {
      id: 2,
      title: "Pending Approval",
    },
  ];

  const formatNumber = (numberToFormat:string) =>{
    let res = Number(numberToFormat).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  }

  const toggleType = (id: number) => {
    setTypeID(id);

    switch (id) {
      case 1:
        dispatch(
          fetchPayments({
            status: "all",
            page: 1,
            per_page: pageSize,
            start: null,
            end: null,
            min: null,
            max: null,
            type: paymentType === 1 ? 'single': 'bulk',
            id:
          selectedAccount?.type === "main_account"
            ? null
            : selectedAccount?.account_id,
          })
        );
        break;
      case 2:
        dispatch(
          fetchPayments({
            status: "Pending",
            page: 1,
            per_page: pageSize,
            start: null,
            end: null,
            min: null,
            max: null,
            type: paymentType === 1 ? 'single': 'bulk',
            id:
          selectedAccount?.type === "main_account"
            ? null
            : selectedAccount?.account_id,
          })
        );
        break;
      default:
        break;
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);
  const [payment, setPayment] = useState<Payment | null>(null);

  const toggleDropdown = (id: string) => {
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };

  const showPaymentInfo = () => {
    setIsOpen(!isOpen);
  };

  const handlePaymentInfo = (payment: Payment) => {
    setPayment(payment);
    setIsOpen(!isOpen);
    setOpenDropdownId(null);
  };

  const handleReceiptDownload = (id: string) => {
    setOpenDropdownId(null);
    dispatch(
      downloadTransactionReceipt({
        id: id,
      })
    );
  };

  const handleApprovePayment = (id: string) => {
    setOpenDropdownId(null);
    // setIsOpen(!isOpen);
    dispatch(
      approvePayment({
        id: id,
      })
    );
  };

  useEffect(() => {
    if (isApproved) {
      setIsOpen(false);
      dispatch(clearIsApproved());

      switch (typeID) {
        case 1:
          dispatch(
            fetchPayments({
              status: "all",
              page: 1,
              per_page: pageSize,
              start: null,
              end: null,
              min: null,
              max: null,
              type: paymentType === 1 ? 'single': 'bulk',
              id:
          selectedAccount?.type === "main_account"
            ? null
            : selectedAccount?.account_id,
            })
          );
          break;
        case 2:
          dispatch(
            fetchPayments({
              status: "Pending",
              page: 1,
              per_page: pageSize,
              start: null,
              end: null,
              min: null,
              max: null,
              type: paymentType === 1 ? 'single': 'bulk',
              id:
          selectedAccount?.type === "main_account"
            ? null
            : selectedAccount?.account_id,
            })
          );
          break;
        default:
          break;
      }
    }
  }, [isApproved, dispatch, typeID, pageSize, selectedAccount, paymentType]);

  const handleDeclinePayment = (id: string) => {
    setOpenDropdownId(null);
    // setIsOpen(!isOpen);
    dispatch(declinePayment({
      id: id
    })).then((res)=>{
      if(res.type === "declinePayment/fulfilled"){
        setIsOpen(false);
        switch (typeID) {
          case 1:
            dispatch(
              fetchPayments({
                status: "all",
                page: 1,
                per_page: pageSize,
                start: null,
                end: null,
                min: null,
                max: null,
                type: paymentType === 1 ? 'single': 'bulk',
                id:
            selectedAccount?.type === "main_account"
              ? null
              : selectedAccount?.account_id,
              })
            );
            break;
          case 2:
            dispatch(
              fetchPayments({
                status: "Pending",
                page: 1,
                per_page: pageSize,
                start: null,
                end: null,
                min: null,
                max: null,
                type: paymentType === 1 ? 'single': 'bulk',
                id:
            selectedAccount?.type === "main_account"
              ? null
              : selectedAccount?.account_id,
              })
            );
            break;
          default:
            break;
        }
      }
      else if(res.type === "declinePayment/rejected"){
        setIsOpen(false);
      }
    })
  };

  const handleFilter = () => {
    const start = moment(startDate).format();
    const end = moment(endDate).format();

    switch (typeID) {
      case 1:
        dispatch(
          fetchPayments({
            status: "all",
            page: 1,
            per_page: pageSize,
            start: start,
            end: end,
            min: minAmt === 0 ? null : minAmt,
            max: maxAmt === 0 ? null : maxAmt,
            type: paymentType === 1 ? 'single': 'bulk',
            id:
          selectedAccount?.type === "main_account"
            ? null
            : selectedAccount?.account_id,
          })
        );
        break;
      case 2:
        dispatch(
          fetchPayments({
            status: "Pending",
            page: 1,
            per_page: pageSize,
            start: start,
            end: end,
            min: minAmt === 0 ? null : minAmt,
            max: maxAmt === 0 ? null : maxAmt,
            type: paymentType === 1 ? 'single': 'bulk',
            id:
          selectedAccount?.type === "main_account"
            ? null
            : selectedAccount?.account_id,
          })
        );
        break;
      default:
        break;
    }

    dispatch(
      fetchPayments({
        status: "all",
        page: 1,
        per_page: pageSize,
        start: start,
        end: end,
        min: minAmt === 0 ? null : minAmt,
        max: maxAmt === 0 ? null : maxAmt,
        type: paymentType === 1 ? 'single': 'bulk',
        id:
          selectedAccount?.type === "main_account"
            ? null
            : selectedAccount?.account_id,
      })
    );
  };

  const handleClearFilter = () => {
    setStartDate("");
    setEndDate("");
    setMinAmt(0);
    setMaxAmt(0);
    setIsToggleAmount(false);
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);

    // const start = moment(startDate).format();
    // const end = moment(endDate).format();


    switch (typeID) {
      case 1:
        dispatch(fetchPayments({
          status: "all",
          page: page,
          per_page: pageSize,
          start: null,
          end: null,
          min: minAmt === 0 ? null : minAmt,
          max: maxAmt === 0 ? null : maxAmt,
          type: paymentType === 1 ? 'single': 'bulk',
          id:
              selectedAccount?.type === "main_account"
                ? null
                : selectedAccount?.account_id,
        })
        )
        break;
      case 2:
        dispatch(fetchPayments({
          status: "Pending",
          page: page,
          per_page: pageSize,
          start: null,
          end: null,
          min: minAmt === 0 ? null : minAmt,
          max: maxAmt === 0 ? null : maxAmt,
          type: paymentType === 1 ? 'single': 'bulk',
          id:
              selectedAccount?.type === "main_account"
                ? null
                : selectedAccount?.account_id,
        }))
        break;
      default:
        break;
    } 
  };

  const onPageSizeChange = (page: number) =>{
     setPageSize(page)
  }

  const getStatusBg = (val: string) => {
    let result;
    switch (val) {
      case "Failed":
        result = "failed-badge";
        break;
      case "Pending":
        result = "pending-badge";
        break;
       case "Processing":
          result = "pending-badge";
          break;
      case "Successful":
        result = "success-badge";
        break;
      default:
        result = "failed-badge";
        break;
    }
    return result;
  };

  const getSidebarStatusBg = (val: string) => {
    let result;
    switch (val) {
      case "Failed":
        result =
          "flex bg-[#fdded4] text-[#b92043] rounded-[4px] px-2 py-1 text-xs font-medium";
        break;
      case "Pending":
        result =
          "flex bg-[#fff3ce] text-[#d5a407] rounded-[4px] px-2 py-1 text-xs font-medium";
        break;
      case "Processing":
        result =
          "flex bg-[#fff3ce] text-[#d5a407] rounded-[4px] px-2 py-1 text-xs font-medium";
        break;
      case "Successful":
        result =
          "flex bg-[#DBFFDF] text-[#2B8C34] rounded-[4px] px-2 py-1 text-xs font-medium";
        break;
      default:
        result =
          "flex bg-[#fdded4] text-[#b92043] rounded-[4px] px-2 py-1 text-xs font-medium";
        break;
    }
    return result;
  };

  // const handleRetryPayment = (payment: Payment) =>{
  //   setPayment(payment);
  //   setOpenDropdownId(null);
  //   setIsShow(true)
  // }

  const handleRetryPaymentConfirm = () =>{
    dispatch(retryPayment({
      id: payment ? payment.id: ""
    })).then((res)=>{
      if(res?.type  === "retryPayment/fulfilled"){
        setIsShow(false)
        dispatch(fetchPayments({
          status: "all",
          page: 1,
          per_page: pageSize,
          start: null,
          end: null,
          min: null,
          max: null,
          type: paymentType === 1 ? 'single': 'bulk',
          id:
          selectedAccount?.type === "main_account"
            ? null
            : selectedAccount?.account_id,
        }))
      }
    })
  }

  useEffect(() => {

    switch (typeID) {
      case 1:
        dispatch(
          fetchPayments({
            status: "all",
            page: 1,
            per_page: pageSize,
            start: null,
            end: null,
            min: null,
            max: null,
            type: paymentType === 1 ? 'single': 'bulk',
            id:
          selectedAccount?.type === "main_account"
            ? null
            : selectedAccount?.account_id,
          })
        );
        break;
      case 2:
        dispatch(
          fetchPayments({
            status: "Pending",
            page: 1,
            per_page: pageSize,
            start: null,
            end: null,
            min: null,
            max: null,
            type: paymentType === 1 ? 'single': 'bulk',
            id:
          selectedAccount?.type === "main_account"
            ? null
            : selectedAccount?.account_id,
          })
        );
        break;
      default:
        dispatch(
          fetchPayments({
            status: "all",
            page: 1,
            per_page: pageSize,
            start: null,
            end: null,
            min: null,
            max: null,
            type: paymentType === 1 ? 'single': 'bulk',
            id:
          selectedAccount?.type === "main_account"
            ? null
            : selectedAccount?.account_id,
          })
        );
        break;
    }

  }, [dispatch, selectedAccount,pageSize, typeID,paymentType]);

  return (
    <>


      {/* Confirmation MODAL */}
       <ModalComponent
        title="Retry Payment"
        subtitle=""
        isOpen={isShow}
        onClose={showModal}
      >
        <div>
          <p className="mt-[10px] text-sm font-mediumn leading-5 text-[#5C715E] mb-8">
            Are you sure you want to retry this payment?
          </p>

          <div className="flex justify-end">
            <div className="flex gap-[40px] items-center cursor-pointer">
              <p
                className=" text-sm font-mediumn leading-5 text-[#5C715E]"
                onClick={() => setIsShow(false)}
              >
                Cancel
              </p>
              <button
              disabled={loader}
               onClick={handleRetryPaymentConfirm}
                className=" outline-none text-white rounded-lg bg-[#2B8C34] disabled:bg-[#ABB6AC] hover:bg-[#24752B] text-sm font-mediumn leading-5 px-[20px] py-[10px]"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </ModalComponent>

      <div className="mt-5 bg-white rounded-[8px] min-h-[100px]">
        <div className="flex justify-between items-center w-full">
          <div className="flex gap-6 p-4">
            {/* START DATE */}
            <div>
              <p className="pb-1 text-sm leading-[18px] text-[#344335]">
                Start Date
              </p>
              <input
                type="date"
                max={moment().format("YYYY-MM-DD")}
                value={startDate}
                onChange={(event) => setStartDate(event.target.value)}
                className="px-4 py-3 rounded-lg h-[45px] text-sm text-[#344335] border-[1px] uppercase border-[#96A397] cursor-pointer outline-none focus:border-[#2B8C34] focus:shadow-[0px_0px_0px_2px_#D5E8D6]"
              />
            </div>

            {/* END DATE */}
            <div>
              <p className="pb-1 text-sm leading-[18px] text-[#344335]">
                End Date
              </p>
              <input
                max={moment().format("YYYY-MM-DD")}
                type="date"
                value={endDate}
                onChange={(event) => setEndDate(event.target.value)}
                className="px-4 py-3 rounded-lg h-[45px] text-sm border-[1px] text-[#344335] uppercase border-[#96A397] focus:border-[#2B8C34] focus:shadow-[0px_0px_0px_2px_#D5E8D6] cursor-pointer outline-none"
              />
            </div>

            {/* AMOUNT */}
            <div>
              <p className="pb-1 text-sm leading-[18px] text-[#344335]">
                Amount
              </p>
              <div className="relative">
                <div
                  className={`flex cursor-pointer  h-[45px] justify-between gap-2 items-center w-full rounded-lg border border-[#96A397] bg-white py-3 px-4 text-[#7C8D7E] text-sm`}
                  style={
                    isToggleAmount
                      ? { boxShadow: "0px 0px 0px 2px #D5E8D6" }
                      : {}
                  }
                  onClick={() => setIsToggleAmount(!isToggleAmount)}
                >
                  <div className="flex flex-wrap  gap-2 items-center ">
                    <p className="text-sm  px-1 py-[2px] text-[#344335] flex items-center gap-2">
                      ₦{minAmt?.toLocaleString()} - ₦{maxAmt?.toLocaleString()}
                    </p>
                  </div>

                  <div className="">
                    {isToggleAmount ? (
                      <ExpandLess
                        style={{ fontSize: "20px", color: "#344335" }}
                      />
                    ) : (
                      <ExpandMore
                        style={{ fontSize: "20px", color: "#344335" }}
                      />
                    )}
                  </div>
                </div>

                {isToggleAmount && (
                  <div
                    style={{
                      boxShadow:
                        "0px 16px 32px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.25)",
                    }}
                    className="absolute mt-[11px] bg-white p-2 flex gap-3 items-center justify-between rounded-lg z-30"
                  >
                    <div className="">
                      <p className="pb-1 text-sm leading-[18px] text-[#344335]">
                        Minimum Amount
                      </p>

                      <div className="px-4 py-3 rounded-lg border-[1px] border-[#96A397] bg-white flex items-center text-sm leading-[18px] text-[#5C715E]">
                        <p className="">₦</p>
                        <input
                          min={0}
                          type="number"
                          onChange={(event) =>
                            setMinAmt(Number(event.target.value))
                          }
                          value={minAmt === 0 ? "" : minAmt}
                          className="border-none outline-none max-w-[120px]"
                        />
                      </div>
                    </div>
                    <div className="">
                      <p className="text-xl font-semibold leading-[32px] text-[#344335]">
                        -
                      </p>
                    </div>
                    <div className="">
                      <p className="pb-1 text-sm leading-[18px] text-[#344335]">
                        Maximum Account
                      </p>

                      <div className="px-4 py-3 rounded-lg border-[1px] border-[#96A397] bg-white flex items-center text-sm leading-[18px] text-[#5C715E]">
                        <p className="">₦</p>
                        <input
                          min={0}
                          type="number"
                          onChange={(event) =>
                            setMaxAmt(Number(event.target.value))
                          }
                          value={maxAmt === 0 ? "" : maxAmt}
                          className="border-none outline-none max-w-[120px]"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex gap-2 items-center mr-4">
            <div
              className="rounded-lg px-6 py-3 bg-[#EDF7EE] cursor-pointer"
              onClick={handleFilter}
            >
              <p className=" text-sm font-medium leading-[20px] text-[#2B8C34]">
                Apply Filter
              </p>
            </div>

            <div
              className="rounded-lg px-6 py-3 bg-none cursor-pointer"
              onClick={handleClearFilter}
            >
              <p className=" text-sm font-medium leading-[20px] text-[#2B8C34]">
                Clear Filter
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Drawer component */}
      <DrawerComponent title="" isOpen={isOpen} onClose={showPaymentInfo}>
        <div className="mt-8 flex flex-col justify-center items-center">
          <div className="w-[48px] h-[48px] rounded-full bg-[#DBFFDF] text-[#2B8C34] flex justify-center font-medium items-center text-base">
            {payment?.recipient?.recipient_name?.charAt(0) || "N/A"}
          </div>
          <h4 className="text-[#344335] font-semibold text-2xl mt-2">
            ₦{Number(payment?.amount)?.toLocaleString() || "N/A"}
          </h4>
          <p className="text-[#5C715E] text-sm font-medium mt-1">
          {payment?.recipient?.recipient_name || "N/A"}
          </p>
        </div>

        <div className="mt-10">
          <div className="flex justify-between items-center">
            <p className="text-[#5C715E] text-xs">Amount</p>
            <p className="text-[#5C715E] text-xs">
              ₦{Number(payment?.amount)?.toLocaleString() || "N/A"}
            </p>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex justify-between items-center">
            <p className="text-[#5C715E] text-xs">Date</p>
            <p className="text-[#5C715E] text-xs">
              {moment(payment?.created_at).format("ll")} |
              {" " + moment(payment?.created_at).format("LT")}
            </p>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex justify-between items-center">
            <p className="text-[#5C715E] text-xs">Initiated By</p>
            <p className="text-[#5C715E] text-xs">
              {payment?.initiated_by || "N/A"}
            </p>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex justify-between items-center">
            <p className="text-[#5C715E] text-xs">Bank Name</p>
            <p className="text-[#5C715E] text-xs">
              {" "}
              {payment?.recipient?.bank?.name || "N/A"}
            </p>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex justify-between items-center">
            <p className="text-[#5C715E] text-xs">Account Number</p>
            <p className="text-[#5C715E] text-xs">
              {payment?.recipient?.account_number || "N/A"}
            </p>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex justify-between items-center">
            <p className="text-[#5C715E] text-xs">Account Name</p>
            <p className="text-[#5C715E] text-xs">
              {payment?.recipient?.recipient_name || "N/A"}
            </p>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex justify-between items-center">
            <p className="text-[#5C715E] text-xs">Source Account</p>
            <p className="text-[#5C715E] text-xs">{payment?.sender || "N/A"} Account</p>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex justify-between items-center">
            <p className="text-[#5C715E] text-xs">Status</p>
            <div className={getSidebarStatusBg(payment?.status || "N/A")}>
              {payment?.status || "N/A"}
            </div>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex justify-between items-center">
            <p className="text-[#5C715E] text-xs">Payment ID</p>
            <p className="text-[#5C715E] text-xs">{payment?.id || "N/A"}</p>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex justify-between items-center">
            <p className="text-[#5C715E] text-xs">Approval Status</p>
            <div
              className={
                payment?.approval_status
                  ? "flex bg-[#DBFFDF] text-[#2B8C34] rounded-[4px] px-2 py-1 text-xs font-medium"
                  : "flex bg-[#fff3ce] text-[#d5a407] rounded-[4px] px-2 py-1 text-xs font-medium"
              }
            >
              {payment?.approval_status ? "Approved" : "Not Approved"}
            </div>
          </div>
        </div>
        <div className="mt-5">
          <div className="flex justify-between items-center">
            <p className="text-[#5C715E] text-xs">Approved/Rejected by</p>
            <p className="text-[#5C715E] text-xs">{payment?.handled_by || "N/A"}</p>
          </div>
        </div>
        <div className="mt-5">
          <div className="flex justify-between items-center">
            <p className="text-[#5C715E] text-xs">Payment Memo</p>
            <p className="text-[#5C715E] text-xs">
              {" "}
              {payment?.narration || "N/A"}
            </p>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex justify-between items-center">
            <p className="text-[#5C715E] text-xs">Payment Type</p>
            <div className="flex bg-[#DBFFDF] text-[#2B8C34] rounded-[4px] px-2 py-1 text-xs font-medium">
              Instant
            </div>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex justify-between items-center">
            <p className="text-[#5C715E] text-xs">Document Upload</p>
            {payment?.document 
                ?
              <a href={payment?.document} target="_blank" rel="noreferrer" className="text-[#2B8C34] overflow-hidden cursor-pointer text-ellipsis whitespace-nowrap w-[220px] underline text-xs">
              {" "}
              {payment?.document || "N/A"}
            </a>
            :
            <p className="text-[#5C715E] text-xs">
              N/A
            </p>
            }
          </div>
        </div>

        {payment &&
          payment.status === "Successful" &&
          payment.approval_status && (
            <div className="flex justify-center mt-10">
              <button
                type="submit"
                disabled={loader}
                onClick={() =>
                  handleReceiptDownload(payment && payment.id ? payment.id : "")
                }
                className="bg-primary 
                        disabled:bg-[#ABB6AC]
                        rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
              >
                Download Payment Receipt
              </button>
            </div>
          )}

        {paymentPermissions.approvePayment && payment &&
          payment.status === "Pending" &&
          !payment.approval_status && (
            <div className="flex gap-3 justify-center mt-10">
              <div>
                <button
                  type="submit"
                  disabled={loader}
                  onClick={() =>
                    handleDeclinePayment(
                      payment && payment.id ? payment.id : ""
                    )
                  }
                  className="bg-[#B92043] 
                      disabled:bg-[#B92043] hover:bg-opacity-[0.9] disabled:bg-opacity-[0.5]
                        rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#B92043] transition-all"
                >
                  Reject Payment
                </button>
              </div>
              <div>
                <button
                  type="submit"
                  disabled={loader}
                  onClick={() =>
                    handleApprovePayment(
                      payment && payment.id ? payment.id : ""
                    )
                  }
                  className="bg-primary 
                        disabled:bg-[#ABB6AC]
                        rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
                >
                  Approve Payment
                </button>
              </div>
            </div>
          )}
      </DrawerComponent>

      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          <p className="text-primary font-semibold text-base leading-5">
            Payments
          </p>

          <ul className="flex mt-5 gap-5 items-center">
            {callTypes.map((item) => (
              <li
                key={item.id}
                onClick={() => toggleType(item.id)}
                className={
                  typeID === item.id
                    ? "flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer relative"
                    : "flex gap-1 items-center pb-1 cursor-pointer relative"
                }
              >
                <p
                  className={
                    typeID === item.id
                      ? "text-primary text-sm font-semibold"
                      : "text-[#96A397] text-sm font-semibold"
                  }
                >
                  {item.title}
                </p>
                {item.id === 2 && payments?.length > 0 && (
                  <div className="bg-[#B92043] w-[4px] h-[4px] rounded-full absolute -right-1 top-0"></div>
                )}
              </li>
            ))}
          </ul>
        </div>

        {/* tables */}
        <div className="relative">
          <div className="relative">
            {loading ? (
              <div>
                <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
                  <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                  <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                  <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                  <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                  <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
                </div>
              </div>
            ) : (
              <>
                {payments?.length === 0 ? (
                  <div>
                    <div className="w-full pb-[80px] mt-[80px] flex items-center justify-center">
                      <p>There's no payment data yet</p>
                    </div>
                  </div>
                ) : (
                  <section>
                    <table className="w-full text-left">
                      <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                        <tr>
                          <th scope="col" className="px-4 py-4 font-semibold ">
                            Date
                          </th>
                          <th scope="col" className="px-4 py-4 font-semibold">
                            Recipient
                          </th>
                          <th scope="col" className="px-4 py-4 font-semibold">
                            Payment Memo
                          </th>
                          <th scope="col" className="px-4 py-4 font-semibold">
                            Status
                          </th>
                          <th scope="col" className="px-4 py-4 font-semibold">
                            Amount
                          </th>
                          <th scope="col" className="px-4 py-4 font-semibold">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {payments?.map((payment, index) => {
                          return (
                            <tr
                              key={index}
                              className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                            >
                              <th
                                scope="row"
                                className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                              >
                                {moment(payment?.created_at).format("lll")}
                              </th>
                              <td className="py-4 px-4 text-[#5C715E]">
                                {payment?.recipient?.recipient_name || "N/A"}
                              </td>
                              <td className="py-4 px-4 text-[#5C715E]">
                                {payment?.narration || "N/A"}
                              </td>
                              <td className="py-4 px-4 text-[#5C715E]">
                                <div className="flex">
                                  <div
                                    className={getStatusBg(
                                      payment?.status || "N/A"
                                    )}
                                  >
                                    <p>{payment?.status || "N/A"}</p>
                                  </div>
                                </div>
                              </td>
                              <td className="py-4 px-4 text-[#5C715E]">
                                ₦{payment ? formatNumber(payment?.amount): 0}
                              </td>
                              <td className="py-4 px-4 text-[#5C715E]">
                                <div className="relative">
                                  <img
                                    src={more_icon}
                                    alt="more icon"
                                    onClick={() => toggleDropdown(payment?.id)}
                                  />

                                  {openDropdownId === payment?.id && (
                                    <ul className="bg-white z-[6] rounded-lg shadow-dropdown py-2 px-2 absolute right-10 top-6 min-w-[187px]">
                                      <li
                                        onClick={() =>
                                          handlePaymentInfo(payment)
                                        }
                                        className="py-3 px-2 cursor-pointer hover:bg-[#EDF7EE]"
                                      >
                                        <p className="text-[#344335] text-sm font-medium">
                                          View Payment
                                        </p>
                                      </li>
                                     {
                                      payment?.status === "Successful" &&
                                      <li
                                        onClick={() =>
                                          handleReceiptDownload(payment.id)
                                        }
                                        className="py-3 px-2 cursor-pointer hover:bg-[#EDF7EE]"
                                      >
                                        <p className="text-[#344335] text-sm font-medium">
                                          Download Receipt
                                        </p>
                                      </li>
                                      }
                                     {/* {payment?.status === 'Failed' && 
                                      <li
                                        onClick={() => handleRetryPayment(payment)}
                                        className="py-3 px-2 cursor-pointer hover:bg-[#EDF7EE]"
                                      >
                                        <p className="text-[#344335] text-sm font-medium">
                                          Retry Payment
                                        </p>
                                      </li>
                                      } */}
                                    </ul>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    <div>
                      <PaginationComponent
                        page={currentPage}
                        onPageChange={(page) => onPageChange(page)}
                        totalItems={total}
                        pageSize={pageSize}
                        onPageSizeChange={(page) => onPageSizeChange(page)}
                        pageSizeShow
                      />
                    </div>
                  </section>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentsTable;
