import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { TransactionsState } from "../../types/Transactions/Transactions";
import {
  getAllRecentTransactions,
  getAllTransactions,
} from "./transactionActions";

const initialState: TransactionsState = {
  transactions: [],
  loading: false,
  total: 0,
  recentTransactions: [],
  recentTransactionsLoading: false,
};

// Configure Redux Persist
const authPersistConfig = {
  key: "transactions",
  storage,
  blacklist: ["loading"],
  // Add any additional configuration options as needed
};

export const transactionSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get all transactions
    builder.addCase(getAllTransactions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getAllTransactions.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.total = action.payload.data.count;
        state.transactions = action.payload.data.data;
      }
    );
    builder.addCase(getAllTransactions.rejected, (state) => {
      state.loading = false;
    });

    // get recent transactions
    builder.addCase(getAllRecentTransactions.pending, (state) => {
      state.recentTransactionsLoading = true;
    });
    builder.addCase(
      getAllRecentTransactions.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.recentTransactionsLoading = false;
        state.recentTransactions = action.payload.data;
      }
    );
    builder.addCase(getAllRecentTransactions.rejected, (state) => {
      state.recentTransactionsLoading = false;
    });
   
  },
});

const transactionsReducer = persistReducer(
  authPersistConfig,
  transactionSlice.reducer
);
// export const {} = transactionSlice.actions;

export default transactionsReducer;
